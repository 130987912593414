import React, { FC } from 'react';
import { ReactComponent as RequiredIcon } from '../../Icons/required_field_default.svg';
import { OutlinedTextFieldProps, SxProps, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface TextInputProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  required?: boolean;
  postfix?: React.ReactElement;
}

export const OutlinedTextInput: FC<TextInputProps> = ({
  required,
  postfix,
  sx,
  InputProps,
  ...props
}: TextInputProps) => {
  const textFieldSx = React.useMemo(() => {
    return sx ? { ...innerSx, ...sx } : innerSx;
  }, [sx]);

  return (
    <TextField
      sx={textFieldSx}
      variant="outlined"
      InputProps={{
        endAdornment: (required && <RequiredIcon />) || postfix || null,
        ...(InputProps || null),
      }}
      {...props}
    />
  );
};

const innerSx: Record<string, SxProps<Theme>> = {
  formInput: {
    width: '100%',

    '& .Mui-focused svg > *': {
      fill: (theme) => theme.palette.primary.main,
    },

    '& .Mui-error svg > *': {
      fill: (theme) => theme.palette.error.main,
    },

    '& .MuiInputLabel-outlined': {
      color: 'grey.500',
    },
  },
};
