import { SxProps, Theme } from '@mui/material/styles';

export const ArtMoneyDistributionSx: Record<string, SxProps<Theme>> = {
  distributionBox: {
    bgcolor: 'grey.100',
    borderRadius: '5px',
    p: 2,
    mt: 1.25,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& svg': {
      color: 'secondary.active',
      ml: 0.5,
    },
  },
};
