import React from 'react';
import { useCreateArtReducerContext } from './useCreateEditArtReducer';
import ChooseType from './Steps/ChooseType';
import ArtInfo from './Steps/ArtInfo';
import SaleInfo from './Steps/SaleInfo/SaleInfo';
import useIsMobileScreen from 'helpers/useIsMobileScreen';

const CurrentStep: React.FC = () => {
  const { state } = useCreateArtReducerContext();

  switch (state.currentStep) {
    case 'FILL_ART_INFO':
      return <ArtInfo />;
    case 'FILL_SALE_INFO':
      return <SaleInfo />;
    default:
      return <ChooseType />;
  }
};

export default CurrentStep;
