import { Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import ga from './ga';
import { adminPanelRoutes, routes } from './app-settings';
import { AuthRoles, RequireRole, WalletRoles } from './components/RequireRole/RequireRole';
import { SignIn } from './pages/SignIn/SignIn';
import { SignUp } from './pages/SignUp/SignUp';
import { NoWalletSignIn } from './pages/NoWalletSignIn/NoWalletSignIn';
import { WalletSignInRequired } from './pages/WalletSignInRequired/WalletSignInRequired';
import { ConnectWallet } from './pages/ConnectWallet/ConnectWallet';
import { WrongWallet } from './pages/WrongWallet/WrongWallet';
import { Home } from './pages/Home';
import { MarketplacePage } from './pages/Marketplace/MarketplaceWrapper';
import { ProfilePage } from './pages/Profile/Layouts/ProfilePage';
import { EditProfilePage } from './pages/Profile/Layouts/EditProfilePage';
import CreateArtPage from './pages/CreateEditArtPage/CreateArtPage';
import EditArtPage from './pages/CreateEditArtPage/EditArtPage';
import { SecondarySale } from './pages/SecondarySale/SecondarySale';
import { ArtPage } from './pages/Art/ArtPage';
import TopArtistsPage from './pages/TopArtists/TopArtistsPage';
import AdminPanelCreatedArtsByArtist from './pages/AdminPanel/AdminPanelCreatedArts/AdminPanelCreatedArtsByArtist';
import { AdminPanelArtsReorder } from './pages/AdminPanel/AdminPanelCreatedArts/AdminPanelArtsReorder';
import AdminPanelFeaturedArtistsAdd from './pages/AdminPanel/AdminPanelFeaturedArtists/AdminPanelFeaturedArtistsAdd';
import { AdminPanelFeaturedArtistReorder } from './pages/AdminPanel/AdminPanelFeaturedArtists/AdminPanelFeaturedArtistReorder';
import { AdminPanelEventsReorder } from './pages/AdminPanel/AdminPanelEvents/AdminPanelEventsReorder';
import { AdminPanelEventsCreate } from './pages/AdminPanel/AdminPanelEvents/AdminPanelEventsCreate';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import AdminPanelUsers from './pages/AdminPanel/AdminPanelUsers/AdminPanelUsers';
import AdminPanelCreatedArts from './pages/AdminPanel/AdminPanelCreatedArts/AdminPanelCreatedArts';
import AdminPanelListOfArtistsWithArts from './pages/AdminPanel/AdminPanelCreatedArts/AdminPanelListOfArtistsWithArts';
import AdminPanelCreatedArtsExplore from './pages/AdminPanel/AdminPanelCreatedArts/AdminPanelCreatedArtsExplore';
import { AdminPanelFinalize } from './pages/AdminPanel/AdminPanelFinalize/AdminPanelFinalize';
import { AdminPanelEvents } from './pages/AdminPanel/AdminPanelEvents/AdminPanelEvents';
import AdminPanelFeaturedArtists from './pages/AdminPanel/AdminPanelFeaturedArtists/AdminPanelFeaturedArtists';
import { AdminPanelMoneyDistribution } from './pages/AdminPanel/AdminPanelMoneyDistribution/AdminPanelMoneyDistribution';
import { AdminPanelMoneyDistributionPrimary } from './pages/AdminPanel/AdminPanelMoneyDistribution/AdminPanelMoneyDistributionPrimary';
import { AdminPanelMoneyDistributionSecondary } from './pages/AdminPanel/AdminPanelMoneyDistribution/AdminPanelMoneyDistributionSecondary';
import { NotFound } from './pages/NotFound';
import AdminPanelGifts from './pages/AdminPanel/AdminPanelCreatedArts/AdminPanelGifts';
import AdminPanelAccessRequests from './pages/AdminPanel/AdminPanelAccessRequests';
import { EmailVerification } from 'pages/EmailVerification/EmailVerification';
import { ChangePassword } from 'pages/ChangePassword/ChangePassword';
import { ForgotPassword } from 'pages/ForgotPassword/ForgotPassword';

export default function Router() {
  const location = useLocation();

  useEffect(() => {
    ga.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    });
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        path={`${routes.emailVerification}/:verificationId`}
        element={
          <RequireRole allowedFor={'GUEST'}>
            <EmailVerification />
          </RequireRole>
        }
      />
      <Route
        path={routes.connect}
        element={
          <RequireRole allowedFor={'GUEST'}>
            <SignIn />
          </RequireRole>
        }
      />
      <Route
        path={routes.signUp}
        element={
          <RequireRole allowedFor={'GUEST'} redirectTo={routes.connectWallet}>
            <SignUp />
          </RequireRole>
        }
      />
      <Route
        path={routes.noWalletSignIn}
        element={
          <RequireRole allowedFor={'GUEST'}>
            <NoWalletSignIn />
          </RequireRole>
        }
      />
      <Route
        path={routes.walletSignInRequired}
        element={
          <RequireRole allowedFor={'GUEST'}>
            <WalletSignInRequired />
          </RequireRole>
        }
      />
      <Route
        path={routes.connectWallet}
        element={
          <RequireRole allowedFor={['VISITOR']}>
            <ConnectWallet />
          </RequireRole>
        }
      />
      <Route path={`${routes.changePassword}/:userId/`}>
        <Route
          path={''}
          element={
            <RequireRole allowedFor={['VISITOR']}>
              <ChangePassword />
            </RequireRole>
          }
        />
        <Route path={':confirmationCode'} element={<ChangePassword />} />
      </Route>
      <Route path={`${routes.forgotPassword}`} element={<ForgotPassword />} />
      <Route path={routes.wrongWallet}>
        <Route
          index
          element={
            <RequireRole allowedFor={'GUEST'}>
              <WrongWallet />
            </RequireRole>
          }
        />
        <Route
          path={':wallet'}
          element={
            <RequireRole allowedFor={'GUEST'}>
              <WrongWallet />
            </RequireRole>
          }
        />
      </Route>
      <Route path={routes.home} element={<Home />} />
      <Route path={routes.marketplace} element={<MarketplacePage />} />
      <Route path={`${routes.userProfile}/:wallet`} element={<ProfilePage />} />
      <Route
        path={`${routes.userProfile}/:wallet/edit`}
        element={
          <RequireRole allowedFor={WalletRoles}>
            <EditProfilePage />
          </RequireRole>
        }
      />
      <Route
        path={`${routes.createArt}/:wallet`}
        element={
          <RequireRole allowedFor={['PM', 'SUPERADMIN']}>
            <CreateArtPage />
          </RequireRole>
        }
      />
      <Route
        path={`${routes.editArt}/:artId`}
        element={
          <RequireRole allowedFor={['PM', 'SUPERADMIN']}>
            <EditArtPage />
          </RequireRole>
        }
      />
      <Route path={`${routes.secondarySale}/:artId/`}>
        <Route
          index
          element={
            <RequireRole allowedFor={WalletRoles}>
              <SecondarySale />
            </RequireRole>
          }
        />
        <Route
          path={`:ownerWallet`}
          element={
            <RequireRole allowedFor={WalletRoles}>
              <SecondarySale />
            </RequireRole>
          }
        />
      </Route>
      <Route path={`${routes.art}/:artId`} element={<ArtPage />} />
      <Route path={routes.topArtists} element={<TopArtistsPage />} />
      <Route
        path={`${routes.adminPanelCreatedArtsWorks}/:wallet/:worksType`}
        element={
          <RequireRole allowedFor={'SUPERADMIN'}>
            <AdminPanelCreatedArtsByArtist />
          </RequireRole>
        }
      />
      <Route
        path={routes.adminPanelCreatedArtsExploreReorder}
        element={
          <RequireRole allowedFor={'SUPERADMIN'}>
            <AdminPanelArtsReorder />
          </RequireRole>
        }
      />
      <Route
        path={routes.adminPanelFeaturedArtistAdd}
        element={
          <RequireRole allowedFor={'SUPERADMIN'}>
            <AdminPanelFeaturedArtistsAdd />
          </RequireRole>
        }
      />
      <Route
        path={routes.adminPanelFeaturedArtistReorder}
        element={
          <RequireRole allowedFor={'SUPERADMIN'}>
            <AdminPanelFeaturedArtistReorder />
          </RequireRole>
        }
      />
      <Route
        path={routes.adminPanelEventsReorder}
        element={
          <RequireRole allowedFor={'SUPERADMIN'}>
            <AdminPanelEventsReorder />
          </RequireRole>
        }
      />
      <Route
        path={routes.adminPanelEventsCreate}
        element={
          <RequireRole allowedFor={'SUPERADMIN'}>
            <AdminPanelEventsCreate />
          </RequireRole>
        }
      />
      <Route
        path={routes.adminPanel}
        element={
          <RequireRole allowedFor={'SUPERADMIN'}>
            <AdminPanel />
          </RequireRole>
        }
      >
        <Route index element={<AdminPanelUsers />} />
        <Route path={adminPanelRoutes.users} element={<AdminPanelUsers />} />
        <Route path={adminPanelRoutes.createdArts} element={<AdminPanelCreatedArts />}>
          <Route index element={<AdminPanelListOfArtistsWithArts worksType={'published'} />} />
          <Route
            path={adminPanelRoutes.createdArtsPublished}
            element={<AdminPanelListOfArtistsWithArts worksType={'published'} />}
          />
          <Route path={adminPanelRoutes.createdArtsGifts} element={<AdminPanelGifts />} />

          <Route path={adminPanelRoutes.createdArtsExplore} element={<AdminPanelCreatedArtsExplore />} />
          <Route
            path={adminPanelRoutes.createdArtsDrafts}
            element={<AdminPanelListOfArtistsWithArts worksType={'drafts'} />}
          />
        </Route>
        <Route path={adminPanelRoutes.finalizeAuction} element={<AdminPanelFinalize />} />
        <Route path={adminPanelRoutes.events} element={<AdminPanelEvents />} />
        <Route path={adminPanelRoutes.featuredArtist} element={<AdminPanelFeaturedArtists />} />
        <Route path={adminPanelRoutes.moneyDistribution} element={<AdminPanelMoneyDistribution />}>
          <Route index element={<AdminPanelMoneyDistributionPrimary />} />
          <Route path={adminPanelRoutes.moneyDistributionPrimary} element={<AdminPanelMoneyDistributionPrimary />} />
          <Route
            path={adminPanelRoutes.moneyDistributionSecondary}
            element={<AdminPanelMoneyDistributionSecondary />}
          />
        </Route>
        <Route path={adminPanelRoutes.accessRequests} element={<AdminPanelAccessRequests />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
