import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { getFieldErrorNames } from 'helpers/getFieldErrorNames';
import { scrollToError } from 'helpers/scrollToError';

interface Props {
  formikProps: FormikProps<any>;
}

export const ScrollToFieldErrors = ({ formikProps }: Props) => {
  const { submitCount, isValid, errors } = formikProps;

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);

    scrollToError(fieldErrorNames);
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
