import React, { FC } from 'react';
import { Container } from '@mui/material';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header';
import { ProfileContext, useUserProfileReducer } from './State/useProfileReducer';

export const ProfilePageWrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useUserProfileReducer();
  return (
    <BaseLayout>
      <Container fixed sx={{ height: '100%', margin: '0 auto' }}>
        <Header />
        <ProfileContext.Provider value={{ state, dispatch }}>{children}</ProfileContext.Provider>
      </Container>
      <Footer />
    </BaseLayout>
  );
};
