export const scrollToError = (fieldErrorNames: string[]) => {
  if (fieldErrorNames.length <= 0) return;

  const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);

  if (!element) return;

  let scrollTop = window.scrollY || element.scrollTop;

  const finalOffset = element.getBoundingClientRect().top + scrollTop - document.documentElement.clientHeight / 2;

  // Scroll to first known error into view
  window.parent.scrollTo({
    top: finalOffset,
    behavior: 'smooth',
  });
};
