import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Checkbox, SxProps, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CheckCard from 'components/UiKit/CheckCrard/CheckCard';
import { Formik, FormikProps, FormikTouched } from 'formik';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import Select from 'components/UiKit/Select/Select';
import { useCreateArtReducerContext } from '../../useCreateEditArtReducer';
import { MobileDateTimePicker } from '@mui/lab';
import { useApiContext } from 'contexts/ApiContext';
import {
  Art,
  ArtBodyTokenTypeEnum,
  CreateAuctionRequestDto,
  CreateAuctionRequestDtoStatusEnum,
  CreateBuyNowDto,
  CreateBuyNowDtoStatusEnum,
} from 'codegen-api/api-totemo-service/models';
import { useSingleContract } from 'abi/useSingleContract';
import { SuccessfullyPublished } from 'components/Modal/SuccessfullyPublished/SuccessfullyPublished';
import { ArtFormRef, ArtStatus, createArtLocalStorage, SaleInfoForm } from '../../types';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { localStorageKeys, routes, startDateOptions } from 'app-settings';
import { CancelModal } from 'components/Modal/CancelModal/CancelModal';
import { Transition } from 'history';
import { addServiceFee, getFullPriceEth } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { saleValidation } from '../../validations';
import MoneyDistribution from './MoneyDistribution';
import { useAppState } from 'contexts/appState/AppContext';
import { useBlocker } from 'hooks/useBlocker';
import useSaleInfoForm from './useSaleInfoForm';
import { noop } from 'helpers';
import { useMultipleContract } from 'abi/useMultipleContract';
import { getFieldErrorNames } from 'helpers/getFieldErrorNames';
import { scrollToError } from 'helpers/scrollToError';
import useIsMobileScreen from 'helpers/useIsMobileScreen';

interface Props {
  artFormRef?: ArtFormRef;
}

const SaleInfo: React.FC<Props> = ({ artFormRef }) => {
  const { t } = useTranslation();
  const [openSuccessModalId, setOpenSuccessModalId] = useState<number | null>(null);
  const [commonError, setCommonError] = useState('');
  const [storedForm, setStoredForm] = useLocalStorage<createArtLocalStorage | null>(localStorageKeys.createArt, null);
  const { wallet } = useParams<{ wallet: string }>();
  const { currentUser } = useAppState();
  const { art: artApi, buyNow: buyNowApi, auction: auctionApi } = useApiContext();
  const { state, dispatch } = useCreateArtReducerContext();
  const {
    createSaleVoucher: createSingleSaleVoucher,
    createAuctionVoucher: createSingleAuctionVoucher,
    mint: mintSingle,
  } = useSingleContract();
  const {
    createSaleVoucher: createMultipleSaleVoucher,
    mint: mintMultiple,
  } = useMultipleContract();
  const navigate = useNavigate();
  const isMobile = useIsMobileScreen();
  const [navigationTx, setNavigationTx] = useState<Transition | null>(null);
  const handleCancelClose = () => setNavigationTx(null);
  const formValues = useSaleInfoForm(storedForm);

  const mapMoneyDistribution = useCallback(
    (saleInfoForm) => ({
      primarySale: {
        ...(saleInfoForm.includeOptionalAddress
          ? {
            address: saleInfoForm.primaryAddress,
            addressPercentage: saleInfoForm.primaryAddressPercentage,
          }
          : {}),
        artistPercentage: saleInfoForm.primaryArtistPercentage,
        servicePercentage: saleInfoForm.primaryServiceFee,
        totemoPercentage: saleInfoForm.primaryTotemoPercentage,
      },
      secondarySale: {
        artistPercentage: saleInfoForm.secondaryArtistPercentage,
        sellerPercentage: saleInfoForm.secondarySellerPercentage,
        servicePercentage: saleInfoForm.secondaryServiceFee,
        totemoPercentage: saleInfoForm.secondaryTotemoPercentage,
      },
    }),
    [],
  );

  const createAuction = async (art: Art, sale: SaleInfoForm, signature: string) => {
    try {
      // @ts-ignore
      const request: CreateAuctionRequestDto = {
        artId: art.id,
        ownerWallet: state.isEdit ? state.art?.authorWallet : wallet!,
        authorWallet: art.authorWallet,
        startDate: getUnixTime(sale.startDate),
        endDate: getUnixTime(sale.endDate!),
        startPrice: +getFullPriceEth(String(sale!.startingPrice), String(sale.primaryServiceFee)),
        isFirstSale: true,
        signature,
        //TODO: replce with correct status
        status: CreateAuctionRequestDtoStatusEnum.ACTIVE,
        assetContract: process.env.REACT_APP_SINGLE_CONTRACT,
        pmWallet: currentUser.wallet,
      };
      await auctionApi.auctionControllerCreate(request);
    } catch (e) {
      throw e;
    }
  };

  const createBuyNow = async (art: Art, sale: SaleInfoForm, signature: string) => {
    try {
      const buyNowDTO: CreateBuyNowDto = {
        artId: art.id,
        ownerWallet: state.isEdit ? state.art?.authorWallet : wallet!,
        authorWallet: art.authorWallet,
        startMarketDate: getUnixTime(sale!.startDate),
        marketPrice: +getFullPriceEth(String(sale!.price), String(sale.primaryServiceFee)),
        amount: +sale!.copies,
        isFirstSale: true,
        signature,
        status: CreateBuyNowDtoStatusEnum.ACTIVE,
        pmWallet: currentUser.wallet,
      };
      await buyNowApi.buyNowControllerCreate(buyNowDTO);
    } catch (e) {
      throw e;
    }
  };

  const signMultipleVoucher = async (saleInfoForm: SaleInfoForm) => {
    const tokenId = state.art?.tokenId ? state.art?.tokenId : Date.now();
    const authorWallet = state.isEdit ? state.art?.authorWallet : wallet!;
    const signature = await createMultipleSaleVoucher(
      tokenId,
      authorWallet,
      currentUser.wallet,
      saleInfoForm,
      authorWallet,
    );

    return { tokenId, signature };
  };

  const signSingleVoucher = async (saleInfoForm: SaleInfoForm) => {
    const tokenId = state.art?.tokenId ? state.art?.tokenId : Date.now();
    const authorWallet = state.isEdit ? state.art?.authorWallet : wallet!;
    const signature =
      saleInfoForm.saleType === 'buy'
        ? await createSingleSaleVoucher(tokenId, authorWallet, currentUser.wallet, saleInfoForm, authorWallet)
        : await createSingleAuctionVoucher(tokenId, authorWallet, currentUser.wallet, saleInfoForm, authorWallet);
    return { tokenId, signature };
  };

  const updateArt = async (saleInfoForm: SaleInfoForm, status: ArtStatus) => {
    const file = state.artInfoForm?.files[0]!;
    const fileName = file ? `${Date.now()}${file.name}` : '';
    const { art, artInfoForm } = state;

    const { data: artRes } = await artApi.artControllerUpdateDraft(
      art?.id,
      // @ts-ignore
      file ? file : '',
      state.artInfoForm?.coverFiles?.[0] ? state.artInfoForm?.coverFiles[0] : '',
      artInfoForm?.title,
      artInfoForm?.description,
      artInfoForm?.location,
      artInfoForm?.event?.id ? artInfoForm?.event?.id.toString() : '',
      // @ts-ignore
      JSON.stringify([...state.artInfoForm?.tags]),
      saleInfoForm!.copies,
      fileName,
      JSON.stringify(mapMoneyDistribution(saleInfoForm)),
      status,
    );

    return artRes;
  };

  const createArt = async (tokenId: number, saleInfoForm: SaleInfoForm, status: ArtStatus) => {
    setStoredForm({
      ...(storedForm || {}),
      saleInfoForm,
    });

    const file = state.artInfoForm?.files[0]!;
    const fileName = `${Date.now()}${file?.name}`;

    const { data: artRes } = await artApi.artControllerCreate(
      // @ts-ignore
      file,
      state.artInfoForm?.coverFiles?.[0] ? state.artInfoForm?.coverFiles[0] : '',
      state.type === 'SINGLE' ? ArtBodyTokenTypeEnum.ERC721 : ArtBodyTokenTypeEnum.ERC1155,
      wallet,
      wallet,
      state.artInfoForm?.title,
      tokenId.toString(),
      state.artInfoForm?.description,
      state.artInfoForm?.event?.id ? state.artInfoForm?.event?.id.toString() : '',
      state.artInfoForm?.location,
      // @ts-ignore
      JSON.stringify([...state.artInfoForm?.tags]),
      saleInfoForm!.copies,
      fileName,
      JSON.stringify(mapMoneyDistribution(saleInfoForm)),
      status,
      saleInfoForm.isGift,
    );

    return artRes;
  };

  const createSale = async (artRes: Art, saleInfoForm: SaleInfoForm, signature: string) => {
    if (saleInfoForm.saleType === 'buy' || saleInfoForm.isGift) {
      await createBuyNow(artRes, saleInfoForm, signature);
    } else {
      await createAuction(artRes, saleInfoForm, signature);
    }
  };

  const validateForm = async (formik: FormikProps<SaleInfoForm>) => {
    const errors = await formik.validateForm();

    const fieldErrorNames = getFieldErrorNames(errors);

    scrollToError(fieldErrorNames);

    if (Object.keys(errors).length) {
      formik.setTouched(errors as unknown as FormikTouched<SaleInfoForm>, true);
      throw new Error('Validation error');
    }

    const { values } = formik;
    const primaryMoneyDistributionPercentageSummary = values.includeOptionalAddress
      ? +values['primaryArtistPercentage'] + +values['primaryTotemoPercentage'] + +values['primaryAddressPercentage']
      : +values['primaryArtistPercentage'] + +values['primaryTotemoPercentage'];
    const secondaryMoneyDistributionPercentageSummary =
      +values['secondarySellerPercentage'] +
      +values['secondaryArtistPercentage'] +
      +values['secondaryTotemoPercentage'];

    if (primaryMoneyDistributionPercentageSummary !== 100 || secondaryMoneyDistributionPercentageSummary !== 100) {
      setCommonError(
        values.includeOptionalAddress ? 'forms.errors.artistTotemoOptionalAddress100' : 'forms.errors.artistTotemo100',
      );
      throw new Error('Validation error');
    } else {
      setCommonError('');
    }
  };

  const validateMultiple = async (formik: FormikProps<SaleInfoForm>) => {
    if (state.type === 'MULTIPLE') {
      formik.setTouched({ copies: true }, true);
    }

    const errors = await formik.validateForm();

    if (Object.keys(errors).length) {
      throw new Error('Validation error');
    }
  };

  const handlePublish = async (formik: FormikProps<SaleInfoForm>) => {

    try {
      if (artFormRef) {
        const infoSubmitted = await artFormRef.current?.submitForm();
        if (!infoSubmitted) return;
      }

      dispatch({ type: 'LOADING', payload: true });

      await validateForm(formik);

      const { values } = formik;
      const { tokenId, signature } =
        state.type === 'MULTIPLE' ? await signMultipleVoucher(values) : await signSingleVoucher(values);

      if (values.isGift) {
        if (state.type === 'MULTIPLE') {
          await mintMultiple(currentUser.wallet, tokenId, values.copies);
        } else {
          await mintSingle(currentUser.wallet, tokenId);
        }
      }

      const artRes = await (state.isEdit ? updateArt(values, 'PUBLISHED') : createArt(tokenId, values, 'PUBLISHED'));

      await createSale(artRes, values, signature);

      dispatch({ type: 'LOADING', payload: false });
      setStoredForm(null);
      setOpenSuccessModalId(artRes.id);
    } catch (e) {
      dispatch({ type: 'LOADING', payload: false });
      console.error(e);
    }
  };

  const handleWithStatus = async (formik: FormikProps<SaleInfoForm>, status: ArtStatus) => {
    try {
      dispatch({ type: 'LOADING', payload: true });
      const { values } = formik;
      await validateMultiple(formik);

      const tokenId = Date.now();
      const artRes = await (state.isEdit ? updateArt(values, status) : createArt(tokenId, values, status));

      dispatch({ type: 'LOADING', payload: false });
      setStoredForm(null);
      setOpenSuccessModalId(artRes.id);
    } catch (e) {
      dispatch({ type: 'LOADING', payload: false });
      console.error(e);
    }
  };

  const saleInfoValidationSchema = useMemo(() => saleValidation(t), [t]);

  const blocker = useCallback(
    (tx: Transition) => {
      if (!openSuccessModalId) {
        setNavigationTx(tx);
      } else {
        tx.retry();
      }
    },
    [openSuccessModalId],
  );

  useBlocker(blocker);
  return (
    <Box sx={sx.container}>
      <Formik<SaleInfoForm>
        initialValues={formValues}
        validationSchema={saleInfoValidationSchema}
        onSubmit={noop}
        enableReinitialize={true}
      >
        {(props) => (
          <React.Fragment>
            <form onChange={props.handleChange}>
              {!props.values.isGift && (
                <Box sx={sx.fields}>
                  <Typography variant="h4" sx={sx.title}>
                    {t('pages.createArt.putOnMarketplace')}
                  </Typography>

                  <Box sx={sx.marketType}>
                    <CheckCard
                      svgName="buy-now"
                      label="pages.createArt.buyNow"
                      value={props.values.saleType === 'buy'}
                      onChange={() => props.setFieldValue('saleType', 'buy')}
                    />
                    <CheckCard
                      svgName="auction"
                      label="pages.createArt.auction"
                      value={props.values.saleType === 'auction'}
                      onChange={() => state.type === 'SINGLE' && props.setFieldValue('saleType', 'auction')}
                    />
                  </Box>

                  <Typography variant="h4" sx={sx.textBlock}>
                    {t('pages.createArt.financeConditions')}
                  </Typography>

                  {props.values['saleType'] === 'auction' ? (
                    <Box sx={sx.inputIndent}>
                      <OutlinedTextInput
                        sx={sx.input}
                        name="startingPrice"
                        id="startingPrice"
                        value={props.values['startingPrice']}
                        label={t('pages.createArt.startingPrice')}
                        error={!!(props.touched.startingPrice && props.errors.startingPrice)}
                        helperText={props.touched.startingPrice && t(props.errors.startingPrice as string)}
                        postfix={
                          <Typography variant="body1" color="grey.500">
                            ETH
                          </Typography>
                        }
                      />
                    </Box>
                  ) : (
                    <Box sx={sx.inputIndent}>
                      <OutlinedTextInput
                        sx={sx.input}
                        name="price"
                        id="price"
                        value={props.values['price']}
                        label={t('pages.createArt.price')}
                        error={!!(props.touched.price && props.errors.price)}
                        helperText={props.touched.price && t(props.errors.price as string)}
                        postfix={
                          <Typography variant="body1" color="grey.500">
                            ETH
                          </Typography>
                        }
                      />
                    </Box>
                  )}

                  {props.values['saleType'] === 'buy' && (
                    <>
                      <Box sx={sx.betweenBox}>
                        <Typography variant="subtitle2" color="grey.700">
                          {t('pages.createArt.serviceFee')}
                        </Typography>
                        <Typography variant="subtitle2" sx={sx.textBold}>
                          {`+${props.values.primaryServiceFee}%`}
                        </Typography>
                      </Box>

                      <Box sx={sx.betweenBox}>
                        <Typography variant="subtitle2" color="grey.700">
                          {t('pages.createArt.finalPrice')}
                        </Typography>
                        <Typography variant="subtitle2" sx={sx.textBold}>
                          {`${addServiceFee(props.values.price, props.values.primaryServiceFee)} ETH`}
                        </Typography>
                      </Box>
                    </>
                  )}

                  {state.type === 'MULTIPLE' && (
                    <>
                      <Typography variant="h4" sx={sx.textBlock}>
                        {t('pages.createArt.amountOfCopies')}
                      </Typography>

                      <Box sx={sx.inputIndent}>
                        <OutlinedTextInput
                          sx={sx.input}
                          name="copies"
                          id="copies"
                          value={props.values['copies']}
                          label={t('pages.createArt.copies')}
                          error={!!(props.touched.copies && props.errors.copies)}
                          helperText={props.touched.copies && t(props.errors.copies as string)}
                        />
                      </Box>
                    </>
                  )}

                  <Typography variant="h4" sx={sx.textBlock}>
                    {props.values['saleType'] === 'buy' ? t('pages.createArt.startDate') : t('pages.createArt.duration')}
                  </Typography>

                  <Box sx={sx.inputIndent}>
                    <Select
                      items={startDateOptions}
                      name="startDateTypeSelector"
                      value={props.values.startDateType}
                      onChange={(e) => props.setFieldValue('startDateType', e.target.value)}
                    />
                  </Box>

                  {props.values.startDateType === 'specific' && (
                    <Box sx={sx.inputGroup}>
                      <MobileDateTimePicker
                        ampm={false}
                        label={t('pages.createArt.startDate')}
                        value={props.values['startDate']}
                        onChange={(e: Date | null) => {
                          props.setFieldValue('startDate', e);
                        }}
                        disablePast={true}
                        maxDateTime={props.values.saleType === 'auction' ? props.values.endDate : undefined}
                        inputFormat="yyyy-MM-dd H:mm"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                  )}

                  {props.values['saleType'] === 'auction' && (
                    <Box sx={sx.inputGroup}>
                      <MobileDateTimePicker
                        ampm={false}
                        label={t('pages.createArt.endDate')}
                        value={props.values['endDate']}
                        onChange={(e: Date | null) => {
                          props.setFieldValue('endDate', e);
                        }}
                        disablePast={true}
                        minDateTime={props.values.startDate}
                        inputFormat="yyyy-MM-dd H:mm"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                  )}

                  <MoneyDistribution formik={props} />

                  {commonError && (
                    <Typography color="error" variant="caption">
                      {t(commonError)}
                    </Typography>
                  )}
                </Box>
              )}
              {
                props.values.isGift && state.type === 'MULTIPLE' && (
                  <>
                    <Typography variant="h4" sx={sx.textBlock}>
                      {t('pages.createArt.amountOfCopies')}
                    </Typography>

                    <Box>
                      <OutlinedTextInput
                        sx={{ mt: 2 }}
                        name="copies"
                        id="copies"
                        value={props.values['copies']}
                        label={t('pages.createArt.copies')}
                        error={!!(props.touched.copies && props.errors.copies)}
                        helperText={props.touched.copies && t(props.errors.copies as string)}
                      />
                    </Box>
                  </>
                )
              }
              <Box sx={sx.giftCheckbox}>
                <Checkbox value={props.values.isGift} onChange={(_, value) => {
                  props.setFieldValue('isGift', value);
                  props.setFieldValue('price', 0);
                }} />
                <Typography fontWeight="bold">Use this NFT as a gift</Typography>
              </Box>

              <Box sx={sx.formButtons}>
                <Box sx={sx.formActions}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handlePublish(props)}
                    sx={sx.publish}
                  >
                    {t('pages.createArt.publish')}
                  </Button>
                  {!isMobile && (
                    <>
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        sx={sx.publishToExploreButton}
                        onClick={() => handleWithStatus(props, 'PROMO')}
                      >
                        {t('pages.createArt.promo')}
                      </Button>
                      <Button
                        sx={sx.cancelButton}
                        onClick={() => navigate(routes.adminPanel)}
                      >
                        {t('common.cancel')}
                      </Button>
                    </>
                  )}
                </Box>

                <Box display="flex" sx={sx.draftPromoButtons}>
                  <Button
                    type="button"
                    sx={sx.draftButton}
                    variant={isMobile ? 'outlined' : 'text'}
                    onClick={() => handleWithStatus(props, 'DRAFT')}
                  >
                    {t('pages.createArt.draft')}
                  </Button>
                  {
                    isMobile && (
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        sx={sx.publishToExploreButton}
                        onClick={() => handleWithStatus(props, 'PROMO')}
                      >
                        {t('pages.createArt.promo')}
                      </Button>
                    )
                  }
                </Box>
              </Box>
            </form>

            <SuccessfullyPublished
              open={!!openSuccessModalId}
              handleClose={() => setOpenSuccessModalId(null)}
              onSuccess={() => navigate(routes.art + '/' + openSuccessModalId)}
            />

            <CancelModal
              open={!!navigationTx}
              handleClose={handleCancelClose}
              handleContinue={() => {
                navigationTx?.retry();
                handleCancelClose();
              }}
            />
          </React.Fragment>
        )}
      </Formik>
    </Box>
  );
};

export default SaleInfo;

const sx: Record<string, SxProps<Theme>> = {
  container: {
    margin: (theme) => theme.spacing(0, 0, 8, 0),
  },
  title: {
    textAlign: 'left',
    mb: 2.5,
  },
  textBlock: {
    mt: 5,
    textAlign: 'left',
  },
  marketType: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },
  inputIndent: theme => ({
    py: 2,
    [theme.breakpoints.up('lg')]: {
      '& .MuiInputBase-root, & .MuiSelect-select': {
        backgroundColor: theme.palette.grey[50],
      },
    },
  }),
  inputGroup: theme => ({
    pb: 2,
    [theme.breakpoints.up('lg')]: {
      '& input': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  }),
  betweenBox: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  publish: theme => ({
    backgroundColor: theme.palette.accent.main,
    [theme.breakpoints.up('lg')]: {
      width: '140px',
    },
  }),
  setMoneyDistribution: {
    width: '100%',
  },
  textBold: {
    fontWeight: 700,
  },
  draftPromoButtons: theme => ({
    display: 'grid',
    gap: '10px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      gridTemplateColumns: '1fr 1fr',
      '& button': {
        minWidth: '47%',
        p: 0,
      },
    },
  }),
  publishToExploreButton: theme => ({
    color: 'common.black',
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 14,
    [theme.breakpoints.up('lg')]: {
      width: '160px',
    },
  }),
  fields: theme => ({
    [theme.breakpoints.up('lg')]: {
      bgcolor: (theme) => theme.palette.grey['50'],
    },
    borderRadius: '5px',
    p: '60px',
  }),
  formButtons: theme => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 6.25,
    gap: 2.5,
    [theme.breakpoints.up('sm')]: {
      mt: 2,
      flexDirection: 'row-reverse',
    },
  }),
  formActions: theme => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '10px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      flexDirection: 'column',
    },
    '& button': {
      minWidth: 0,
    },
  }),
  draftButton: theme => ({
    color: 'common.black',
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 14,
    width: '100%',
    minWidth: 0,
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.grey[600],
      p: 1,
    },
  }),
  cancelButton: {
    width: '120px',
  },
  giftCheckbox: {
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    mt: 4,
    px: 2.5,
    py: 4,
    gap: 2.5,
    backgroundColor: theme => theme.palette.grey['100'],
  },
};
