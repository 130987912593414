import React, { FC } from 'react';
import { Container } from '@mui/material';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header';
import { ArtDetailedViewProvider } from 'pages/Art/ArtDetailedViewContext';

export const ArtPageWrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <BaseLayout>
      <Container fixed sx={{ margin: '0 auto' }}>
        <Header />
        <ArtDetailedViewProvider>{children}</ArtDetailedViewProvider>
      </Container>
      <Footer />
    </BaseLayout>
  );
};
