import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { useApiContext } from 'contexts/ApiContext';
import { Event } from 'codegen-api/api-totemo-service/models';
import { Loader } from 'components/UiKit/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { CarouselWrapper } from '../CarouselWrapper';

export const UpcomingEvents = () => {
  const { t } = useTranslation();
  const api = useApiContext();
  const [eventsList, setEventsList] = useState<Event[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchAllEvents = async () => {
      try {
        const resp = await api.events.eventsControllerFindAll();
        const ordered = resp.data;
        setEventsList(ordered);
      } catch (e) {}
      setIsLoading(false);
    };

    fetchAllEvents();
  }, [api.events]);

  if (!eventsList?.length) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container sx={{ mb: 8.75 }} id="events">
      <Typography variant="h1" textAlign="center" mt={8.75} mb={5}>
        {t('homePage.upcomingEvents')}
      </Typography>

      <CarouselWrapper>
        {eventsList?.map((event: Event) => {
          return (
            <Box
              component="a"
              href={event.link}
              rel="noreferrer"
              target="_blank"
              sx={sx.poster}
              style={{ backgroundImage: `url(${event.image.url})` }}
              key={event.image.key}
            />
          );
        })}
      </CarouselWrapper>
    </Container>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  poster: (theme) => ({
    display: 'block',
    mb: 2,
    width: '100%',
    height: 0,
    paddingTop: '65%',
    background: 'no-repeat center/cover',
    [theme.breakpoints.up('lg')]: {
      height: (theme) => theme.spacing(33.375),
      width: (theme) => theme.spacing(51.25),
      borderRadius: 0.8,
    },
  }),
};
