import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoResults = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="paragraphLarge" color="primary" mt={3} fontWeight={600} component={'p'} px={2.5}>
      {t('common.noResults')}
    </Typography>
  );
};
