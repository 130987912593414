import { useTranslation } from 'react-i18next';
import { Question } from './types';

export const useFaqValues = (): Question[] => {
  const { t } = useTranslation();
  return [
    {
      question: t('homePage.faqQuestionFirst'),
      answer: t('homePage.faqAnswerFirst'),
    },
    {
      question: t('homePage.faqQuestionSecond'),
      answer: t('homePage.faqAnswerSecond'),
    },
    {
      question: t('homePage.faqQuestionThird'),
      answer: t('homePage.faqAnswerThird'),
    },
    {
      question: t('homePage.faqQuestionFourth'),
      answer: t('homePage.faqAnswerFourth'),
    },
  ];
};
