import React, { useCallback } from 'react';
import { useApp } from 'contexts/appState/AppContext';
import { useLogout } from 'contexts/appState/useLogout';
import { devLog } from 'helpers';
import { localStorageKeys } from 'app-settings';
import { ConnectorData, useAccount, useDisconnect } from 'wagmi';
import { useWC2Context } from '../WC2Layer/WC2Context';

export const useWeb3ProviderEventsHandler = () => {
  const [state, dispatch] = useApp();
  const { currentUser } = state;
  const logout = useLogout();
  const { disconnect } = useDisconnect();
  const { connector: activeConnector } = useAccount();
  const { isConnected } = useWC2Context();
  const isLoggedIn = currentUser.wallet || currentUser.email;

  const handleAccountChanged = useCallback(
    (accounts: [string]) => {
      if (accounts[0].toLowerCase() === currentUser.wallet) return;
      devLog('Account was changed');
      if (!currentUser.wallet) {
        localStorage.removeItem(localStorageKeys.walletconnect);
        disconnect();
        return;
      }
      logout();
    },
    [currentUser.wallet],
  );

  const handleChainChanged = useCallback(
    async (chain: { id: number; unsupported: boolean }) => {
      devLog('Chain was changed');

      if (chain.unsupported) {
        return dispatch({ type: 'SHOW_WRONG_NETWORK' });
      } else {
        return dispatch({ type: 'CLOSE_WRONG_NETWORK' });
      }
    },
    [dispatch],
  );

  // TODO: investigate if possible to remove this effect (page refresh on any page triggered redirect to homepage for guests)
  React.useEffect(() => {
    if (!isConnected && !isLoggedIn) {
      logout(true);
    }
  }, [isConnected]);

  React.useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
      if (account) {
        devLog('new account', account);
        handleAccountChanged([account]);
      } else if (chain) {
        devLog('new chain', chain);
        handleChainChanged(chain);
      }
    };

    if (activeConnector) {
      devLog('add web3 listeners');
      activeConnector.on('change', handleConnectorUpdate);
    }

    return () => {
      devLog('remove web3 listeners');
      return activeConnector?.off('change', handleConnectorUpdate) as any;
    };
  }, [activeConnector, handleAccountChanged, handleChainChanged]);
};
