import React, { ChangeEvent, FC } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface SocialNetworkInputProps {
  label: string;
  icon: JSX.Element;
  defaultValue?: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error: boolean;
  helperText: string;
}

export const SocialNetworksInput: FC<SocialNetworkInputProps> = ({ label, icon, defaultValue, name, onChange, error, helperText }) => {
  return (
    <Box sx={sx.networkInput}>
      <Box sx={sx.inputLabel}>
        {icon}
        <Typography variant="paragraphMedium">{label}</Typography>
      </Box>
      <TextField
        variant="outlined"
        placeholder="https://"
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  networkInput: {
    display: 'flex',
    flexDirection: 'column',
    mb: 2.5,
    '&:last-of-type': {
      mb: 7.5,
    },
  },

  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
    mb: 1.2,
    '& > svg': {
      textAlign: 'center',
    },
  },
};
