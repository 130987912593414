import React, { FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { ArtsTab } from './ArtsTab';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';
import { useProfileReducerContext } from '../../State/useProfileReducer';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={sx.tabContainer}>
          <Typography variant="paragraphLarge" color="primary">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

interface Props {
  isArtistProfile?: boolean | undefined;
}

export const ProfileTabs: FC<Props> = ({ isArtistProfile }: Props) => {
  const { t } = useTranslation();
  const { dispatch } = useProfileReducerContext();
  const [currentTab, setCurrentTab] = React.useState(() => (!!isArtistProfile ? 1 : 0));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    dispatch({ type: 'TOGGLE_ART_TABS' });
  };

  const { wallet } = useParams<{ wallet: string }>();

  return (
    <Box sx={sx.tabsContainer}>
      <Box sx={sx.tabNames}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="profile tabs" centered>
          <Tab label={t('profilePage.ownedTab')} {...a11yProps(0)} sx={sx.tabName} />
          {isArtistProfile && <Tab label={t('profilePage.creationsTab')} {...a11yProps(1)} sx={sx.tabName} />}
        </Tabs>
      </Box>

      <TabPanel value={currentTab} index={0}>
        <ArtsTab wallet={wallet} artType={'owned'} />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <ArtsTab wallet={wallet} artType={'creations'} />
      </TabPanel>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  tabsContainer: {
    minHeight: (theme) => theme.spacing(31),
  },
  tabNames: {
    borderBottom: 1,
    borderColor: 'divider',
    mb: 3.75,
  },
  tabName: {
    fontFamily: 'Playfair Display',
    fontSize: '18px',
  },
};
