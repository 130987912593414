import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApiContext } from 'contexts/ApiContext';
import type { SxProps, Theme } from '@mui/material/styles';
import type { Offer, User } from 'codegen-api/api-totemo-service/models';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';

export const UserOfferSection: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { offers: offersApi, users: usersApi, socket } = useApiContext();
  const [offer, setOffer] = React.useState<Offer | null>(null);
  const [adminUser, setAdminUser] = React.useState<Partial<User> | null>(null);
  const [isConfirming, setIsConfirming] = React.useState<boolean>(false);

  React.useEffect(() => {
    const loadOffers = async () => {
      try {
        const result = await offersApi.offersControllerGetByOfferToWallet();
        // TODO: update swagger scheme
        //@ts-ignore
        if (!result.data || !result.data.length) return;
        // TODO: update swagger scheme
        //@ts-ignore
        setOffer(result.data[result.data.length - 1]);
      } catch (e) {}
    };

    loadOffers();
  }, [offersApi]);

  React.useEffect(() => {
    if (!offer) return;

    const loadAdminProfile = async () => {
      try {
        const result = await usersApi.userControllerFindOneByWalletOrFail(offer.offerFrom);
        result.data && setAdminUser(result.data);
      } catch (e) {}
    };

    loadAdminProfile();
  }, [usersApi, offer]);

  React.useEffect(() => {
    const notificationListener = async (messageString: string) => {
      try {
        const parsedOffer: Offer = JSON.parse(JSON.parse(messageString).data);
        parsedOffer.type === 'GRANT_ROLE' && parsedOffer.status === 'PENDING' && setOffer(parsedOffer);
      } catch (e) {}
    };

    socket!.on('notification', notificationListener);

    return () => {
      socket!.off('notification', notificationListener);
    };
  }, [socket, usersApi]);

  if (!offer || !adminUser) return null;

  const handleConfirm = async () => {
    setIsConfirming(true);
    try {
      await offersApi.offersControllerUpdateStatus({ isApproved: true, offerFrom: adminUser.wallet });
      setOffer(null);
    } catch (e) {}
    setIsConfirming(false);
  };

  return (
    <Box sx={sx.root}>
      <Typography fontWeight={600}>{t('profilePage.userWantsToBeYourPM', { nickname: adminUser.username })}</Typography>
      <Typography mt={1} mb={2.5}>
        {t('profilePage.pleaseSignTransaction')}
      </Typography>
      <AsyncButton
        isLoading={isConfirming}
        color={'accent'}
        variant={'contained'}
        size={'medium'}
        onClick={handleConfirm}
      >
        {t('profilePage.confirmDelegation')}
      </AsyncButton>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  root: {
    border: '1px solid',
    borderColor: 'accent.main',
    borderRadius: '5px',
    p: 2,
    mb: 2.5,
    mt: -2,
  },
};
