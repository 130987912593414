import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { ReactComponent as TimerIcon } from 'components/Icons/timer_line.svg';

export const ArtAuctionEnded: React.FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Box sx={sx.root}>
      <Typography fontWeight={600} color={'text.secondary'}>
        {t('artPage.auction')}
      </Typography>
      <Box sx={sx.status}>
        <Typography fontWeight={600} mr={1.25} color={'inherit'}>
          {t('artPage.auctionStatusEnded')}
        </Typography>
        <TimerIcon />
      </Box>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    color: 'primary.main',
  },
};
