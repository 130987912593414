import React from 'react';
import { Box, ClickAwayListener, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArtMoneyDistributionSx as sx } from './ArtMoneyDistributionSx';
import { ReactComponent as InfoIcon } from 'components/Icons/information_line.svg';
import { PrimarySaleData, SecondarySaleData } from 'types';

type Props = {
  moneyDistribution: {
    primarySale: PrimarySaleData;
    secondarySale: SecondarySaleData;
  };
};

export const ArtMoneyDistributionAdmin: React.FC<Props> = React.memo(({ moneyDistribution }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h3">{t('artPage.moneyDistribution')}</Typography>
      <Box mt={2.5}>
        <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600}>
          {t('artPage.primarySale')}
        </Typography>

        <Box display={'flex'} gap={1.25}>
          <Box flex="1 1 50%">
            <Box sx={sx.distributionBox}>
              <Typography variant="paragraphMedium" color={'primary.accent'}>
                {t('artPage.artist')}
              </Typography>
              <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
                {moneyDistribution.primarySale.artistPercentage}%
              </Typography>
            </Box>
          </Box>
          <Box flex="1 1 50%">
            <Box sx={sx.distributionBox}>
              <Typography variant="paragraphMedium" color={'primary.accent'}>
                {t('artPage.totemo')}
              </Typography>
              <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
                {moneyDistribution.primarySale.totemoPercentage}%
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display={'flex'} gap={1.25}>
          <Box flex="1 1 50%">
            <Box sx={sx.distributionBox}>
              <Typography variant="paragraphMedium" color={'primary.accent'}>
                <Box display={'flex'} alignItems={'center'}>
                  <span>{t('artPage.optionalAddress')}</span>
                  <ClickableTooltipIcon tKey="" />
                </Box>
              </Typography>
              <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
                {moneyDistribution.primarySale.addressPercentage}%
              </Typography>
            </Box>
          </Box>
          <Box flex="1 1 50%">
            <Box sx={sx.distributionBox}>
              <Typography variant="paragraphMedium" color={'primary.accent'}>
                <Box display={'flex'} alignItems={'center'}>
                  <span>{t('artPage.serviceFee')}</span>
                  <ClickableTooltipIcon tKey="" />
                </Box>
              </Typography>
              <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
                +{moneyDistribution.primarySale.servicePercentage}%
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={3.75} mb={5}>
        <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600}>
          {t('artPage.secondarySale')}
        </Typography>

        <Box display={'flex'} gap={1.25}>
          <Box flex="1 1 50%">
            <Box sx={sx.distributionBox}>
              <Typography variant="paragraphMedium" color={'primary.accent'}>
                {t('artPage.seller')}
              </Typography>
              <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
                {moneyDistribution.secondarySale.sellerPercentage}%
              </Typography>
            </Box>
          </Box>
          <Box flex="1 1 50%">
            <Box sx={sx.distributionBox}>
              <Typography variant="paragraphMedium" color={'primary.accent'}>
                {t('artPage.artist')}
              </Typography>
              <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
                {moneyDistribution.secondarySale.artistPercentage}%
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display={'flex'} gap={1.25}>
          <Box flex="1 1 50%">
            <Box sx={sx.distributionBox}>
              <Typography variant="paragraphMedium" color={'primary.accent'}>
                {t('artPage.totemo')}
              </Typography>
              <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
                {moneyDistribution.secondarySale.totemoPercentage}%
              </Typography>
            </Box>
          </Box>
          <Box flex="1 1 50%">
            <Box sx={sx.distributionBox}>
              <Typography variant="paragraphMedium" color={'primary.accent'}>
                <Box display={'flex'} alignItems={'center'}>
                  <span>{t('artPage.serviceFee')}</span>
                  <ClickableTooltipIcon tKey="artPage.secondaryServiceFeeHint" />
                </Box>
              </Typography>
              <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
                +{moneyDistribution.secondarySale.servicePercentage}%
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

const ClickableTooltipIcon: React.FC<{ tKey: string }> = ({ tKey }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleTooltipClose = () => setIsOpen(false);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={t(tKey) as string}
        placement={'top'}
        onClose={handleTooltipClose}
        open={isOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <InfoIcon onClick={() => setIsOpen(true)} />
      </Tooltip>
    </ClickAwayListener>
  );
};
