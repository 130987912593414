import React from 'react';
import { Avatar, Box, Container, Divider, SxProps, Typography, Link as MuiLink } from '@mui/material';
import { defaultAvatarSrc, routes } from 'app-settings';
import { WalletHex } from 'components/UiKit/WalletHex/WalletHex';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { ReactComponent as ForwardIcon } from 'components/Icons/forward.svg';
import { Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { User } from 'codegen-api/api-totemo-service/models';
import { AdminPanelUsersManageBtn } from 'pages/AdminPanel/AdminPanelUsers/AdminPanelUsersManage/AdminPanelUsersManageBtn';

const AdminPanelUsersManage: React.FC<{ user: User }> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Container sx={sx.root}>
      <Divider />
      <Avatar sx={sx.avatar} alt={user.username} src={user.settings?.avatar?.url || defaultAvatarSrc} />
      {user.wallet ? (
        <Typography
          component={MuiLink}
          underline="none"
          href={`${process.env.REACT_APP_ETHERSCAN}/address/${user.wallet}`}
          target="_blank"
          rel="noreferrer noopener"
          variant="paragraphLarge"
          sx={sx.openUser}
        >
          <Box sx={sx.walletContainer}>
            <WalletHex hex={user.wallet} />
          </Box>
          <OpenInNewIcon sx={sx.openIcon} />
        </Typography>
      ) : null}
      <Typography component="div" variant="paragraphSmall" sx={sx.email}>
        {user.email || '-'}
      </Typography>
      {user.wallet ? (
        <Box sx={sx.profileLink}>
          <Link to={`${routes.userProfile}/${user.wallet}`}>
            <Box component="span">{t('adminPanel.viewFullProfile')}</Box>
            <ForwardIcon />
          </Link>
        </Box>
      ) : null}
      <AdminPanelUsersManageBtn user={user} />
    </Container>
  );
};

export default AdminPanelUsersManage;

const sx: Record<string, SxProps<Theme>> = {
  root: { bgcolor: 'grey.100', px: 2.5, pb: 5 },
  avatar: {
    mt: 2.5,
    mb: 1.25,
  },
  openUser: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    maxWidth: '100%',
  },
  walletContainer: {
    maxWidth: (theme) => `calc(100% - ${theme.spacing(3.75)})`,
  },
  openIcon: { ml: 2, width: 14, height: 14, color: 'grey.600' },
  email: { color: 'grey.600', pb: 3, display: 'block' },
  profileLink: {
    mb: 3,
    a: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      fontWeight: 600,
      svg: {
        ml: 0.5,
        position: 'relative',
        top: 1,
        height: (theme) => theme.spacing(2),
        width: (theme) => theme.spacing(2),
      },
    },
  },
};
