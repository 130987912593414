import React from 'react';
import { Card } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';
import type { Art } from 'codegen-api/api-totemo-service/models';
import VideoBox from '../VideoBox/VideoBox';
import checkIsVideo from '../../helpers/isVideo';

export const ArtPreviewMedia: React.FC<{ art: Art }> = React.memo(({ art }) => {
  const isVideo = checkIsVideo(art.artImage.url);

  return (
    <Card sx={sx.artMedia}>
      {isVideo ? (
        <VideoBox className={'art-preview'} src={art.artImage.url} controls={true} voluble={true} />
      ) : (
        <img className={'art-preview'} src={art.artImage.url} alt={art.name} />
      )}
    </Card>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  artMedia: {
    filter: 'drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2))',
    width: '100%',
    mb: 2.5,
    '& .art-preview': {
      width: '100%',
      objectFit: 'contain',
      display: 'block',
    },
  },
};
