import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { useApiContext } from 'contexts/ApiContext';
import { Loader } from 'components/UiKit/Loader/Loader';
import type { Art } from 'codegen-api/api-totemo-service/models';
import Avatar from '@mui/material/Avatar';
import { CreateAuctionRequestDtoStatusEnum } from 'codegen-api/api-totemo-service/models';
import { finalizeWindow, routes } from 'app-settings';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FilterIcon } from 'components/Icons/filter-3-line.svg';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

interface PlainAuctionWithArt {
  art: Art;
  artId: number;
  authorId: number;
  authorWallet: string;
  created: string;
  endDate: number;
  id: number;
  isFirstSale: true;
  ownerId: number;
  ownerWallet: string;
  pmWallet: string;
  signature: string;
  startDate: number;
  startPrice: string;
  status: CreateAuctionRequestDtoStatusEnum;
  updated: string;
}

export const AdminPanelFinalize: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const api = useApiContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [auctions, setAuctions] = React.useState<PlainAuctionWithArt[]>([]);
  const [order, setOrder] = React.useState<'ASC' | 'DESC'>('ASC');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenSortOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const fetchAuctions = async () => {
      try {
        setIsLoading(true);
        const resp = (await api.auction.auctionControllerFindWaitingFinalize(order)).data;
        setAuctions(resp as unknown as PlainAuctionWithArt[]);
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    };
    fetchAuctions();
  }, [order, api]);

  if (isLoading) {
    return (
      <Box p={2.5}>
        <Loader />
      </Box>
    );
  }

  const handleOpenArt = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const artId = e.currentTarget.href.split('/').pop();
    navigate(`${routes.art}/${artId}`);
  };

  const handleChangeSort = (order: 'ASC' | 'DESC') => {
    setOrder(order);
    handleCloseMenu();
  };

  return (
    <Box p={2.5} pt={2}>
      <Box sx={sx.header}>
        <Typography color="grey.600">{t('adminPanel.artworksWithCount', { count: auctions.length })}</Typography>
        <Button variant={'text'} size={'medium'} sx={sx.filterBtn} onClick={handleOpenSortOptions}>
          <FilterIcon />
          <span>{t('common.sortBy')}</span>
        </Button>

        <Menu
          id={'sort menu'}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseMenu}
          MenuListProps={{ 'aria-labelledby': '' }}
        >
          <MenuItem selected={order === 'ASC'} onClick={() => handleChangeSort('ASC')}>
            {t('common.oldestToNewest')}
          </MenuItem>
          <MenuItem selected={order === 'DESC'} onClick={() => handleChangeSort('DESC')}>
            {t('common.newestToOldest')}
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        {auctions.map((auction) => {
          return (
            <Box
              key={auction.id}
              sx={sx.auction}
              component="a"
              href={`${routes.art}/${auction.art.id}`}
              onClick={handleOpenArt}
            >
              <Avatar sx={sx.preview} alt={auction.art.name} src={auction.art.artImage.url} variant="rounded" />
              <Box sx={sx.auctionName}>
                <Typography>{auction.art.name}</Typography>
                <ExpirationDate endDate={auction.endDate} />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});

const ExpirationDate: React.FC<{ endDate: number }> = ({ endDate }) => {
  return (
    <Typography variant={'paragraphSmall'} display={'block'} color="text.secondary">
      {format(endDate * 1000 + finalizeWindow, 'dd/MM/yyyy HH:mm')}
    </Typography>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 0.5,
  },
  filterBtn: {
    position: 'relative',
    right: (theme) => theme.spacing(-2),
    svg: {
      width: (theme) => theme.spacing(2),
      height: (theme) => theme.spacing(2),
      mr: 1.25,
    },
  },
  auction: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    py: 1.25,
    bgcolor: 'background.default',
  },
  auctionName: {
    flex: 1,
    py: 1.25,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: 'grey.300',
  },
  preview: {
    mr: 1.5,
    flex: '0 0 auto',
  },
};
