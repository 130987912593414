import React, { CSSProperties } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import Logo from 'components/Header/Logo';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';

export const SignInLayout: React.FC<{ titleTKey?: string, children: React.ReactNode }> = ({
  titleTKey,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <Box sx={sx.root}>
        <Box sx={sx.logoContainer}>
          <Box sx={sx.banner}>
            <Logo sx={sx.logo as CSSProperties} />
          </Box>
        </Box>
        {titleTKey ? (
          <Typography variant="h1" textAlign="center">
            {t(titleTKey)}
          </Typography>
        ) : null}
        <>{children}</>
      </Box>
    </BaseLayout>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  logoContainer: {
    mb: 6,
  },
  banner: {
    height: '220px',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.80) 75%, #FFFFFF 100%), url(/assets/images/sign_in_banner.jpg), #646668',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
  },
  logo: {
    width: '210px',
  },
};
