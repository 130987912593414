import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system';

export const Loader = () => {
  return (
    <Box sx={sx.loader}>
      <CircularProgress color="secondary" />
    </Box>
  );
};

const sx: Record<string, SxProps> = {
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
};
