/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ChangePasswordDto } from '../models';
import { DeleteResult } from '../models';
import { ForgotPasswordDto } from '../models';
import { PartialTypeClass } from '../models';
import { ResetForgottenPasswordDto } from '../models';
import { SignUpUserDto } from '../models';
import { UpdateUploadFileDto } from '../models';
import { UpdateUserDto } from '../models';
import { UploadFileSchema } from '../models';
import { User } from '../models';
import { UserPaginatedDto } from '../models';
import { UserSettingsSchema } from '../models';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} file 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddUserImage: async (file: string, wallet: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling userControllerAddUserImage.');
            }
            // verify required parameter 'wallet' is not null or undefined
            if (wallet === null || wallet === undefined) {
                throw new RequiredError('wallet','Required parameter wallet was null or undefined when calling userControllerAddUserImage.');
            }
            const localVarPath = `/users/upload-file/{wallet}`
                .replace(`{${"wallet"}}`, encodeURIComponent(String(wallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerChangePassword: async (body: ChangePasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerChangePassword.');
            }
            const localVarPath = `/users/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} file 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEditArtStudio: async (file: string, wallet: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling userControllerEditArtStudio.');
            }
            // verify required parameter 'wallet' is not null or undefined
            if (wallet === null || wallet === undefined) {
                throw new RequiredError('wallet','Required parameter wallet was null or undefined when calling userControllerEditArtStudio.');
            }
            const localVarPath = `/users/artist/upload-background/{wallet}`
                .replace(`{${"wallet"}}`, encodeURIComponent(String(wallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSettingsSchema} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEditProfile: async (body: UserSettingsSchema, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerEditProfile.');
            }
            const localVarPath = `/users/artist/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [isArtist] 
         * @param {string} [isFeatured] 
         * @param {string} [artStatus] 
         * @param {boolean} [authoredOnly] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAll: async (searchQuery?: string, isArtist?: string, isFeatured?: string, artStatus?: string, authoredOnly?: boolean, limit?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (isArtist !== undefined) {
                localVarQueryParameter['isArtist'] = isArtist;
            }

            if (isFeatured !== undefined) {
                localVarQueryParameter['isFeatured'] = isFeatured;
            }

            if (artStatus !== undefined) {
                localVarQueryParameter['artStatus'] = artStatus;
            }

            if (authoredOnly !== undefined) {
                localVarQueryParameter['authoredOnly'] = authoredOnly;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindOneByWalletOrFail: async (wallet: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wallet' is not null or undefined
            if (wallet === null || wallet === undefined) {
                throw new RequiredError('wallet','Required parameter wallet was null or undefined when calling userControllerFindOneByWalletOrFail.');
            }
            const localVarPath = `/users/{wallet}`
                .replace(`{${"wallet"}}`, encodeURIComponent(String(wallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerForgotPassword: async (body: ForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerForgotPassword.');
            }
            const localVarPath = `/users/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetFullUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userControllerGetFullUser.');
            }
            const localVarPath = `/users/fullUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetNewNonce: async (wallet: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wallet' is not null or undefined
            if (wallet === null || wallet === undefined) {
                throw new RequiredError('wallet','Required parameter wallet was null or undefined when calling userControllerGetNewNonce.');
            }
            const localVarPath = `/users/wallet/{wallet}/nonce`
                .replace(`{${"wallet"}}`, encodeURIComponent(String(wallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetNonceAndUpdateById: async (wallet: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wallet' is not null or undefined
            if (wallet === null || wallet === undefined) {
                throw new RequiredError('wallet','Required parameter wallet was null or undefined when calling userControllerGetNonceAndUpdateById.');
            }
            const localVarPath = `/users/wallet/{wallet}/nonce-update`
                .replace(`{${"wallet"}}`, encodeURIComponent(String(wallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetTopArtist: async (limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/getTopArtist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemove: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userControllerRemove.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetForgottenPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerResetForgottenPassword: async (body: ResetForgottenPasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerResetForgottenPassword.');
            }
            const localVarPath = `/users/reset-forgotten-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignUpUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSingUp: async (body: SignUpUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerSingUp.');
            }
            const localVarPath = `/users/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} body 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate: async (body: UpdateUserDto, wallet: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerUpdate.');
            }
            // verify required parameter 'wallet' is not null or undefined
            if (wallet === null || wallet === undefined) {
                throw new RequiredError('wallet','Required parameter wallet was null or undefined when calling userControllerUpdate.');
            }
            const localVarPath = `/users/{wallet}`
                .replace(`{${"wallet"}}`, encodeURIComponent(String(wallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wallet 
         * @param {string} isFeatured 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateFeatureArtist: async (wallet: string, isFeatured: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wallet' is not null or undefined
            if (wallet === null || wallet === undefined) {
                throw new RequiredError('wallet','Required parameter wallet was null or undefined when calling userControllerUpdateFeatureArtist.');
            }
            // verify required parameter 'isFeatured' is not null or undefined
            if (isFeatured === null || isFeatured === undefined) {
                throw new RequiredError('isFeatured','Required parameter isFeatured was null or undefined when calling userControllerUpdateFeatureArtist.');
            }
            const localVarPath = `/users/artists/featured/{wallet}`
                .replace(`{${"wallet"}}`, encodeURIComponent(String(wallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isFeatured !== undefined) {
                localVarQueryParameter['isFeatured'] = isFeatured;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array&lt;User&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateFeatureUsersOrder: async (body: Array<User>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerUpdateFeatureUsersOrder.');
            }
            const localVarPath = `/users/artists/featured/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} file 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerAddUserImage(file: string, wallet: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileSchema>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerAddUserImage(file, wallet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ChangePasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerChangePassword(body: ChangePasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerChangePassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} file 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerEditArtStudio(file: string, wallet: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUploadFileDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerEditArtStudio(file, wallet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserSettingsSchema} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerEditProfile(body: UserSettingsSchema, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsSchema>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerEditProfile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [isArtist] 
         * @param {string} [isFeatured] 
         * @param {string} [artStatus] 
         * @param {boolean} [authoredOnly] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindAll(searchQuery?: string, isArtist?: string, isFeatured?: string, artStatus?: string, authoredOnly?: boolean, limit?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPaginatedDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerFindAll(searchQuery, isArtist, isFeatured, artStatus, authoredOnly, limit, page, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindOneByWalletOrFail(wallet: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialTypeClass>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerFindOneByWalletOrFail(wallet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerForgotPassword(body: ForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerForgotPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetFullUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerGetFullUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetNewNonce(wallet: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerGetNewNonce(wallet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetNonceAndUpdateById(wallet: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerGetNonceAndUpdateById(wallet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetTopArtist(limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPaginatedDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerGetTopArtist(limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerRemove(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResult>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ResetForgottenPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerResetForgottenPassword(body: ResetForgottenPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerResetForgottenPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SignUpUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSingUp(body: SignUpUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerSingUp(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateUserDto} body 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdate(body: UpdateUserDto, wallet: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialTypeClass>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerUpdate(body, wallet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} wallet 
         * @param {string} isFeatured 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateFeatureArtist(wallet: string, isFeatured: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerUpdateFeatureArtist(wallet, isFeatured, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array&lt;User&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateFeatureUsersOrder(body: Array<User>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).userControllerUpdateFeatureUsersOrder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} file 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddUserImage(file: string, wallet: string, options?: any): AxiosPromise<UploadFileSchema> {
            return UsersApiFp(configuration).userControllerAddUserImage(file, wallet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangePasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerChangePassword(body: ChangePasswordDto, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).userControllerChangePassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} file 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEditArtStudio(file: string, wallet: string, options?: any): AxiosPromise<UpdateUploadFileDto> {
            return UsersApiFp(configuration).userControllerEditArtStudio(file, wallet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSettingsSchema} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEditProfile(body: UserSettingsSchema, options?: any): AxiosPromise<UserSettingsSchema> {
            return UsersApiFp(configuration).userControllerEditProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchQuery] 
         * @param {string} [isArtist] 
         * @param {string} [isFeatured] 
         * @param {string} [artStatus] 
         * @param {boolean} [authoredOnly] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAll(searchQuery?: string, isArtist?: string, isFeatured?: string, artStatus?: string, authoredOnly?: boolean, limit?: number, page?: number, options?: any): AxiosPromise<UserPaginatedDto> {
            return UsersApiFp(configuration).userControllerFindAll(searchQuery, isArtist, isFeatured, artStatus, authoredOnly, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindOneByWalletOrFail(wallet: string, options?: any): AxiosPromise<PartialTypeClass> {
            return UsersApiFp(configuration).userControllerFindOneByWalletOrFail(wallet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerForgotPassword(body: ForgotPasswordDto, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).userControllerForgotPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetFullUser(id: number, options?: any): AxiosPromise<User> {
            return UsersApiFp(configuration).userControllerGetFullUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetNewNonce(wallet: string, options?: any): AxiosPromise<number> {
            return UsersApiFp(configuration).userControllerGetNewNonce(wallet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetNonceAndUpdateById(wallet: string, options?: any): AxiosPromise<number> {
            return UsersApiFp(configuration).userControllerGetNonceAndUpdateById(wallet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetTopArtist(limit?: number, options?: any): AxiosPromise<UserPaginatedDto> {
            return UsersApiFp(configuration).userControllerGetTopArtist(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemove(id: string, options?: any): AxiosPromise<DeleteResult> {
            return UsersApiFp(configuration).userControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetForgottenPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerResetForgottenPassword(body: ResetForgottenPasswordDto, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).userControllerResetForgottenPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignUpUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSingUp(body: SignUpUserDto, options?: any): AxiosPromise<User> {
            return UsersApiFp(configuration).userControllerSingUp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} body 
         * @param {string} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate(body: UpdateUserDto, wallet: string, options?: any): AxiosPromise<PartialTypeClass> {
            return UsersApiFp(configuration).userControllerUpdate(body, wallet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} wallet 
         * @param {string} isFeatured 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateFeatureArtist(wallet: string, isFeatured: string, options?: any): AxiosPromise<User> {
            return UsersApiFp(configuration).userControllerUpdateFeatureArtist(wallet, isFeatured, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array&lt;User&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateFeatureUsersOrder(body: Array<User>, options?: any): AxiosPromise<Array<User>> {
            return UsersApiFp(configuration).userControllerUpdateFeatureUsersOrder(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {string} file 
     * @param {string} wallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerAddUserImage(file: string, wallet: string, options?: any) {
        return UsersApiFp(this.configuration).userControllerAddUserImage(file, wallet, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {ChangePasswordDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerChangePassword(body: ChangePasswordDto, options?: any) {
        return UsersApiFp(this.configuration).userControllerChangePassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} file 
     * @param {string} wallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerEditArtStudio(file: string, wallet: string, options?: any) {
        return UsersApiFp(this.configuration).userControllerEditArtStudio(file, wallet, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UserSettingsSchema} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerEditProfile(body: UserSettingsSchema, options?: any) {
        return UsersApiFp(this.configuration).userControllerEditProfile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [searchQuery] 
     * @param {string} [isArtist] 
     * @param {string} [isFeatured] 
     * @param {string} [artStatus] 
     * @param {boolean} [authoredOnly] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerFindAll(searchQuery?: string, isArtist?: string, isFeatured?: string, artStatus?: string, authoredOnly?: boolean, limit?: number, page?: number, options?: any) {
        return UsersApiFp(this.configuration).userControllerFindAll(searchQuery, isArtist, isFeatured, artStatus, authoredOnly, limit, page, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} wallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerFindOneByWalletOrFail(wallet: string, options?: any) {
        return UsersApiFp(this.configuration).userControllerFindOneByWalletOrFail(wallet, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {ForgotPasswordDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerForgotPassword(body: ForgotPasswordDto, options?: any) {
        return UsersApiFp(this.configuration).userControllerForgotPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetFullUser(id: number, options?: any) {
        return UsersApiFp(this.configuration).userControllerGetFullUser(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} wallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetNewNonce(wallet: string, options?: any) {
        return UsersApiFp(this.configuration).userControllerGetNewNonce(wallet, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} wallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetNonceAndUpdateById(wallet: string, options?: any) {
        return UsersApiFp(this.configuration).userControllerGetNonceAndUpdateById(wallet, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetTopArtist(limit?: number, options?: any) {
        return UsersApiFp(this.configuration).userControllerGetTopArtist(limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerRemove(id: string, options?: any) {
        return UsersApiFp(this.configuration).userControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {ResetForgottenPasswordDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerResetForgottenPassword(body: ResetForgottenPasswordDto, options?: any) {
        return UsersApiFp(this.configuration).userControllerResetForgottenPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {SignUpUserDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerSingUp(body: SignUpUserDto, options?: any) {
        return UsersApiFp(this.configuration).userControllerSingUp(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateUserDto} body 
     * @param {string} wallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdate(body: UpdateUserDto, wallet: string, options?: any) {
        return UsersApiFp(this.configuration).userControllerUpdate(body, wallet, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} wallet 
     * @param {string} isFeatured 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdateFeatureArtist(wallet: string, isFeatured: string, options?: any) {
        return UsersApiFp(this.configuration).userControllerUpdateFeatureArtist(wallet, isFeatured, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {Array&lt;User&gt;} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdateFeatureUsersOrder(body: Array<User>, options?: any) {
        return UsersApiFp(this.configuration).userControllerUpdateFeatureUsersOrder(body, options).then((request) => request(this.axios, this.basePath));
    }
}
