import React, { useEffect, useState } from 'react';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box, Backdrop, CircularProgress,
} from '@mui/material';
import { useApiContext } from 'contexts/ApiContext';
import { AccessRequest, Meta } from 'codegen-api/api-totemo-service/models';

const AdminPanelAccessRequests = () => {
  const [page, setPage] = useState(1);
  const { accessRequests } = useApiContext();
  const [requests, setRequests] = useState<AccessRequest[]>([]);
  const [pagination, setPagination] = useState<Meta>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    accessRequests
      .accessRequestsControllerFindAll(
        100,
        page,
      )
      .then((resp) => {
        setRequests(resp.data?.items || []);
        setPagination(resp.data?.meta);
      })
      .catch()
      .finally(() => setIsLoading(false));
  }, [page, accessRequests]);

  return <Box width={500}>
    <Backdrop
      open={isLoading}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Phone Number</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          requests.map(request => {
            return <TableRow>
              <TableCell>{request.email}</TableCell>
              <TableCell>{request.phoneNumber}</TableCell>
            </TableRow>;
          })
        }
      </TableBody>
    </Table>
    {
      pagination && (
        <Pagination
          count={pagination.totalPages}
          defaultPage={1}
          page={page}
          onChange={(_, page) => setPage(page)}
        />
      )}
  </Box>;
};

export default AdminPanelAccessRequests;
