import React from 'react';
import { alpha, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { SignInLayout } from 'components/SignInLayout/SignInLayout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { routes } from 'app-settings';
import { devLog } from 'helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiContext } from 'contexts/ApiContext';

type TErrorMsgTranslate = '' | 'userNotFound' | 'wrongUserRole' | 'wrongPassword' | 'requestNotFound';

export const ChangePassword: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, confirmationCode } = useParams<{ userId: string; confirmationCode?: string }>();
  const resetPasswordMode = !!confirmationCode;
  const { users } = useApiContext();
  const validationSchema = useChangePasswordValidationSchema(resetPasswordMode);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const errorMapping: Record<string, TErrorMsgTranslate> = {
    'User not found': 'userNotFound',
    'Incorrect user role': 'wrongUserRole',
    'Invalid old password': 'wrongPassword',
    'Request not found': 'requestNotFound',
  };

  const [asyncError, setAsyncError] = React.useState<TErrorMsgTranslate>('');
  const formik = useFormik<{ oldPassword: string; newPassword: string; repeatPassword: string }>({
    validationSchema,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    },
    onSubmit: async ({ oldPassword, newPassword }) => {
      try {
        setIsSubmitting(true);

        if (resetPasswordMode) {
          await users.userControllerResetForgottenPassword({ newPassword, confirmationCode });
        } else {
          await users.userControllerChangePassword({ userId, oldPassword, newPassword });
        }

        navigate(routes.home);
      } catch (e: any) {
        devLog(e);
        const errorMessage = e.response?.data?.message || '';
        setAsyncError(errorMapping[errorMessage] || '');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <SignInLayout titleTKey={'common.changePassword'}>
      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
        <Box sx={sx.inputsContainer}>
          {!resetPasswordMode && (
            <OutlinedTextInput
              sx={{ mb: 2.5 }}
              required={true}
              name="oldPassword"
              id="oldPassword"
              type="password"
              autoComplete="password"
              label={t('forms.oldPassword')}
              error={!!((formik.touched.oldPassword && formik.errors.oldPassword) || asyncError)}
              helperText={formik.touched.oldPassword && t(formik.errors.oldPassword as string)}
            />
          )}
          <OutlinedTextInput
            sx={{ mb: 2.5 }}
            required={true}
            name="newPassword"
            id="newPassword"
            type="password"
            autoComplete="new-password"
            label={t('forms.newPassword')}
            error={!!((formik.touched.newPassword && formik.errors.newPassword) || asyncError)}
            helperText={formik.touched.newPassword && t(formik.errors.newPassword as string)}
          />
          <OutlinedTextInput
            required={true}
            name="repeatPassword"
            id="repeatPassword"
            type="password"
            autoComplete="new-password"
            label={t('forms.repeatPassword')}
            error={!!((formik.touched.repeatPassword && formik.errors.repeatPassword) || asyncError)}
            helperText={formik.touched.repeatPassword && t(formik.errors.repeatPassword as string)}
          />
          {!!asyncError && <Box sx={sx.errorContainer}>{t(`forms.errors.${asyncError}`)}</Box>}
          <Box sx={sx.submitContainer}>
            <AsyncButton isLoading={isSubmitting} type="submit" variant="contained" sx={sx.submit}>
              {t('common.submit')}
            </AsyncButton>
          </Box>
        </Box>
      </form>
    </SignInLayout>
  );
});

const useChangePasswordValidationSchema = (resetPasswordMode: boolean) => {
  return React.useMemo(() => {
    return Yup.object().shape({
      oldPassword: Yup.string().when([], {
        is: () => resetPasswordMode,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
      newPassword: Yup.string()
        .min(8)
        .max(255)
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*+,-.:;?@^_~])[A-Za-z\d!#$%&*+,-.:;?@^_~]{8,}$/,
          'forms.errors.passwordMustContain',
        )
        .test({
          message: 'forms.errors.mustNotBeOldPassword',
          test: (newPassword, context) => {
            if (!newPassword) {
              return true;
            }
            const formValues = context.parent;
            if (formValues.oldPassword === newPassword) {
              return new Yup.ValidationError([
                context.createError({
                  path: `${context.path}`,
                  message: 'forms.errors.mustNotBeOldPassword',
                }),
              ]);
            }

            return true;
          },
        }),
      repeatPassword: Yup.string()
        .min(8)
        .max(255)
        .required()
        .test({
          message: 'forms.errors.mustMatchNewPassword',
          test: (repeatPassword, context) => {
            if (!repeatPassword) {
              return true;
            }
            const formValues = context.parent;
            if (formValues.newPassword !== repeatPassword) {
              return new Yup.ValidationError([
                context.createError({
                  path: `${context.path}`,
                  message: 'forms.errors.mustMatchNewPassword',
                }),
              ]);
            }

            return true;
          },
        }),
    });
  }, []);
};

const sx: Record<string, SxProps<Theme>> = {
  subtitle: {
    mt: 3.75,
    fontWeight: 700,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    display: 'block',
    span: {
      display: 'block',
    },
  },
  inputsContainer: {
    width: { sm: 600, xs: '100%' },
    m: '0 auto',
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    mt: 5,
  },
  submitContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  submit: { my: 3.75 },
  errorContainer: {
    alignSelf: 'stretch',
    textAlign: 'center',
    bgcolor: (theme) => alpha(theme.palette.error.main, 0.2),
    color: 'error.main',
    p: 1,
    borderRadius: '4px',
    mt: 2.5,
  },
};
