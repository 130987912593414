import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CollapsibleParagraph from 'react-collapsible-paragraph';
import type { Art } from 'codegen-api/api-totemo-service/models';

export const ArtDescription: React.FC<{ art: Art }> = React.memo(({ art }) => {
  const { t } = useTranslation();
  const locales = React.useMemo(() => {
    return { expand: `${t('common.readMore')}`, collapse: `${t('common.readLess')}` };
  }, [t]);

  return (
    <Box mt={2.5}>
      <Typography fontSize="20px">Description</Typography>
      <Box color="text.secondary" mt={2}>
        {/* @ts-ignore */}
        <CollapsibleParagraph lines={4} locales={locales}>
          {art.description}
        </CollapsibleParagraph>
      </Box>
    </Box>
  );
});
