import React from 'react';
import type { Art } from 'codegen-api/api-totemo-service/models';
import { SecondarySaleData } from 'types';
import { DEFAULT_MONEY_DISTRIBUTION_SECONDARY } from 'helpers/moneyDistributionHelpers';

export const useSecondarySaleMoneyDistribution = (art: Art) => {
  return React.useMemo<SecondarySaleData>(() => {
    try {
      return JSON.parse(art.moneyDistribution).secondarySale;
    } catch (e) {
      return DEFAULT_MONEY_DISTRIBUTION_SECONDARY;
    }
  }, [art.moneyDistribution]);
};
