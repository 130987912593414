import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n';

console.log('ENVIRONMENT: ', process.env.REACT_APP_ENVIRONMENT);

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
