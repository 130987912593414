import { Container } from '@mui/material';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header';
import { UpcomingEvents } from './UpcomingEvents/UpcomingEvents';
import { FeaturedArtist } from './FeaturedArtist/FeaturedArtist';
import { Explore } from './Explore/Explore';
import { Newsletters } from './Newsletters/Newsletters';
import { FeedOfArts } from './FeedOfArts/FeedOfArts';
import TopArtists from './TopArtists/TopArtists';
import { FeaturedIn } from './FeaturedIn/FeaturedIn';
import { FAQ } from './FAQ/FAQ';
import React from 'react';

export const Home = () => {
  return (
    <BaseLayout>
      <Container fixed sx={{ margin: '0 auto' }}>
        <Header />
        <FeedOfArts />
        <TopArtists />
        <UpcomingEvents />
        <FeaturedArtist />
        <Explore />
      </Container>
      <FAQ />
      <FeaturedIn />
      <Newsletters />
      <Footer />
    </BaseLayout>
  );
};
