import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { routes } from 'app-settings';

const AdminPanelUsersManageCreateArt: React.FC<{ wallet: string }> = ({ wallet }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button fullWidth color={'accent'} variant={'contained'} onClick={() => navigate(`${routes.createArt}/${wallet}`)}>
      {t('adminPanel.createArt')}
    </Button>
  );
};

export default AdminPanelUsersManageCreateArt;
