import type { Auction, BuyNow } from 'codegen-api/api-totemo-service/models';
import * as Ethers from 'ethers';

export const getMarketPrice = (saleData: BuyNow | Auction | null, amount = 1): number => {
  if (!saleData) return 0;

  let pricePerItem;

  if ('marketPrice' in saleData) {
    pricePerItem = saleData.marketPrice;
  } else {
    pricePerItem = saleData.bids?.length ? saleData.bids[0].bidPrice : saleData.startPrice;
  }

  if (amount === 1) return pricePerItem;

  return +Ethers.utils.formatEther(Ethers.utils.parseEther(pricePerItem).mul(amount).toString());
};
