import React from 'react';
import { Box, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';

const AdminPanelUsersNoResults: React.FC<{ value: string }> = ({ value }) => {
  const { t } = useTranslation();

  return (
    <Box sx={sx.root}>
      <Box sx={sx.title}>{t('adminPanel.usersNoResultsTitle')}</Box>
      <Box sx={sx.text}>{t('adminPanel.usersNoResultsText', { value })}</Box>
    </Box>
  );
};

export default AdminPanelUsersNoResults;

const sx: Record<string, SxProps<Theme>> = {
  root: {
    px: 2.5,
  },
  title: {
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
    fontSize: 18,
    mb: 0.25,
  },
  text: {
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    color: (theme) => theme.palette.text.secondary,
  },
};
