/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface CreateAuctionRequestDto
 */
export interface CreateAuctionRequestDto {
    /**
     * Art ID
     * @type {number}
     * @memberof CreateAuctionRequestDto
     */
    artId: any;
    /**
     * Owner wallet
     * @type {string}
     * @memberof CreateAuctionRequestDto
     */
    ownerWallet: any;
    /**
     * Author wallet
     * @type {string}
     * @memberof CreateAuctionRequestDto
     */
    authorWallet: any;
    /**
     * Product manager wallet
     * @type {string}
     * @memberof CreateAuctionRequestDto
     */
    pmWallet: any;
    /**
     * Auction start date.
     * @type {number}
     * @memberof CreateAuctionRequestDto
     */
    startDate: any;
    /**
     * Auction end date.
     * @type {number}
     * @memberof CreateAuctionRequestDto
     */
    endDate: any;
    /**
     * Auction start price.
     * @type {number}
     * @memberof CreateAuctionRequestDto
     */
    startPrice: any;
    /**
     * Is send art to auction first time?
     * @type {boolean}
     * @memberof CreateAuctionRequestDto
     */
    isFirstSale: any;
    /**
     * Auction signature for blockchain.
     * @type {string}
     * @memberof CreateAuctionRequestDto
     */
    signature: any;
    /**
     * Contract from other nft buy-now. It is need for selling nft on other buyNow
     * @type {string}
     * @memberof CreateAuctionRequestDto
     */
    assetContract?: any;
    /**
     * auction status
     * @type {string}
     * @memberof CreateAuctionRequestDto
     */
    status: CreateAuctionRequestDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateAuctionRequestDtoStatusEnum {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    BUY = 'BUY',
    CANCELED = 'CANCELED'
}

