import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme } from '@mui/material/styles';
import { iconsConfig } from './IconsConfig';

export const FeaturedIn = () => {
  const { t } = useTranslation();

  return (
    <Container sx={sx.wrapper}>
      <Typography variant="h1" textAlign="center" mb={5}>
        {t('homePage.featuredIn')}
      </Typography>

      <Box sx={sx.iconsWrapper}>
        {iconsConfig?.map((item) => {
          return (
            <Box sx={sx.icon} component="a" href={item.link} key={item.link}>
              {item.icon}
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  wrapper: {
    mb: 8.75,
    px: 2.5,
    pt: 7.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mx: 'auto',
  },
  iconsWrapper: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  icon: {
    flex: 1,
    maxWidth: 159,
    color: 'grey.600',
  },
};
