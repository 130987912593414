/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface CreateBuyNowDto
 */
export interface CreateBuyNowDto {
    /**
     * Art ID
     * @type {number}
     * @memberof CreateBuyNowDto
     */
    artId: any;
    /**
     * Owner wallet
     * @type {string}
     * @memberof CreateBuyNowDto
     */
    ownerWallet: any;
    /**
     * Author wallet
     * @type {string}
     * @memberof CreateBuyNowDto
     */
    authorWallet: any;
    /**
     * Product manager wallet
     * @type {string}
     * @memberof CreateBuyNowDto
     */
    pmWallet: any;
    /**
     * start market date
     * @type {number}
     * @memberof CreateBuyNowDto
     */
    startMarketDate: any;
    /**
     * market price
     * @type {number}
     * @memberof CreateBuyNowDto
     */
    marketPrice: any;
    /**
     * amount
     * @type {number}
     * @memberof CreateBuyNowDto
     */
    amount: any;
    /**
     * is first sale
     * @type {boolean}
     * @memberof CreateBuyNowDto
     */
    isFirstSale: any;
    /**
     * signature
     * @type {string}
     * @memberof CreateBuyNowDto
     */
    signature: any;
    /**
     * Current status for art
     * @type {string}
     * @memberof CreateBuyNowDto
     */
    status: CreateBuyNowDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateBuyNowDtoStatusEnum {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    BUY = 'BUY',
    CANCELED = 'CANCELED'
}

