// WARNING: due to codegen, the api context uses globalAxios instance
// so all interceptors are bound to it.

import React, { useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { devLog } from 'helpers';
import { DefaultApi } from 'codegen-api/api-totemo-service/apis/default-api';
import { AppSettingsApi } from 'codegen-api/api-totemo-service/apis/app-settings-api';
import { EventsApi } from 'codegen-api/api-totemo-service/apis/events-api';
import { ExchangeRateApi } from 'codegen-api/api-totemo-service/apis/exchange-rate-api';
import { UsersApi } from 'codegen-api/api-totemo-service/apis/users-api';
import { UserRolesApi } from 'codegen-api/api-totemo-service/apis/user-roles-api';
import { AuthApi } from 'codegen-api/api-totemo-service/apis/auth-api';
import { ArtApi } from 'codegen-api/api-totemo-service/apis/art-api';
import { BuyNowApi } from 'codegen-api/api-totemo-service/apis/buy-now-api';
import { AuctionsApi } from 'codegen-api/api-totemo-service/apis/auctions-api';
import { OffersApi } from 'codegen-api/api-totemo-service/apis/offers-api';
import { BidsApi } from 'codegen-api/api-totemo-service/apis/bids-api';
import { ArtHistoryApi } from 'codegen-api/api-totemo-service/apis/art-history-api';
import { TagsApi } from 'codegen-api/api-totemo-service/apis/tags-api';
import { SubscribersApi } from 'codegen-api/api-totemo-service/apis/subscribers-api';
import { FollowApi } from 'codegen-api/api-totemo-service/apis/follow-api';
import { SignupLinkApi } from 'codegen-api/api-totemo-service/apis/signup-link-api';
import { AccessRequestApi } from '../codegen-api/api-totemo-service';

const apiServiceConfig = { basePath: process.env.REACT_APP_API_HOST };

export interface IApi {
  default: DefaultApi;
  appSettings: AppSettingsApi;
  events: EventsApi;
  exchangeRate: ExchangeRateApi;
  users: UsersApi;
  userRoles: UserRolesApi;
  auth: AuthApi;
  art: ArtApi;
  buyNow: BuyNowApi;
  auction: AuctionsApi;
  bids: BidsApi;
  artHistory: ArtHistoryApi;
  offers: OffersApi;
  subscribers: SubscribersApi;
  tags: TagsApi;
  follow: FollowApi;
  signUpLink: SignupLinkApi;
  socket?: Socket;
  accessRequests: AccessRequestApi;
}

const api: IApi = {
  default: new DefaultApi(apiServiceConfig),
  appSettings: new AppSettingsApi(apiServiceConfig),
  events: new EventsApi(apiServiceConfig),
  exchangeRate: new ExchangeRateApi(apiServiceConfig),
  users: new UsersApi(apiServiceConfig),
  userRoles: new UserRolesApi(apiServiceConfig),
  art: new ArtApi(apiServiceConfig),
  buyNow: new BuyNowApi(apiServiceConfig),
  auction: new AuctionsApi(apiServiceConfig),
  bids: new BidsApi(apiServiceConfig),
  artHistory: new ArtHistoryApi(apiServiceConfig),
  offers: new OffersApi(apiServiceConfig),
  auth: new AuthApi(apiServiceConfig),
  tags: new TagsApi(apiServiceConfig),
  subscribers: new SubscribersApi(apiServiceConfig),
  follow: new FollowApi(apiServiceConfig),
  signUpLink: new SignupLinkApi(apiServiceConfig),
  accessRequests: new AccessRequestApi(apiServiceConfig),
};

try {
  api.socket = io(process.env.REACT_APP_SOCKETS_HOST, {
    path: process.env.REACT_APP_SOCKETS_PATH,
    transports: ['websocket'],
  });
} catch (e) {
  console.log('Socket is not connected');
}

const ApiContext = React.createContext<IApi | undefined>(undefined);

const ApiProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  React.useEffect(() => {
    const socket = api.socket;
    if (!socket) return;
    socket.on('connect', connectSocketListener);
    socket.on('disconnect', disconnectSocketListener);

    return () => {
      socket.off('connect', connectSocketListener);
      socket.off('disconnect', disconnectSocketListener);
    };
  }, []);
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export function useApiContext() {
  const context = useContext(ApiContext);

  if (typeof context === 'undefined') {
    throw new Error('useApiContext must be used within an ApiProvider');
  }

  return context;
}

export default ApiProvider;

const connectSocketListener = () => {
  console.log('Socket is connected: ', api.socket);
};

const disconnectSocketListener = () => {
  console.log('Socket is disconnected');
};

const notReadyMessage = 'socket is not ready';
export const sendSocketEvent = (event: SocketEvent) => {
  const send = () => {
    if (!api.socket || !api.socket.connected) {
      throw new Error(notReadyMessage);
    }

    api.socket.emit(event.name, event.data);
    devLog('Socket event emitted: ', event.name, event.data);
  };

  try {
    send();
  } catch (e: any) {
    if (e?.message === notReadyMessage) {
      setTimeout(send, 1000);
    } else {
      throw e;
    }
  }
};

export type SocketEvent = { name: 'joinUserRoom'; data: string };
