import React from 'react';
import { useTranslation } from 'react-i18next';

export interface DistanceObject {
  days: number,
  hours: number,
  minutes: number,
  seconds: number
}

export const useEndDateDistance = (endDateTimestamp: number, returnObject?: boolean): DistanceObject | string => {
  const { i18n } = useTranslation();

  const getEndDateDistance = React.useCallback(
    (endDate: number): string | DistanceObject => {
      const diff = endDate - Date.now();

      if (diff <= 0) return '';

      const days = Math.floor(diff / 86400_000);
      let rest = diff % 86400_000;
      const hours = Math.floor(rest / 3600_000);
      rest = rest % 3600_000;
      const minutes = Math.floor(rest / 60_000);
      rest = rest % 60_000;
      const seconds = Math.floor(rest / 1000);

      if (returnObject) {
        return {
          days,
          hours,
          minutes,
          seconds,
        };
      }

      switch (i18n.language) {
        case 'jp': {
          //TODO: replace with localized value
          return `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : ''} ${seconds}s`;
        }
        default: {
          return `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : ''} ${seconds}s`;
        }
      }
    },
    [i18n.language, returnObject],
  );
  const [distance, setDistance] = React.useState<DistanceObject | string>(getEndDateDistance(endDateTimestamp));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDistance(getEndDateDistance(endDateTimestamp));
    }, 1000);

    return () => clearInterval(interval);
  }, [endDateTimestamp, getEndDateDistance, returnObject]);

  return distance;
};
