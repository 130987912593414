import React from 'react';
import ETT from './contracts/TotemoRoleProxy';
import { UserRolesHexMap } from 'components/RequireRole/RequireRole';
import { prepareWriteContract, writeContract } from '@wagmi/core';
import { Address } from 'viem';

const contractAddress = process.env.REACT_APP_ROLE_CONTRACT;

interface ReturnValue {
  grantRole: (wallet: string) => Promise<void | Error>;
  isWaiting: boolean;
}

export const useGrantRole = (): ReturnValue => {
  const [isWaiting, setIsWaiting] = React.useState<boolean>(false);

  const grantRole = React.useCallback(
    async (wallet: string) => {
      setIsWaiting(true);
      let result;
      try {
        const { request } = await prepareWriteContract({
          address: contractAddress as Address,
          abi: ETT.abi,
          functionName: 'grantRole',
          args: [
            UserRolesHexMap['ARTIST'] as Address, wallet as Address,
          ],
        });
        result = await writeContract(request);
      } catch (e: unknown) {
        setIsWaiting(false);
        throw e;
      }
      setIsWaiting(false);
      return result;
    },
    [],
  );

  return { grantRole, isWaiting };
};
