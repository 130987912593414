import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Footer } from '../../components/Footer/Footer';
import { BaseLayout } from '../../components/BaseLayout/BaseLayout';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { SxProps, Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import useTopArtistsPagination from '../../hooks/useTopArtistsPagination';
import { getTotalSale } from 'helpers/user';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import { Header } from '../../components/Header';

const TopArtistsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { artists } = useTopArtistsPagination(100);

  return (
    <BaseLayout>
      <Container fixed sx={{ height: '100%', m: '0 auto' }}>
        <Header />
        <Container sx={sx.container}>
          <Typography variant="h1" align="center">
            {t('topArtists.title')}
          </Typography>

          <Box sx={sx.header}>
            <Typography variant="body1">{t('topArtists.artists')}</Typography>
            <Typography variant="body1">{t('topArtists.totalSale')}</Typography>
          </Box>

          <Divider />

          <Box sx={sx.artistList}>
            {artists.map((user, index) => (
              <Box key={user.id} sx={sx.artistBox} onClick={() => navigate(`${routes.userProfile}/${user.wallet}`)}>
                <Typography sx={sx.artistNumber} variant="subtitle1">
                  {index + 1}
                </Typography>

                <Box sx={sx.artistAvatar}>
                  <Avatar sx={sx.preview} alt={'username'} src={user.settings?.avatar?.url} variant="circular" />
                </Box>

                <Box sx={sx.artistNameValue}>
                  <Typography variant="body2" sx={sx.artistName}>
                    {user.username || user.wallet}
                  </Typography>

                  <Typography variant="body2" sx={sx.artistValue}>
                    {getTotalSale(user.artistTotalSale)}Ξ
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Container>
      </Container>
      <Footer />
    </BaseLayout>
  );
};

export default TopArtistsPage;

const sx: Record<string, SxProps<Theme>> = {
  container: {
    boxSizing: 'border-box',
    padding: '0 20px',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    pb: 1,
    pt: 5,
  },
  artistList: {
    padding: '20px 0',
  },
  artistBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    mb: 4,
  },
  artistNumber: {
    width: '5%',
    fontSize: 18,
  },
  artistNameValue: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    boxSizing: 'border-box',
  },
  artistName: {
    color: 'black',
    fontSize: 16,
    fontWeight: 600,
    width: '70%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  artistValue: {
    color: 'grey.700',
    fontSize: 16,
    width: '30%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'right',
  },
  artistAvatar: {
    width: '50px',
  },
  preview: {
    width: 40,
    height: 40,
  },
};
