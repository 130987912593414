import React, { useRef } from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { ReactComponent as MoveIcon } from 'components/Icons/move_list_item.svg';
import { arrayMove, List } from 'react-movable';
import { Loader } from 'components/UiKit/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { defaultAvatarSrc, routes } from 'app-settings';
import { useApiContext } from 'contexts/ApiContext';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { User } from 'codegen-api/api-totemo-service/models';
import { ReactComponent as ReturnIcon } from 'components/Icons/back.svg';
import Avatar from '@mui/material/Avatar';
import { NonMovableContainer } from 'components/UiKit/NonMovableContainer/NonMovableContainer';
import { Header } from 'components/Header';

export const AdminPanelFeaturedArtistReorder: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { users: userApi } = useApiContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [artists, setArtists] = React.useState<User[]>([]);

  const container = useRef(null);

  const handleChangeOrder = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setArtists(arrayMove(artists, oldIndex, newIndex));
  };

  const handleRedirectToFeaturedArtist = () => {
    navigate(routes.adminPanelFeaturedArtist);
  };

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await userApi.userControllerUpdateFeatureUsersOrder(artists.map((item, i) => ({ ...item, order: i + 1 })));
      navigate(routes.adminPanelFeaturedArtist);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    const loadArtists = () => {
      userApi
        .userControllerFindAll(undefined, 'true', 'true', undefined)
        .then((res) => setArtists((res.data.items as unknown as User[]) || []));
      setIsLoading(false);
    };

    loadArtists();
  }, [userApi]);

  return (
    <BaseLayout>
      <Container ref={container} sx={sx.root}>
        <Header />
        <Box pt={0} sx={sx.content}>
          <Typography variant="h3" ml={2.5} display={'flex'} alignItems={'center'}>
            <IconButton
              color="primary"
              aria-label="return"
              component="span"
              sx={sx.returnBtn}
              onClick={handleRedirectToFeaturedArtist}
            >
              <ReturnIcon />
            </IconButton>
            {t('adminPanel.reorderTitle')}
          </Typography>
          <List
            values={artists}
            onChange={handleChangeOrder}
            lockVertically={true}
            renderList={({ children, props }) => (
              <Box sx={sx.list} {...props}>
                {children}
              </Box>
            )}
            renderItem={({ value, props }) => (
              <Box component="li" key={value.id} sx={sx.art} {...props}>
                <Box sx={sx.icon}>
                  <MoveIcon />
                </Box>
                <NonMovableContainer sx={sx.nonMovable}>
                  <Avatar sx={sx.avatar} alt={value.username} src={value.settings?.avatar?.url || defaultAvatarSrc} />
                  <Typography variant="paragraphMedium" sx={sx.name}>
                    {value.username}
                  </Typography>
                </NonMovableContainer>
              </Box>
            )}
          />
          <Box>{isLoading && <Loader />}</Box>
          <Box sx={sx.saveBtnContainer}>
            <AsyncButton isLoading={isSubmitting} fullWidth variant="contained" color="accent" onClick={handleSave}>
              {t('common.save')}
            </AsyncButton>
          </Box>
        </Box>
      </Container>
    </BaseLayout>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  list: {
    m: 0,
    p: 0,
    listStyleType: 'none',
  },
  art: {
    display: 'flex',
    alignItems: 'center',
    mt: 2.5,
    px: 2.5,
  },
  icon: {
    color: 'grey.600',
    fontSize: 0,
    p: 0,
    flex: '0 0 auto',
  },
  avatar: {
    ml: 1.25,
    mr: 1.8,
    flex: '0 0 auto',
  },
  name: {
    height: (theme) => theme.spacing(7.5),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  saveBtnContainer: {
    width: '100%',
    p: 2.5,
    backgroundColor: 'background.default',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.25)',
  },
  returnBtn: {
    p: 0,
    mr: 2,
  },
  nonMovable: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    height: '100%',
    borderBottom: (theme) => `solid 1px ${theme.palette.grey[300]}`,
  },
  root: {
    m: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
};
