import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Chip, Container, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ImageUploader from 'components/UiKit/ImageUploader/ImageUploader';
import { Theme } from '@mui/material/styles';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCreateArtReducerContext } from '../useCreateEditArtReducer';
import { useBlocker } from 'hooks/useBlocker';
import { Transition } from 'history';
import { CancelModal } from 'components/Modal/CancelModal/CancelModal';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { localStorageKeys } from 'app-settings';
import { ArtFormRef, ArtInfoForm, createArtLocalStorage } from '../types';
import { ReactComponent as CloseIcon } from 'components/Icons/remove_tag.svg';
import { useApiContext } from 'contexts/ApiContext';
import { useDebounce } from 'use-debounce';
import { Tag, Event } from 'codegen-api/api-totemo-service/models';
import { ScrollToFieldErrors } from 'components/ScrollToFieldErrors/ScrollToFieldErrors';
import { ReactComponent as ArrowIcon } from 'components/Icons/arrow_down.svg';
import { ReactComponent as ClearIcon } from 'components/Icons/close.svg';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import isEmpty from 'lodash/isEmpty';

type FormValues = {
  title: string;
  description: string;
  location: string;
  tags?: Tag[];
  event?: Event;
};

const formInitialValues = {
  title: '',
  description: '',
  location: '',
  tags: [],
  event: undefined,
};

interface Props {
  artFormRef?: ArtFormRef;
}

const ArtInfo: React.FC<Props> = ({}) => {
  const { t } = useTranslation();
  const { tags: tagsApi, events: eventsApi } = useApiContext();
  const { state, dispatch } = useCreateArtReducerContext();
  const [storedForm, setStoredForm] = useLocalStorage<createArtLocalStorage | null>(localStorageKeys.createArt, null);

  const [fileError, setFileError] = useState(false);
  const [loadedFiles, setLoadedFiles] = useState<Array<File>>([]);
  const [showCoverFiles, setShowCoverFiles] = useState<boolean>(false);
  const [loadedVideoCoverFiles, setLoadedVideoCoverFiles] = useState<Array<File>>([]);
  const [fileCoverError, setFileCoverError] = useState(false);

  const [navigationTx, setNavigationTx] = useState<Transition | null>(null);

  const [tags, setTags] = useState<Tag[]>(storedForm?.artInfoForm?.tags || state.artInfoForm?.tags || []);
  const [searchTagsValue] = useDebounce(state.searchTagValue, 400);
  const [isTagOptionsOpen, setTagsOpen] = useState<boolean>(false);
  const [isEventsOptionsOpen, setEventsOpen] = useState<boolean>(false);
  const [tagOptionsLoading, setTagsLoading] = useState<boolean>(false);
  const [eventsOptionsLoading, setEventsLoading] = useState<boolean>(false);

  React.useEffect(() => {
    setTagsLoading(true);
    tagsApi
      .tagControllerFindAll(10, searchTagsValue || undefined)
      .then((resp) => {
        dispatch({ type: 'FETCH_TAGS', payload: resp.data });
        setTagsLoading(false);
      })
      .catch((_e) => {
        dispatch({ type: 'FETCH_TAGS', payload: [] });
        setTagsLoading(false);
      });
  }, [dispatch, searchTagsValue, tagsApi]);

  React.useEffect(() => {
    if (loadedFiles.length) {
      setShowCoverFiles(true);
    } else {
      setShowCoverFiles(false);
      setLoadedVideoCoverFiles([]);
    }
  }, [loadedFiles]);

  React.useEffect(() => {
    setEventsLoading(true);
    eventsApi
      .eventsControllerFindAll()
      .then((resp) => {
        dispatch({ type: 'FETCH_EVENTS', payload: resp.data });
        setEventsLoading(false);
      })
      .catch((_e) => {
        dispatch({ type: 'FETCH_EVENTS', payload: [] });
        setEventsLoading(false);
      });
  }, [dispatch, eventsApi, tagsApi]);

  const handleCancelClose = () => setNavigationTx(null);

  const handleClickNextToSale = (form: ArtInfoForm) => {
    if (!state.isEdit) {
      setStoredForm({
        ...(storedForm ? storedForm : {}),
        artInfoForm: form,
      });
    }
    dispatch({ type: 'UPDATE_ART_INFO', payload: form });
  };

  const onSubmit = ({ title, description, location, event }: FormValues) => {
    const formValues: ArtInfoForm = {
      title,
      description,
      location,
      files: loadedFiles,
      coverFiles: loadedVideoCoverFiles,
      // @ts-ignore
      tags,
      event,
    };
    handleClickNextToSale(formValues);
  };

  const artInfoValidationSchema = useMemo(() => {
    return Yup.object().shape({
      title: Yup.string()
        .trim()
        .required(t('forms.errors.fieldRequired', { field: 'Title' }))
        .max(500, t('forms.errors.fieldMaxLength', { field: 'Title', length: 500 })),
    });
  }, [t]);

  const initialValues = useMemo(() => {
    if (state.isEdit && state.artInfoForm) {
      return state.artInfoForm;
    }

    if (storedForm && storedForm?.artInfoForm) {
      return storedForm.artInfoForm;
    }

    return formInitialValues;
  }, [storedForm, state]);

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: artInfoValidationSchema,
    onSubmit,
  });

  const {
    handleSubmit,
    handleChange,
    errors,
    touched,
    dirty,
    values,
    setFieldValue,
    setFieldTouched,
    setStatus,
    status,
  } = formik;

  const handleChangeSearchTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    // validation message if there is a value
    setFieldTouched('tags', !!e.target.value.length, true);

    if (e.target.value.indexOf(' ') !== -1) {
      setStatus({ tags: t('forms.errors.fieldUnsupportedCharacters') });
    } else if (e.target.value.length > 50) {
      setStatus({ tags: t('forms.errors.fieldMaxLength', { length: 50 }) });
    } else if (tags.length >= 11) {
      setStatus({ tags: t('forms.errors.fieldMaxLengthTags', { length: 11 }) });
    } else {
      setStatus({});
    }

    dispatch({ type: 'SEARCH_TAGS_CHANGED', payload: e.target.value });
  };

  const handleAddTags = (event: React.SyntheticEvent, value: (string | Tag)[], reason: string) => {
    if (!status?.tags && tags.length < 11 && (reason === 'createOption' || reason === 'selectOption')) {
      // @ts-ignore
      const mappedTags = value.map((tag: Tag) => {
        return { id: tag.id || undefined, name: tag.name || tag };
      });

      setTags(mappedTags);

      setFieldValue('tags', mappedTags);
    } else {
      setStatus({});
      setFieldTouched('tags', false, true);
    }
  };

  const handleDeleteTags = (label: string) => () => {
    const filteredTags = tags?.filter((item: Tag) => item.name !== label);
    setTags(filteredTags || []);

    setFieldValue('tags', filteredTags || []);
  };

  const blocker = useCallback(
    (tx: Transition) => {
      if (loadedFiles.length || dirty) {
        setNavigationTx(tx);
      } else {
        tx.retry();
      }
    },
    [loadedFiles, dirty],
  );

  useBlocker(blocker);

  const navigateToImageInput = () => {
    const element = document.querySelector(`label[for='image']`);
    if (!element) return;

    let scrollTop = window.scrollY || element.scrollTop;

    const finalOffset = element.getBoundingClientRect().top + scrollTop - document.documentElement.clientHeight / 2;

    window.parent.scrollTo({
      top: finalOffset,
      behavior: 'smooth',
    });
  };

  const handleClickNext = () => {
    if (!state.isEdit && (!loadedFiles.length || (showCoverFiles && !loadedVideoCoverFiles.length))) {
      setFileError(true);
      setFileCoverError(true);
      navigateToImageInput();
      return;
    }
    handleSubmit();

  };

  return (
    <Container sx={sx.container}>
      <ImageUploader
        name={'image'}
        onChange={(files) => setLoadedFiles(files)}
        onError={(error) => setFileError(!!error)}
        max={1}
        value={state.art?.artImage?.url}
        text={t('pages.createArt.chooseFileNote')}
        errorText={fileError ? t('pages.createArt.chooseFileErrorText') : undefined}
      />

      {showCoverFiles && <ImageUploader
        name={'coverImage'}
        onChange={(files) => setLoadedVideoCoverFiles(files)}
        onError={(error) => setFileCoverError(!!error)}
        max={1}
        value={state.art?.previewImage?.url}
        label={t('pages.createArt.chooseCoverImageFileLabel')}
        text={t('pages.createArt.chooseImageFileNote')}
        errorText={fileCoverError ? t('pages.createArt.chooseFileErrorText') : undefined}
        accept="image/png,image/jpeg,image/gif,image/webp"
      />}

      <Typography variant="h4" sx={sx.title}>
        {t('pages.createArt.nftInformation')}
      </Typography>
      <form onChange={handleChange}>
        <ScrollToFieldErrors formikProps={formik} />
        <Box sx={sx.inputIndent}>
          <OutlinedTextInput
            required={true}
            name="title"
            id="title"
            value={values['title']}
            label={t('pages.createArt.title')}
            error={!!errors.title}
            helperText={errors.title && t(errors.title as string)}
          />
        </Box>

        <Box sx={sx.inputIndent}>
          <OutlinedTextInput
            required={false}
            multiline={true}
            rows={4}
            value={values['description']}
            name="description"
            id="description"
            label={t('pages.createArt.description')}
            error={!!(touched.description && errors.description)}
            helperText={touched.description && t(errors.description as string)}
          />
        </Box>

        <Box sx={sx.inputIndent}>
          <OutlinedTextInput
            required={false}
            name="location"
            id="location"
            value={values['location']}
            label={t('pages.createArt.location')}
            error={!!(touched.location && errors.location)}
            helperText={touched.location && t(errors.location as string)}
          />
        </Box>

        <Box sx={sx.inputIndent}>
          <Autocomplete
            id="tags"
            multiple
            freeSolo
            value={tags}
            open={isTagOptionsOpen}
            onOpen={() => setTagsOpen(true)}
            onClose={() => setTagsOpen(false)}
            loading={tagOptionsLoading}
            popupIcon={false}
            options={state.tags!}
            filterSelectedOptions
            getOptionLabel={(option: Tag) => option.name}
            isOptionEqualToValue={(option: Tag, value: Tag) => option.name === value.name}
            clearIcon={false}
            onChange={handleAddTags}
            renderTags={() => null}
            renderInput={(params) => (
              <OutlinedTextInput
                {...params}
                label={t('common.tags')}
                onChange={handleChangeSearchTags}
                error={!!(touched.tags && (errors.tags || status?.tags))}
                helperText={touched.tags && (t(errors.tags as string) || status?.tags)}
              />
            )}
          />

          <Box mt={1} sx={sx.chipContainer}>
            {tags?.map((item, idx) => (
              <Chip
                sx={sx.chip}
                variant="filled"
                key={`${item.name} ${idx}`}
                label={item.name}
                deleteIcon={<CloseIcon />}
                onDelete={handleDeleteTags(item.name)}
              />
            ))}
          </Box>
        </Box>

        <Box sx={sx.inputIndent}>
          <Autocomplete
            open={isEventsOptionsOpen}
            onOpen={() => setEventsOpen(true)}
            onClose={() => setEventsOpen(false)}
            loading={eventsOptionsLoading}
            getOptionLabel={(option: Event) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            clearIcon={<ClearIcon />}
            options={state.events?.length ? state.events : []}
            onChange={(_event, value) => setFieldValue('event', value)}
            renderInput={(params) => (
              <OutlinedTextInput {...params} name="event" id="event" label={t('pages.createArt.eventName')} />
            )}
            popupIcon={<ArrowIcon />}
            defaultValue={initialValues.event}
          />
        </Box>
      </form>

      <>
        <Button variant="contained" color="secondary" onClick={handleClickNext} sx={sx.next}>
          {t('pages.createArt.next')}
        </Button>

        <Typography variant="caption">{t('pages.createArt.nextDescription')}</Typography>
      </>
      )

      <CancelModal
        open={!!navigationTx}
        handleClose={handleCancelClose}
        handleContinue={() => {
          navigationTx?.retry();
          handleCancelClose();
        }}
      />
    </Container>
  );
};

export default ArtInfo;

const sx: Record<string, SxProps<Theme>> = {
  container: {
    margin: (theme) => theme.spacing(0, 0, 8, 0),
  },
  title: {
    margin: '40px 0 0 0',
    textAlign: 'left',
  },
  inputIndent: {
    padding: '15px 0 0 0',
  },
  next: {
    width: '100%',
    margin: '50px 0 0 0',
  },
  chip: {
    backgroundColor: 'grey.150',
    height: 40,
    borderRadius: 1,
    fontWeight: 'bold',
    '& .MuiChip-deleteIcon': {
      mr: 2,
    },
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
};
