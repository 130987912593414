import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { noop } from 'helpers';
import { ArtOwnerItemSx as sx } from 'pages/Art/ArtTabs/ArtOwnerItemSx';
import {
  ArtArtStatusEnum,
  BuyNow,
  CreateBuyNowDtoStatusEnum,
  SaleTransactionInfo,
} from 'codegen-api/api-totemo-service/models';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { EMPTY_USER, useAppState } from 'contexts/appState/AppContext';
import { isBuyNowAvailableMultiple } from 'pages/Art/utils/buyNow';
import { useMultipleContractCancel } from 'abi/useMultipleContractCancel';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { useApiContext } from 'contexts/ApiContext';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { EmailNotVerified } from 'components/Modal/EmailNotVerified/EmailNotVerified';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';

export const ArtOwnerSaleAction: React.FC<{ buyNow: BuyNow; isPrimaryMultiple?: boolean }> = React.memo(
  ({ buyNow, isPrimaryMultiple }) => {
    const { t } = useTranslation();
    const { dispatch, state } = useArtDetailedView();
    const api = useApiContext();
    const { currentUser } = useAppState();
    const { cancelBuyNowVoucher } = useMultipleContractCancel();
    const [isCanceling, setIsCanceling] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const isVisitor = useHasRole('VISITOR');
    const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = React.useState<boolean>(false);

    const handleClickBuy = () => {
      if (!isAuthorized) {
        navigate(routes.connect);
      } else if (!web3Authorized) {
        // web 2 authorized
        if (!isVisitor) {
          setIsVerifyEmailModalOpen(true);
        } else {
          navigate(routes.connectWallet);
        }
      } else {
        dispatch({ type: 'SHOW_DRAWER_FOR_SELECTED_BUY_NOW', payload: buyNow.id });
      }
    };

    const isPending =
      buyNow.transactions?.some(
        (transaction: SaleTransactionInfo) => transaction.signer?.toLowerCase() === currentUser.wallet,
      ) && !isPrimaryMultiple;

    if (isPending) {
      return (
        <Button sx={sx.buy} fullWidth variant={'contained'} color={'secondary'} size="medium" onClick={noop}>
          {t('artPage.pending')}
        </Button>
      );
    }

    const web3Authorized = !!currentUser.wallet && currentUser.wallet !== EMPTY_USER.wallet;
    const isAuthorized = web3Authorized || currentUser.email;

    const showBuyButton = !isPending && isBuyNowAvailableMultiple(buyNow, currentUser.wallet);
    if (showBuyButton) {
      return (
        <>
          <Button sx={sx.buy} fullWidth variant={'contained'} color={'primary'} size="medium" onClick={handleClickBuy}>
            {t('artPage.buy')}
          </Button>
          <EmailNotVerified open={isVerifyEmailModalOpen} handleClose={() => setIsVerifyEmailModalOpen(false)} />
        </>
      );
    }
    const showCancelSalesButton = isCancelSaleAvailable(buyNow, currentUser.wallet);

    if (showCancelSalesButton) {
      const handleCancelBuyNow = async () => {
        try {
          setIsCanceling(true);
          if (buyNow.isFirstSale && state.art!.owners?.length > 1) {
            const resp = await cancelBuyNowVoucher({
              tokenId: state.art!.tokenId,
              authorWallet: buyNow.authorWallet,
              pmWallet: buyNow.pmWallet,
              price: buyNow.marketPrice,
              startDate: buyNow.startMarketDate,
              copies: state.art!.amount,
              ownerWallet: buyNow.ownerWallet,
              signature: buyNow.signature,
            });

            await api.buyNow.buyNowControllerCancelMultiple({
              buyNowId: buyNow.id,
              transactionHash: resp.result.hash,
              uuid: resp.uuid,
              signer: resp.result.from.toLowerCase(),
              amount: buyNow.amount,
            });
            dispatch({
              type: 'UPDATE_BUY_NOW_STATUS',
              payload: { id: buyNow.id, status: CreateBuyNowDtoStatusEnum.PENDING },
            });
            setIsCanceling(false);
            return;
          }

          await api.buyNow.buyNowControllerCancelMultiple({
            buyNowId: buyNow.id,
            amount: buyNow.amount,
          } as any);

          dispatch({
            type: 'CANCEL_SUCCESS',
            payload: {
              infoPopupType: buyNow.isFirstSale ? 'movedToDrafts' : '',
              artStatus: buyNow.isFirstSale ? ArtArtStatusEnum.DRAFT : ArtArtStatusEnum.PUBLISHED,
              saleId: buyNow.id,
            },
          });
        } catch (e) {
          console.log(e);
        }

        setIsCanceling(false);
      };

      return (
        <AsyncButton
          isLoading={isCanceling || (!!isPrimaryMultiple && buyNow.status === CreateBuyNowDtoStatusEnum.PENDING)}
          sx={sx.buy}
          fullWidth
          variant={'contained'}
          color={'secondary'}
          size="medium"
          onClick={handleCancelBuyNow}
        >
          {t('common.cancel')}
        </AsyncButton>
      );
    }
    return null;
  },
);

const isCancelSaleAvailable = (buyNow: BuyNow, currentUserWallet: string): boolean => {
  return currentUserWallet === buyNow.ownerWallet || currentUserWallet === buyNow.pmWallet;
};
