import React from 'react';
import { getUnixTime } from 'date-fns';
import TotemoSingle from './contracts/TotemoSingle';
import { SaleInfoForm } from 'pages/CreateEditArtPage/types';
import { getFullPriceWei } from 'pages/CreateEditArtPage/utils';
import { signTypedData } from '@wagmi/core';
import { useAccount, useChainId } from 'wagmi';
import { prepareWriteContract, writeContract } from '@wagmi/core';
import { Art, Auction } from '../codegen-api/api-totemo-service/models';
import { getMarketPrice } from '../helpers/getMarketPrice';
import { useApiContext } from '../contexts/ApiContext';
import { Address, parseEther } from 'viem';

type Mint = (
  to: string,
  tokenId: number,
) => Promise<string>

type TransferFrom = (
  from: string,
  to: string,
  tokenId: number,
) => Promise<string>

type FinalizeAuction = (art: Art, saleData: Auction) => Promise<{ finalizeAuctionResult: { from?: Address, hash: string } }>

type ReturnValues = {
  isLoading: boolean;
  createSaleVoucher(
    tokenId: number,
    authorWallet: string,
    pmWallet: string,
    saleInfo: SaleInfoForm,
    ownerWallet: string,
    isSecondarySale?: boolean,
  ): Promise<string>;
  createAuctionVoucher(
    tokenId: number,
    authorWallet: string,
    pmWallet: string,
    saleInfo: SaleInfoForm,
    ownerWallet: string,
    isSecondarySale?: boolean,
  ): Promise<string>;
  mint: Mint
  transferFrom: TransferFrom
  finalizeAuction: FinalizeAuction
};

const contractAddress = process.env.REACT_APP_SINGLE_CONTRACT as `0x${string}`;

export const useSingleContract = (): ReturnValues => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const chainId = useChainId();
  const { auction: auctionApi } = useApiContext();
  const { address: buyerWallet } = useAccount();

  const createSaleVoucher = React.useCallback(
    async (
      tokenId: number,
      authorWallet: string,
      pmWallet: string,
      saleInfo: SaleInfoForm,
      ownerWallet: string,
      isSecondarySale?: boolean,
    ): Promise<string> => {
      setIsLoading(true);
      try {
        const domain = {
          name: 'TOTEMO_VOUCHER',
          version: '1',
          verifyingContract: contractAddress,
          chainId,
        };


        // prettier-ignore
        const types = {
          TotemoSingleVoucher: [
            { name: 'tokenId', type: 'uint256' },
            { name: 'startMarketDate', type: 'uint256' },
            { name: 'author', type: 'address' },
            { name: 'isFirstSale', type: 'bool' },
            { name: 'owner', type: 'address' },
            { name: 'assetContract', type: 'address' },
            { name: 'price', type: 'uint256' },
            { name: 'pm', type: 'address' },
          ],
        };

        const weiPrice = getFullPriceWei(String(saleInfo.price), String(saleInfo.primaryServiceFee));
        const voucher = {
          tokenId: tokenId,
          startMarketDate: getUnixTime(saleInfo.startDate),
          author: authorWallet,
          isFirstSale: !isSecondarySale,
          owner: ownerWallet,
          assetContract: contractAddress,
          price: weiPrice,
          pm: pmWallet,
        };

        return signTypedData({
          domain: domain,
          types,
          primaryType: 'TotemoSingleVoucher',
          message: voucher,
        });
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [chainId],
  );
  const createAuctionVoucher = React.useCallback(
    async (
      tokenId: number,
      authorWallet: string,
      pmWallet: string,
      saleInfo: SaleInfoForm,
      ownerWallet: string,
      isSecondarySale?: boolean,
    ): Promise<string> => {
      setIsLoading(true);
      try {
        const domain = {
          name: 'TOTEMO_VOUCHER',
          version: '1',
          verifyingContract: process.env.REACT_APP_SINGLE_CONTRACT as `0x${string}`,
          chainId,
        };

        // prettier-ignore
        const types = {
          TotemoAuctionVoucher: [
            { name: 'tokenId', type: 'uint256' },
            { name: 'startAuctionDate', type: 'uint256' },
            { name: 'endAuctionDate', type: 'uint256' },
            { name: 'author', type: 'address' },
            { name: 'isFirstSale', type: 'bool' },
            { name: 'owner', type: 'address' },
            { name: 'assetContract', type: 'address' },
            { name: 'minBid', type: 'uint256' },
            { name: 'pm', type: 'address' },
          ],
        };

        const weiPrice = getFullPriceWei(String(saleInfo.startingPrice), String(saleInfo.primaryServiceFee));
        const voucher = {
          tokenId: tokenId,
          startAuctionDate: getUnixTime(saleInfo.startDate),
          endAuctionDate: getUnixTime(saleInfo.endDate!),
          author: authorWallet,
          isFirstSale: !isSecondarySale,
          owner: ownerWallet,
          assetContract: process.env.REACT_APP_SINGLE_CONTRACT,
          minBid: weiPrice, // TODO: deprecated
          pm: pmWallet,
        };

        return signTypedData({
          domain: domain,
          types,
          primaryType: 'TotemoAuctionVoucher',
          message: voucher,
        });
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [chainId],
  );

  const mint = React.useCallback<Mint>(
    async (
      to,
      tokenId,
    ) => {
      setIsLoading(true);

      try {
        const { request } = await prepareWriteContract({
          address: contractAddress,
          abi: TotemoSingle.abi,
          functionName: 'mint',
          args: [
            to as Address, BigInt(tokenId),
          ],
        });

        const { hash } = await writeContract(request);
        return hash;
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [],
  );

  const transferFrom = React.useCallback<TransferFrom>(
    async (
      from,
      to,
      tokenId,
    ) => {
      setIsLoading(true);
      try {
        const { request } = await prepareWriteContract({
          address: contractAddress,
          abi: TotemoSingle.abi,
          functionName: 'transferFrom',
          args: [
            from as Address, to as Address, BigInt(tokenId),
          ],
        });
        const contractResult = await writeContract(request);
        return contractResult.hash;
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [],
  );


  const finalizeAuction: FinalizeAuction = React.useCallback(async (art: Art, saleData: Auction) => {
    setIsLoading(true);
    let finalizeAuctionResult;

    try {
      const optionalAddress: Address[] = [];
      let fees: bigint[] = [];
      try {
        const moneyDistribution = JSON.parse(art.moneyDistribution);
        const { address, addressPercentage, servicePercentage, totemoPercentage, artistPercentage } =
          moneyDistribution[saleData.isFirstSale ? 'primarySale' : 'secondarySale'];

        fees = [BigInt(+totemoPercentage), BigInt(+servicePercentage), BigInt(+artistPercentage)];
        if (address && addressPercentage) {
          optionalAddress.push(address);
          fees.push(addressPercentage);
        }
      } catch (e) {
        setIsLoading(false);
      }
      const weiPrice = parseEther(getMarketPrice(saleData).toString());

      const voucher = {
        tokenId: art.tokenId,
        startAuctionDate: saleData.startDate,
        endAuctionDate: saleData.endDate,
        author: saleData.authorWallet,
        isFirstSale: saleData.isFirstSale,
        owner: saleData.ownerWallet,
        assetContract: contractAddress,
        minBid: weiPrice, // TODO: deprecated
        pm: saleData.pmWallet,
        signature: saleData.signature,
      };

      // @ts-ignore
      finalizeAuctionResult = await writeContract({
        address: contractAddress,
        abi: TotemoSingle.abi,
        functionName: 'finalizeAuction',
        args: [
          buyerWallet as Address,
          voucher,
          optionalAddress,
          fees,
        ],
        value: weiPrice,
      });


      await auctionApi.auctionControllerLockSingle({
        auctionId: saleData.id,
        transactionHash: finalizeAuctionResult.hash,
        signer: buyerWallet,
      });

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }

    return {
      finalizeAuctionResult: {
        ...finalizeAuctionResult,
        from: buyerWallet,
      },
    };
  }, []);

  return { isLoading, createSaleVoucher, createAuctionVoucher, mint, transferFrom, finalizeAuction };
};
