import React from 'react';
import {
  DEFAULT_MONEY_DISTRIBUTION_PRIMARY,
  DEFAULT_MONEY_DISTRIBUTION_SECONDARY,
} from 'helpers/moneyDistributionHelpers';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import type { BuyNow } from 'codegen-api/api-totemo-service/models';

export const useServiceFee = (buyNow?: BuyNow): number => {
  const { state } = useArtDetailedView();
  const saleData = buyNow || state.saleData;
  return React.useMemo(() => {
    if (!state.art || !saleData) return 0;
    let distribution;
    try {
      distribution = JSON.parse(state.art.moneyDistribution)[saleData.isFirstSale ? 'primarySale' : 'secondarySale'];
    } catch (e) {
      distribution = saleData.isFirstSale ? DEFAULT_MONEY_DISTRIBUTION_PRIMARY : DEFAULT_MONEY_DISTRIBUTION_SECONDARY;
    }
    return +distribution.servicePercentage;
  }, [state.art, saleData]);
};
