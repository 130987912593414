import React, { useEffect } from 'react';
import AsyncButton from '../../components/UiKit/AsyncButton/AsyncButton';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useArtDetailedView } from './ArtDetailedViewContext';
import { useApiContext } from 'contexts/ApiContext';
import { isAuction } from 'helpers/isAuction';
import { isActiveAuction } from './utils/auction';
import { Auction } from 'codegen-api/api-totemo-service/models';
import { EMPTY_USER, useAppState } from 'contexts/appState/AppContext';
import { useTranslation } from 'react-i18next';

const ArtFollow: React.FC = () => {
  const { currentUser } = useAppState();
  const { state } = useArtDetailedView();
  const { t } = useTranslation();
  const { follow: followApi } = useApiContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFollow, setIsFollow] = React.useState(false);

  const artOnAuction = isAuction(state.saleData);
  const isActive = artOnAuction ? isActiveAuction(state.saleData as Auction) : false;
  const auction = state.saleData as Auction;

  const isFollowingAllowed =
    !!currentUser.wallet &&
    currentUser.wallet !== EMPTY_USER.wallet &&
    artOnAuction &&
    isActive &&
    currentUser.wallet !== auction?.ownerWallet;

  useEffect(() => {
    if (isFollowingAllowed) {
      followApi
        .followControllerIsFollow(auction?.id, 'AUCTION')
        .then(({ data }) => {
          const isFollow = data.follow;
          setIsFollow(isFollow);
        })
        .catch(() => {
          setIsFollow(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [followApi, auction]);

  const handleToggleFollowing = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const body = {
        followedId: auction?.id,
        type: 'AUCTION',
      };
      await (isFollow ? followApi.followControllerUnfollow(body) : followApi.followControllerCreate(body));
      setIsFollow(!isFollow);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isFollowingAllowed) {
    return null;
  }

  return (
    <AsyncButton
      variant="contained"
      color={isFollow ? 'secondary' : 'accent'}
      type="button"
      isLoading={isLoading}
      sx={sx.button}
      onClick={handleToggleFollowing}
    >
      {t(isFollow ? 'artPage.unfollow' : 'artPage.follow')}
    </AsyncButton>
  );
};

export default ArtFollow;

const sx: Record<string, SxProps<Theme>> = {
  button: {
    minWidth: 'auto',
    minHeight: 'auto',
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    p: 1.25,
  },
};
