import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { validateMaxSize } from 'helpers/file';
import Image, { DataUrl } from './Image';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  onChange: (files: File[]) => void;
  onError: (error: string) => void;
  max: number;
  text: string;
  errorText?: string;
  accept?: string;
  value?: string;
  label?: string;
}

const defaults = {
  accept: 'image/png,image/jpeg,image/gif,image/webp,video/mp4',
};

const maxSizeByte = 100000000;

const ImageUploader: React.FC<Props> = ({
  name,
  onChange,
  onError,
  max,
  text,
  errorText,
  value,
  accept = defaults.accept,
  label,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [dataUrls, setDataUrls] = useState<Array<DataUrl>>([]);
  const [loadedFiles, setLoadedFiles] = useState<Array<File>>([]);
  const [error, setError] = useState<string>('');

  const onChangeRef = useRef(onChange);
  const onErrorRef = useRef(onError);

  useEffect(() => {
    if (!value) return;

    setDataUrls([{ data: value!, type: 'image' }]);
  }, [value]);

  useEffect(() => {
    onChangeRef.current(loadedFiles);
  }, [loadedFiles]);

  useEffect(() => {
    onErrorRef.current(error);
  }, [error]);

  const uploadFile = (file: File): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (_e) => {
      setDataUrls((prevState) => [
        { data: reader.result, type: file.type.startsWith('image/') ? 'image' : 'video' },
        ...prevState,
      ]);
      setLoadedFiles((prevState) => [file, ...prevState]);
    };
  };

  const handleUploadImageClick = (event: ChangeEvent<HTMLInputElement>): void => {
    const files = event.target.files;
    const validSize = validateMaxSize(files!, maxSizeByte);

    if (!validSize) {
      setError('fileSize');
      return;
    }

    setError('');

    const cachedFiles: File[] = [];

    for (let i = 0; i < files!.length; i++) {
      if (max <= i + dataUrls.length) {
        return;
      }
      const file = files!.item(i);
      uploadFile(file!);
      cachedFiles.push(file!);
    }

    inputRef.current!.value = '';
  };

  const handleClearImg = (index: number) => {
    setDataUrls(dataUrls.filter((file, i) => i !== index));
    setLoadedFiles(loadedFiles.filter((file, i) => i !== index));
    inputRef.current!.value = '';
  };

  return (
    <Box>
      <input
        ref={inputRef}
        accept={accept}
        style={{ display: 'none' }}
        id={name}
        multiple={false}
        type="file"
        onChange={handleUploadImageClick}
      />

      <Box sx={sx.area}>
        {!dataUrls.length && (
          <Box>
            <label htmlFor={name}>
              <Typography variant="h4" sx={sx.chooseButton}>
                {label || t('forms.chooseFile')}
              </Typography>
            </label>
            <Typography
              variant="paragraphMedium"
              component="p"
              sx={sx.text}
              color={!!errorText ? 'error.main' : 'grey.600'}
            >
              {errorText || text}
            </Typography>
          </Box>
        )}

        {!!dataUrls.length && (
          <Box sx={sx.imgWrapper}>
            {dataUrls.map((url, index) => (
              <Image key={url.data!.toString() + index} index={index} url={url} handleClearImg={handleClearImg} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ImageUploader;

const sx: Record<string, SxProps<Theme>> = {
  area: {
    minHeight: '194px',
    bgcolor: 'grey.100',
    width: '100%',
    padding: (theme) => theme.spacing(4, 1),
    position: 'relative',
    borderRadius: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
    overflow: 'hidden',
    padding: '0',

    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.5) !important',
    },
  },
  text: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
    fontWeight: 600,
  },
  chooseButton: {
    color: 'white',
    padding: '17px 56px 17px 56px',
    borderRadius: '5px',
    backgroundColor: 'black',
    margin: (theme) => theme.spacing(0, 0, 2.5, 0),
  },
};
