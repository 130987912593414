import React from 'react';
import { Box, Chip } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';
import type {Art, Tag} from 'codegen-api/api-totemo-service/models';

export const ArtTags: React.FC<{ art: Art }> = React.memo(({ art }) => {
  if (!art.tags) return null;

  return (
    <Box sx={sx.tags}>
      {art.tags.map((tag: Tag) => (
        <Chip sx={sx.tag} label={tag.name} key={tag.id} />
      ))}
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1.2,
    mt: 2.5,
  },
  tag: {
    borderRadius: 0.6,
    textTransform: 'capitalize',
  },
};
