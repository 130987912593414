import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SxProps, Theme } from '@mui/material/styles';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';

const LEARN_MORE_URL = 'https://linktr.ee/TOTEMO';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return <Box sx={sx.root}>
    <Box sx={sx.links}>
      <Link sx={sx.link} onClick={() => navigate(routes.noWalletSignIn)}>
        <Typography fontWeight="bold">
          {t('signIn.lookInside')}
        </Typography>
        <OpenInNewIcon />
      </Link>
      <Link sx={sx.link} href={LEARN_MORE_URL} target="_blank">
        <Typography fontWeight="bold">
          {t('signIn.learnMore')}
        </Typography>
        <OpenInNewIcon />
      </Link>
    </Box>
    <Typography
      variant="paragraphSmall"
      sx={sx.info}
    >
      <Trans
        i18nKey={'signIn.warnDescription'}
        components={[
          <Link
            target="_blank"
            underline="none"
            href="mailto:artist@totemo.com"
            rel="noreferrer"
          />,
          <Typography
            variant="paragraphSmall"
            display="inline"
            component="span"
            color="common.white"
          />,
        ]}
      />
    </Typography>
  </Box>;

};

export default Footer;


const sx: Record<string, SxProps<Theme>> = {
  root: theme => ({
    px: 2.5,
    pt: 4.25,
    pb: 7,
    borderRadius: '20px 20px 0 0',
    backgroundColor: '#CDD0D3',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      px: '75px',
      py: '45px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
  links: theme => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      mb: 4,
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: 10,
    },
  }),
  info: {
    display: 'block',
    textAlign: 'center',
    verticalAlign: 'center',
    color: 'common.white',
    fontWeight: 600,
  },

  link: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '60px',
    py: 2.5,
    textDecoration: 'none',
    cursor: 'pointer',
    gap: 3,
    ':hover': {
      color: theme => theme.palette.accent.main,
    },
  },
};
