import React, { FC } from 'react';
import Carousel, { DotProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { noop } from 'helpers';
import useIsMobileScreen from 'helpers/useIsMobileScreen';

const CustomDot: FC<DotProps> = ({ index, active, onClick }: DotProps) => {
  const isMobile = useIsMobileScreen();

  const dotStyle = isMobile
    ? 'custom-dot custom-dot-active custom-dot-mobile'
    : 'custom-dot custom-dot-active custom-dot-desktop';
  const dotActiveStyle = isMobile ? 'custom-dot-active-mobile' : 'custom-dot-active-desktop';

  const carouselItems: [] = [];
  return (
    <div className={active ? `${dotStyle} ${dotActiveStyle} ` : `${dotStyle} custom-dot-inactive`} onClick={onClick}>
      {React.Children.toArray(carouselItems)[index!]}
    </div>
  );
};

const responsiveDefault = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface Props {
  children: React.ReactNode;
  responsiveSettings?: any;
  className?: string,
}

export const CarouselWrapper: FC<Props> = ({ children, responsiveSettings, className }) => {
  return (
    <>
      {/* @ts-ignore*/}
      <Carousel
        responsive={responsiveSettings ? responsiveSettings : responsiveDefault}
        infinite
        autoPlay
        autoPlaySpeed={5000}
        transitionDuration={500}
        arrows={false}
        showDots
        renderDotsOutside
        containerClass={'carousel-wrapper'}
        customDot={<CustomDot onClick={noop} />}
        draggable={false}
        swipeable={true}
        className={className}
      >
        {children}
      </Carousel>
    </>

  );
};
