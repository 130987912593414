import React from 'react';
import { useAppDispatch } from 'contexts/appState/AppContext';
import { useApiContext } from 'contexts/ApiContext';
import { localStorageKeys, routes } from 'app-settings';
import { noop } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { useDisconnect } from 'wagmi';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const { auth } = useApiContext();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();

  return React.useCallback(
    (skipRedirect?: boolean) => {
      const token = localStorage.getItem(localStorageKeys.jwt);
      if (token) {
        auth.authControllerLogout(`Bearer ${token}`).catch(noop);
      }
      localStorage.removeItem(localStorageKeys.profile);
      localStorage.removeItem(localStorageKeys.jwt);
      disconnect();
      !skipRedirect && navigate(routes.home);
      dispatch({ type: 'LOGOUT' });
    },
    [disconnect, navigate, dispatch, auth],
  );
};
