import React from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { ReactComponent as MoveIcon } from 'components/Icons/move_list_item.svg';
import { arrayMove, List } from 'react-movable';
import { Loader } from 'components/UiKit/Loader/Loader';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from 'app-settings';
import { useApiContext } from 'contexts/ApiContext';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { Art } from 'codegen-api/api-totemo-service/models';
import { ReactComponent as ReturnIcon } from 'components/Icons/back.svg';
import { NonMovableContainer } from 'components/UiKit/NonMovableContainer/NonMovableContainer';
import { Header } from 'components/Header';

export const AdminPanelArtsReorder: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { art: artApi } = useApiContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [arts, setArts] = React.useState<Art[]>([]);

  const handleChangeOrder = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setArts(arrayMove(arts, oldIndex, newIndex));
  };

  const handleRedirectToExploreArts = () => {
    navigate(routes.adminPanelCreatedArtsExplore);
  };

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await artApi.artControllerUpdateArtOrder(arts.map((item, i) => ({ ...item, order: i + 1 })));

      navigate(routes.adminPanelCreatedArtsExplore);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    const loadEvents = async () => {
      try {
        // @ts-ignore
        const resp = (await artApi.artControllerFindAll('PROMO')).data.items || [];

        setArts(resp as Art[]);
      } finally {
        setIsLoading(false);
      }
    };

    loadEvents();
  }, [artApi]);

  React.useLayoutEffect(() => {
    const scrollToChosenArt = () => {
      window.scrollTo({
        top: state as number,
        behavior: 'smooth',
      });
    };

    if (arts.length) {
      scrollToChosenArt();
    }
  }, [arts.length, state]);

  return (
    <BaseLayout>
      <Container fixed>
        <Header />
        <Box pt={0} pb={14}>
          <Typography variant="h3" ml={2.5} display={'flex'} alignItems={'center'}>
            <IconButton
              color="primary"
              aria-label="return"
              component="span"
              sx={sx.returnBtn}
              onClick={handleRedirectToExploreArts}
            >
              <ReturnIcon />
            </IconButton>
            {t('adminPanel.reorderTitle')}
          </Typography>
          <List
            values={arts}
            onChange={handleChangeOrder}
            lockVertically={true}
            renderList={({ children, props }) => (
              <Box sx={sx.list} {...props}>
                {children}
              </Box>
            )}
            renderItem={({ value, props }) => (
              <Box component="li" key={value.id} sx={sx.art} {...props}>
                <Box sx={sx.icon}>
                  <MoveIcon />
                </Box>
                <NonMovableContainer sx={sx.nonMovable}>
                  <Box sx={sx.poster}>
                    <img alt={value.name} src={value.artImage.url} />
                  </Box>
                  <Box sx={sx.name}>
                    <span>{value.name}</span>
                  </Box>
                </NonMovableContainer>
              </Box>
            )}
          />
          {isLoading && (
            <Box>
              <Loader />
            </Box>
          )}
          <Box sx={sx.saveBtnContainer}>
            <AsyncButton isLoading={isSubmitting} fullWidth variant="contained" color="accent" onClick={handleSave}>
              {t('common.save')}
            </AsyncButton>
          </Box>
        </Box>
      </Container>
    </BaseLayout>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  list: {
    m: 0,
    p: 0,
    listStyleType: 'none',
  },
  art: {
    display: 'flex',
    alignItems: 'center',
    mt: 2.5,
    px: 2.5,
  },
  icon: {
    color: 'grey.600',
    fontSize: 0,
    p: 0,
    flex: '0 0 auto',
  },
  poster: {
    flex: '0 0 auto',
    ml: 1.5,
    mr: 1.3,
    img: {
      display: 'block',
      objectFit: 'cover',
      width: (theme) => theme.spacing(7.5),
      height: (theme) => theme.spacing(7.5),
      borderRadius: '5px',
    },
  },
  name: {
    borderBottom: (theme) => `solid 1px ${theme.palette.grey[300]}`,
    height: (theme) => theme.spacing(7.5),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  saveBtnContainer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    p: 2.5,
    backgroundColor: 'background.default',
    zIndex: 10,
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.25)',
  },
  returnBtn: {
    p: 0,
    mr: 2,
  },
  nonMovable: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    height: '100%',
  },
};
