import React from 'react';
import { Box, CardMedia, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

type Prop = {
  svgName: string;
  value: boolean;
  label: string;
  onChange(value: boolean): void;
};

const CheckCard: React.FC<Prop> = ({ value, onChange, label, svgName }) => {
  const { t } = useTranslation();

  return (
    <Box
      flex={'1 1 auto'}
      onClick={() => onChange(!value)}
      sx={{ ...sx.container, ...(value ? {} : sx.selected) } as SxProps<Theme>}
    >
      <CardMedia component="img" image={`/assets/icons/${svgName}.svg`} alt="buy-now" sx={sx.icon} />

      <Typography variant="body1" color="primary.main" sx={sx.text}>
        {t(label)}
      </Typography>
    </Box>
  );
};

export default CheckCard;

const sx: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 160,
    height: 122,
    px: 2,
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    cursor: 'pointer',
  },
  selected: {
    opacity: 0.5,
  },
  icon: {
    width: 26,
    height: 26,
    mb: 2,
  },
  text: {
    fontWeight: 700,
  },
};
