import React from 'react';
import { MarketPlaceFormValues } from '../Form/MarketPlaceFormValues';
import { User, Event, Tag } from 'codegen-api/api-totemo-service/models';
import { ArtWithSaleInfo } from 'types';

interface ArtsInfo {
  artsCount: number;
  totalPages: number;
}

interface State {
  isLoading: boolean;
  filters: Partial<MarketPlaceFormValues>;
  error: Error;
  artists: User[];
  events: Event[];
  arts: ArtWithSaleInfo[];
  artsCount: number;
  maxPriceOnMarketPlace: number;
  isFiltersOpen: boolean;
  tags: Tag[];
  searchTagValue?: string;
  currentPage: number;
  totalPages: number;
  checkedTags: Tag[];
}

export const initialFilters = {
  artist: '',
  minPrice: 0,
  maxPrice: 1000,
  tags: [],
  events: '',
};

type Action =
  | { type: 'FILL_FILTER'; payload: Partial<MarketPlaceFormValues> }
  | { type: 'RESET_FILTERS' }
  | { type: 'FETCH_ARTISTS'; payload: User[] }
  | { type: 'FETCH_EVENTS'; payload: Event[] }
  | { type: 'SET_ARTS_INFO'; payload: ArtsInfo }
  | { type: 'SET_MAX_PRICE_ON_MARKET_PLACE'; payload: number }
  | { type: 'CHECK_TAGS'; payload: string[] }
  | { type: 'FETCH_TAGS'; payload: Tag[] }
  | { type: 'SEARCH_TAGS_CHANGED'; payload: string }
  | { type: 'OPEN_FILTER_MODAL' }
  | { type: 'CLOSE_FILTER_MODAL' }
  | { type: 'CHECK_POPULAR_TAGS'; payload: Tag[] }
  | { type: 'FETCH_MORE' };

const initialState = {
  isLoading: true,
  filters: initialFilters,
  error: {} as Error,
  artists: [],
  events: [],
  arts: [],
  artsCount: 0,
  maxPriceOnMarketPlace: 1000,
  isFiltersOpen: false,
  tags: [],
  currentPage: 1,
  totalPages: 0,
  checkedTags: [{ name: 'All', id: undefined }],
};

const reducer = (state: State, action: Action): State => {
  let result = state;
  switch (action.type) {
    case 'OPEN_FILTER_MODAL': {
      result = { ...state, isFiltersOpen: true };
      break;
    }

    case 'CLOSE_FILTER_MODAL': {
      result = { ...state, isFiltersOpen: false };
      break;
    }

    case 'FILL_FILTER': {
      result = { ...state, filters: action.payload, currentPage: 1 };
      break;
    }

    case 'CHECK_POPULAR_TAGS': {
      result = {
        ...state,
        filters: {
          ...state.filters,
          tags: action.payload,
        },
        currentPage: 1,
        checkedTags: action.payload,
      };
      break;
    }

    case 'FETCH_TAGS': {
      result = { ...state, tags: action.payload };
      break;
    }

    case 'SEARCH_TAGS_CHANGED': {
      result = { ...state, searchTagValue: action.payload };
      break;
    }

    case 'FETCH_ARTISTS': {
      result = { ...state, artists: action.payload };
      break;
    }

    case 'FETCH_EVENTS': {
      result = { ...state, events: action.payload };
      break;
    }

    case 'SET_ARTS_INFO': {
      result = {
        ...state,
        artsCount: action.payload.artsCount,
        isLoading: false,
        currentPage: state.currentPage,
        totalPages: action.payload.totalPages,
      };
      break;
    }

    case 'SET_MAX_PRICE_ON_MARKET_PLACE': {
      result = {
        ...state,
        filters: { ...state.filters, maxPrice: action.payload },
        maxPriceOnMarketPlace: action.payload,
      };
      break;
    }

    case 'RESET_FILTERS': {
      result = { ...state, filters: { ...initialFilters, maxPrice: state.maxPriceOnMarketPlace }, currentPage: 1 };
      break;
    }

    case 'FETCH_MORE': {
      result = { ...state, currentPage: state.currentPage + 1 };
      break;
    }
  }
  return result;
};

const MarketplaceContext = React.createContext<{ state: State; dispatch: React.Dispatch<Action> } | undefined>(
  undefined,
);

export const MarketplaceProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(reducer, initialState);

  return <MarketplaceContext.Provider value={{ state, dispatch }}>{children}</MarketplaceContext.Provider>;
};

export const useMarketplaceContext = () => {
  const context = React.useContext(MarketplaceContext);

  if (context === undefined) {
    throw new Error('useMarketplaceContext must be used within a MarketplaceContext');
  }
  return context;
};
