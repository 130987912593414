import { ArtWithSaleInfo } from 'types';
import { useApiContext } from 'contexts/ApiContext';
import { useMarketplaceContext } from '../State/MarketplaceContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useFilterCounter } from './useFilterCounter';
import { Art, CreateBuyNowDtoStatusEnum, Event, User } from 'codegen-api/api-totemo-service/models';
import { limitArtsPerPage, socketNotificationTypes } from 'app-settings';

export const useFetchArt = () => {
  const { art: artsApi, socket } = useApiContext();
  const { state, dispatch } = useMarketplaceContext();
  const filtersCounter = useFilterCounter(state.filters);
  const { filters, artists, events, maxPriceOnMarketPlace } = state;

  const [arts, setArts] = useState<ArtWithSaleInfo[]>([]);

  const onRemoveArtCallback = useCallback(
    (artId: number) => {
      setArts((arts) => arts.filter((art) => art.id !== artId));

      const artsCountAfterRefresh = state.artsCount - 1;
      const artsPagesAfterRefresh = Math.ceil((state.artsCount - 1) / limitArtsPerPage);

      dispatch({
        type: 'SET_ARTS_INFO',
        payload: {
          artsCount: artsCountAfterRefresh,
          totalPages: artsPagesAfterRefresh < state.totalPages ? artsPagesAfterRefresh : state.totalPages,
        },
      });
    },
    [dispatch, state.artsCount, state.totalPages],
  );

  const onAddArtCallback = useCallback(
    async (artId: number) => {
      const resp = (await artsApi.artControllerFindOneOrFail(artId.toString())).data;
      const mappedArt = mapArtResponse({ items: [resp] } as unknown as Art);

      setArts([...mappedArt, ...arts]);

      const artsCountAfterRefresh = state.artsCount + 1;
      const artsPagesAfterRefresh = Math.ceil((state.artsCount + 1) / limitArtsPerPage);

      dispatch({
        type: 'SET_ARTS_INFO',
        payload: {
          artsCount: artsCountAfterRefresh,
          totalPages: artsPagesAfterRefresh > state.totalPages ? artsPagesAfterRefresh : state.totalPages,
        },
      });
    },
    [arts, artsApi, dispatch, state.artsCount, state.totalPages],
  );

  React.useEffect(() => {
    const notificationListener = async (messageString: string) => {
      try {
        const message = JSON.parse(messageString);
        const { eventType } = message;

        const removeArtSocketMessage =
          (eventType === socketNotificationTypes.saleStatusChanged && CreateBuyNowDtoStatusEnum.PENDING) ||
          (eventType === socketNotificationTypes.saleStatusChanged && CreateBuyNowDtoStatusEnum.CANCELED);

        const addArtSocketMessage =
          eventType === socketNotificationTypes.artCreated ||
          (eventType === socketNotificationTypes.saleStatusChanged && CreateBuyNowDtoStatusEnum.ACTIVE);

        if (![socketNotificationTypes.saleStatusChanged, socketNotificationTypes.artCreated].includes(eventType))
          return;

        const data: Record<string, any> = JSON.parse(message.data);

        switch (true) {
          case !!removeArtSocketMessage: {
            if (!arts.find((art) => art.id === data.artId)) {
              return;
            }
            onRemoveArtCallback(data.artId);
            break;
          }
          case !!addArtSocketMessage: {
            if (!!arts.find((art) => art.id === data.artId)) {
              return;
            }
            await onAddArtCallback(data.artId);
            break;
          }
          default:
            return;
        }
      } catch (e) {}
    };

    if (!filtersCounter) {
      socket!.on('notification', notificationListener);
    }

    return () => {
      socket!.off('notification', notificationListener);
    };
  }, [arts, filtersCounter, onAddArtCallback, onRemoveArtCallback, socket]);

  useEffect(() => {
    fetchArts();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCounter, state.checkedTags, state.currentPage]);

  const mapArtResponse = (artResponse: Art): ArtWithSaleInfo[] => {
    // @ts-ignore
    return artResponse.items.map((art) => {
      const saleData =
        (art.buyNows?.length && art.buyNows[0]) || (art.auctions?.length && art.auctions[0]) || undefined;

      return {
        ...art,
        id: art.id,
        name: art.name || '',
        artImage: art.artImage,
        amount: art.amount,
        artStatus: art.artStatus,
        owner: Array.isArray(art.owners) ? art.owners[art.owners.length - 1] : [],
        author: art.author || {},
        saleData: saleData || null,
        tokenType: art.tokenType || '',
      };
    });
  };

  const fetchArts = async () => {
    const body = !!filtersCounter
      ? {
          authorId:
            artists.find((artist: User) => {
              return artist.username === filters.artist;
            })?.id || undefined,
          eventId:
            events.find((event: Event) => {
              return event.name === filters.events;
            })?.id || undefined,
          minPrice: filters.minPrice === 0 ? undefined : filters.minPrice,
          maxPrice: filters.maxPrice === maxPriceOnMarketPlace ? undefined : filters.maxPrice,
          tags: filters.tags?.map((item) => item.id).toString() || undefined,
        }
      : undefined;

    try {
      const artsResponse = (
        await artsApi.artControllerFilter(
          state.currentPage,
          limitArtsPerPage,
          body?.authorId,
          undefined,
          body?.minPrice,
          body?.maxPrice,
          body?.tags,
          body?.eventId,
        )
      ).data;

      const artsWithSaleInfo = mapArtResponse(artsResponse);

      const artsPaginated = state.currentPage > 1 ? [...arts, ...artsWithSaleInfo] : artsWithSaleInfo;

      setArts(artsPaginated);

      dispatch({
        type: 'SET_ARTS_INFO',
        // @ts-ignore
        payload: {
          // @ts-ignore
          artsCount: artsResponse.meta.totalItems || 0,
          // @ts-ignore
          totalPages: artsResponse.meta.totalPages || 0,
        },
      });
    } finally {
    }
  };

  return arts;
};
