import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { InputType } from '../../../types';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as ArrowRight } from '../../../../../components/Icons/arrow_right.svg';

interface Props {
  handleClose(): void;
  content: Array<InputType>;
  contentModalFlow: string | undefined;
}

export const ContentModal: FC<Props> = ({ handleClose, content, contentModalFlow }) => {
  const { t } = useTranslation();
  return (
    <Box sx={sx.modalWrapper} role="presentation">
      <Box sx={sx.heading}>
        <Typography variant="h6" textAlign="left">
          {t(`profilePage.${contentModalFlow}Links`)}
        </Typography>

        <IconButton onClick={handleClose} sx={sx.closeBtn}>
          <CloseIcon />
        </IconButton>
      </Box>

      {content.map((item) => {
        return (
          <Box sx={sx.podcastLine}>
            <Typography variant="paragraphMedium">{item.name}</Typography>
            <Button
              variant="contained"
              size="small"
              component={"a"}
              href={item.link}
              target="_blank"
              sx={sx.linkBtn}
              disabled={!item.link}
            >
              <ArrowRight />
              <b>{t('common.link')}</b>
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  modalWrapper: {
    width: 'auto',
    px: 2.5,
    pt: 3.75,
    pb: 5.5,
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 3.75,
  },
  closeBtn: {
    justifyContent: 'flex-end',
    p: 0,
    color: 'grey.400',
  },
  podcastLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2.1,
  },
  linkBtn: {
    gap: 1.5,
    px: 2.5,
    backgroundColor: 'grey.100',
    color: 'common.black',
    '&:active, &:hover': {
      backgroundColor: 'grey.100',
    },
  },
};
