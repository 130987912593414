import React from 'react';
import { Box } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtArtStatusEnum, Auction, SaleTransactionInfo } from 'codegen-api/api-totemo-service/models';
import { ArtSaleNotStarted } from 'pages/Art/ArtSaleNotStarted';
import { ArtAuctionInProgress } from './ArtAuctionInProgress';
import { ArtAuctionNotFinalized } from 'pages/Art/ArtAuctionStatus/ArtAuctionNotFinalized';
import { ArtAuctionEnded } from 'pages/Art/ArtAuctionStatus/ArtAuctionEnded';
import { ArtAuctionProcessing } from 'pages/Art/ArtAuctionStatus/ArtAuctionProcessing';
import { isAuction } from 'helpers/isAuction';
import { finalizeWindow } from 'app-settings';
import { ArtPendingBanner } from 'pages/Art/ArtPendingBanner';
import { useAppState } from 'contexts/appState/AppContext';

export const ArtAuctionStatus: React.FC = React.memo(() => {
  const { state } = useArtDetailedView();
  const { currentUser } = useAppState();

  if (!isAuction(state.saleData)) return null;
  const saleData = state.saleData as Auction;
  const startDateTimestamp = saleData.startDate * 1000;
  const endDateTimestamp = saleData.endDate * 1000;
  const isCurrentUserWaitingTransactionEnd = state.art?.auctions.some((auction: Auction) =>
    auction?.transactions?.some((tx: SaleTransactionInfo) => tx.signer === currentUser.wallet),
  );

  if (isCurrentUserWaitingTransactionEnd) {
    return <ArtPendingBanner />;
  }

  if (state.art?.artStatus === ArtArtStatusEnum.NOTFORSALE) {
    return (
      <Box sx={sx.root}>
        <ArtAuctionEnded />
      </Box>
    );
  }

  if (state.art?.artStatus === ArtArtStatusEnum.PENDING) {
    return (
      <Box sx={sx.root}>
        <ArtAuctionProcessing />
      </Box>
    );
  }

  if (state.art?.artStatus !== ArtArtStatusEnum.PUBLISHED) return null;

  if (startDateTimestamp > Date.now()) {
    return <ArtSaleNotStarted startDateTimestamp={startDateTimestamp} />;
  }

  if (endDateTimestamp > Date.now()) {
    return (
      <Box sx={sx.root}>
        <ArtAuctionInProgress endDateTimestamp={endDateTimestamp} />
      </Box>
    );
  }

  if (saleData.bids[0]) {
    console.log(saleData.bids[0].bidderWallet);
    return (
      <Box sx={sx.root}>
        <ArtAuctionNotFinalized
          finalizeDateTimestamp={endDateTimestamp + finalizeWindow}
          winnerWallet={saleData.bids[0].bidderWallet}
        />
      </Box>
    );
  }

  return null;
});

const sx: Record<string, SxProps<Theme>> = {
  root: theme => ({
    mt: 2.5,
    bgcolor: 'grey.100',
    borderRadius: '5px',
    p: 2.5,
    [theme.breakpoints.up('lg')]: {
      mt: 0,
      width: '250px',
    },
  }),
};
