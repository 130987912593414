import React, { useCallback, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MoreIcon } from 'components/Icons/more_action.svg';
import { useContextMenu } from 'components/UiKit/ContextMenu/useContextMenu';
import { Footer } from 'components/Footer/Footer';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BackButton from 'components/UiKit/BackButton/BackButton';
import { useApiContext } from 'contexts/ApiContext';
import { Art, UpdateArtDtoArtStatusEnum } from '../../../codegen-api/api-totemo-service/models';
import { Loader } from 'components/UiKit/Loader/Loader';
import { worksTypeMap } from './types';
import { ConfirmDeleteModal, DeleteModal } from 'components/Modal/DeleteModal/DeleteModal';
import { Header } from 'components/Header';
import { routes } from 'app-settings';

interface ArtistInfo {
  username: string;
  worksCount?: number;
}

const AdminPanelCreatedArtsByArtist: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const api = useApiContext();
  const navigate = useNavigate();
  const { worksType, wallet } = useParams<{ worksType: 'drafts' | 'published'; wallet: string }>();
  const { anchorEl, handleOpenMenu, handleCloseMenu, getDataValue } = useContextMenu();

  const [artist, setArtist] = React.useState<ArtistInfo | null>(null);
  const [arts, setArts] = useState<Art[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isDeleteWarnModalOpen, setIsDeleteWarnModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  React.useEffect(() => {
    if (!wallet || !worksType) return;
    const fetchInitialInfo = async () => {
      try {
        const username = await api.users.userControllerFindOneByWalletOrFail(wallet).then((res) => res.data.username);

        // @ts-ignore
        const works: Array<Art> = await api.art.artControllerFindAll(worksTypeMap[worksType], wallet).then((res) => {
          return res.data.items || [];
        });

        setArtist({ username, worksCount: works?.length || 0 });
        setArts(works);
      } catch (e) {
      }
      setIsLoading(false);
    };

    fetchInitialInfo();
  }, [api.art, api.users, wallet, worksType]);

  const handlePublishToExplore = () => {
    const id = getDataValue('id');

    const publishToExplore = async () => {
      if (typeof id === 'string') {
        setArts(arts.filter((work) => work.id !== parseInt(id)));
      }

      try {
        await api.art.artControllerUpdate({ artStatus: UpdateArtDtoArtStatusEnum.PROMO, id });
      } catch (e) {
      }
    };

    publishToExplore();
    handleCloseMenu();
  };

  const handleEditItem = async () => {
    const id = getDataValue('id');
    navigate(`${routes.editArt}/${id}`);
    handleCloseMenu();
  };

  const handleToggleDeleteModal = () => {
    setIsDeleteWarnModalOpen(!isDeleteWarnModalOpen);
  };

  const handleToggleConfirmDeleteModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const getArtSaleType = useCallback((art: Art) => {
    if (art.buyNows.length) {
      return 'adminPanel.buyNow';
    }

    if (art.auctions.length) {
      return 'adminPanel.auction';
    }

    return '';
  }, []);

  const handleDeleteItem = async () => {
    handleCloseMenu();

    const id = getDataValue('id');

    try {
      await api.art.artControllerDeleteDraft(id as string);
      setArts(arts.filter((work) => work.id !== parseInt(id!)));
      handleToggleDeleteModal();
      handleToggleConfirmDeleteModal();
    } catch (e) {
    }
  };

  if (isLoading) {
    return (
      <BaseLayout>
        <Container fixed sx={sx.container}>
          <Header />
          <Loader />
        </Container>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Container fixed sx={sx.container}>
        <Header />
        <Box sx={sx.contentRoot}>
          <Box sx={sx.title}>
            <BackButton />
            <Typography flex="1 1 auto" fontWeight={600}>
              {artist?.username}
            </Typography>
            <Typography color={'text.secondary'}>
              {t('adminPanel.artworksWithCount', { count: arts.length })}
            </Typography>
          </Box>
          {arts.map((item) => (
            <Box key={item.id} sx={sx.work} data-id={item.id}>
              <Box component={Link} sx={sx.link} to={`${routes.art}/${item.id}`}>
                <Avatar sx={sx.preview} alt={item.name} src={item.artImage.url} variant="rounded" />
                <Box sx={sx.name}>{item.name}</Box>
              </Box>

              {worksType === 'published' ? (
                <Box color={'text.secondary'}>{t(getArtSaleType(item))}</Box>
              ) : (
                <IconButton sx={sx.icon} onClick={handleOpenMenu}>
                  <MoreIcon />
                </IconButton>
              )}
            </Box>
          ))}
          <Menu
            id={'item menu'}
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleCloseMenu}
            MenuListProps={{ 'aria-labelledby': '' }}
          >
            {
              // Mui Menu component doesn't accept React.Fragment as a child
              [
                <MenuItem key={'edit'} onClick={handleEditItem}>
                  {t('common.edit')}
                </MenuItem>,
                <MenuItem key={'delete'} onClick={handleToggleDeleteModal}>
                  {t('common.delete')}
                </MenuItem>,
                <MenuItem key={'publish'} onClick={handlePublishToExplore}>
                  {t('adminPanel.publish')}
                </MenuItem>,
              ]
            }
          </Menu>
        </Box>

        <DeleteModal open={isDeleteWarnModalOpen} handleClose={handleToggleDeleteModal} onDelete={handleDeleteItem} />

        <ConfirmDeleteModal open={isConfirmModalOpen} handleClose={handleToggleConfirmDeleteModal} />
      </Container>
      <Footer />
    </BaseLayout>
  );
});

export default AdminPanelCreatedArtsByArtist;

const sx: Record<string, SxProps<Theme>> = {
  contentRoot: {
    m: 2.5,
  },
  container: {
    m: '0 auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    mb: 1.25,
  },
  work: {
    display: 'flex',
    alignItems: 'center',
    py: 1.25,
    bgcolor: 'background.default',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: 'grey.300',
  },
  name: {
    flex: '1 1 auto',
    mx: 1.5,
  },
  icon: {
    p: 0,
  },
  link: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    textDecoration: 'none',
    color: 'common.black',
  },
};
