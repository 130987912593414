import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSecondarySaleMultiple } from './SecondarySaleMultipleProvider';
import { ArtPreviewMedia } from 'components/Art/ArtPreviewMedia';
import { ArtDescription } from 'components/Art/ArtDescription';
import { ArtTags } from 'components/Art/ArtTags';
import { ArtLocationAndEvent } from 'components/Art/ArtLocationAndEvent';
import { SecondarySaleMultipleBuyNow } from './SecondarySaleMultipleBuyNow/SecondarySaleMultipleBuyNow';

export const SecondarySaleMultiple: React.FC = React.memo(() => {
  const { state } = useSecondarySaleMultiple();
  const { art } = state;
  const { t } = useTranslation();

  return (
    <Box px={2.5} pb={6}>
      <Typography variant={'h2'}>{t('secondarySale.title')}</Typography>

      <Typography mt={1.25} color="text.secondary">
        {`@${art.author.username}${art.author.fullname ? ' / ' + art.author.fullname : ''}`}
      </Typography>

      <Box my={2.5}>
        <ArtPreviewMedia art={art} />
      </Box>

      <Typography variant={'h3'}>{art.name}</Typography>

      <ArtDescription art={art} />

      <Box mb={3}>
        <ArtLocationAndEvent art={art} />
      </Box>

      <ArtTags art={art} />

      <SecondarySaleMultipleBuyNow />
    </Box>
  );
});
