import { Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApiContext } from 'contexts/ApiContext';
import { User } from 'codegen-api/api-totemo-service/models';
import { Loader } from '../../../components/UiKit/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { ArtistCard } from './ArtistCard';
import { CarouselWrapper } from '../CarouselWrapper';
import styles from './index.module.css';

const carouselSettings = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const FeaturedArtist = () => {
  const { t } = useTranslation();
  const api = useApiContext();
  const [featuredArtistList, setFeaturedArtistList] = useState<User[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchFeaturedArtists = async () => {
      try {
        const artists = await api.users.userControllerFindAll(undefined, 'true', 'true');
        setFeaturedArtistList((artists.data.items as unknown as User[]) || []);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };

    fetchFeaturedArtists();
  }, [api.art, api.users]);

  if (!featuredArtistList?.length) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container sx={{ mb: 8.75 }} id="artists">
      <Typography variant="h1" textAlign="center" mt={8.75} mb={5}>
        {t('homePage.featuredArtist')}
      </Typography>

      <CarouselWrapper responsiveSettings={carouselSettings} className={styles.carousel}>
        {featuredArtistList?.map((user: User, idx) => {
          return <ArtistCard user={user} key={idx} arts={user.arts || new Array(4).fill({})} />;
        })}
      </CarouselWrapper>
    </Container>
  );
};
