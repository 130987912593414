import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { BaseModal } from 'components/Modal/BaseModal';
import { EMPTY_USER, useApp } from 'contexts/appState/AppContext';
import { useLogout } from 'contexts/appState/useLogout';
import { defaultChain } from 'app-settings';

export const WrongNetworkModal: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const [state] = useApp();
  const logout = useLogout();
  const hasWallet = state.currentUser.wallet !== EMPTY_USER.wallet;

  const handleSignOut = () => {
    logout(true);
    setTimeout(() => window.location.reload());
  };

  return (
    <BaseModal handleClose={() => {
      logout();
    }} isCloseHidden={true} open={state.isWrongNetworkOpen}>
      <Box sx={sx.container}>
        <Box sx={sx.imageBlock}>
          <img src={'/assets/icons/network_icon.svg'} alt="wrong-network" />
        </Box>
        <Typography sx={sx.title} variant="h2">
          {t('wrongNetwork.title')}
        </Typography>
        <Typography
          variant="paragraphLarge">{t('wrongNetwork.description', { chainName: defaultChain.name })}</Typography>
        <Box sx={sx.buttonContainer}>
          {hasWallet ? (
            <Button fullWidth variant="contained" onClick={handleSignOut}>
              {t('common.signOut')}
            </Button>
          ) : (
            <Button fullWidth variant="outlined" color="primary" onClick={() => window.location.reload()}>
              {t('wrongNetwork.close')}
            </Button>
          )}
        </Box>
      </Box>
    </BaseModal>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  container: {
    textAlign: 'center',
  },
  imageBlock: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    mt: 3.75,
    mb: 1.25,
    textAlign: 'inherit',
  },
  buttonContainer: {
    mt: 3.75,
  },
};
