import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc } from 'app-settings';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MoreIcon } from 'components/Icons/more_action.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { User } from 'codegen-api/api-totemo-service/models';
import { useTranslation } from 'react-i18next';
import { useContextMenu } from 'components/UiKit/ContextMenu/useContextMenu';
import { useApiContext } from 'contexts/ApiContext';
import { NonMovableContainer } from 'components/UiKit/NonMovableContainer/NonMovableContainer';

interface Props {
  featuredUsers: User[];
  onChange: (users: User[]) => void;
}

const AdminPanelFeaturedArtistsList: React.FC<Props> = ({ featuredUsers, onChange }) => {
  const { t } = useTranslation();
  const { users: usersApi } = useApiContext();

  const { anchorEl, handleOpenMenu, handleCloseMenu, getDataValue } = useContextMenu();

  const handleDeleteItem = async () => {
    const wallet = getDataValue('wallet');
    try {
      await usersApi.userControllerUpdateFeatureArtist(wallet as string, 'false');
      const filteredArtists = featuredUsers.filter((artist) => {
        return artist.wallet !== wallet;
      });
      onChange(filteredArtists);
    } catch (e) {}
    handleCloseMenu();
  };

  if (!featuredUsers.length) {
    return (
      <Typography variant="paragraphMedium" color="text.secondary" textAlign="center" mt={6.25}>
        {t('adminPanel.nobodyIsFeatured')}
      </Typography>
    );
  }

  return (
    <>
      <Box component="ul" sx={sx.list}>
        {featuredUsers.map((artist) => {
          return (
            <Box component="li" key={artist.wallet} sx={sx.user} data-wallet={artist.wallet}>
              <NonMovableContainer sx={sx.nonMovable}>
              <Avatar sx={sx.avatar} alt={artist.username} src={artist.settings?.avatar?.url || defaultAvatarSrc} />
              <Box sx={sx.username}>{artist.username}</Box>
              <IconButton sx={sx.icon} onClick={handleOpenMenu}>
                <MoreIcon />
              </IconButton>
            </NonMovableContainer>
            </Box>
          );
        })}
      </Box>

      <Menu
        id={'item menu'}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': '' }}
      >
        <MenuItem onClick={handleDeleteItem}>{t('common.delete')}</MenuItem>
      </Menu>
    </>
  );
};

export default AdminPanelFeaturedArtistsList;

const sx: Record<string, SxProps<Theme>> = {
  list: {
    width: '100%',
    my: 3,
    px: 0,
    listStyleType: 'none',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    minHeight: (theme) => theme.spacing(8.5),
    bgcolor: 'background.default',
    borderBottom: (theme) => `solid 1px ${theme.palette.grey[300]}`,
  },
  nonMovable: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    minHeight: (theme) => theme.spacing(8.5),
  },
  icon: {
    color: 'grey.600',
    fontSize: 0,
    p: 0,
    flex: '0 0 auto',
  },
  username: {
    fontWeight: 600,
    mx: 2,
    flexGrow: 1,
  },
  avatar: {
    ml: 1.25,
    flex: '0 0 auto',
  },
};
