import React, { FC } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { routes } from 'app-settings';
import { Art } from 'codegen-api/api-totemo-service/models';
import { useNavigate } from 'react-router-dom';
import { ArtistLabel } from '../ArtistLabel';
import checkIsVideo from '../../../helpers/isVideo';
import VideoBox from '../../../components/VideoBox/VideoBox';

interface Props {
  art: Art;
}

export const ArtCard: FC<Props> = ({ art }) => {
  const navigate = useNavigate();
  const isVideo = checkIsVideo(art.artImage.url);

  const handleRedirectToArtPage = (id: string) => {
    navigate(`${routes.art}/${id}`);
  };

  return (
    <Card sx={sx.card} onClick={() => handleRedirectToArtPage(art.id)}>
      {isVideo ? (
        <VideoBox className={'video-box'} src={art.artImage.url} />
      ) : (
        <CardMedia component="img" height="335" image={art.artImage.url} alt="art" sx={sx.artImage} />
      )}
      <CardContent sx={sx.artName}>
        <Typography variant="h3" component="p" mb={2.5}>
          {art.name}
        </Typography>

        <ArtistLabel artist={art.author} />
      </CardContent>
    </Card>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  card: (theme) => ({
    width: '100%',
    border: (theme) => `1px solid ${theme.palette.grey[300]}`,
    boxShadow: 'none',
    borderRadius: '5px',
    mb: 5,
    [theme.breakpoints.up('lg')]: {
      width: (theme) => theme.spacing(37.5),
    },
    '& .video-box': {
      height: 335,
      display: 'block',
      width: '100%',
      objectFit: 'cover',
    },
  }),
  artImage: {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  artName: {
    px: 2.5,
    py: 1.8,
  },
};
