import { Box, Fade, Modal } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import IconButton from '@mui/material/IconButton';

export interface BaseModalProps {
  handleClose?: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  mx?: number;
  open: boolean;
  isCloseHidden?: boolean;
  children?: React.ReactNode;
}

export const BaseModal: React.FC<BaseModalProps> = ({ handleClose, isCloseHidden, open, mx = 5, children }) => {
  const sx = useSx(mx);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={backdropProps}
      >
        <Fade in={open}>
          <Box sx={sx.popup}>
            {!isCloseHidden ? (
              <IconButton onClick={handleClose} sx={sx.closeBtn}>
                <CloseIcon />
              </IconButton>
            ) : null}
            {children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

const backdropProps = {
  timeout: 500,
};

const useSx = (mx: number): Record<string, SxProps<Theme>> => {
  return React.useMemo(() => {
    return {
      closeBtn: {
        position: 'absolute',
        p: 1.25,
        top: 0,
        right: 0,
        color: 'grey.400',
        '.MuiSvgIcon-root': {
          fontSize: (theme) => theme.spacing(3),
        },
      },
      popup: theme => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 275,
        bgcolor: 'background.paper',
        p: 5,
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        width: `calc(100vw - ${theme.spacing(mx)})`,
        [theme.breakpoints.up('sm')]: {
          width: '470px',
          padding: '50px',
        },
      }),
    };
  }, [mx]);
};
