import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Typography, Button, Link } from '@mui/material';
import { SxProps } from '@mui/system';
import Divider from '@mui/material/Divider';
import { User } from 'codegen-api/api-totemo-service/models';
import { ProfileTabs } from '../Tabs/ProfileTabs';
import { Theme } from '@mui/material/styles';
import { defaultAvatarSrc } from 'app-settings';
import { inputsSocialNetworksConfig } from '../shared';
import { SocialNetworks } from '../../types';
import { UserRolesTKeyMap } from 'components/RequireRole/RequireRole';
import { UserRoleName } from 'types';
import { InviteBar } from 'pages/Profile/Layouts/ProfileView/Components/InviteBar';

interface Props {
  user: User;
  isCurrentUser: boolean;
  hasRightsToEdit: boolean | undefined;

  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;

  socialLinksMap: Map<SocialNetworks, string> | undefined;
}

export const UserProfile: FC<Props> = ({ user, hasRightsToEdit, onClick, socialLinksMap, isCurrentUser }) => {
  const { t } = useTranslation();
  const { username, fullName, settings, role, phoneNumber } = user;

  return (
    <Box sx={sx.pageWrapper}>
      <Box sx={sx.content}>
        <Box sx={sx.infoWrapper}>
          <Avatar alt={username} src={settings?.avatar?.url || defaultAvatarSrc} sx={sx.avatar} />
          <Typography variant="h2" textAlign="center" sx={{ mb: 1 }}>
            {username}
          </Typography>
          <Typography variant="paragraphMedium" color="text.secondary" textAlign="center" sx={{ mb: 1 }}>
            {fullName || ''}
          </Typography>

          <Typography variant="paragraphMedium" color="text.secondary" textAlign="center" sx={{ mb: 1 }}>
            {phoneNumber || ''}
          </Typography>

          <Box sx={sx.label}>{t(UserRolesTKeyMap[role[0].role as UserRoleName])}</Box>

          {isCurrentUser ? <InviteBar /> : null}

          <Divider sx={sx.divider} />

          <Box sx={sx.socialLinks}>
            {inputsSocialNetworksConfig.map(
              ({ label, icon }) =>
                !!socialLinksMap?.get(`${label}`) && (
                  <Link
                    key={label}
                    sx={sx.linkLabel}
                    target={'_blank'}
                    href={socialLinksMap?.get(`${label}`)}
                    rel="noreferrer"
                  >
                    {icon}
                  </Link>
                ),
            )}
          </Box>

          {hasRightsToEdit && (
            <Button variant="outlined" size="large" sx={{ mb: 8 }} onClick={onClick}>
              {t('profilePage.editProfileBtn')}
            </Button>
          )}
        </Box>

        <Box sx={sx.artsWrapper}>
          <ProfileTabs />
        </Box>
      </Box>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  pageWrapper: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    pl: 3,
    pr: 3,
    [theme.breakpoints.up('lg')]: {
      p: 0,
    },
  }),
  avatar: (theme) => ({
    height: 95,
    width: 95,
    mb: 2,
    [theme.breakpoints.up('lg')]: {
      height: (theme) => theme.spacing(17.5),
      width: (theme) => theme.spacing(17.5),
      alignSelf: 'flex-start',
    },
  }),
  content: (theme) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 14,
    },
  }),
  infoWrapper: (theme) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
      width: (theme) => theme.spacing(45),
    },
  }),
  artsWrapper: {
    width: '100%',
    height: '100%',
  },
  label: {
    height: 20,
    borderRadius: 3,
    textAlign: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'grey.200',
    pl: 1,
    pr: 1,
    mb: 2.5,
  },
  divider: (theme) => ({
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      width: '100%',
    },
  }),
  socialLinks: {
    display: 'flex',
    flexWrap: 'no-wrap',
    gap: 1,
    justifyContent: 'space-between',
    mt: 2,
    mb: 4,
  },
  linkLabel: {
    '& svg': {
      '&:hover > *': {
        fill: (theme) => {
          return theme.palette.primary.light;
        },
      },
    },
  },
};
