import React from 'react';
import { Box, Button, Divider, Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { useContractBuyNow } from 'abi/useContractBuyNow';
import { ethers } from 'ethers';
import { getPriceWithoutFeeETH } from 'pages/CreateEditArtPage/utils';
import { useServiceFee } from 'pages/Art/utils/useServiceFee';
import { useBalanceEth } from 'pages/Art/utils/useBalanceEth';
import { ArtDrawerPaperProps, ArtDrawerSx } from 'pages/Art/ArtDrawer/ArtDrawerStyles';
import { ArtBodyTokenTypeEnum, BuyNow } from 'codegen-api/api-totemo-service/models';
import { getMarketPrice } from 'helpers/getMarketPrice';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';

export const ArtBuyNowDrawer: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { state, dispatch } = useArtDetailedView();
  const [isPurchasing, setIsPurchasing] = React.useState<boolean>(false);
  const balanceEth = useBalanceEth();
  const serviceFee = useServiceFee();
  const { purchaseArtBuyNow } = useContractBuyNow();

  const [amount, setAmount] = React.useState<{ value: number; error: string }>({ value: 1, error: '' });

  if (!state.art || !state.saleData) {
    return null;
  }

  const handleClose = () => dispatch({ type: 'HIDE_DRAWER' });

  const handleByNow = () => {
    const purchase = async () => {
      if (!state.art || !state.saleData || !('marketPrice' in state.saleData)) return;

      setIsPurchasing(true);
      try {
        const resp = await purchaseArtBuyNow(state.art, state.saleData, amount.value);

        if (!resp) {
          throw new Error('Purchase failed');
        }

        if (state.art.tokenType === ArtBodyTokenTypeEnum.ERC721) {
          dispatch({
            type: 'SET_PENDING_TO_SINGLE',
            payload: {
              signer: resp.redeemResult.from.toLowerCase(),
              transactionHash: resp.redeemResult.hash,
            },
          });
        } else {
          dispatch({
            type: 'ADD_TRANSACTION',
            payload: {
              transaction: {
                amount: amount.value,
                signer: resp.redeemResult.from.toLowerCase(),
                transactionHash: resp.redeemResult.hash,
                uuid: resp.txUuid,
              },
              saleId: state.saleData.id,
              saleType: 'BUYNOW',
            },
          });
        }
        handleClose();
      } catch (e) {
        console.log(e);
      }
      setIsPurchasing(false);
    };

    purchase();
  };

  const handleAmountChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!state.saleData || !('amount' in state.saleData)) return;

    const value = Number(e.target.value);
    let error;
    switch (true) {
      case value < 1: {
        error = t('forms.errors.minValue', { value: 1 });
        break;
      }
      case value > state.saleData.amount: {
        error = t('forms.errors.maxValue', { value: state.saleData.amount });
        break;
      }
      default: {
        error = '';
      }
    }

    setAmount({ value, error });
  };

  const marketPriceEth = getMarketPrice(state.saleData, amount.value).toString();
  const originalPrice = getPriceWithoutFeeETH(marketPriceEth, String(serviceFee));
  const feeValue = ethers.utils.formatEther(
    ethers.utils.parseEther(marketPriceEth).sub(ethers.utils.parseEther(originalPrice)),
  );

  const showQuantity =
    state.art.tokenType === ArtBodyTokenTypeEnum.ERC1155 && 'amount' in state.saleData && state.saleData.isFirstSale;

  return (
    <Drawer anchor={'bottom'} open={state.isDrawerOpen} onClose={handleClose} PaperProps={ArtDrawerPaperProps}>
      <Box sx={sx.content}>
        <Typography variant={'h2'}>{t('artPage.confirmPurchase')}</Typography>

        <Box mt={2}>
          <Typography color={'text.secondary'} component="span">
            {t('artPage.youArePurchasing')}
          </Typography>
          <Typography color={'primary.main'} component="span" fontWeight={600} ml={0.5}>
            {state.art.name}
          </Typography>
        </Box>
        {!!showQuantity && (
          <Box>
            <Typography variant={'paragraphSmall'} fontWeight={600} display={'block'} mt={2.5} mb={0.5}>
              {t('artPage.enterQuantity')}
            </Typography>
            <OutlinedTextInput
              name="amount"
              id="amount"
              inputProps={{ type: 'number', step: '1', min: '1', max: (state.saleData as BuyNow).amount }}
              value={amount.value.toString()}
              error={!!amount.error}
              helperText={amount.error}
              onChange={handleAmountChange}
            />
          </Box>
        )}

        <Box mt={3} mb={5}>
          <Box sx={sx.priceItem}>
            <Typography component="span">{t('artPage.price')}</Typography>
            <Typography component="span">{`${originalPrice} ETH`}</Typography>
          </Box>
          <Box sx={sx.priceItem}>
            <Typography component="span">{`${t('artPage.serviceFee')} ${serviceFee}%`}</Typography>
            <Typography component="span">{`${feeValue} ETH`}</Typography>
          </Box>
          <Divider sx={sx.divider} />
          <Box sx={sx.priceItem}>
            <Typography component="span" color={'text.secondary'}>
              {t('artPage.yourBalance')}
            </Typography>
            <Typography component="span" color={'text.secondary'}>{`${balanceEth} ETH`}</Typography>
          </Box>
          <Box sx={sx.priceItem}>
            <Typography component="span" color={'primary.main'}>
              {t('artPage.youPay')}
            </Typography>
            <Typography component="span" sx={sx.total}>{`${marketPriceEth} ETH`}</Typography>
          </Box>
        </Box>

        <Box sx={sx.buttons}>
          <AsyncButton
            disabled={!!amount.error}
            isLoading={isPurchasing}
            onClick={handleByNow}
            variant={'contained'}
            color={'primary'}
          >
            {t('common.confirm')}
          </AsyncButton>
          <Button sx={sx.cancel} onClick={handleClose}>
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
});

const sx: Record<string, SxProps<Theme>> = ArtDrawerSx;
