import React from 'react';
import * as Ethers from 'ethers';
import { v4 as uuidv4 } from 'uuid';
import { useAccount } from 'wagmi';
import { writeContract } from '@wagmi/core';
import { Address, parseEther } from 'viem';

import { Art, ArtBodyTokenTypeEnum, BuyNow } from 'codegen-api/api-totemo-service/models';
import TotemoSingle from 'abi/contracts/TotemoSingle';
import TotemoMultiple from 'abi/contracts/TotemoMultiple';
import { useApiContext } from 'contexts/ApiContext';
import { getMarketPrice } from 'helpers/getMarketPrice';
import { devLog } from 'helpers';
import { VoucherMultiple, VoucherSingle } from './types';

const contractAddressSingle = process.env.REACT_APP_SINGLE_CONTRACT;
const contractAddressMultiple = process.env.REACT_APP_MULTIPLE_CONTRACT;

type ReturnValues = {
  isLoading: boolean;
  purchaseArtBuyNow: (
    art: Art,
    buyNow: BuyNow,
    amount?: number,
  ) => Promise<{ redeemResult: Ethers.ContractTransaction; txUuid?: string } | undefined>;
};

export const useContractBuyNow = (): ReturnValues => {
  const { buyNow } = useApiContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { address: buyerWallet } = useAccount();

  const purchaseArtBuyNow = React.useCallback(
    async (art: Art, saleData: BuyNow, amount = 1) => {
      setIsLoading(true);
      let redeemResult;
      let txUuid;
      const isMultiple = art.tokenType === ArtBodyTokenTypeEnum.ERC1155;


      try {
        const contractAddress = (isMultiple ? contractAddressMultiple : contractAddressSingle) as Address;

        const optionalAddress: Address[] = [];

        let fees: bigint[] = [];
        try {
          const moneyDistribution = JSON.parse(art.moneyDistribution);
          const { address, addressPercentage, servicePercentage, totemoPercentage, artistPercentage } =
            moneyDistribution[saleData.isFirstSale ? 'primarySale' : 'secondarySale'];

          fees = [BigInt(+totemoPercentage), BigInt(+servicePercentage), BigInt(+artistPercentage)];
          if (address && addressPercentage) {
            optionalAddress.push(address);
            fees.push(addressPercentage);
          }
        } catch (e) {
          setIsLoading(false);
        }

        const weiPricePerItem = parseEther(getMarketPrice(saleData).toString());

        if (isMultiple) {
          const voucher: VoucherMultiple = {
            tokenId: art.tokenId,
            startMarketDate: saleData.startMarketDate,
            author: saleData.authorWallet,
            isFirstSale: saleData.isFirstSale,
            owner: saleData.ownerWallet,
            assetContract: contractAddress,
            price: weiPricePerItem,
            pm: saleData.pmWallet,
            signature: saleData.signature,
            data: '0x6732',
            amount: saleData.isFirstSale ? art.amount : 1,
          };
          // TODO replace with stronger identifier
          txUuid = Ethers.utils.formatBytes32String(uuidv4().replaceAll('-', '').slice(1));

          devLog('Voucher for purchase multiple: ', voucher);

          // @ts-ignore
          redeemResult = await writeContract({
            address: contractAddress as Address,
            abi: TotemoMultiple.abi,
            functionName: 'redeem',
            args: [
              buyerWallet as Address, BigInt(amount), txUuid, voucher, optionalAddress, fees,
            ],
            value: amount === 1 ? weiPricePerItem : weiPricePerItem * BigInt(amount),
          });

          await buyNow.buyNowControllerLockMultiple({
            buyNowId: saleData.id,
            transactionHash: redeemResult.hash,
            uuid: txUuid,
            signer: buyerWallet,
            amount,
          });
        } else {
          const voucher: VoucherSingle = {
            tokenId: art.tokenId,
            startMarketDate: saleData.startMarketDate,
            author: saleData.authorWallet,
            isFirstSale: saleData.isFirstSale,
            owner: saleData.ownerWallet,
            assetContract: contractAddress,
            price: weiPricePerItem,
            pm: saleData.pmWallet,
            signature: saleData.signature,
            data: '0x6732',
            amount: saleData.isFirstSale ? art.amount : 1,
          };

          // @ts-ignore
          redeemResult = await writeContract({
            address: contractAddress,
            abi: TotemoSingle.abi,
            functionName: 'redeem',
            args: [
              buyerWallet!, voucher, optionalAddress, fees,
            ],
            value: weiPricePerItem,
          });

          await buyNow.buyNowControllerLockSingle({
            buyNowId: saleData.id,
            transactionHash: redeemResult.hash,
            signer: buyerWallet,
          });
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }

      return {
        redeemResult: {
          ...redeemResult,
          from: buyerWallet,
        },
        txUuid,
      };
    },
    [buyNow],
  );

  return { isLoading, purchaseArtBuyNow };
};
