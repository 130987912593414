/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EmailConfirmation } from '../models';
import { UserRole } from '../models';
/**
 * SignupLinkApi - axios parameter creator
 * @export
 */
export const SignupLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} link 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmationControllerActivate: async (link: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'link' is not null or undefined
            if (link === null || link === undefined) {
                throw new RequiredError('link','Required parameter link was null or undefined when calling emailConfirmationControllerActivate.');
            }
            const localVarPath = `/signup-link/{link}`
                .replace(`{${"link"}}`, encodeURIComponent(String(link)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmationControllerCreate: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling emailConfirmationControllerCreate.');
            }
            const localVarPath = `/signup-link/create/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignupLinkApi - functional programming interface
 * @export
 */
export const SignupLinkApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} link 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailConfirmationControllerActivate(link: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRole>> {
            const localVarAxiosArgs = await SignupLinkApiAxiosParamCreator(configuration).emailConfirmationControllerActivate(link, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailConfirmationControllerCreate(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfirmation>> {
            const localVarAxiosArgs = await SignupLinkApiAxiosParamCreator(configuration).emailConfirmationControllerCreate(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SignupLinkApi - factory interface
 * @export
 */
export const SignupLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} link 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmationControllerActivate(link: string, options?: any): AxiosPromise<UserRole> {
            return SignupLinkApiFp(configuration).emailConfirmationControllerActivate(link, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmationControllerCreate(email: string, options?: any): AxiosPromise<EmailConfirmation> {
            return SignupLinkApiFp(configuration).emailConfirmationControllerCreate(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignupLinkApi - object-oriented interface
 * @export
 * @class SignupLinkApi
 * @extends {BaseAPI}
 */
export class SignupLinkApi extends BaseAPI {
    /**
     * 
     * @param {string} link 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignupLinkApi
     */
    public emailConfirmationControllerActivate(link: string, options?: any) {
        return SignupLinkApiFp(this.configuration).emailConfirmationControllerActivate(link, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignupLinkApi
     */
    public emailConfirmationControllerCreate(email: string, options?: any) {
        return SignupLinkApiFp(this.configuration).emailConfirmationControllerCreate(email, options).then((request) => request(this.axios, this.basePath));
    }
}
