import { useCallback, useState } from 'react';
import { localStorageKeys } from 'app-settings';

export function useLocalStorage<T>(
  key: typeof localStorageKeys[keyof typeof localStorageKeys],
  initialValue: T,
): [T, (value: T) => unknown] {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(key);

    if (item === null) return initialValue;

    try {
      return JSON.parse(item) as T;
    } catch (e) {
      return item as unknown as T;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);
        window.localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(valueToStore) : String(value));
      } catch (error) {
        return error;
      }
    },
    [setStoredValue, storedValue, key],
  );

  return [storedValue, setValue];
}
