import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';

interface Props {
  isPublishing: boolean;
  artId: string | number;
}

export const SecondarySaleSubmit: React.FC<Props> = React.memo(({ isPublishing, artId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box mt={5}>
      <AsyncButton fullWidth isLoading={isPublishing} variant="contained" color="accent" type="submit">
        {t('secondarySale.publish')}
      </AsyncButton>
      <Button sx={sx.cancel} fullWidth type="button" onClick={() => navigate(`${routes.art}/${artId}`)}>
        {t('common.cancel')}
      </Button>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  cancel: {
    mt: 2,
  },
};
