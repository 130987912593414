import React from 'react';
import { useCreateArtReducerContext } from './useCreateEditArtReducer';
import { useTranslation } from 'react-i18next';

const Title: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useCreateArtReducerContext();

  switch (state.currentStep) {
    case 'FILL_ART_INFO':
    case 'FILL_SALE_INFO': {
      if (state.isEdit) {
        return state.type === 'SINGLE' ? t('pages.createArt.editSingleArt') : t('pages.createArt.editMultipleArt');
      }

      return state.type === 'SINGLE' ? t('pages.createArt.createSingleArt') : t('pages.createArt.createMultipleArt');
    }
    default:
      return t('pages.createArt.createArt');
  }
};

export default Title;
