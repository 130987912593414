import React from 'react';
import { ReactComponent as Bijutsutecho } from 'components/Icons/bijutsutecho.svg';
import { ReactComponent as Eyescream } from 'components/Icons/eyescream.svg';
import { ReactComponent as Getnews } from 'components/Icons/getnews.svg';
import { ReactComponent as YahooJapan } from 'components/Icons/yahoo_japan.svg';
import { ReactComponent as Qetic } from 'components/Icons/getic.svg';
import { ReactComponent as Mixmag } from 'components/Icons/mixmag.svg';
import { ReactComponent as Warp } from 'components/Icons/warp.svg';
import { ReactComponent as Tomosha } from 'components/Icons/tomosha.svg';

export const iconsConfig: Array<{ icon: JSX.Element; link: string }> = [
  { icon: <Bijutsutecho />, link: 'https://bijutsutecho.com/magazine/news/market/25129' },
  { icon: <Eyescream />, link: 'https://eyescream.jp/art/108632/' },
  { icon: <Getnews />, link: 'https://getnews.jp/archives/3212013' },
  { icon: <YahooJapan />, link: 'https://news.yahoo.co.jp/articles/44dfc3b8732486460dd882f7a1bab8cbbe20082f' },
  { icon: <Qetic />, link: 'https://qetic.jp/art-culture/totemo-la-mano-fria-nft-220120/422418/' },
  { icon: <Mixmag />, link: 'https://www.mixmag.jp/news/la-mano-fria-totemo.html' },
  { icon: <Warp />, link: 'https://www.warpweb.jp/culture/feature/art/61445/' },
  { icon: <Tomosha />, link: '/assets/22AC02.pdf' },
];
