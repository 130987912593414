import React from 'react';
import { Box, SxProps } from '@mui/material';

export const WalletHex: React.FC<{ hex: string; tailLength?: number; emptyValue?: string }> = ({
  hex,
  tailLength = 15,
  emptyValue = null,
}) => {
  if (!hex) return typeof emptyValue === 'string' ? <>{emptyValue}</> : emptyValue;
  return (
    <Box sx={sx.walletValue}>
      <Box component="span" sx={sx.croppedPart}>
        {hex.slice(0, -tailLength)}
      </Box>
      <Box component="span">{hex.slice(-tailLength)}</Box>
    </Box>
  );
};

const sx: Record<string, SxProps> = {
  walletValue: {
    maxWidth: '100%',
    display: 'flex',
    flexGrow: 1,
    span: {
      display: 'inline-block',
    },
  },
  croppedPart: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '65%',
  },
};
