import React from 'react';
import {
  DEFAULT_MONEY_DISTRIBUTION_PRIMARY,
  DEFAULT_MONEY_DISTRIBUTION_SECONDARY,
} from 'helpers/moneyDistributionHelpers';
import { ArtWithSaleInfo } from 'types';

export const useServiceFee = (art: ArtWithSaleInfo): number => {
  return React.useMemo(() => {
    if (!art || !art.saleData) return 0;
    let distribution;
    try {
      distribution = JSON.parse(art.moneyDistribution)[
        art.saleData.isFirstSale ? 'primarySale' : 'secondarySale'
        ];
    } catch (e) {
      distribution = art.saleData.isFirstSale
        ? DEFAULT_MONEY_DISTRIBUTION_PRIMARY
        : DEFAULT_MONEY_DISTRIBUTION_SECONDARY;
    }
    return +distribution.servicePercentage;
  }, [art]);
}