import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArtOwnerItemSx as sx } from './ArtOwnerItemSx';
import type { ArtOwner } from 'codegen-api/api-totemo-service/models';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc, routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';

interface Props {
  artOwner: ArtOwner;
  notForSaleCount: number;
  currentUserWallet: string;
}

export const ArtOwnerInfoItem: React.FC<Props> = React.memo(({ artOwner, notForSaleCount, currentUserWallet }) => {
  const { t } = useTranslation();
  const { state } = useArtDetailedView();
  const navigate = useNavigate();
  const showSellButton = currentUserWallet === artOwner.ownerWallet;

  return (
    <Box sx={sx.itemContainer}>
      <Avatar src={artOwner.owner.settings?.avatar?.url || defaultAvatarSrc} sx={sx.avatar} />
      <Box flex={'1 1 auto'}>
        <Typography variant={'paragraphSmall'} display={'block'} color={'primary.main'}>
          {`@${artOwner.owner.username}`}
        </Typography>
        <Typography variant={'paragraphSmall'} display={'block'} color={'text.secondary'}>
          {t('artPage.itemsForSale', { count: notForSaleCount })}
        </Typography>
      </Box>
      {showSellButton && (
        <Box display={'flex'} alignItems={'center'}>
          <Button
            sx={sx.buy}
            fullWidth
            variant={'contained'}
            color={'secondary'}
            size="medium"
            onClick={() => navigate(`${routes.secondarySale}/${state.art!.id}/${artOwner.ownerWallet}`)}
          >
            {t('artPage.sell')}
          </Button>
        </Box>
      )}
    </Box>
  );
});
