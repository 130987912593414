import React from 'react';
import { createTheme } from '@mui/material';
import { common } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
    appBarColor: Palette['primary'];
  }

  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
    appBarColor?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
    appBarColor: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    paragraphLarge: React.CSSProperties;
    paragraphMedium: React.CSSProperties;
    paragraphSmall: React.CSSProperties;
    paragraphXSmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    paragraphLarge?: React.CSSProperties;
    paragraphMedium?: React.CSSProperties;
    paragraphSmall?: React.CSSProperties;
    paragraphXSmall?: React.CSSProperties;
  }

  interface PaletteColor {
    hover?: string;
  }

  interface SimplePaletteColorOptions {
    hover?: string;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    paragraphLarge: true;
    paragraphMedium: true;
    paragraphSmall: true;
    paragraphXSmall: true;
  }
}

export type ThemeColors = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;

const palette = {
  background: {
    default: 'white',
  },
  primary: {
    main: '#000000',
    hover: '#2B2B2B',
    active: '#393939',
    disabled: '#DCE0E4',
  },
  accent: {
    main: '#8280F8',
    hover: '#9088F6',
    active: '#A19AF2',
    disabled: '#CDCCFC',
  },
  secondary: {
    main: '#DCE0E4',
    hover: '#CDD0D3',
    active: '#B6BABE',
    disabled: '#DCE0E4',
  },
  text: {
    primary: '#131313',
    secondary: '#6C7075',
    disabled: '#9A9FA5',
  },
  grey: {
    50: '#F2F3F4',
    100: '#F2F4F4',
    200: '#EAEDF0',
    300: '#DCE0E4',
    400: '#CDD0D3',
    500: '#B6BABE',
    600: '#9A9FA5',
    700: '#6C7075',
    800: '#393939',
    900: '#2B2B2B',
  },
  blue: {
    100: '#889CAB',
  },
  common: {
    ...common,
  },
  appBarColor: {
    main: '#FFFFFF',
  },
  error: {
    main: '#FF6C2D',
  },
};

export const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1025,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'Playfair Display', 'sans-serif', 'Arial'].join(','),
    h6: {
      fontFamily: 'Playfair Display',
      fontSize: '24px',
      fontWeight: 900,
      fontStyle: 'normal',
      lineHeight: '120%',
      letterSpacing: '0.03em',
      textAlign: 'center',
      color: palette.common.black,
    },
    h5: {
      fontFamily: 'Playfair Display',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '120%',
      letterSpacing: '0.03em',
      textAlign: 'left',
      color: '#131313',
    },
    paragraphLarge: {
      fontFamily: 'Source Sans Pro',
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: '150%',
      color: palette.text.primary,
      fontStyle: 'normal',
    },
    paragraphMedium: {
      fontFamily: 'Source Sans Pro',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '150%',
      color: palette.text.primary,
      fontStyle: 'normal',
      textAlign: 'left',
    },
    paragraphSmall: {
      fontFamily: 'Source Sans Pro',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '140%',
      color: palette.text.primary,
      fontStyle: 'normal',
      letterSpacing: '0.01em',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'center',
    },
    paragraphXSmall: {
      fontFamily: 'Source Sans Pro',
      fontSize: '12px',
      fontWeight: 'normal',
      lineHeight: '150%',
      color: palette.text.primary,
      fontStyle: 'normal',
      letterSpacing: '0.015em',
      textAlign: 'left',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '--header-height': '70px',
        },
        'body .headlines': {
          fontFamily: 'Open Sans, sans-serif',
        },
        'body .currency-menu': {
          maxWidth: '80px',
        },
        'body .accent-color': {
          color: palette.accent.main,
        },
        'body .unstyled-link': {
          color: 'inherit',
          textDecoration: 'none',
        },
        'body .react-foldable-paragraph-v-1-0-0-handler': {
          color: palette.accent.main,
          '&:hover': {
            color: palette.accent.hover,
          },
          '&:active': {
            color: palette.accent.active,
          },
        },

        'body .react-multi-carousel-dot-list': {
          position: 'static',
          listStyle: 'none',
          padding: 0,
          margin: 0,
        },

        'body .custom-dot': {
          marginRight: 10,
          marginTop: 30,
          cursor: 'pointer',

          border: 'none',
          borderRadius: '50%',
        },

        'body .custom-dot-desktop': {
          height: 8,
          width: 8,
        },

        'body .custom-dot-mobile': {
          height: 7,
          width: 7,
        },

        'body .custom-dot-active': {
          borderRadius: '50px',
          backgroundColor: palette.common.black,
          transition: 'all 0.5s ease-in-out',
        },

        'body .custom-dot-active-desktop': {
          width: 50,
        },

        'body .custom-dot-active-mobile': {
          width: 30,
        },

        'body .custom-dot-inactive': {
          backgroundColor: palette.grey[300],
        },
        'body .top-gradient': {
          height: '114px',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          background:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%)',
        },
        'body .bottom-gradient': {
          height: '114px',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%)',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'large',
      },
      variants: [
        {
          props: { color: 'primary', variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: palette.primary.hover,
            },
            '&:active': {
              backgroundColor: palette.primary.active,
            },
            '&:disabled': {
              backgroundColor: palette.primary.disabled,
              color: palette.common.white,
            },
          },
        },
        {
          props: { color: 'secondary', variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: palette.secondary.hover,
            },
            '&:active': {
              backgroundColor: palette.secondary.active,
            },
            '&:disabled': {
              backgroundColor: palette.secondary.disabled,
              color: palette.text.disabled,
            },
          },
        },
        {
          props: { color: 'accent', variant: 'contained' },
          style: {
            color: palette.common.white,
            '&:hover': {
              backgroundColor: palette.accent.hover,
            },
            '&:active': {
              backgroundColor: palette.accent.active,
            },
            '&:disabled': {
              backgroundColor: palette.accent.disabled,
              color: palette.common.white,
            },
          },
        },
        {
          props: { color: 'primary', variant: 'outlined' },
          style: {
            border: `1px solid ${palette.grey['300']}`,
            '&:hover': {
              border: `1px solid ${palette.common.black}`,
              backgroundColor: palette.common.white,
            },
            '&:active': {
              border: `1px solid ${palette.grey['600']}`,
              backgroundColor: palette.common.white,
            },
            '&:disabled': {
              border: `1px solid ${palette.grey['300']}`,
              color: palette.text.disabled,
            },
          },
        },
        {
          props: { color: 'primary', variant: 'text' },
          style: {
            '&:hover': {
              color: palette.primary.hover,
              backgroundColor: 'unset',
            },
            '&:active': {
              color: palette.primary.active,
              backgroundColor: 'unset',
            },
            '&:disabled': {
              color: palette.grey['300'],
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            minWidth: 220,
            padding: '14px 20px',
            fontWeight: 700,
            fontStyle: 'normal',
            letterSpacing: '0.02em',
            fontSize: '18px',
            fontFamily: 'Playfair Display',
            lineHeight: '120%',
            minHeight: 50,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '12.5px 20px',
            fontWeight: 600,
            fontStyle: 'normal',
            letterSpacing: '0.01em',
            fontSize: '14px',
            fontFamily: 'Source Sans Pro',
            lineHeight: '110%',
          },
        },
        {
          props: { size: 'small' },
          style: {
            padding: '7.5px 4px',
            fontWeight: 400,
            fontStyle: 'normal',
            letterSpacing: '0.01em',
            fontSize: '14px',
            fontFamily: 'Source Sans Pro',
            lineHeight: '140%',
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '5px',
          display: 'flex',
          '&:hover': {
            boxShadow: '0px 0px 5px rgba(255, 255, 255, 0.2), 0px 0px 30px rgba(255, 255, 255, 0.35)',
          },
          '&:active': {
            boxShadow: '0px 0px 5px rgba(255, 255, 255, 0.65), 0px 0px 30px rgba(255, 255, 255, 0.45);',
          },
          '&:disabled': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro',
        },
      },
    },
    MuiOutlinedInput: {
      variants: [
        {
          props: {
            color: 'primary',
          },
          style: {
            '&:hover': {
              borderColor: palette.primary.main,
            },
            '&:active': {
              borderColor: palette.primary.main,
            },
            '&:error': {
              color: palette.error.main,
            },
          },
        },
        {
          props: {
            multiline: true,
          },
          style: {
            minHeight: 120,
            alignItems: 'flex-start',
          },
        },
      ],
      styleOverrides: {
        root: {
          fontFamily: ['Source Sans Pro', 'Playfair Display', 'sans-serif', 'Arial'].join(','),
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          fontFamily: ['Source Sans Pro', 'Playfair Display', 'sans-serif', 'Arial'].join(','),
          height: 30,
          '&::after': {
            content: 'none',
          },
          '&::before': {
            content: 'none',
          },
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
          paddingTop: 4,
          paddingBottom: 5,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          margin: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro',
          boxShadow: 'none',
          marginBottom: '30px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 'var(--header-height)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 15,
          color: palette.text.secondary,
          fontSize: '14px',
          lineHeight: '140%',
          backgroundColor: palette.common.white,
          boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.25)',
          borderRadius: 5,
          whiteSpace: 'pre',
          margin: '8px !important',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '12px 20px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro',
          boxShadow: 'none',
          minWidth: 'auto',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro',
          borderBottom: 'none',
          padding: '10px 20px',
          fontSize: 16,
        },
        head: {
          fontWeight: 600,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro',
          fontWeight: 600,
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 16,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 0,
          width: '100%',
          borderRadius: 5,
          backgroundColor: palette.common.white,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.grey[600],
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          width: '60px',
          height: '60px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: '14px',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            minWidth: 0,
            flex: 1,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minWidth: 140,
          minHeight: 40,
        },
      },
    },
  },
});

theme.typography.h1 = {
  fontFamily: 'Playfair Display',
  fontSize: '48px',
  fontWeight: 900,
  lineHeight: '110%',
  letterSpacing: '0.03em',
  textAlign: 'left',
  color: palette.text.primary,
  [theme.breakpoints.only('xs')]: {
    fontSize: '36px',
  },
};

theme.typography.h2 = {
  fontFamily: 'Playfair Display',
  fontSize: '30px',
  fontWeight: 900,
  lineHeight: '110%',
  letterSpacing: '0.03em',
  textAlign: 'left',
  color: palette.text.primary,
  margin: 0,
  [theme.breakpoints.only('xs')]: {
    fontSize: '24px',
  },
};

theme.typography.h3 = {
  fontFamily: 'Playfair Display',
  fontSize: '24px',
  fontWeight: 'bold',
  lineHeight: '110%',
  letterSpacing: '0.03em',
  textAlign: 'left',
  color: palette.text.primary,
  [theme.breakpoints.only('xs')]: {
    fontSize: '20px',
    lineHeight: '120%',
  },
};

theme.typography.h4 = {
  fontFamily: 'Playfair Display',
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '120%',
  letterSpacing: '0.02em',
  textAlign: 'center',
  color: palette.text.primary,
};
