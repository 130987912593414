import React from 'react';
import { Container } from '@mui/material';
import { Footer } from 'components/Footer/Footer';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { Loader } from 'components/UiKit/Loader/Loader';
import type { Art, ArtOwner } from 'codegen-api/api-totemo-service/models';
import { useApiContext } from 'contexts/ApiContext';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'app-settings';
import { SecondarySaleSingle } from './SecondarySaleSingle/SecondarySaleSingle';
import { SecondarySaleSingleProvider } from 'pages/SecondarySale/SecondarySaleSingle/SecondarySaleSingleProvider';
import { useAppState } from 'contexts/appState/AppContext';
import { ArtBodyTokenTypeEnum } from 'codegen-api/api-totemo-service/models';
import { SecondarySaleMultiple } from 'pages/SecondarySale/SecondarySaleMultiple/SecondarySaleMultiple';
import { SecondarySaleMultipleProvider } from './SecondarySaleMultiple/SecondarySaleMultipleProvider';
import { Header } from 'components/Header';

export const SecondarySale: React.FC = React.memo(() => {
  const { artId } = useParams<{ artId: string; ownerWallet?: string }>();
  const { currentUser } = useAppState();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const api = useApiContext();
  const [art, setArt] = React.useState<Art | null>(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!artId) return;

    const fetchArt = async () => {
      try {
        const art = (await api.art.artControllerFindOneOrFail(artId)).data;
        if (art.owners.some((owner: ArtOwner) => owner.ownerWallet === currentUser.wallet)) {
          setArt(art);
          setIsLoading(false);
          return;
        }

        navigate(`${routes.art}/${artId}`);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    };

    fetchArt();
  }, [api.art, artId, currentUser.wallet, navigate]);

  if (isLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (!art) {
    return <Wrapper />;
  }

  if (art.tokenType === ArtBodyTokenTypeEnum.ERC721) {
    return (
      <Wrapper>
        <SecondarySaleSingleProvider art={art}>
          <SecondarySaleSingle />
        </SecondarySaleSingleProvider>
      </Wrapper>
    );
  }

  if (art.tokenType === ArtBodyTokenTypeEnum.ERC1155) {
    return (
      <Wrapper>
        <SecondarySaleMultipleProvider art={art}>
          <SecondarySaleMultiple />
        </SecondarySaleMultipleProvider>
      </Wrapper>
    );
  }

  return <Wrapper />;
});

const Wrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <BaseLayout>
    <Container fixed>
      <Header />
      {children}
    </Container>
    <Footer />
  </BaseLayout>
);
