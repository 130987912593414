import { useEffect } from 'react';
import { Art, ArtArtStatusEnum } from 'codegen-api/api-totemo-service/models';
import { ArtWithSaleInfo } from 'types';
import { TypeArts } from '../types';
import { useApiContext } from 'contexts/ApiContext';
import { useProfileReducerContext } from '../State/useProfileReducer';
import { limitArtsPerPage } from 'app-settings';

export const useFetchArtsForTabs = (artType: TypeArts, userWallet: string | undefined) => {
  const { art: artsApi } = useApiContext();
  const { state, dispatch } = useProfileReducerContext();

  const nonDraftsStatuses = [
    ArtArtStatusEnum.PROMO,
    ArtArtStatusEnum.NOTFORSALE,
    ArtArtStatusEnum.PENDING,
    ArtArtStatusEnum.PUBLISHED,
  ];

  const getMappedArts = (arts: Art[]): ArtWithSaleInfo[] => {
    return arts.map((art) => {
      const saleData = (art.buyNows?.length && art.buyNows[0]) || (art.auctions?.length && art.auctions[0]);

      return {
        ...art,
        id: art.id,
        name: art.name || '',
        artImage: art.artImage,
        amount: art.amount,
        artStatus: art.artStatus,
        owner: art.owners[(art.owners?.length || 1) - 1]?.owner || [],
        author: art.author || {},
        saleData: saleData || null,
        tokenType: art.tokenType || '',
      };
    });
  };

  useEffect(() => {
    const fetchArtsList = async () => {
      if (artType === 'creations') {
        dispatch({
          type: 'SET_LOADING_ARTS',
          payload: true,
        });
        try {
          const arts = (
            await artsApi.artControllerFindAll(
              // @ts-ignore
              nonDraftsStatuses,
              userWallet,
              undefined,
              undefined,
              undefined,
              limitArtsPerPage,
              state.currentArtsPage,
            )
          ).data;

          const mappedArts = getMappedArts(arts.items as unknown as Art[]);

          dispatch({
            type: 'FETCH_ARTS',
            payload: { arts: [...state.arts, ...mappedArts], totalPages: arts.meta.totalPages },
          });
        } finally {
          dispatch({
            type: 'SET_LOADING_ARTS',
            payload: false,
          });
        }
      }

      if (artType === 'owned') {
        dispatch({
          type: 'SET_LOADING_ARTS',
          payload: true,
        });
        try {
          // @ts-ignore
          const arts = (
            await artsApi.artControllerFindAll(
              // @ts-ignore
              nonDraftsStatuses,
              undefined,
              userWallet,
              undefined,
              true,
              limitArtsPerPage,
              state.currentArtsPage,
            )
          ).data;

          const mappedArts = getMappedArts(arts.items as unknown as Art[]);

          dispatch({
            type: 'FETCH_ARTS',
            payload: { arts: [...state.arts, ...mappedArts], totalPages: arts.meta.totalPages },
          });
        } finally {
          dispatch({
            type: 'SET_LOADING_ARTS',
            payload: false,
          });
        }
      }
    };

    fetchArtsList();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWallet, state.currentArtsPage]);

  return state.arts;
};
