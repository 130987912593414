import React, { Reducer, useContext } from 'react';
import { UpdateUploadFileDto, User } from 'codegen-api/api-totemo-service/models';
import { ArtWithSaleInfo } from 'types';

interface State {
  isProfileLoading: boolean;
  userInfo: User;
  arts: ArtWithSaleInfo[];
  currentArtsPage: number;
  totalPages: number;
  isArtsLoading: boolean;
  error: Error;
}

type Action =
  | { type: 'FETCH_USER_INFO_FULFILLED'; payload: User }
  | { type: 'FETCH_USER_INFO_REJECTED'; payload: Error }
  | { type: 'EDIT_USER_INFO_FULFILLED' }
  | { type: 'EDIT_ARTSTUDIO_INFO_FULFILLED' }
  | { type: 'EDIT_ARTSTUDIO_BACKGROUND_FULFILLED'; payload: UpdateUploadFileDto | void }
  | { type: 'FETCH_ARTS'; payload: { arts: ArtWithSaleInfo[]; totalPages: number } }
  | { type: 'FETCH_MORE_ARTS' }
  | { type: 'TOGGLE_ART_TABS' }
  | { type: 'SET_LOADING_ARTS'; payload: boolean };

const initialState = {
  isProfileLoading: true,
  userInfo: {
    settings: {
      avatar: {
        url: '',
        key: '',
      },
      background: {
        url: '',
        key: '',
      },
      podcast: [],
      video: [],
      social: [],
    },
  } as User,
  arts: [],
  currentArtsPage: 1,
  totalPages: 0,
  isArtsLoading: false,
  error: {} as Error,
};

const reducer = (state: State, action: Action): State => {
  let result = state;
  switch (action.type) {
    case 'FETCH_USER_INFO_FULFILLED': {
      result = { ...state, isProfileLoading: false, userInfo: action.payload };
      break;
    }
    case 'FETCH_USER_INFO_REJECTED': {
      result = { ...initialState };
      break;
    }
    case 'EDIT_ARTSTUDIO_BACKGROUND_FULFILLED': {
      result = {
        ...state,
        userInfo: { ...state.userInfo, settings: { ...state.userInfo.settings, background: action.payload } },
      };
      break;
    }
    case 'FETCH_ARTS': {
      result = { ...state, arts: action.payload.arts, totalPages: action.payload.totalPages };
      break;
    }
    case 'FETCH_MORE_ARTS': {
      result = { ...state, currentArtsPage: state.currentArtsPage + 1 };
      break;
    }
    case 'SET_LOADING_ARTS': {
      result = { ...state, isArtsLoading: action.payload };
      break;
    }
    case 'TOGGLE_ART_TABS': {
      result = { ...state, currentArtsPage: 1, arts: [] };
      break;
    }
  }
  return result;
};

export const useUserProfileReducer = () => {
  return React.useReducer<Reducer<State, Action>>(reducer, initialState);
};

export const useProfileReducerContext = () => {
  const context = useContext(ProfileContext);

  if (typeof context === 'undefined') {
    throw new Error('useProfileContext must be used within an ProfileContext.Provider');
  }

  return { state: context.state, dispatch: context.dispatch };
};

export const ProfileContext = React.createContext<{ state: State; dispatch: React.Dispatch<Action> } | undefined>(
  undefined,
);
