import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { BaseModal, BaseModalProps } from 'components/Modal/BaseModal';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { ArtArtStatusEnum } from 'codegen-api/api-totemo-service/models';

type Props = Omit<BaseModalProps, 'handleClose'>;

export const ArtSaleCancelSuccessPopup: React.FC<Props> = React.memo((props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useArtDetailedView();
  const isAdmin = useHasRole('SUPERADMIN');

  let titleTKey;
  let descriptionTKey;
  let submitTKey;
  let handleSubmit;

  const handleClose = () => dispatch({ type: 'HIDE_INFO_POPUP' });

  if (state.art?.artStatus === ArtArtStatusEnum.NOTFORSALE) {
    titleTKey = 'artPage.wasMovedNotForSaleTitle';
    descriptionTKey = isAdmin ? 'artPage.wasMovedNotForSaleDescription' : 'artPage.wasMovedNotForSaleDescriptionAdmin';
    submitTKey = 'artPage.goToProfile';
    handleSubmit = () => navigate(`${routes.userProfile}/${state.art?.authorWallet}`);
  } else {
    titleTKey = 'artPage.wasMovedToDraftsTitle';
    descriptionTKey = isAdmin ? 'artPage.wasMovedToDraftsDescriptionAdmin' : '';
    submitTKey = isAdmin ? 'artPage.goToAdminPanel' : 'common.continue';
    handleSubmit = isAdmin ? () => navigate(`${routes.adminPanelCreatedArtsDrafts}`) : handleClose;
  }

  return (
    <BaseModal handleClose={handleClose} {...props}>
      <Box sx={sx.container}>
        <Typography variant="h6" mb={1.25}>
          {t(titleTKey)}
        </Typography>
        {descriptionTKey ? (
          <Typography component="div" variant="paragraphLarge" textAlign="center">
            {t(descriptionTKey)}
          </Typography>
        ) : null}
        <Button sx={sx.confirm} fullWidth variant="contained" color="primary" onClick={handleSubmit}>
          {t(submitTKey)}
        </Button>
      </Box>
    </BaseModal>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  confirm: {
    mt: 2.75,
  },
};
