import { useSingleContract } from 'abi/useSingleContract';
import { useAppState } from 'contexts/appState/AppContext';
import { useSecondarySaleSingle } from '../SecondarySaleSingleProvider';
import { useApiContext } from 'contexts/ApiContext';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { CreateAuctionRequestDtoStatusEnum } from 'codegen-api/api-totemo-service/models';
import { routes } from 'app-settings';
import { getUnixTime } from 'date-fns';
import { getFullPriceEth } from 'pages/CreateEditArtPage/utils';

export const useSecondarySaleSingleAuctionSubmit = (servicePercentage: number) => {
  const { createAuctionVoucher } = useSingleContract();
  const { currentUser } = useAppState();
  const { state, dispatch } = useSecondarySaleSingle();
  const api = useApiContext();
  const navigate = useNavigate();

  return React.useCallback(
    async (values) => {
      try {
        dispatch({ type: 'START_PUBLISHING' });
        const ownerWallet = state.art.owners[0].ownerWallet;

        const signature = await createAuctionVoucher(
          state.art.tokenId,
          state.art.authorWallet,
          currentUser.wallet,
          {
            startingPrice: values.price,
            startDate: values.startDate,
            endDate: values.endDate,
            startDateType: values.startDateType,
            primaryServiceFee: servicePercentage,
          } as any,
          ownerWallet,
          true,
        );

        await api.auction.auctionControllerCreate({
          artId: state.art.id,
          ownerWallet,
          authorWallet: state.art.authorWallet,
          pmWallet: currentUser.wallet,
          startDate: getUnixTime(values.startDate),
          endDate: getUnixTime(values.endDate),
          startPrice: +getFullPriceEth(String(values.price), String(servicePercentage)),
          isFirstSale: false,
          signature,
          status: CreateAuctionRequestDtoStatusEnum.ACTIVE,
        });

        navigate(`${routes.art}/${state.art.id}`);
      } catch (e) {
        console.log(e);
        dispatch({ type: 'STOP_PUBLISHING' });
      }
    },
    [
      dispatch,
      createAuctionVoucher,
      state.art.tokenId,
      state.art.authorWallet,
      state.art.owners,
      state.art.id,
      currentUser.wallet,
      servicePercentage,
      api.auction,
      navigate,
    ],
  );
};
