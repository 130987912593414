import React from 'react';
import { Box, SxProps, Backdrop, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Theme } from '@mui/material/styles';

import { useApiContext } from 'contexts/ApiContext';
import { AdminPanelUsersTable } from 'pages/AdminPanel/AdminPanelUsers/AdminPanelUsersTable';
import { SearchInput } from 'components/UiKit/SearchInput/SearchInput';
import AdminPanelUsersNoResults from 'pages/AdminPanel/AdminPanelUsers/AdminPanelUsersNoResults';
import { useAdminPanelUsersReducer } from 'pages/AdminPanel/AdminPanelUsers/useAdminPanelUsersReducer';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import UserInfo from './UserInfo';
import {
  adminPanelUsersAllColumns as allColumns,
} from 'pages/AdminPanel/AdminPanelUsers/constants';

const AdminPanelUsers = () => {
  const { t } = useTranslation();
  const { users: usersApi, socket } = useApiContext();
  const [state, dispatch] = useAdminPanelUsersReducer();
  const [page, setPage] = React.useState(1);
  const isMobileScreen = useIsMobileScreen();

  const handleChangeColumn = (id: string, index: number) => {
    dispatch({ type: 'COLUMN_CHANGED', payload: { id, index } });
  };

  const handleChangeSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    dispatch({ type: 'SEARCH_CHANGED', payload: e.target.value });
  }, 400);

  const selectUser = (id: number) => {
    dispatch({ type: 'SELECT_USER', payload: { id } });

  };

  React.useEffect(() => {
    dispatch({ type: 'LOADING' });
    usersApi
      .userControllerFindAll(
        state.searchValue || undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        isMobileScreen ? 100 : 13,
        page,
      )
      .then((resp) => {
        dispatch({ type: 'LOADED', payload: resp.data });
      })
      .catch();
  }, [usersApi, state.searchValue, dispatch, page]);

  React.useEffect(() => {
    const notificationListener = async (messageString: string) => {
      try {
        const data: any = JSON.parse(JSON.parse(messageString).data);
        const affectedId = data.toId;
        if (state.users.find((item) => item.id === affectedId)) {
          const resp = await usersApi.userControllerGetFullUser(affectedId);
          dispatch({ type: 'UPDATE_SINGLE_USER', payload: resp.data });
        }
      } catch (e) {
        dispatch({ type: 'RELOAD' });
      }
    };

    socket!.on('notification', notificationListener);

    return () => {
      socket!.off('notification', notificationListener);
    };
  }, [socket, usersApi, dispatch, state.users]);

  React.useEffect(() => {
    if (!isMobileScreen) return;
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [page, isMobileScreen]);


  const selectedUser = React.useMemo(() => {
    return state.users.find(user => user.id === state.selectedUserId);
  }, [state.selectedUserId]);

  React.useEffect(() => {
    if (isMobileScreen) {
      dispatch({ type: 'SET_COLUMNS', payload: { columns: allColumns.slice(0, 2) } });
    } else {
      dispatch({ type: 'SET_COLUMNS', payload: { columns: allColumns } });
    }

  }, [isMobileScreen]);


  return (
    <Box sx={sx.container}>
      <Backdrop
        sx={sx.backdrop}
        open={state.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Box sx={sx.searchContainer}>
          <SearchInput
            onChange={handleChangeSearch}
            id="search"
            placeholder={t('adminPanel.usersSearchPlaceholder')}
            fullWidth
            sx={sx.searchInput}
          />
        </Box>
        {state.users.length || !state.searchValue ? (
          <AdminPanelUsersTable
            columns={state.columns}
            rows={state.users}
            onChangeColumn={handleChangeColumn}
            pagination={state.meta}
            setPage={setPage}
            currentPage={page}
            selectUser={selectUser}
            selectedUserId={state.selectedUserId}
          />
        ) : (
          <AdminPanelUsersNoResults value={state.searchValue} />
        )}
      </Box>
      {
        !isMobileScreen && (
          <UserInfo user={selectedUser} />
        )
      }
    </Box>
  );
};

export default AdminPanelUsers;

const sx: Record<string, SxProps<Theme>> = {
  container: (theme) => ({
    display: 'grid',
    gap: '40px',
    mt: '30px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '5fr 1fr',
    },
  }),
  searchContainer: {
    px: 2.5,
    mb: '30px',
  },
  searchInput: {
    height: '50px',
  },
  backdrop: {
    color: '#fff',
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
};
