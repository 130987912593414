import React from 'react';
import { ArtSaleNotStarted } from 'pages/Art/ArtSaleNotStarted';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { BuyNow, SaleTransactionInfo } from 'codegen-api/api-totemo-service/models';
import { useAppState } from 'contexts/appState/AppContext';
import { ArtPendingBanner } from 'pages/Art/ArtPendingBanner';

export const ArtBuyNowStatus: React.FC = React.memo(() => {
  const { state } = useArtDetailedView();
  const { currentUser } = useAppState();

  if (!state.saleData || !('startMarketDate' in state.saleData)) return null;

  const startDateTimestamp = state.saleData.startMarketDate * 1000;

  if (startDateTimestamp > Date.now()) {
    return <ArtSaleNotStarted startDateTimestamp={startDateTimestamp} />;
  }

  const isCurrentUserWaitingTransactionEnd = state.art?.buyNows.some((buyNow: BuyNow) =>
    buyNow?.transactions?.some(
      (tx: SaleTransactionInfo) => tx.signer === currentUser.wallet && buyNow.ownerWallet !== currentUser.wallet,
    ),
  );

  if (isCurrentUserWaitingTransactionEnd) {
    return <ArtPendingBanner />;
  }

  return null;
});
