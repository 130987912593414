import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import type { Art } from 'codegen-api/api-totemo-service/models';

export const ArtLocationAndEvent: React.FC<{ art: Art }> = React.memo(({ art }) => {
  const { t } = useTranslation();

  return (
    <Box mt={2.5}>
      <Box display={'flex'}>
        <Typography variant="paragraphSmall" sx={sx.title}>
          {t('artPage.location')}
        </Typography>
        <Typography variant="paragraphSmall">{art.location || '-'}</Typography>
      </Box>
      {/* @ts-ignore */}
      {!!art.event && (
        <>
          <Divider sx={sx.divider} />
          <Box display={'flex'}>
            <Typography variant="paragraphSmall" sx={sx.title}>
              {t('artPage.event')}
            </Typography>
            <Box />
          </Box>
        </>
      )}
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  title: {
    fontWeight: 600,
    color: 'primary.main',
    flex: (theme) => `0 0 ${theme.spacing(11)}`,
    justifyContent: 'flex-start',
  },
  divider: { my: 2 },
};
