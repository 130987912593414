import React from 'react';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { BaseModal, BaseModalProps } from '../BaseModal';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';

interface Props extends BaseModalProps {
  handleContinue: () => void;
}

export const CancelModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <BaseModal {...props}>
      <Box sx={sx.box}>
        <Typography variant="h6">{t('common.areYouSure')}</Typography>
        <Typography component="div" variant="paragraphLarge" sx={sx.description}>
          {t('common.allWillBeLost')}
        </Typography>
        <Button variant="contained" color="primary" sx={sx.button} onClick={props.handleContinue}>
          {t('common.continue')}
        </Button>
      </Box>
    </BaseModal>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  box: { px: 1, pb: 3 },
  description: { mt: 2, textAlign: 'center' },
  button: { mt: 4, width: '100%' },
};
