import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApiContext } from 'contexts/ApiContext';
import { useTranslation } from 'react-i18next';
import { ArtCard } from './ArtCard';
import { Art } from 'codegen-api/api-totemo-service/models';
import { SxProps, Theme } from '@mui/material/styles';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { limitArtsPerPage } from 'app-settings';

export const Explore = () => {
  const { t } = useTranslation();
  const api = useApiContext();

  const [arts, setArts] = useState<Art[] | undefined>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchArts = async () => {
      try {
        const arts = await api.art.artControllerFindAll(
          // @ts-ignore
          'PROMO',
          undefined,
          undefined,
          undefined,
          undefined,
          limitArtsPerPage,
          page,
        );
        const data = arts.data.items as Art[];
        if (!!data.length) {
          setArts((prevArts) => {
            return [...(prevArts as Art[]), ...data];
          });

          if (page === arts.data.meta.totalPages) {
            setHasMore(false);
          }
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };

    fetchArts();
  }, [api.art, page]);

  const handleViewMore = () => {
    setPage((prevPageNumber) => prevPageNumber + 1);
  };

  if (!arts?.length) {
    return null;
  }

  return (
    <Container sx={sx.wrapper} id="explore">
      <Typography variant="h1" textAlign="center" mb={5}>
        {t('homePage.explore')}
      </Typography>

      <Box sx={sx.cardsWrapper}>
        {arts?.map((art, idx) => {
          return <ArtCard art={art} key={idx} />;
        })}
      </Box>

      {hasMore && (
        <AsyncButton isLoading={isLoading} variant="outlined" sx={{ mt: 2.5 }} onClick={handleViewMore}>
          {t('common.viewMore')}
        </AsyncButton>
      )}
    </Container>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  wrapper: {
    mb: 8.75,
    px: 2.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardsWrapper: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      gap: 3.75,
      flexWrap: 'wrap',
    },
  }),
};
