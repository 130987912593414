import ReactGA from 'react-ga4';

const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

if (measurementId) {
  ReactGA.initialize(measurementId);
}

export default ReactGA;

