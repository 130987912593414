import { AppAction, AppState } from 'contexts/appState/types';
import produce, { current } from 'immer';
import { EMPTY_ROLE, EMPTY_USER } from 'contexts/appState/AppContext';
import { devLog } from 'helpers';

export const appReducer = produce((state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      state.currentUser = action.payload;
      if (action.payload.sub) {
        state.currentUser = { ...state.currentUser, id: action.payload.sub };
      }
      if (!state.currentUser.role) {
        state.currentUser.role = [];
      }
      if (!state.currentUser.role.length) {
        state.currentUser.role.push({
          ...EMPTY_ROLE,
          role: state.currentUser.wallet ? 'COLLECTOR' : 'GUEST',
        });
      }
      break;
    case 'UPDATE_CURRENT_USER':
      state.currentUser = { ...state.currentUser, ...action.payload };
      if (!state.currentUser.role) {
        state.currentUser.role = [];
      }
      if (!state.currentUser.role.length) {
        state.currentUser.role.push({
          ...EMPTY_ROLE,
          role: state.currentUser.wallet ? 'COLLECTOR' : 'GUEST',
        });
      }
      break;
    case 'SET_EXCHANGE_RATE':
      state.exchangeRate = { ...action.payload };
      break;
    case 'SHOW_WRONG_NETWORK':
      state.isWrongNetworkOpen = true;
      break;
    case 'CLOSE_WRONG_NETWORK':
      state.isWrongNetworkOpen = false;
      break;
    case 'LOGOUT':
      state.currentUser = { ...EMPTY_USER };
      state.isWrongNetworkOpen = false;
      break;
    default:
      //@ts-ignore
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  //@ts-ignore
  devLog(action.type, action.payload, current(state));

  return state;
});
