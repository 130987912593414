import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { ArtHistory } from 'pages/Art/ArtHistory/ArtHistory';
import { ArtOwners } from 'pages/Art/ArtTabs/ArtOwners';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtArtStatusEnum } from 'codegen-api/api-totemo-service/models';

export const ArtTabs: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = React.useState<'history' | 'owners'>('owners');
  const { state } = useArtDetailedView();

  if (!state.art || state.art.artStatus === ArtArtStatusEnum.DRAFT) {
    return null;
  }

  return (
    <Box my={5}>
      <Tabs variant="fullWidth" value={currentTab} onChange={(e, value) => setCurrentTab(value)} centered>
        <Tab sx={sx.tab} value={'owners'} label={t('artPage.owners')} />
        <Tab sx={sx.tab} value={'history'} label={t('artPage.history')} />
      </Tabs>
      <Box pt={2.5}>{currentTab === 'owners' ? <ArtOwners /> : <ArtHistory />}</Box>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  tab: {
    '&.MuiTab-root': {
      fontFamily: 'Playfair Display',
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '120%',
      letterSpacing: '0.02em',
      textAlign: 'center',
      textTransform: 'none',
    },
  },
};
