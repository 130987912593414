import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link, Typography, useTheme } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';
import { useAppState } from 'contexts/appState/AppContext';
import { SignInLayout } from 'components/SignInLayout/SignInLayout';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import Footer from './Footer';
import Connect from './Connect';

const METAMASK_DOWNLOAD_LINK = 'https://metamask.io/download/';

export const SignIn = React.memo(() => {
  const { t } = useTranslation();
  const { currentUser } = useAppState();
  const isLoggedIn = currentUser.wallet || currentUser.email;
  const theme = useTheme();
  const isMobile = useIsMobileScreen();

  if (isLoggedIn) return null;

  const perks = t('signIn.totemoPerks', { returnObjects: true }) as Array<string>;

  return (
    <SignInLayout titleTKey="">
      <Box sx={sx.content}>
        <Typography variant="h2" textAlign="center" sx={sx.title}>
          {t('signIn.totemoDescription')}
        </Typography>
        <Box mt={isMobile ? 2.5 : 3}>
          <Box sx={sx.perks}>
            {perks.map((perk) => (
              <li>
                <Trans>{perk}</Trans>
              </li>
            ))}
          </Box>

          {isMobile && (
            <Box sx={sx.featuringArtists}>
              <Typography color={theme.palette.text.disabled} fontSize="sm">
                {t('signIn.featuredArtists')}:
              </Typography>
              <Typography fontWeight="bold">Chris RWK, BAKIBAKI, Falko One and more</Typography>
            </Box>
          )}

          <Connect />

          <Box display="flex" justifyContent="center" mb={3.5}>
            <Typography textAlign="center" fontSize="14px">
              <Trans
                i18nKey={'signIn.downloadMetamask'}
                components={[
                  <Link
                    target="_blank"
                    sx={{ color: theme.palette.accent.main }}
                    href={METAMASK_DOWNLOAD_LINK}
                    rel="noreferrer"
                  />,
                ]}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
      {!isMobile && (
        <Box sx={sx.featuringArtists} mt="90px">
          <Typography color={theme.palette.text.disabled} fontSize="small">
            {t('signIn.featuredArtists')}:
          </Typography>
          <Typography fontWeight="bold" fontSize="md">
            Chris RWK, BAKIBAKI, Falko One and more
          </Typography>
        </Box>
      )}
      <Footer />
    </SignInLayout>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  subtitle: { mt: 3.75, fontWeight: 700, whiteSpace: 'pre-line', textAlign: 'center' },
  description: { mt: 0.5, fontWeight: 400, whiteSpace: 'pre-line', textAlign: 'center', color: 'text.secondary' },

  metamaskLogo: {
    display: 'flex',
    alignItems: 'center',
    pt: 3.75,
    pb: 2.5,
  },
  metamaskLogoTitle: {
    ml: 2.5,
  },
  secondaryBtn: {
    fontWeight: 600,
    m: 2,
  },

  featuringArtists: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 3.5,
    [theme.breakpoints.up('sm')]: {
      gap: 1,
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }),

  content: (theme) => ({
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      mx: 2.5,
    },
    [theme.breakpoints.up('sm')]: {
      width: '860px',
    },
  }),

  perks: (theme) => ({
    px: 2.5,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  }),
  title: {
    px: 2.5,
    maxWidth: '670px',
    margin: '0 auto',
  },
};
