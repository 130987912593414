import { Box, CardContent, Typography } from '@mui/material';
import { defaultAvatarHomepageSrc, routes } from 'app-settings';
import React, { FC } from 'react';
import { Art, User } from 'codegen-api/api-totemo-service/models';
import { SxProps, Theme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useIsMobileScreen from '../../../helpers/useIsMobileScreen';

interface Props {
  user: User;
  arts: Art[];
}

export const ArtistCard: FC<Props> = ({ user, arts }) => {
  const navigate = useNavigate();
  const isMobileScreen = useIsMobileScreen();

  return (
    <Box sx={sx.card} onClick={() => navigate(`${routes.userProfile}/${user.wallet}`)}>
      <Box sx={sx.media}>
        <Box
          sx={
            [
              // @ts-ignore
              sx.avatar,
              {
                backgroundImage: `url("${user.settings?.avatar?.url || defaultAvatarHomepageSrc}")`,
              },
            ]
          }
        />

        <Box sx={sx.arts}>
          {arts?.slice(0, 4).map((art, idx) => {
            return (
              // @ts-ignore
              <Box sx={[sx.art, { backgroundImage: `url("${art.artImage?.url}")` }]} key={idx} />
            );
          })}
        </Box>
      </Box>

      <CardContent sx={sx.content}>
        <Typography gutterBottom variant="h2" textAlign={isMobileScreen ? 'center' : 'start'}>
          {user.fullName || ''}
        </Typography>

        <Typography gutterBottom variant="h5" textAlign={isMobileScreen ? 'center' : 'start'}>
          {`@${user.username}`}
        </Typography>

        <Typography
          variant="paragraphSmall"
          color="text.default"
          textAlign={isMobileScreen ? 'center' : 'start'}
        >
          {user.bio || ''}
        </Typography>
      </CardContent>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  media: theme => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 1.25,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: 0.5,
      gridTemplateColumns: '5fr 1fr',
    },
  }),
  card: theme => ({
    backgroundColor: 'grey.100',
    mx: 'auto',
    borderRadius: 0.8,
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      height: '300px',
      gridTemplateColumns: '1fr 1fr',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '1fr 1fr',
    },
  }),
  arts: theme => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 0.5,
    [theme.breakpoints.up('sm')]: {
      gap: 1.25,
      gridTemplateColumns: '1fr 1fr',
    },
  }),
  art: {
    backgroundColor: 'grey.300',
    borderTopRightRadius: 5,
    background: 'no-repeat center/cover',
  },

  avatar: {
    backgroundColor: 'grey.300',
    borderTopLeftRadius: 5,
    background: 'no-repeat center/cover',
  },
  content: theme => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 2.5,
    [theme.breakpoints.up('sm')]: {
      padding: '35px 45px',
    },
  }),
};
