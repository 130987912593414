import { SxProps, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import React, { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { useApiContext } from 'contexts/ApiContext';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';

type FormValues = {
  email: string;
};

const formInitialValues = {
  email: '',
};

export const Newsletters = () => {
  const { t } = useTranslation();

  const { subscribers: subscribersApi } = useApiContext();

  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);

  const validationSchema = useSubscribeValidationSchema();

  const onSubmit = async ({ email }: FormValues, formik: FormikHelpers<FormValues>) => {
    try {
      await subscribersApi.lettersSubscribersControllerCreate({ subscribersEmail: email });

      formik.resetForm();

      setConfirmOpen(true);

      setTimeout(function () {
        setConfirmOpen(false);
      }, 10000);
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 409) {
        formik.setFieldError('email', t('forms.errors.subscriberExist', { email: email }));
      }
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: formInitialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, handleChange, errors, values, touched, isSubmitting } = formik;

  return (
    <Box sx={sx.wrapper}>
      <form onChange={handleChange} onSubmit={handleSubmit}>
        {isConfirmOpen ? (
          <ConfirmElement />
        ) : (
          <>
            <Typography variant="h2" textAlign={'center'}>
              {t('homePage.newsLettersTitle')}
            </Typography>

            <Typography variant="paragraphSmall" mt={1.9} textAlign={'center'}>
              {t('homePage.newsLettersTitleDesc')}
            </Typography>
            <Box sx={sx.emailInput}>
              <OutlinedTextInput
                sx={sx.input}
                required={true}
                value={values.email}
                name="email"
                id="email"
                autoComplete="off"
                label={t('forms.email')}
                error={!!(touched.email && errors.email)}
                helperText={touched.email && t(errors.email as string)}
              />

              <AsyncButton isLoading={isSubmitting} type="submit" variant="contained" sx={sx.submit}>
                {t('homePage.subscribeBtn')}
              </AsyncButton>
            </Box>
          </>
        )}
      </form>
    </Box>
  );
};

const ConfirmElement = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={sx.imageBlock}>
        <img src={'/assets/icons/succs-published.svg'} alt="success-published" />
      </Box>

      <Typography variant="h2" textAlign="center">
        {t('homePage.thankYou')}
      </Typography>

      <Typography variant="paragraphSmall" textAlign="center" mt={1.875}>
        {t('homePage.confirmSubscription')}
      </Typography>
    </>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 'auto',
    px: 2.5,
    py: 8.5,
    width: '100%',
    background: `url('/assets/images/default_cover.png') no-repeat center/cover`,
  },
  input: {
    mx: 'auto',
    backgroundColor: 'transparent',
    '&:active, &:focus, &:hover, &:focus-visible': {
      backgroundColor: 'transparent',
    },
  },
  submit: {
    mx: 'auto',
  },
  imageBlock: { mb: 5, display: 'flex', justifyContent: 'center' },
  emailInput: theme => ({
    mt: 5,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 2.5,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '2fr 1fr',
      flexDirection: 'row',
    },
  }),
};

const useSubscribeValidationSchema = () => {
  return React.useMemo(() => {
    return Yup.object().shape({
      email: Yup.string().trim().required('forms.errors.fieldRequired').email('forms.errors.emailInvalid'),
    });
  }, []);
};
