import React, { CSSProperties, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from 'app-settings';

const Logo: React.FC<{ link?: string, sx?: CSSProperties }> = memo(({ link = routes.home, sx }) => {
  return (
    <NavLink to={link} style={sx}>
      <img style={{ display: 'block' }} alt="logo" src={'/assets/icons/logo.svg'} width="100%" />
    </NavLink>
  );
});

export default Logo;
