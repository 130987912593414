import React from 'react';
import { ThemeProvider } from '@mui/material';
import ApiProvider from './ApiContext';
import { theme } from 'styles/theme';
import { AppStateProvider } from 'contexts/appState/AppContext';
import { AppEffects } from 'contexts/appEffects/useAppEffects';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import { BrowserRouter } from 'react-router-dom';
import WC2LayerProvider from './WC2Layer/WC2LayerProvider';

const SingletonProviders: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <BrowserRouter>
        <ApiProvider>
          <WC2LayerProvider>
            <AppStateProvider>
              <AppEffects />
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </AppStateProvider>
          </WC2LayerProvider>
        </ApiProvider>
      </BrowserRouter>
    </LocalizationProvider>
  );
};

export default SingletonProviders;
