import React from 'react';

type Output = {
  anchorEl: null | HTMLElement;
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseMenu: () => void;
  getDataValue: (dataPropName: string) => string | undefined;
};

export const useContextMenu = (): Output => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getDataValue = (dataPropName: string) => {
    if (!anchorEl) return;
    const el = anchorEl.closest<HTMLElement>(`[data-${dataPropName}]`);
    if (!el) return;
    return el.dataset[dataPropName];
  };

  return {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
    getDataValue,
  };
};
