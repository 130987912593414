import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLink } from 'react-router-dom';
import { routes } from 'app-settings';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { EMPTY_USER, useAppDispatch, useAppState } from 'contexts/appState/AppContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PersonIcon } from 'components/Icons/person.svg';
import { ReactComponent as SignOutIcon } from 'components/Icons/sign_out.svg';
import { ReactComponent as CopyIcon } from 'components/Icons/copy.svg';
import { ReactComponent as KeyIcon } from 'components/Icons/key.svg';
import { ReactComponent as WalletIcon } from 'components/Icons/wallet.svg';
import { copyString } from 'helpers/copyString';
import { keyframes } from '@mui/system';
import { WalletHex } from 'components/UiKit/WalletHex/WalletHex';
import { useApiContext } from 'contexts/ApiContext';
import { useLogout } from 'contexts/appState/useLogout';
import { useHasRole } from 'components/RequireRole/RequireRole';

interface Props {
  isMenuOpen: boolean;
  onClose: () => void;
}

const HeaderCurrentUserMenu: React.FC<Props> = ({ isMenuOpen, onClose: handleClose }) => {
  const { currentUser } = useAppState();
  const appDispatch = useAppDispatch();
  const [isAnimated, setIsAnimated] = React.useState(true);
  const { t } = useTranslation();
  const [currency, setCurrency] = React.useState(currentUser.currencyType);
  const logout = useLogout();
  const { users } = useApiContext();
  const isVisitor = useHasRole('VISITOR');

  const handleLogout = () => {
    handleClose();
    logout();
  };
  const handleCopy = () => copyString(currentUser.wallet);

  React.useEffect(() => {
    if (!isMenuOpen) return;

    if (isAnimated) {
      setIsAnimated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  React.useEffect(() => {
    if (currentUser.wallet === EMPTY_USER.wallet) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.wallet]);

  const handleChangeCurrency = async (value: string) => {
    setCurrency(value);
    try {
      //TODO: fix swagger scheme
      //@ts-ignore
      await users.userControllerUpdate({ currencyType: value }, currentUser.wallet);
    } catch (e) {}
    appDispatch({ type: 'UPDATE_CURRENT_USER', payload: { currencyType: value } });
  };

  return (
    <Box
      sx={sx.menu}
      onAnimationEnd={() => !isMenuOpen && setIsAnimated(true)}
      className={isAnimated ? undefined : isMenuOpen ? 'animate-down' : 'animate-up'}
    >
      <Box sx={sx.userInfo}>
        <Typography variant="h3" sx={sx.username}>
          {currentUser.username}
        </Typography>
        {currentUser.wallet && (
          <Box sx={sx.wallet}>
            <Box sx={sx.walletHex}>
              <WalletHex hex={currentUser.wallet} />
            </Box>
            <Box sx={sx.copy} onClick={handleCopy}>
              <CopyIcon />
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={sx.userSettings}>
        <Box sx={sx.settingsItem}>
          <Typography sx={sx.itemTitle}>{t('header.primaryCurrency')}</Typography>
          <Select
            sx={sx.select}
            size={'small'}
            value={currency}
            onChange={(e) => handleChangeCurrency(e.target.value)}
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={{
              classes: {
                list: 'currency-menu',
              },
            }}
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="JPY">JPY</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
          </Select>
        </Box>
      </Box>
      <Divider />
      <List>
        {currentUser.wallet && (
          <ListItem disablePadding>
            <ListItemButton
              component={NavLink}
              to={`${routes.userProfile}/${currentUser.wallet}`}
              onClick={handleClose}
            >
              <Box sx={sx.listIconContainer}>
                <PersonIcon />
              </Box>
              <ListItemText primary={t('header.myProfile')} />
            </ListItemButton>
          </ListItem>
        )}
        {isVisitor && (
          <>
            <ListItem disablePadding sx={sx.accentListItem}>
              <ListItemButton component={NavLink} to={routes.connectWallet} onClick={handleClose}>
                <Box sx={sx.listIconContainer}>
                  <WalletIcon fill="white" width={20} />
                </Box>
                <ListItemText primary={t('common.connectWallet')} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={NavLink}
                to={`${routes.changePassword}/${currentUser.id}`}
                onClick={handleClose}
              >
                <Box sx={sx.listIconContainer}>
                  <KeyIcon width={20} />
                </Box>
                <ListItemText primary={t('common.changePassword')} />
              </ListItemButton>
            </ListItem>
          </>
        )}
        <ListItem disablePadding>
          <ListItemButton onClick={handleLogout}>
            <Box sx={sx.listIconContainer}>
              <SignOutIcon />
            </Box>
            <ListItemText primary={t('common.signOut')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default HeaderCurrentUserMenu;

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;
const slideOut = keyframes`
  from {
    display: block;
    transform: translateY(0);
  }
  to {
    display: block;
    transform: translateY(-100%);
  }
`;

const sx: Record<string, SxProps<Theme>> = {
  menu: {
    display: 'none',
    top: 'var(--header-height)',
    left: 0,
    right: 0,
    position: 'absolute',
    boxShadow: '3px 3px 18px rgba(30, 30, 30, 0.2)',
    backgroundColor: 'common.white',
    borderRadius: '0 0 10px 10px',
    '&.animate-down': {
      display: 'block',
      animation: `225ms cubic-bezier(0, 0, 0.2, 1) 0ms ${slideIn}`,
    },
    '&.animate-up': {
      display: 'block',
      animation: `225ms cubic-bezier(0, 0, 0.2, 1) 0ms ${slideOut}`,
    },
  },
  userInfo: {
    p: 2.5,
  },
  wallet: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  walletHex: {
    maxWidth: (theme) => `calc(100% - ${theme.spacing(3.75)})`,
  },
  copy: {
    display: 'flex',
    alignItems: 'center',
    color: 'grey.600',
  },
  username: {
    textTransform: 'capitalize',
    mb: 1,
  },
  userSettings: {
    pb: 1.25,
  },
  settingsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2.5,
    py: 1.25,
  },
  itemTitle: {
    fontWeight: 'bold',
  },
  select: {
    backgroundColor: 'grey.50',
    fontWeight: 600,
    maxWidth: 80,
  },
  accentListItem: {
    backgroundColor: (theme) => theme.palette.accent.main,
    color: 'common.white',
  },
  listIconContainer: {
    display: 'flex',
    alignItems: 'center',
    mr: 2,
  },
  currencyList: {
    maxWidth: 80,
  },
};
