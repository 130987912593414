import { Switch, SwitchProps, SxProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

export const StyledSwitch = (props: SwitchProps) => {
  return <Switch sx={sx} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />;
};

const sx: SxProps<Theme> = {
  width: (theme) => theme.spacing(5.25),
  height: (theme) => theme.spacing(3.25),
  padding: 0,
  '& .MuiSwitch-switchBase': {
    p: 0,
    m: 0.25,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: (theme) => `translateX(${theme.spacing(2)})`,
      color: 'common.white',
      '& + .MuiSwitch-track': {
        bgcolor: 'accent.main',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: 'accent.active',
      border: (theme) => `${theme.spacing(0.75)} solid ${theme.palette.common.white}`,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: (theme) => theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: (theme) => (theme.palette.mode === 'light' ? 0.7 : 0.3),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: (theme) => theme.spacing(2.75),
    height: (theme) => theme.spacing(2.75),
  },
  '& .MuiSwitch-track': {
    borderRadius: (theme) => theme.spacing(1.625),
    backgroundColor: (theme) => theme.palette.grey[500],
    opacity: 1,
    transition: (theme) =>
      theme.transitions.create(['background-color'], {
        duration: 500,
      }),
  },
};
