import React from 'react';
import type { Art, Auction, Bid, BuyNow, SaleTransactionInfo } from 'codegen-api/api-totemo-service/models';
import {
  ArtArtStatusEnum,
  ArtBodyTokenTypeEnum,
  CreateBuyNowDtoStatusEnum,
} from 'codegen-api/api-totemo-service/models';
import { isAuction } from 'helpers/isAuction';

interface State {
  isLoading: boolean;
  isDrawerOpen: boolean;
  isInfoPopupOpen: boolean;
  infoPopupType: 'movedToDrafts' | 'movedNotForSale' | '';
  art: Art | null;
  saleData: BuyNow | Auction | null;
}

const initialState: State = {
  isLoading: false,
  isDrawerOpen: false,
  isInfoPopupOpen: false,
  infoPopupType: '',
  art: null,
  saleData: null,
};

export type Action =
  | { type: 'SET_ART'; payload: Art }
  | { type: 'SET_SALE_DATA'; payload: BuyNow | Auction }
  | { type: 'UPDATE_ART_STATUS'; payload: ArtArtStatusEnum }
  | {
  type: 'UPDATE_BUY_NOW_STATUS';
  payload: {
    status: CreateBuyNowDtoStatusEnum;
    id: number;
  };
}
  | { type: 'SHOW_DRAWER' }
  | { type: 'SHOW_DRAWER_FOR_SELECTED_BUY_NOW'; payload: number }
  | {
  type: 'CANCEL_SUCCESS';
  payload: {
    infoPopupType: 'movedToDrafts' | 'movedNotForSale' | '';
    artStatus: ArtArtStatusEnum;
    saleId: number;
  };
}
  | { type: 'CANCEL_SALE'; payload: { saleType: 'BUYNOW' | 'AUCTION'; saleId: number } }
  | { type: 'HIDE_INFO_POPUP' }
  | { type: 'SET_PENDING_TO_SINGLE'; payload: Partial<SaleTransactionInfo> }
  | {
  type: 'ADD_TRANSACTION';
  payload: { transaction: SaleTransactionInfo; saleId: number; saleType: 'BUYNOW' | 'AUCTION' };
}
  | {
  type: 'REMOVE_FAILED_TRANSACTION';
  payload: { transactionHash: string; saleId: number; saleType: 'BUYNOW' | 'AUCTION' };
}
  | { type: 'HIDE_DRAWER' }
  | { type: 'ADD_BID'; payload: Bid }
  | { type: 'ADD_BID_AND_HIDE_DRAWER'; payload: Bid }
  | { type: 'WINNER_CHANGED'; payload: { artId: number; winnerId: number; winnerWallet: string } }
  | { type: 'RERENDER' }
  | { type: 'RESET' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'RESET': {
      return { ...initialState };
    }

    case 'RERENDER': {
      return { ...state };
    }

    case 'SET_ART': {
      (['buyNows', 'auctions'] as const).forEach((saleArr) => {
        action.payload[saleArr] = action.payload[saleArr].map((item: BuyNow | Auction) => {
          if (!item.transactions) return { ...item, transactions: [] };

          return { ...item, transactions: Array.isArray(item.transactions) ? item.transactions : [item.transactions] };
        });
      });

      const saleData = action.payload.buyNows[0] || action.payload.auctions[0] || null;
      return { ...state, isLoading: false, art: action.payload, saleData };
    }

    case 'SHOW_DRAWER': {
      return { ...state, isDrawerOpen: true };
    }

    case 'SHOW_DRAWER_FOR_SELECTED_BUY_NOW': {
      if (!state.art) return state;
      const saleData = state.art.buyNows?.find((buyNow: BuyNow) => buyNow.id === action.payload) || null;

      return { ...state, isDrawerOpen: true, saleData: { ...saleData } };
    }

    case 'SET_PENDING_TO_SINGLE': {
      if (!state.art || !state.saleData) return state;
      const arrayName = isAuction(state.saleData) ? 'auctions' : 'buyNows';
      const saleData = { ...state.saleData, transactions: [...state.saleData.transactions, action.payload] };

      return {
        ...state,
        art: { ...state.art, artStatus: ArtArtStatusEnum.PENDING, [arrayName]: [saleData] },
        saleData,
      };
    }

    case 'CANCEL_SUCCESS': {
      if (!state.art) return state;
      const art = { ...state.art, artStatus: action.payload.artStatus };
      if (action.payload.artStatus === ArtArtStatusEnum.DRAFT) {
        art.buyNows = [];
        art.auctions = [];
      } else {
        art.buyNows = art.buyNows.filter((item: BuyNow) => item.id !== action.payload.saleId);
        art.auctions = art.auctions.filter((item: Auction) => item.id !== action.payload.saleId);
      }

      let saleData = state.saleData;
      if (saleData?.id === action.payload.saleId) {
        saleData = null;
      }

      return {
        ...state,
        art: { ...art },
        saleData,
        isInfoPopupOpen: !!action.payload.infoPopupType,
        infoPopupType: action.payload.infoPopupType,
      };
    }

    case 'CANCEL_SALE': {
      if (!state.art) return state;

      const art = { ...state.art };
      const arrayName = action.payload.saleType === 'BUYNOW' ? 'buyNows' : 'auctions';
      art[arrayName] = art[arrayName].filter((item: BuyNow | Auction) => item.id !== action.payload.saleId);
      let saleData = state.saleData;

      if (!art[arrayName].length) {
        art.artStatus = ArtArtStatusEnum.DRAFT;
        saleData = null;
      }

      if (saleData?.id === action.payload.saleId) {
        saleData = null;
      }

      return { ...state, art, saleData };
    }

    case 'HIDE_INFO_POPUP': {
      return { ...state, isInfoPopupOpen: false, infoPopupType: '' };
    }

    case 'ADD_TRANSACTION': {
      if (!state.art || !state.saleData) return state;

      const arrayName = action.payload.saleType === 'BUYNOW' ? 'buyNows' : 'auctions';
      const updatedBuyNowIdx = state.art[arrayName].findIndex(
        (sale: BuyNow | Auction) => sale.id === action.payload.saleId,
      );
      const updatedTransactions = state.art[arrayName][updatedBuyNowIdx].transactions.filter(
        (tx: SaleTransactionInfo) => tx.transactionHash !== action.payload.transaction.transactionHash,
      );
      updatedTransactions.push(action.payload.transaction);

      const art = {
        ...state.art,
        [arrayName]: [
          ...state.art[arrayName].slice(0, updatedBuyNowIdx),
          {
            ...state.art[arrayName][updatedBuyNowIdx],
            transactions: updatedTransactions,
          },
          ...state.art[arrayName].slice(updatedBuyNowIdx + 1),
        ],
      };

      return { ...state, art, saleData: art[arrayName][updatedBuyNowIdx] };
    }

    case 'REMOVE_FAILED_TRANSACTION': {
      if (!state.art || !state.saleData) return state;

      const arrayName = action.payload.saleType === 'BUYNOW' ? 'buyNows' : 'auctions';
      const saleIdx = state.art[arrayName].findIndex((item: BuyNow | Auction) => item.id === action.payload.saleId);

      if (saleIdx === -1) return state;

      const updatedSale = {
        ...state.art[arrayName][saleIdx],
        transactions: state.art[arrayName][saleIdx].transactions.filter(
          (tx: SaleTransactionInfo) => tx.transactionHash !== action.payload.transactionHash,
        ),
      };

      if (!updatedSale.transactions.length) {
        updatedSale.status = 'ACTIVE';
      }

      const art = {
        ...state.art,
        [arrayName]: [
          ...state.art[arrayName].slice(0, saleIdx),
          updatedSale,
          ...state.art[arrayName].slice(saleIdx + 1),
        ],
      };

      if (state.art.tokenType === ArtBodyTokenTypeEnum.ERC721) {
        art.artStatus = ArtArtStatusEnum.PUBLISHED;
      }

      const saleData = action.payload.saleId === state.saleData.id ? updatedSale : state.saleData;

      return { ...state, art, saleData };
    }

    case 'HIDE_DRAWER': {
      if (!state.art) return { ...state, isDrawerOpen: false };

      const saleData = state.art.buyNows[0] || state.art.auctions[0] || null;
      return { ...state, isDrawerOpen: false, saleData };
    }

    case 'SET_SALE_DATA':
      return { ...state, isLoading: !state.art, saleData: action.payload };

    case 'UPDATE_ART_STATUS':
      if (!state.art) return state;

      return { ...state, art: { ...state.art, artStatus: action.payload } };

    case 'UPDATE_BUY_NOW_STATUS':
      if (!state.art) return state;

      const saleData =
        state.saleData?.id === action.payload.id
          ? { ...state.saleData, status: action.payload.status }
          : state.saleData;
      const buyNows = state.art.buyNows.map((item: BuyNow) =>
        item.id === action.payload.id ? { ...item, status: action.payload.status } : item,
      );

      return { ...state, art: { ...state.art, buyNows }, saleData };

    case 'ADD_BID': {
      if (!state.saleData || !state.art) return state;
      const saleData = { ...state.saleData, bids: [action.payload, ...(state.saleData as Auction).bids] };
      return {
        ...state,
        art: { ...state.art, auctions: [{ ...saleData }] },
        saleData,
      };
    }

    case 'ADD_BID_AND_HIDE_DRAWER': {
      if (!state.saleData || !state.art) return state;
      const saleData = { ...state.saleData, bids: [action.payload, ...(state.saleData as Auction).bids] };

      return {
        ...state,
        isDrawerOpen: false,
        art: { ...state.art, auctions: [{ ...saleData }] },
        saleData,
      };
    }

    case 'WINNER_CHANGED': {
      if (!state.saleData || !state.art) return state;

      const saleData = {
        ...state.saleData,
        bids: (state.saleData as Auction).bids.filter((bid: Bid) => bid.bidderId === action.payload.winnerId),
      };
      return {
        ...state,
        isDrawerOpen: false,
        art: { ...state.art, auctions: [{ ...saleData }] },
        saleData,
      };
    }

    default:
      return state;
  }
};

const ArtDetailedViewContext = React.createContext<{ state: State; dispatch: React.Dispatch<Action> } | undefined>(
  undefined,
);

export const ArtDetailedViewProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(reducer, initialState);

  return <ArtDetailedViewContext.Provider value={{ state, dispatch }}>{children}</ArtDetailedViewContext.Provider>;
};

export const useArtDetailedView = () => {
  const context = React.useContext(ArtDetailedViewContext);
  if (context === undefined) {
    throw new Error('useArtDetailedView must be used within a ArtDetailedViewContext');
  }
  return context;
};
