import { Box, Link, SxProps, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as SpotifyIcon } from 'components/Icons/spotify.svg';
import { ReactComponent as RedditIcon } from 'components/Icons/reddit.svg';
import { ReactComponent as DiscordIcon } from 'components/Icons/discord.svg';
import React from 'react';
import { Theme } from '@mui/system';
import { routes } from 'app-settings';
import { useTranslation } from 'react-i18next';

const socialIconsConfig = [
  {
    link: 'https://www.facebook.com/profile.php?id=100076245445797',
    IconComponent: <FacebookIcon />,
  },
  {
    link: 'https://www.instagram.com/totemo.art/?utm_medium=copy_link',
    IconComponent: <InstagramIcon />,
  },
  {
    link: 'https://twitter.com/totemoart',
    IconComponent: <TwitterIcon />,
  },
  {
    link: 'https://m.youtube.com/channel/UC-idbgBVSgzQmUKlJSoIISg/featured',
    IconComponent: <YouTubeIcon />,
  },
  {
    link: 'https://open.spotify.com/show/45bpFO32IA3qjMq11nsMUw?',
    IconComponent: <SpotifyIcon />,
  },
  {
    link: 'https://www.reddit.com/user/TOTEMOART',
    IconComponent: <RedditIcon />,
  },
  {
    link: 'https://discord.gg/Wju7u65a',
    IconComponent: <DiscordIcon />,
  },
];

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box component="footer" sx={sx.root}>
      <Box sx={sx.content}>
        <Box sx={sx.socialLinks}>
          {socialIconsConfig.map(({ link, IconComponent }) => (
            <Link key={link} sx={sx.linkLabel} target={'_blank'} href={link} rel="noreferrer">
              {IconComponent}
            </Link>
          ))}
        </Box>
        <Box sx={sx.infoLinks}>
          <Link
            href="https://docs.google.com/document/d/17bv1b7GFzdRsRcI3V2U7k92flrNmIr4L"
            target="_blank"
            rel="noreferrer"
            sx={sx.linkItem}
          >
            <Typography variant="paragraphMedium" sx={sx.faqBaseStyles} component="p" textAlign="center">
              {t('footer.privacyPolicy')}
            </Typography>
          </Link>

          <Link
            href="https://docs.google.com/document/d/1qga6DbrfuNDp_6UB0b1DJBcG0-hMYJbc"
            target="_blank"
            rel="noreferrer"
            sx={sx.linkItem}
          >
            <Typography variant="paragraphMedium" sx={sx.faqBaseStyles} component="p" textAlign="center">
              {t('footer.termsOfUse')}
            </Typography>
          </Link>

          <Link href={`${routes.home}#faq`} sx={sx.linkItem}>
            <Typography variant="paragraphMedium" sx={sx.faqBaseStyles} component="p" textAlign="center">
              {t('footer.faq')}
            </Typography>
          </Link>

          <Link href="mailto: contact@totemo.art" sx={sx.linkItem}>
            <Typography variant="paragraphMedium" sx={sx.faqBaseStyles} component="p" textAlign="center">
              {t('footer.contactUs')}
            </Typography>
          </Link>
        </Box>
      </Box>

      <Typography component="div" sx={sx.copyRight} variant="paragraphXSmall">
        © {t('footer.copyright')}
      </Typography>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  root: theme => ({
    bgcolor: 'common.black',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      px: '70px',
      py: 4,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
      pb: 2.5,
    },
  }),
  socialLinks: theme => ({
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      gap: 5,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      py: 5,
      px: 2.5,
      borderBottom: `1px solid`,
      borderBottomColor: 'grey.900',
    },
  }),
  infoLinks: {
    display: 'flex',
    gap: 4,
    py: 2.5,
    px: 2.5,
  },
  copyRight: {
    minWidth: '210px',
    textAlign: 'center',
    color: 'grey.700',
  },
  socialIcon: (theme) => ({
    width: 22,
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
    [theme.breakpoints.only('sm')]: {
      my: 0,
      mx: 2,
    },
  }),
  linkLabel: {
    height: '24px',
    color: 'grey.600',
    '& svg': {
      '&:hover': {
        color: 'white',
      },
    },
  },
  faqBaseStyles: { color: 'grey.700', fontWeight: 'bold', flex: 1, fontSize: 14 },
  content: theme => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  }),
};
