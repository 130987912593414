import React from 'react';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import {
  DEFAULT_MONEY_DISTRIBUTION_PRIMARY,
  DEFAULT_MONEY_DISTRIBUTION_SECONDARY,
} from 'helpers/moneyDistributionHelpers';
import { useAppState } from 'contexts/appState/AppContext';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { ArtMoneyDistributionAdmin } from 'pages/Art/MoneyDistribution/ArtMoneyDistributionAdmin';
import { ArtMoneyDistributionAuthor } from 'pages/Art/MoneyDistribution/ArtMoneyDistributionAuthor';

const defaultDistribution = {
  primarySale: DEFAULT_MONEY_DISTRIBUTION_PRIMARY,
  secondarySale: DEFAULT_MONEY_DISTRIBUTION_SECONDARY,
};

export const ArtMoneyDistribution: React.FC = React.memo(() => {
  const { currentUser } = useAppState();
  const { state } = useArtDetailedView();
  const isCurrentUserAdmin = useHasRole('SUPERADMIN');

  const moneyDistribution = React.useMemo(() => {
    if (!state.art) return;
    try {
      return JSON.parse(state.art.moneyDistribution);
    } catch (e) {
      return defaultDistribution;
    }
  }, [state.art]);

  if (!state.art) {
    return null;
  }

  const isCurrentUserAuthor = currentUser.wallet === state.art.authorWallet;

  if (!isCurrentUserAdmin && !isCurrentUserAuthor) {
    return null;
  }

  if (isCurrentUserAdmin) {
    return <ArtMoneyDistributionAdmin moneyDistribution={moneyDistribution} />;
  }

  return <ArtMoneyDistributionAuthor moneyDistribution={moneyDistribution} />;
});
