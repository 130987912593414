import type { SxProps, Theme } from '@mui/material/styles';

export const ArtDrawerSx: Record<string, SxProps<Theme>> = {
  content: theme => ({
    bgcolor: 'background.default',
    [theme.breakpoints.down('sm')]: {
      py: 5,
      px: 3.75,
    },
  }),
  paper: { borderRadius: '5px 5px 0 0' },
  priceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  },
  divider: {
    mb: 1.25,
  },
  total: {
    color: 'primary.main',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '150%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cancel: {
    mt: 2,
  },
  note: {
    bgcolor: 'grey.200',
    borderRadius: '5px',
    p: 2,
    mt: 2.5,
    mb: 5,
  },
};

export const ArtDrawerPaperProps = { sx: ArtDrawerSx.paper };
