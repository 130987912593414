import React, { Reducer } from 'react';
import {
  adminPanelUsersAllColumns as allColumns,
  AdminPanelUsersColumn,
} from 'pages/AdminPanel/AdminPanelUsers/constants';
import { Meta, User, UserPaginatedDto } from 'codegen-api/api-totemo-service/models';

interface State {
  isLoading: boolean;
  columns: AdminPanelUsersColumn[];
  users: User[];
  searchValue: string;
  reloadCounter: number;
  meta: Meta;
  selectedUserId?: number;
}

type Action =
  | { type: 'LOADED'; payload: UserPaginatedDto }
  | { type: 'COLUMN_CHANGED'; payload: { id: string; index: number } }
  | { type: 'SEARCH_CHANGED'; payload: string }
  | { type: 'UPDATE_SINGLE_USER'; payload: User }
  | { type: 'SELECT_USER', payload: { id: number } }
  | { type: 'SET_COLUMNS', payload: { columns: AdminPanelUsersColumn[] } }
  | { type: 'RELOAD' }
  | { type: 'LOADING'};

const initialState = {
  isLoading: true,
  columns: allColumns.slice(0, 2),
  users: [],
  searchValue: '',
  reloadCounter: 0,
  meta: {
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
  },
  selectedUserId: undefined,
};

const reducer = (state: State, action: Action): State => {
  let result = state;
  switch (action.type) {
    case 'LOADING': {
      result = { ...state, isLoading: true };
      break;
    }
    case 'LOADED': {
      result = { ...state, isLoading: false, users: action.payload.items, meta: action.payload.meta };
      break;
    }
    case 'COLUMN_CHANGED': {
      const { id, index } = action.payload;
      const columns = [...state.columns];
      const duplicateIndex = columns.findIndex((item) => item.id === id);

      if (duplicateIndex > -1) {
        // swap elements
        [columns[duplicateIndex], columns[index]] = [columns[index], columns[duplicateIndex]];
      } else {
        columns[index] = allColumns.find((item) => item.id === id) as AdminPanelUsersColumn;
      }

      result = { ...state, columns };
      break;
    }
    case 'SEARCH_CHANGED': {
      result = { ...state, isLoading: true, searchValue: action.payload };
      break;
    }
    case 'RELOAD': {
      result = { ...state, reloadCounter: state.reloadCounter + 1 };
      break;
    }
    case 'UPDATE_SINGLE_USER': {
      result = {
        ...state,
        users: state.users.map((item) => (item.wallet === action.payload.wallet ? action.payload : item)),
      };
      break;
    }
    case 'SELECT_USER': {
      result = { ...state, selectedUserId: action.payload.id };
      break;
    }
    case 'SET_COLUMNS': {
      result = { ...state, columns: action.payload.columns };
      break;
    }
  }
  return result;
};

export const useAdminPanelUsersReducer = () => {
  return React.useReducer<Reducer<State, Action>>(reducer, initialState);
};
