import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as ViewsImg } from 'components/Icons/views.svg';
import { SxProps, Theme } from '@mui/material/styles';


const ArtViews: React.FC<{ count: string | number }> = React.memo(({ count }) => {
  return (
    <Box sx={sx.views}>
      <ViewsImg />
      <Box>{count}</Box>
    </Box>
  );
});
export default ArtViews;


const sx: Record<string, SxProps<Theme>> = {
  views: theme => ({
    gap: 0.5,
    color: 'text.secondary',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      p: '12px 15px',
      borderRadius: '5px',
      backgroundColor: theme.palette.grey['100'],
    },
  }),
};
