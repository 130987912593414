import React from 'react';
import { User } from 'codegen-api/api-totemo-service/models';
import { useApiContext } from 'contexts/ApiContext';

interface ReturnProps {
  artists: User[];
}

export default function useTopArtistsPagination(limit = 15): ReturnProps {
  const [artists, setArtists] = React.useState<User[]>([]);
  const { users: userApi } = useApiContext();

  React.useEffect(() => {
    const load = async () => {
      try {
        const res = await userApi.userControllerGetTopArtist(limit);
        setArtists(res.data.items);
      } catch (e) {
        console.error(e);
      }
    };

    load().then();
  }, [limit, userApi]);

  return { artists };
}
