import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiContext } from 'contexts/ApiContext';
import { useUserProfileReducer } from '../State/useProfileReducer';
import { ProfilePageWrapper } from '../ProfilePageWrapper';
import { routes } from 'app-settings';
import { Loader } from 'components/UiKit/Loader/Loader';
import { ProfileForm } from './ProfileForm/ProfileForm';
import { isUserHasRole } from 'components/RequireRole/RequireRole';
import { InputType, SocialNetworks, Wallet } from '../types';
import { useAppState } from 'contexts/appState/AppContext';
import { useHasRightsToEdit } from '../hooks/useHasRightsToEdit';

export const EditProfilePage = () => {
  const navigate = useNavigate();
  const { users: usersApi } = useApiContext();
  const [state, dispatch] = useUserProfileReducer();
  const { currentUser } = useAppState();

  const { wallet } = useParams<Wallet>();

  const fetchUserInfo = useCallback(
    async (wallet: string) => {
      try {
        //TODO: replace with single request
        const responseUser = await usersApi.userControllerFindOneByWalletOrFail(wallet);
        const fullUser = await usersApi.userControllerGetFullUser(responseUser.data.id);

        dispatch({ type: 'FETCH_USER_INFO_FULFILLED', payload: fullUser.data });
      } catch (error: any) {
        dispatch({ type: 'FETCH_USER_INFO_REJECTED', payload: error.data });
        navigate(routes.notFound);
      }
    },
    [dispatch, navigate, usersApi],
  );

  React.useEffect(() => {
    if (!wallet) {
      return;
    }
    fetchUserInfo(wallet);
  }, [dispatch, wallet, navigate, usersApi, fetchUserInfo]);

  const redirectToProfilePage = useCallback(() => {
    navigate(`${routes.userProfile}/${wallet}`);
  }, [navigate, wallet]);

  const isArtist = useMemo(() => {
    if (!!state.userInfo?.role) {
      return isUserHasRole(state.userInfo, 'ARTIST');
    }
  }, [state.userInfo]);

  const hasRightsToEdit = useHasRightsToEdit(currentUser, wallet);

  const socialLinksMap = useMemo(() => {
    if (state.userInfo?.settings?.social?.length > 0) {
      return new Map<SocialNetworks, string>(
        state.userInfo?.settings?.social?.map((item: InputType) => {
          return [item?.name, item?.link];
        }),
      );
    }
  }, [state.userInfo?.settings]);

  const profileFormProps = {
    user: state.userInfo,
    isArtist,
    onClick: redirectToProfilePage,
    socialLinksMap,
  };

  if (state.isProfileLoading) {
    return <Loader />;
  }

  if (!hasRightsToEdit) {
    navigate(`${routes.userProfile}/${wallet}`);
  }

  return (
    <ProfilePageWrapper>
      <ProfileForm {...profileFormProps} />
    </ProfilePageWrapper>
  );
};
