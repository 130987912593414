import React from 'react';
import { User } from 'codegen-api/api-totemo-service/models';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { UserRoleName } from 'types';
import AdminPanelUsersManageCreateArt from './AdminPanelUsersManageCreateArt';
import AdminPanelUsersManageAuthenticate from './AdminPanelUsersManageAuthenticate';
import { AdminPanelUsersManagePropose } from './AdminPanelUsersManagePropose';

export const AdminPanelUsersManageBtn: React.FC<{ user: User }> = ({ user }) => {
  let userRole: UserRoleName = user?.role?.[0]?.role;
  const isPersonalManagerForUser = useHasRole('PM', user.wallet);
  if (isPersonalManagerForUser && userRole === 'ARTIST') {
    return <AdminPanelUsersManageCreateArt wallet={user.wallet} />;
  }

  if (userRole === 'PM') {
    userRole = 'SUPERADMIN';
  }

  if (userRole === 'COLLECTOR') {
    return <AdminPanelUsersManageAuthenticate user={user} />;
  }

  if (userRole === 'ARTIST') {
    return <AdminPanelUsersManagePropose wallet={user.wallet} />;
  }

  return null;
};
