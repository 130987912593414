import React from 'react';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { socketNotificationTypes } from 'app-settings';
import { useApiContext } from 'contexts/ApiContext';

const handlingEvents = [
  socketNotificationTypes.saleStatusChanged,
  socketNotificationTypes.transactionFailed,
  socketNotificationTypes.winnerchanged,
];

export const UpdateArtSockets: React.FC = React.memo(() => {
  const { socket } = useApiContext();
  const { state, dispatch } = useArtDetailedView();

  const artId = state.art?.id;

  React.useEffect(() => {
    const notificationListener = async (messageString: string) => {
      if (!artId) return;
      try {
        const message = JSON.parse(messageString);
        const { eventType } = message;
        if (!handlingEvents.includes(eventType)) return;
        const data: Record<string, any> = JSON.parse(message.data);

        if (data.artId !== artId) return;

        if (eventType === socketNotificationTypes.transactionFailed) {
          dispatch({
            type: 'REMOVE_FAILED_TRANSACTION',
            payload: { transactionHash: data.transactionHash, saleId: data.id, saleType: data.type },
          });
        }

        const isTransactionAdded =
          eventType === socketNotificationTypes.saleStatusChanged && data.saleStatus === 'PENDING' && data.transaction;
        if (isTransactionAdded) {
          dispatch({
            type: 'ADD_TRANSACTION',
            payload: { transaction: data.transaction, saleId: data.saleId, saleType: data.saleType },
          });
        }

        const isCanceled = eventType === socketNotificationTypes.saleStatusChanged && data.saleStatus === 'CANCELED';
        if (isCanceled) {
          dispatch({
            type: 'CANCEL_SALE',
            payload: {
              saleType: data.saleType,
              saleId: +data.saleId,
            },
          });
        }

        const isWinnerChanged = eventType === socketNotificationTypes.winnerchanged;
        if (isWinnerChanged) {
          dispatch({
            type: 'WINNER_CHANGED',
            payload: {
              artId: +data.artId,
              winnerId: +data.winnerId,
              winnerWallet: data.winnerWallet,
            },
          });
          //TODO: find more elegant solution than reload whole page
          window.location.reload();
        }
      } catch (e) {}
    };

    socket!.on('notification', notificationListener);

    return () => {
      socket!.off('notification', notificationListener);
    };
  }, [socket, artId, dispatch]);

  return null;
});
