import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const commonSx: Record<string, SxProps<Theme>> = {
  container: theme => ({
    py: 0,
    px: 2.5,
    m: '0 auto',
    [theme.breakpoints.up('sm')]: {
      px: 0,
    },
  }),
  backWrapper: {
    margin: (theme) => theme.spacing(0, 0, 2.5, 0),
    cursor: 'pointer',
  },
  back: {
    color: 'blue.100',
    fontWeight: '600',
    fontSize: '16px',
  },
  backIcon: {
    padding: 0,
    margin: (theme) => theme.spacing(0, 1.25, 0, 0),
    color: 'blue.100',
  },
  titleWrapper: {
    px: 2.5,
    margin: (theme) => theme.spacing(0, 0, 1.25, 0),
  },
  zeroIndent: {
    padding: 0,
    margin: 0,
  },
  description: {
    px: 2.5,
    fontWeight: 400,
    fontSize: '16px',
    margin: (theme) => theme.spacing(0, 0, 9, 0),
  },
  inlineFlex: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  between: {
    justifyContent: 'space-between',
  },
  loader: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '100%',
    zIndex: 100,
  },
};
