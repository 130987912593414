import React from 'react';
import {
  Divider,
  Pagination,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { Meta, User } from 'codegen-api/api-totemo-service/models';
import AdminPanelUsersTableRow from 'pages/AdminPanel/AdminPanelUsers/AdminPanelUsersTableRow';
import {
  AdminPanelUsersColumn,
  adminPanelUsersAllColumns as allColumns,
} from 'pages/AdminPanel/AdminPanelUsers/constants';
import { DropDown } from 'components/UiKit/DropDown/DropDown';
import useIsMobileScreen from 'helpers/useIsMobileScreen';

interface Props {
  columns: AdminPanelUsersColumn[];
  rows: User[];
  onChangeColumn: (value: string, index: number) => void;
  pagination: Meta;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number,
  selectUser: (id: number) => void,
  selectedUserId?: number
}

export const AdminPanelUsersTable: React.FC<Props> = ({
  columns,
  onChangeColumn: handleColumnChange,
  rows,
  pagination,
  setPage,
  currentPage,
  selectUser,
  selectedUserId,
}) => {
  const { t } = useTranslation();
  const isMobileScreen = useIsMobileScreen();

  const dropDownItems = React.useMemo(() => {
    return allColumns.map((column) => ({
      value: column.id,
      label: t(column.titleTKey),
    }));
  }, [t]);

  return (
    <TableContainer sx={sx.root}>
      <Table size="small" sx={sx.table}>
        <TableHead sx={{ mb: 30 }}>
          <TableRow>
            {columns.map(({ id, titleTKey }, columnIndex) => (
              <TableCell key={id} sx={sx.headerCell}>
                <Box sx={{ py: '10px' }}>
                  {isMobileScreen
                    ? <DropDown
                      items={dropDownItems}
                      value={id}
                      onChange={(e) => handleColumnChange(e.target.value, columnIndex)}
                    />
                    : <span>{titleTKey}</span>
                  }
                </Box>
              </TableCell>
            ))}
          </TableRow>
          <TableRow sx={sx.dividerRow}>
            <TableCell colSpan={columns.length}>
              <Divider />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <AdminPanelUsersTableRow
              key={row.id}
              row={row}
              columns={columns}
              isSelected={selectedUserId === row.id}
              setSelectedUserId={selectUser}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={pagination.totalPages}
        defaultPage={1}
        page={currentPage}
        onChange={(_, page) => setPage(page)}
        sx={sx.pagination}
      />
    </TableContainer>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  table: {
    tableLayout: 'fixed',
    '& .MuiTableCell-root': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  dividerRow: {
    '& .MuiTableCell-root': {
      py: 0,
    },
  },
  headerCell: {
    '& .MuiInput-root': {
      border: 0,
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    p: 3,
  },
};
