import { useApp } from 'contexts/appState/AppContext';
import React from 'react';
import { useApiContext } from 'contexts/ApiContext';

type Currencies = 'usd' | 'jpy' | 'eur';

let isLoading = false;

export const useExchangeRate = (ethPrice: number) => {
  const [state, dispatch] = useApp();
  const { exchangeRate } = useApiContext();

  React.useEffect(() => {
    const refetchRates = async () => {
      isLoading = true;
      try {
        const rates = (await exchangeRate.exchangeRatesListenerControllerGetCurrentExchangeRate()).data.ethereum;

        dispatch({ type: 'SET_EXCHANGE_RATE', payload: { rates, timestamp: Date.now() } });
      } catch (e) {}
      isLoading = false;
    };

    if (Date.now() - state.exchangeRate.timestamp > 60000 && !isLoading) {
      refetchRates();
    }
  }, [dispatch, exchangeRate, state.exchangeRate.timestamp]);

  if (!state.exchangeRate.rates) return undefined;

  const fiatValue = ethPrice * state.exchangeRate.rates[state.currentUser.currencyType.toLowerCase() as Currencies];

  return {
    fiatValue,
    fiatType: state.currentUser.currencyType,
    fiatPrice: new Intl.NumberFormat(getCurrencyLocale(state.currentUser.currencyType), {
      style: 'currency',
      currency: state.currentUser.currencyType,
    }).format(fiatValue),
  };
};

const getCurrencyLocale = (type: string): string => {
  switch (type) {
    case 'JPY': {
      return 'ja-JP';
    }
    case 'EUR': {
      return 'de-DE';
    }
    default: {
      return 'en-US';
    }
  }
};
