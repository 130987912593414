import { Box, Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { allowedAvatarFormats } from 'pages/Profile/types';

interface Props {
  handleClose(): void;
  onChange?(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

export const UploadCoverModal: FC<Props> = ({ handleClose, onChange }) => {
  const { t } = useTranslation();
  return (
    <Box sx={sx.modalWrapper} role="presentation">
      <Typography variant="h6" textAlign="left">
        {t('profilePage.uploadCover')}
      </Typography>

      <Button component={'label'} sx={sx.photoInput} color={'primary'} fullWidth variant={'contained'}>
        <input
          accept={allowedAvatarFormats.join(',')}
          type="file"
          id="userPhoto"
          name="userPhoto"
          hidden
          onChange={onChange}
        />
        <span>{t('profilePage.selectFile')}</span>
      </Button>

      <Button variant="text" color="primary" sx={{ mt: 3.6, width: '100%' }} onClick={handleClose}>
        {t('common.cancel')}
      </Button>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  modalWrapper: {
    width: 'auto',
    px: 2.5,
    pt: 3.75,
    pb: 5.5,
  },
  photoInput: {
    mt: 5,
  },
};
