/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateFollowDto } from '../models';
import { Follow } from '../models';
import { IsFollowResponseDto } from '../models';
/**
 * FollowApi - axios parameter creator
 * @export
 */
export const FollowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFollowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followControllerCreate: async (body: CreateFollowDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling followControllerCreate.');
            }
            const localVarPath = `/follow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} followId 
         * @param {string} followType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followControllerIsFollow: async (followId: number, followType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'followId' is not null or undefined
            if (followId === null || followId === undefined) {
                throw new RequiredError('followId','Required parameter followId was null or undefined when calling followControllerIsFollow.');
            }
            // verify required parameter 'followType' is not null or undefined
            if (followType === null || followType === undefined) {
                throw new RequiredError('followType','Required parameter followType was null or undefined when calling followControllerIsFollow.');
            }
            const localVarPath = `/follow/isFollow/{followId}/{followType}`
                .replace(`{${"followId"}}`, encodeURIComponent(String(followId)))
                .replace(`{${"followType"}}`, encodeURIComponent(String(followType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateFollowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followControllerUnfollow: async (body: CreateFollowDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling followControllerUnfollow.');
            }
            const localVarPath = `/follow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FollowApi - functional programming interface
 * @export
 */
export const FollowApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFollowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followControllerCreate(body: CreateFollowDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Follow>> {
            const localVarAxiosArgs = await FollowApiAxiosParamCreator(configuration).followControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} followId 
         * @param {string} followType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followControllerIsFollow(followId: number, followType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsFollowResponseDto>> {
            const localVarAxiosArgs = await FollowApiAxiosParamCreator(configuration).followControllerIsFollow(followId, followType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateFollowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followControllerUnfollow(body: CreateFollowDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FollowApiAxiosParamCreator(configuration).followControllerUnfollow(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FollowApi - factory interface
 * @export
 */
export const FollowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateFollowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followControllerCreate(body: CreateFollowDto, options?: any): AxiosPromise<Follow> {
            return FollowApiFp(configuration).followControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} followId 
         * @param {string} followType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followControllerIsFollow(followId: number, followType: string, options?: any): AxiosPromise<IsFollowResponseDto> {
            return FollowApiFp(configuration).followControllerIsFollow(followId, followType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateFollowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followControllerUnfollow(body: CreateFollowDto, options?: any): AxiosPromise<void> {
            return FollowApiFp(configuration).followControllerUnfollow(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FollowApi - object-oriented interface
 * @export
 * @class FollowApi
 * @extends {BaseAPI}
 */
export class FollowApi extends BaseAPI {
    /**
     * 
     * @param {CreateFollowDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowApi
     */
    public followControllerCreate(body: CreateFollowDto, options?: any) {
        return FollowApiFp(this.configuration).followControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} followId 
     * @param {string} followType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowApi
     */
    public followControllerIsFollow(followId: number, followType: string, options?: any) {
        return FollowApiFp(this.configuration).followControllerIsFollow(followId, followType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CreateFollowDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowApi
     */
    public followControllerUnfollow(body: CreateFollowDto, options?: any) {
        return FollowApiFp(this.configuration).followControllerUnfollow(body, options).then((request) => request(this.axios, this.basePath));
    }
}
