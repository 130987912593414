import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

export const ArtActionButtonSx: Record<string, SxProps<Theme>> = {
  wrapper: {
    p: 2.5,
    mx: -2.5,
    //boxShadow: '0px -5px 15px rgba(0, 0, 0, 0.15)',
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
    bgcolor: 'background.default',
  },
};
