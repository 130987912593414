import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { useApiContext } from 'contexts/ApiContext';
import { Art } from 'codegen-api/api-totemo-service/models';
import { Loader } from 'components/UiKit/Loader/Loader';
import { CarouselWrapper } from '../CarouselWrapper';
import { ArtistLabel } from '../ArtistLabel';
import { routes } from 'app-settings';
import { Link } from 'react-router-dom';
import isVideo from '../../../helpers/isVideo';
import VideoBox from '../../../components/VideoBox/VideoBox';

export const FeedOfArts = () => {
  const api = useApiContext();
  const [artsList, setArtsList] = useState<Art[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchFeedOfArts = async () => {
      try {
        const resp = (await api.art.artControllerFeedOfArts()).data;
        setArtsList(resp);
      } catch (e) {}
      setIsLoading(false);
    };

    fetchFeedOfArts();
  }, [api.art]);

  if (!artsList?.length) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box mb={8.75}>
      <CarouselWrapper>
        {artsList?.map((art: Art) => {
          const isVideoMediaType = isVideo(art.artImage.url);
          return isVideoMediaType ? (
            <Box key={art.artImage.key} component={Link} to={`${routes.art}/${art.id}`} sx={sx.videoPoster}>
              <Box className={'top-gradient'} />
              <Box className={'bottom-gradient'} />
              <Box sx={sx.video}>
                <VideoBox className="video-poster" src={art.artImage.url} />
              </Box>
              <Box sx={sx.videoLabel}>
                <ArtistLabel artist={art.author} white={true} />
              </Box>
              <Typography variant="h2" color="common.white" sx={sx.videoName}>
                {art.name}
              </Typography>
            </Box>
          ) : (
            <Box
              key={art.artImage.key}
              component={Link}
              to={`${routes.art}/${art.id}`}
              sx={sx.poster}
              style={{ backgroundImage: `url(${art.artImage.url})` }}
            >
              <Box className={'top-gradient'} />
              <Box className={'bottom-gradient'} />
              <ArtistLabel artist={art.author} white={true} />
              <Typography variant="h2" color="common.white" sx={sx.imageName}>
                {art.name}
              </Typography>
            </Box>
          );
        })}
      </CarouselWrapper>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  poster: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    mb: 2,
    height: (theme) => theme.spacing(40.5),
    width: (theme) => theme.spacing(40.5),
    paddingTop: '65%',
    background: 'no-repeat center/cover',
    borderRadius: '5px',
    padding: 2.5,
    mx: 'auto',
    textDecoration: 'none',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      height: (theme) => theme.spacing(51.25),
      width: (theme) => theme.spacing(51.25),
    },
  }),
  videoPoster: (theme) => ({
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    mb: 2,
    height: (theme) => theme.spacing(40.5),
    width: (theme) => theme.spacing(40.5),
    borderRadius: '5px',
    mx: 'auto',
    textDecoration: 'none',
    [theme.breakpoints.up('lg')]: {
      height: (theme) => theme.spacing(51.25),
      width: (theme) => theme.spacing(51.25),
    },
  }),
  video: {
    '& .video-poster': {
      minHeight: (theme) => theme.spacing(40.5),
      objectFit: 'cover',
      width: '100%',
      display: 'block',
    },
  },
  imageName: {
    position: 'relative',
    zIndex: 1,
  },
  videoName: {
    position: 'absolute',
    zIndex: 1,
    left: (theme) => theme.spacing(2.5),
    bottom: (theme) => theme.spacing(2.5),
  },
  videoLabel: {
    position: 'absolute',
    zIndex: 1,
    left: (theme) => theme.spacing(2.5),
    top: (theme) => theme.spacing(2.5),
  },
};
