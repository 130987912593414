import React from 'react';
import { getUnixTime } from 'date-fns';
import { CreateBuyNowDtoStatusEnum } from 'codegen-api/api-totemo-service/models';
import { routes } from 'app-settings';
import { useApiContext } from 'contexts/ApiContext';
import { useAppState } from 'contexts/appState/AppContext';
import { useNavigate } from 'react-router-dom';
import { getFullPriceEth } from 'pages/CreateEditArtPage/utils';
import { useSecondarySaleMultiple } from '../SecondarySaleMultipleProvider';
import { useMultipleContract } from 'abi/useMultipleContract';

export const useSecondarySaleMultipleByNowSubmit = (servicePercentage: number, ownerWallet: string) => {
  const { createSaleVoucher } = useMultipleContract();
  const { currentUser } = useAppState();
  const { state, dispatch } = useSecondarySaleMultiple();
  const api = useApiContext();
  const navigate = useNavigate();

  return React.useCallback(
    async (values) => {
      try {
        dispatch({ type: 'START_PUBLISHING' });
        const signature = await createSaleVoucher(
          state.art.tokenId,
          state.art.authorWallet,
          currentUser.wallet,
          {
            price: values.price,
            startDate: values.startDate,
            copies: 1,
            primaryServiceFee: servicePercentage,
          } as any,
          ownerWallet,
          true,
        );

        await api.buyNow.buyNowControllerCreate({
          artId: state.art.id,
          ownerWallet,
          authorWallet: state.art.authorWallet,
          pmWallet: currentUser.wallet,
          startMarketDate: getUnixTime(values.startDate),
          marketPrice: +getFullPriceEth(String(values.price), String(servicePercentage)),
          amount: 1,
          isFirstSale: false,
          signature,
          status: CreateBuyNowDtoStatusEnum.ACTIVE,
        });

        navigate(`${routes.art}/${state.art.id}`);
      } catch (e) {
        console.log(e);
        dispatch({ type: 'STOP_PUBLISHING' });
      }
    },
    [
      dispatch,
      createSaleVoucher,
      state.art.tokenId,
      state.art.authorWallet,
      state.art.id,
      currentUser.wallet,
      servicePercentage,
      ownerWallet,
      api.buyNow,
      navigate,
    ],
  );
};
