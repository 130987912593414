import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { ReactComponent as ReorderIcon } from 'components/Icons/reorder.svg';
import { Loader } from 'components/UiKit/Loader/Loader';
import { AdminPanelEventsCard } from './AdminPanelEventsCard';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import type { Event } from 'codegen-api/api-totemo-service/models/event';
import { useApiContext } from 'contexts/ApiContext';

export const AdminPanelEvents: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = useApiContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [events, setEvents] = React.useState<Event[]>([]);

  const handleClickReorder = () => {
    navigate(routes.adminPanelEventsReorder);
  };

  const handleClickCreate = () => {
    navigate(routes.adminPanelEventsCreate);
  };

  const handleDeleteEvent = (event: Event) => {
    const deleteEvent = async () => {
      try {
        await api.events.eventsControllerRemove(event.id, event.image.key);
        setEvents(events.filter((item) => item.id !== event.id));
      } catch (e) {}
      setIsLoading(false);
    };

    deleteEvent();
  };

  React.useEffect(() => {
    const fetchEvents = () => {
      try {
        api.events.eventsControllerFindAll().then((res) => {
          setEvents(res.data);
        });
      } catch (e) {}
      setIsLoading(false);
    };

    fetchEvents();
  }, [api.events]);

  return (
    <Box m={2.5}>
      <Box display="flex">
        <Button sx={sx.createBtn} color="secondary" variant="contained" onClick={handleClickCreate}>
          {t('adminPanel.createEventBtn')}
        </Button>
        <Button color="secondary" variant="contained" sx={sx.reorderBtn} onClick={handleClickReorder}>
          <ReorderIcon />
        </Button>
      </Box>
      <Box mb={isLoading ? 2.5 : 10}>
        {events.map((item) => (
          <AdminPanelEventsCard key={item.id} event={item} onDelete={handleDeleteEvent} />
        ))}
      </Box>
      <Box>{isLoading && <Loader />}</Box>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  createBtn: {
    flex: '1 1 auto',
  },
  reorderBtn: {
    minWidth: 'auto',
    p: 1.75,
    ml: 1.5,
  },
};
