import React from 'react';
import Box from '@mui/material/Box';
import { Link, SxProps, Typography } from '@mui/material';
import { routes } from '../../app-settings';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useHasRole } from '../RequireRole/RequireRole';

const nonAdminItems = [
  { title: 'header.marketplace', linkUrl: routes.marketplace },
  { title: 'header.events', linkUrl: `${routes.homeEvents}` },
  { title: 'header.artists', linkUrl: `${routes.homeArtists}` },

];

const adminItems = [...nonAdminItems, { title: 'header.adminPanel', linkUrl: `${routes.adminPanel}` }];


const Navigation = () => {

  const isAdmin = useHasRole('SUPERADMIN');
  const { t } = useTranslation();

  const items = isAdmin ? adminItems : nonAdminItems;

  return (
    <Box sx={sx.navigation}>
      {items.map(item => (
        <Link href={item.linkUrl} sx={sx.linkItem}>
          <Typography sx={sx.linkText}>
            {t(item.title)}
          </Typography>
        </Link>
      ))}
    </Box>
  );

};

export default Navigation;

const sx: Record<string, SxProps<Theme>> = {
  navigation: {
    display: 'flex',
    alignItems: 'center',
    gap: '50px',
  },
  linkItem: {
    textDecoration: 'none',
  },
  linkText: {
    fontWeight: 'bold',
  }
};
