import React, { FC } from 'react';
import { alpha, Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseModal, BaseModalProps } from 'components/Modal/BaseModal';
import { useLogout } from 'contexts/appState/useLogout';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';

interface IButtonConfig {
  text?: string;
  onClick?: React.MouseEventHandler;
}

interface IEmailNotVerified extends BaseModalProps {
  title?: string;
  secondaryText?: string;
  primaryButtonConfig?: IButtonConfig;
  secondaryButtonConfig?: IButtonConfig;
  showPrimaryButton?: boolean;
  showSecondaryButton?: boolean;
  errorMessage?: string;
}

export const EmailNotVerified: FC<IEmailNotVerified> = ({
  title,
  secondaryText,
  primaryButtonConfig,
  secondaryButtonConfig,
  showPrimaryButton = true,
  showSecondaryButton = true,
  errorMessage = '',
  ...rest
}) => {
  const { t } = useTranslation();
  const logout = useLogout();
  const navigate = useNavigate();

  const handleGoHome: React.MouseEventHandler = (e) => {
    rest.handleClose?.(e);
    navigate(routes.home);
  };

  const handleLogout: React.MouseEventHandler = (e) => {
    rest.handleClose?.(e);
    logout();
  };

  return (
    <BaseModal {...rest}>
      <Box px={1} pb={3}>
        <Typography variant="h2" textAlign="center">
          {title || t('common.pleaseCheckYourInboxToVerifyYourRegistration')}
        </Typography>
        {secondaryText && (
          <Typography variant="h5" textAlign="center" color="gray" mt={2}>
            {secondaryText}
          </Typography>
        )}
        {showPrimaryButton && (
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={primaryButtonConfig?.onClick || handleGoHome}
            >
              {primaryButtonConfig?.text || t('common.goToHome')}
            </Button>
          </Box>
        )}
        {showSecondaryButton && (
          <Box mt={2.5}>
            <Button variant="outlined" fullWidth onClick={secondaryButtonConfig?.onClick || handleLogout}>
              {secondaryButtonConfig?.text || t('common.signOut')}
            </Button>
          </Box>
        )}
        {!!errorMessage && <Box sx={sx.errorContainer}>{errorMessage}</Box>}
      </Box>
    </BaseModal>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  errorContainer: {
    alignSelf: 'stretch',
    textAlign: 'center',
    bgcolor: (theme) => alpha(theme.palette.error.main, 0.2),
    color: 'error.main',
    p: 1,
    borderRadius: '4px',
    mt: 2.5,
  },
};
