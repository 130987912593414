import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Back } from 'components/Icons/back.svg';

const BackButton: React.FC = React.memo(() => {
  const navigate = useNavigate();

  return (
    <Box fontSize={0} pr={2} onClick={() => navigate(-1)}>
      <Back />
    </Box>
  );
});

export default BackButton;
