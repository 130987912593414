import React from 'react';
import TotemoMultiple from './contracts/TotemoMultiple';
import { SaleInfoForm } from 'pages/CreateEditArtPage/types';
import { getFullPriceWei } from 'pages/CreateEditArtPage/utils';
import { getUnixTime } from 'date-fns';
import { useChainId } from 'wagmi';
import { signTypedData, prepareWriteContract, writeContract } from '@wagmi/core';
import { Address } from 'viem';

type Mint = (
  to: string,
  tokenId: string,
  amount: number,
) => Promise<string>

type TransferFrom = (
  from: string,
  to: string,
  tokenId: string,
) => Promise<string>

type ReturnValues = {
  isLoading: boolean;
  createSaleVoucher(
    tokenId: number,
    authorWallet: string,
    pmWallet: string,
    saleInfo: SaleInfoForm,
    ownerWallet: string,
    isSecondarySale?: boolean,
  ): Promise<string>;
  mint: Mint,
  transferFrom: TransferFrom
};

const contractAddress = process.env.REACT_APP_MULTIPLE_CONTRACT;

export const useMultipleContract = (): ReturnValues => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const chainId = useChainId();

  const createSaleVoucher = React.useCallback(
    async (
      tokenId: number,
      authorWallet: string,
      pmWallet: string,
      saleInfo: SaleInfoForm,
      ownerWallet,
      isSecondarySale = false,
    ): Promise<string> => {
      setIsLoading(true);
      try {

        const domain = {
          name: 'TOTEMO_VOUCHER',
          version: '1',
          verifyingContract: contractAddress as Address,
          chainId: chainId,
        };

        const types = {
          TotemoMultipleVoucher: [
            { name: 'tokenId', type: 'uint256' },
            { name: 'startMarketDate', type: 'uint256' },
            { name: 'author', type: 'address' },
            { name: 'isFirstSale', type: 'bool' },
            { name: 'owner', type: 'address' },
            { name: 'assetContract', type: 'address' },
            { name: 'price', type: 'uint256' },
            { name: 'pm', type: 'address' },
            { name: 'amount', type: 'uint256' },
            { name: 'data', type: 'bytes' },
          ],
        };

        const weiPrice = getFullPriceWei(String(saleInfo.price), String(saleInfo.primaryServiceFee));
        const voucher = {
          tokenId: tokenId,
          startMarketDate: getUnixTime(saleInfo.startDate),
          author: authorWallet,
          isFirstSale: !isSecondarySale,
          owner: ownerWallet,
          assetContract: contractAddress,
          price: weiPrice,
          pm: pmWallet,
          amount: isSecondarySale ? '1' : saleInfo.copies,
          data: '0x6732',
        };

        // @ts-ignore
        return signTypedData({
          domain: domain,
          types,
          primaryType: 'TotemoMultipleVoucher',
          message: voucher,
        });
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [],
  );

  const mint = React.useCallback<Mint>(
    async (
      to,
      tokenId,
      amount,
    ) => {
      setIsLoading(true);

      try {
        const { request } = await prepareWriteContract({
          address: contractAddress as Address,
          abi: TotemoMultiple.abi,
          functionName: 'mint',
          args: [
            to as Address, BigInt(tokenId), BigInt(amount), '0x6732',
          ],
        });
        const { hash } = await writeContract(request);
        return hash;
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [],
  );


  const transferFrom: TransferFrom = async (
    from,
    to,
    tokenId,
  ) => {
    setIsLoading(true);
    try {
      const { request } = await prepareWriteContract({
        address: contractAddress as Address,
        abi: TotemoMultiple.abi,
        functionName: 'safeTransferFrom',
        args: [
          from as Address, to as Address, BigInt(tokenId), BigInt(1), '0x6732',
        ],
      });
      const { hash } = await writeContract(request);
      return hash;
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  return { isLoading, createSaleVoucher, mint, transferFrom };
};
