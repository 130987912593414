import { ArtArtStatusEnum, ArtBodyTokenTypeEnum, BuyNow } from '../../../codegen-api/api-totemo-service/models';
import { EMPTY_USER, useAppState } from 'contexts/appState/AppContext';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { isBuyNowAvailableMultiple } from 'pages/Art/utils/buyNow';

export const useCanPurchase = (): boolean => {
  const { state } = useArtDetailedView();
  const { currentUser } = useAppState();
  const isCurrentUserAdmin = useHasRole('SUPERADMIN');

  const isSingle = state.art?.tokenType === ArtBodyTokenTypeEnum.ERC721;

  if (isSingle) {
    const isPublished = state.art?.artStatus === ArtArtStatusEnum.PUBLISHED;
    const isCurrentUserAuthorAndOwner =
      currentUser.wallet === state.art?.authorWallet && currentUser.wallet === state.art?.owners[0].wallet;
    const hasWallet = currentUser.wallet !== EMPTY_USER.wallet;

    return isPublished && hasWallet && !isCurrentUserAdmin && !isCurrentUserAuthorAndOwner;
  } else {
    return !!state.saleData && isBuyNowAvailableMultiple(state.saleData as BuyNow, currentUser.wallet);
  }
};
