import React, { Reducer, useContext } from 'react';
import { ART_TYPE, ArtInfoForm, CreationSteps, SaleInfoForm } from './types';
import { Art, Tag, User, Event } from 'codegen-api/api-totemo-service/models';

export const steps = {
  CHOOSE_ART_TYPE: '0',
  FILL_ART_INFO: '1',
  FILL_SALE_INFO: '2',
};

export interface State {
  currentStep: CreationSteps;
  isLoading: boolean;
  artist?: User;
  art?: Art;
  type?: ART_TYPE;
  isEdit: boolean;
  artInfoForm?: ArtInfoForm;
  saleInfoForm?: SaleInfoForm;
  tags?: Tag[];
  events?: Event[];
  searchTagValue?: string;
}

export const initialState: State = {
  currentStep: 'CHOOSE_ART_TYPE',
  isEdit: false,
  isLoading: true,
  tags: [],
};

export type Action =
  | { type: 'UPDATE_ART_TYPE'; payload: ART_TYPE }
  | { type: 'UPDATE_ART_INFO'; payload: ArtInfoForm }
  | { type: 'UPDATE_INITIAL_EDIT'; payload: State }
  | { type: 'INITIALIZED'; payload: User }
  | { type: 'LOADING'; payload: boolean }
  | { type: 'RESET' }
  | { type: 'FETCH_TAGS'; payload: Tag[] }
  | { type: 'FETCH_EVENTS'; payload: Event[] }
  | { type: 'SEARCH_TAGS_CHANGED'; payload: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'RESET':
      return { ...initialState };
    case 'UPDATE_ART_TYPE':
      return { ...state, type: action.payload, currentStep: 'FILL_ART_INFO' };
    case 'UPDATE_INITIAL_EDIT':
      return { ...state, ...action.payload };
    case 'LOADING':
      return { ...state, isLoading: action.payload };
    case 'INITIALIZED':
      return { ...state, artist: action.payload, isLoading: false };
    case 'UPDATE_ART_INFO':
      return { ...state, artInfoForm: action.payload, currentStep: 'FILL_SALE_INFO' };
    case 'FETCH_TAGS':
      return { ...state, tags: action.payload };
    case 'FETCH_EVENTS':
      return { ...state, events: action.payload };
    case 'SEARCH_TAGS_CHANGED':
      return { ...state, searchTagValue: action.payload };
    default:
      return state;
  }
};

const useCreateEditArtReducer = (state: State) => {
  return React.useReducer<Reducer<State, Action>>(reducer, state);
};
export default useCreateEditArtReducer;

export const useCreateArtReducerContext = () => {
  const context = useContext(CreateArtContext);

  if (typeof context === 'undefined') {
    throw new Error('useCreateArtReducerContext must be used within an CreateArtContext.Provider');
  }

  return { state: context.state, dispatch: context.dispatch };
};

export const CreateArtContext = React.createContext<{ state: State; dispatch: React.Dispatch<Action> } | undefined>(
  undefined,
);
