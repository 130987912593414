import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, SxProps, TableCell, TableRow } from '@mui/material';
import { User } from 'codegen-api/api-totemo-service/models';
import { AdminPanelUsersColumn } from 'pages/AdminPanel/AdminPanelUsers/constants';
import AdminPanelUsersManage from 'pages/AdminPanel/AdminPanelUsers/AdminPanelUsersManage/AdminPanelUsersManage';
import type { UserRoleName } from 'types';
import { UserRolesTKeyMap } from 'components/RequireRole/RequireRole';
import { WalletHex } from 'components/UiKit/WalletHex/WalletHex';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import { Theme } from '@mui/material/styles';

interface Props {
  row: User;
  columns: AdminPanelUsersColumn[];
  isSelected: boolean;
  setSelectedUserId: (id: number) => void;
}

const AdminPanelUsersTableRow: React.FC<Props> = ({ row, columns, isSelected, setSelectedUserId }) => {
  const isMobileScreen = useIsMobileScreen();

  const sx = useSx(isSelected);

  const handleSelectUser = (id: number) => {
    setSelectedUserId(id);
  };
  return (
    <>
      <TableRow key={row.id} sx={sx.row} onClick={() => handleSelectUser(row.id)}>
        {columns.map((column) => (
          <TableCell key={column.id} sx={sx.cell}>
            {column.id === 'role' ? (
              <RoleCellContent row={row} />
            ) : column.id === 'wallet' ? (
              <WalletHex hex={row.wallet} emptyValue={'-'} tailLength={8} />
            ) : (
              row[column.id as keyof User] ?? '-'
            )}
          </TableCell>
        ))}
      </TableRow>
      {isMobileScreen && (
        <TableRow key={row.wallet + 'expansion'} sx={sx.expansion}>
          <TableCell sx={sx.expansionCell} colSpan={columns.length}>
            <Collapse in={isSelected} timeout="auto" unmountOnExit>
              <AdminPanelUsersManage user={row} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default AdminPanelUsersTableRow;

const RoleCellContent: React.FC<{ row: User }> = ({ row }) => {
  const { t } = useTranslation();

  return <>{t(UserRolesTKeyMap[(row?.role?.[0]?.role ?? 'GUEST') as UserRoleName])}</>;
};

const useSx = (isSelected: boolean): Record<string, SxProps<Theme>> => {
  return React.useMemo(
    () => ({
      row: {
        backgroundColor: isSelected ? 'grey.100' : 'none',
        borderLeft: isSelected ? '3px solid' : null,
        borderColor: (theme) => theme.palette.accent.main,
        height: '50px',
        px: 3,
      },
      cell: {
        fontWeight: isSelected ? 700 : 400,
        py: '17px',
      },
      expansion: {
        bgcolor: 'grey.100',
      },
      expansionCell: {
        p: 0,
      },
    }),
    [isSelected],
  );
};
