import React from 'react';
import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { useAppState } from 'contexts/appState/AppContext';
import { ReactComponent as InviteIcon } from 'components/Icons/invite_user.svg';
import { copyString } from 'helpers/copyString';
import { routes } from 'app-settings';

export const InviteBar: React.FC = React.memo(() => {
  const { currentUser } = useAppState();
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const handleClickInvite = React.useCallback(async () => {
    const inviteLink = `${process.env.REACT_APP_DOMAIN}${routes.signUp}`;

    if (!navigator.canShare) {
      copyString(`Join Totemo.art! ${inviteLink}`);
      setIsCopied(true);
      return;
    }
    try {
      await navigator.share({
        text: 'Join Totemo.art! ',
        title: 'Totemo.art ',
        url: inviteLink,
      });
      console.log('Share success!');
    } catch (err) {
      console.error('Share error!', err);
    }
  }, [currentUser.wallet]);

  return (
    <Box sx={sx.root}>
      <Box sx={sx.topContent}>
        <Box sx={sx.invitationsLeft}>
          <Trans
            i18nKey={'profilePage.invitationsLeft'}
            components={[<strong style={{ paddingRight: 5 }} />, <span />]}
            values={{
              count: currentUser.availableInvites || 0,
            }}
          />
        </Box>
        <Box sx={sx.invited}>
          <Trans
            i18nKey={'profilePage.invited'}
            components={[<strong style={{ paddingRight: 5 }} />, <span />]}
            values={{
              count: currentUser.totalInvited || 0,
            }}
          />
        </Box>
      </Box>
      <Box sx={sx.invite} onClick={handleClickInvite}>
        {isCopied ? (
          t('profilePage.linkCopied')
        ) : (
          <>
            {<InviteIcon />}
            <span>{t('profilePage.invite')}</span>
          </>
        )}
      </Box>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  root: (theme) => ({
    border: `1px solid ${theme.palette.grey['300']}`,
    borderRadius: '4px',
    display: 'flex',
    py: 1.25,
    justifyContent: 'space-between',
    fontSize: '14px',
    lineHeight: '17px',
    whiteSpace: 'nowrap',
    px: '5px',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      border: 'none',
      width: '100%',
      gap: '5px',
    },
  }),

  invite: (theme) => ({
    fontWeight: 600,
    color: 'accent.main',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    px: '15px',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
      height: '50px',
      backgroundColor: theme.palette.grey[100],
    },
  }),
  loader: { maxWidth: '17px', maxHeight: '17px' },
  invitationsLeft: (theme) => ({
    px: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: (theme) => `1px solid ${theme.palette.grey['300']}`,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      borderRight: (theme) => `2px solid ${theme.palette.grey['100']}`,
    },
  }),
  invited: (theme) => ({
    px: '15px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      borderRight: (theme) => `1px solid ${theme.palette.grey['300']}`,
    },
  }),
  topContent: (theme) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      height: '60px',
      width: '100%',
      justifyContent: 'space-between',
    },
  }),
};
