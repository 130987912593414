import React, { FC } from 'react';
import { SwipeableDrawer } from '@mui/material';

interface Props {
  isShowing: boolean;

  handleOpen?: () => void;

  handleClose?: () => void;
  children?: React.ReactNode;
}

export const DownBaseModal: FC<Props> = ({ isShowing, children, handleOpen, handleClose }) => {
  return (
    <>
      {/* @ts-ignore */}
      <SwipeableDrawer
        anchor="bottom"
        open={isShowing}
        onOpen={handleOpen || (() => {
        })}
        onClose={handleClose || (() => {
        })}
      >
        {children}
      </SwipeableDrawer>
    </>

  );
};
