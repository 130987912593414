import { SxProps, Theme } from '@mui/material/styles';
import { Box, Collapse, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { Question } from './types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useFaqValues } from './config';

export const FAQ = () => {
  const { t } = useTranslation();
  const faqValues = useFaqValues();
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

  const makeKey = ({ question }: Question) => {
    return question.toString();
  };

  const itemsList: Question[] = useMemo(() => {
    return faqValues.map((question: Question) => ({
      ...question,
      isExpanded: expandedItems.has(makeKey(question)),
    }));
  }, [expandedItems, faqValues]);

  const toggleExpand = useCallback(
    (key: string) => {
      const newSet = new Set(expandedItems);

      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }

      setExpandedItems(newSet);
    },
    [expandedItems],
  );

  return (
    <Box sx={sx.wrapper} id="faq">
      <Box sx={sx.content}>
        <Typography variant="h2" component="p" sx={sx.title}>
          {t('homePage.buyingStreetArtFaqHeader')}
        </Typography>

        <List component="div" sx={{ width: '100%' }}>
          {itemsList.map((question) => {
            return (
              <Box key={makeKey(question)}>
                <ListItemButton
                  onClick={() => toggleExpand(makeKey(question))}
                  aria-expanded={question.isExpanded}
                  sx={sx.button}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="paragraphMedium"
                        component="p"
                        sx={question.isExpanded ? sx.expanded : sx.collapsed}
                      >
                        {question.question}
                      </Typography>
                    }
                  />
                  {question.isExpanded ? <ExpandLess /> : <ExpandMore sx={{ color: 'grey.600' }} />}
                </ListItemButton>
                <Divider sx={question.isExpanded ? sx.expanded : sx.collapsed} />
                <Collapse in={question.isExpanded} timeout="auto" unmountOnExit>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="paragraphMedium" component="p" color="text.secondary" px={2.5}>
                        {question.answer}
                      </Typography>
                    }
                  />
                </Collapse>
              </Box>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  wrapper: theme => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 'auto',
    px: 2.5,
    pt: 6.25,
    pb: 7.5,
    width: '100%',
    backgroundColor: 'grey.50',
    [theme.breakpoints.up('md')]: {
      py: 10,
    },
  }),
  content: theme => ({
    m: '0 auto',
    [theme.breakpoints.up('md')]: {
      width: '1100px',
    },
  }),
  button: {
    pt: 1.875,
    pb: 2.5,
  },
  expanded: {
    borderColor: 'common.black',
    fontWeight: 600,
  },
  collapsed: {
    borderColor: 'grey.300',
  },
  title: theme => ({
    alignSelf: 'flex-start',
    mb: 1.875,
    ml: 2.5,
    [theme.breakpoints.up('lg')]: {
      mb: 8,
    },
  }),
};
