import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import { SxProps } from '@mui/system';

export const SignInButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Tooltip title={t('header.signIn') as string}>
      <Button sx={sx.btn} variant="text" size="small" onClick={() => navigate(routes.connect)}>
        {t('header.signIn')}
      </Button>
    </Tooltip>
  );
};

const sx: Record<string, SxProps> = {
  btn: {
    fontWeight: 600,
  },
};
