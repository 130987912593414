import React from 'react';
import { useApiContext } from 'contexts/ApiContext';
import { useApp } from 'contexts/appState/AppContext';
import { localStorageKeys } from 'app-settings';
import { User } from 'codegen-api/api-totemo-service/models';
import { getUserDataFromJWT } from 'helpers/jwt';
import { useLogout } from 'contexts/appState/useLogout';
import { Web3Provider } from '@ethersproject/providers/src.ts/web3-provider';
import { devLog } from 'helpers';
import { useWC2Context } from '../WC2Layer/WC2Context';
import { useAccount } from 'wagmi';

export type WalletAndProvider = {
  wallet: string;
  provider: Web3Provider;
};

export const useLogin = () => {
  const { auth, userRoles } = useApiContext();
  const [, dispatch] = useApp();
  const logout = useLogout();
  const { makeSignature, isWrongChain } = useWC2Context();
  const { address } = useAccount();

  const signMessage = React.useCallback(
    async (updateWallet) => {
      try {
        const signature = await makeSignature(updateWallet);
        if (signature) {
          const tokenResp = await auth.authControllerLogin({ signature, wallet: address });
          // @ts-ignore TODO: Remove ts-ignore, force back team fix SWAGGER
          const jwt = tokenResp.data.access_token;
          localStorage.setItem(localStorageKeys.jwt, jwt);
          const userData: Omit<User, 'currencyType'> = getUserDataFromJWT(jwt);
          const roles = await userRoles.userRolesControllerFindOne({ userWallet: userData.wallet });
          dispatch({
            type: 'SET_CURRENT_USER',
            payload: {
              currencyType: 'USD',
              ...userData,
              role: roles?.data,
            },
          });
        }
      } catch (error) {
        !updateWallet && logout(true);
        throw error;
      }
    },
    [address, makeSignature, auth, userRoles, dispatch, logout],
  );

  return React.useCallback(
    async (updateWallet) => {
      try {
        if (isWrongChain) {
          dispatch({ type: 'SHOW_WRONG_NETWORK' });
          throw new Error('Wrong chain id');
        }
        await signMessage(updateWallet);
      } catch (e) {
        devLog(e);
        throw e;
      }
    },
    [signMessage, isWrongChain],
  );
};
