import React from 'react';
import { alpha, Box, Checkbox, Divider, FormControlLabel, Link, Typography } from '@mui/material';
import { SignInLayout } from 'components/SignInLayout/SignInLayout';
import { localStorageKeys, routes } from 'app-settings';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import type { SxProps } from '@mui/system';
import { useAppDispatch } from 'contexts/appState/AppContext';
import { useNavigate } from 'react-router-dom';
import { useApiContext } from 'contexts/ApiContext';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import * as Yup from 'yup';
import { RegExps } from 'helpers/regExps';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { GoogleRecaptchaV2 } from 'pages/SignUp/GoogleRecaptchaV2';
import type { Theme } from '@mui/material/styles';
import { EmailNotVerified } from 'components/Modal/EmailNotVerified/EmailNotVerified';

type FormValues = {
  username: string;
  email: string;
  password: string;
  agreement: boolean;
  recaptcha: string;
};

const formInitialValues: FormValues = {
  username: '',
  email: '',
  password: '',
  agreement: false,
  recaptcha: '',
};

export const SignUp = () => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { users, auth } = useApiContext();
  const navigate = useNavigate();
  const validationSchema = useSignUpValidationSchema();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [asyncError, setAsyncError] = React.useState<boolean>(false);
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = React.useState<boolean>(false);

  const onSubmit = async ({ username, email, password, recaptcha }: FormValues) => {
    try {
      setIsSubmitting(true);
      setAsyncError(false);

      const resp = await users.userControllerSingUp(
        { email, password, username },
        {
          headers: { recaptcha },
        },
      );

      const tokenResp = await auth.authControllerLogin({ email, password });
      //@ts-ignore
      const jwt = tokenResp.data.access_token;
      localStorage.setItem(localStorageKeys.jwt, jwt);
      appDispatch({
        type: 'SET_CURRENT_USER',
        payload: resp.data,
      });
      setIsVerifyEmailModalOpen(true);
    } catch (e) {
      window.grecaptcha.reset();
      // @ts-ignore
      if (e.response.status === 409 && e.response.data.message === 'User with email already exist') {
        formik.setFieldError('email', t('forms.errors.notUniqueEmail', { field: email }));
      }
      // @ts-ignore
      if (e.response.status === 409 && e.response.data.message === 'User with username already exist') {
        formik.setFieldError('username', t('forms.errors.notUniqueUsername', { field: username }));
      }
      // @ts-ignore
      if (e.response.status === 404 || e.response.status === 500 || e.response.status === 400) {
        setAsyncError(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: formInitialValues,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = formik;

  const handleRecaptchaChange = React.useCallback(
    (token: string) => setFieldValue('recaptcha', token),
    [setFieldValue],
  );

  const handleCloseVerifyEmailModal = React.useCallback(() => {
    setIsVerifyEmailModalOpen(false);
    navigate(routes.home);
  }, [navigate]);

  return (
    <SignInLayout titleTKey={'signUp.pageTitle'}>
      <form onChange={handleChange} onSubmit={handleSubmit}>
        <Subtitle />
        <Box sx={sx.inputsContainer}>
          <OutlinedTextInput
            sx={{ mb: 2.5 }}
            required={true}
            name="username"
            id="username"
            autoComplete="username"
            label={t('forms.username')}
            error={!!(touched.username && errors.username)}
            helperText={touched.username && t(errors.username as string)}
          />
          <OutlinedTextInput
            sx={{ mb: 2.5 }}
            required={true}
            name="email"
            id="email"
            autoComplete="email"
            label={t('forms.email')}
            error={!!(touched.email && errors.email)}
            helperText={touched.email && t(errors.email as string)}
          />
          <OutlinedTextInput
            required={true}
            name="password"
            id="password"
            type="password"
            autoComplete="password"
            label={t('forms.password')}
            error={!!(touched.password && errors.password)}
            helperText={touched.password && t(errors.password as string)}
          />

          {asyncError ? <Box sx={sx.asyncError}>{t('forms.errors.somethingWentWrong')}</Box> : null}

          <Divider sx={sx.divider} />
          <TermsLinks />
          <Box mt={2.5}>
            <GoogleRecaptchaV2 onChange={handleRecaptchaChange} />
          </Box>
          <Box sx={sx.submitContainer}>
            <AsyncButton
              isLoading={isSubmitting}
              type="submit"
              variant="contained"
              sx={sx.submit}
              disabled={!values.agreement || !values.recaptcha}
            >
              {t('signUp.submit')}
            </AsyncButton>
          </Box>
        </Box>
      </form>
      <EmailNotVerified open={isVerifyEmailModalOpen} handleClose={handleCloseVerifyEmailModal} />
    </SignInLayout>
  );
};

const Subtitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Typography component="span" variant="paragraphSmall" textAlign="center" sx={sx.subtitle}>
      <Box component="span" color="text.secondary">
        {t('signUp.description')}
      </Box>
      <Box component="span" fontWeight={600}>
        <Trans
          i18nKey={'signUp.descriptionEmphasized'}
          components={[
            <Link
              target="_blank"
              underline="none"
              className={'accent-color'}
              href="mailto:totemo@totemo.com"
              rel="noreferrer"
            />,
          ]}
        />
      </Box>
    </Typography>
  );
};

const TermsLinks: React.FC = () => {
  return (
    <FormControlLabel
      sx={sx.agreement}
      control={<Checkbox sx={sx.checkBox} name="agreement" />}
      label={
        <Typography variant="paragraphSmall" sx={sx.termsText}>
          <Box component="span">
            <Trans
              i18nKey={'signUp.readTerms'}
              components={[
                <Link
                  href={routes.termsOfServices}
                  className={'accent-color'}
                  target="_blank"
                  underline="none"
                  rel="noreferrer"
                />,
                <Link
                  href={routes.privacyPolicy}
                  className={'accent-color'}
                  target="_blank"
                  underline="none"
                  rel="noreferrer"
                />,
              ]}
            />
          </Box>
        </Typography>
      }
    />
  );
};

const sx: Record<string, SxProps<Theme>> = {
  subtitle: { display: 'block', mt: 4, fontWeight: 400 },
  inputsContainer: {
    width: { sm: 600, xs: '100%' },
    m: '0 auto',
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    mt: 5,
  },
  submitContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid',
    borderColor: 'grey.300',
  },
  submit: { my: 3.75 },
  divider: { mt: 3.75, width: '100%' },
  agreement: { m: 0, mt: 2.5, alignItems: 'flex-start' },
  checkBox: {
    p: 0,
    mr: 2.25,
  },
  termsText: { fontWeight: 600, fontFamily: 'Source Sans Pro' },
  asyncError: {
    alignSelf: 'stretch',
    textAlign: 'center',
    bgcolor: (theme) => alpha(theme.palette.error.main, 0.2),
    color: 'error.main',
    p: 1,
    borderRadius: '4px',
    mt: 2.5,
  },
};

const useSignUpValidationSchema = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return Yup.object().shape({
      username: Yup.string()
        .trim()
        .required('forms.errors.fieldRequired')
        .min(1, 'forms.errors.usernameMinLength')
        .max(30, t('forms.errors.fieldMaxLength', { length: 30 }))
        .matches(RegExps.username, t('forms.errors.fieldUnsupportedCharacters')),
      email: Yup.string().trim().required('forms.errors.fieldRequired').email('forms.errors.emailInvalid'),
      password: Yup.string()
        .min(8)
        .max(255)
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*+,-.:;?@^_~])[A-Za-z\d!#$%&*+,-.:;?@^_~]{8,}$/,
          'forms.errors.passwordMustContain',
        ),
    });
  }, [t]);
};
