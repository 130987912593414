import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApiContext } from 'contexts/ApiContext';
import Box from '@mui/material/Box';
import { User } from 'codegen-api/api-totemo-service/models';
import Button from '@mui/material/Button';
import type { SxProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import AdminPanelFeaturedArtistsList from './AdminPanelFeaturedArtistsList';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import { ReactComponent as ReorderIcon } from 'components/Icons/reorder.svg';
import { Loader } from 'components/UiKit/Loader/Loader';

const AdminPanelFeaturedArtists: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { users: usersApi } = useApiContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [artistList, setArtistList] = React.useState<User[]>([]);

  const handleClickReorder = () => {
    navigate(routes.adminPanelFeaturedArtistReorder);
  };

  React.useEffect(() => {
    const fetchFeaturedArtist = () => {
      usersApi
        .userControllerFindAll(undefined, 'true', 'true')
        .then((resp) => {
          setArtistList((resp.data.items as unknown as User[]) || []);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    };

    fetchFeaturedArtist();
  }, [usersApi]);

  if (isLoading) {
    return (
      <Box sx={sx.wrapper}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box sx={sx.wrapper}>
      <Box display="flex" sx={{ width: '100%' }}>
        <Button
          sx={sx.addBtn}
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => navigate(routes.adminPanelFeaturedArtistAdd)}
        >
          {t('adminPanel.addFeaturedArtistBtn')}
        </Button>

        <Button color="secondary" variant="contained" sx={sx.reorderBtn} onClick={handleClickReorder}>
          <ReorderIcon />
        </Button>
      </Box>

      <AdminPanelFeaturedArtistsList featuredUsers={artistList} onChange={setArtistList} />
    </Box>
  );
};

export default AdminPanelFeaturedArtists;

const sx: Record<string, SxProps<Theme>> = {
  wrapper: {
    width: '100%',
    p: 2.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  reorderBtn: {
    minWidth: 'auto',
    p: 1.75,
    ml: 1.5,
  },
  addBtn: {
    flex: 'auto',
  },
};
