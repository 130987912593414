import React from 'react';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { BaseModal, BaseModalProps } from '../BaseModal';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';

interface Props extends BaseModalProps {
  onSuccess(): void;
}

export const SuccessfullyPublished: React.FC<Props> = ({ onSuccess, ...props }) => {
  const { t } = useTranslation();

  return (
    <BaseModal {...props}>
      <Box sx={sx.container}>
        <Box sx={sx.imageBlock}>
          <img src={'/assets/icons/succs-published.svg'} alt="success-published" />
        </Box>
        <Typography variant="h6">{t('pages.createArt.artPublished')}</Typography>
        <Button variant="outlined" sx={sx.button} onClick={onSuccess}>
          {t('pages.createArt.goToArt')}
        </Button>
      </Box>
    </BaseModal>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  container: { px: 1, pb: 3 },
  imageBlock: { mb: 5, display: 'flex', justifyContent: 'center' },
  button: { mt: 4, width: '100%' },
};
