import React from 'react';
import { Box, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppRoute, routes } from 'app-settings';
import { adminPanelSx } from '../AdminPanelStyles';
import useIsMobileScreen from '../../../helpers/useIsMobileScreen';
import { AdminPanelMoneyDistributionPrimary } from './AdminPanelMoneyDistributionPrimary';
import { AdminPanelMoneyDistributionSecondary } from './AdminPanelMoneyDistributionSecondary';

export const AdminPanelMoneyDistribution: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileScreen = useIsMobileScreen();

  const handleChangeTab = (e: React.SyntheticEvent, tab: AppRoute) => {
    navigate(tab);
  };

  const currentTab =
    location.pathname === routes.adminPanelMoneyDistribution
      ? routes.adminPanelMoneyDistributionPrimary
      : location.pathname;

  return (
    <Box sx={sx.root}>
      {
        isMobileScreen
          ? (
            <>
              <Tabs sx={adminPanelSx.subTabs} value={currentTab} onChange={handleChangeTab} centered>
                <Tab value={routes.adminPanelMoneyDistributionPrimary} label={t('adminPanel.tabPrimarySale')} />
                <Tab value={routes.adminPanelMoneyDistributionSecondary} label={t('adminPanel.tabSecondarySale')} />
              </Tabs>
              <Outlet />
            </>
          )
          : (
            <Box sx={sx.desktopView}>
              <Box sx={sx.section}>
                <Typography sx={sx.title}>Primary Sale</Typography>
                <AdminPanelMoneyDistributionPrimary />
              </Box>
              <Box sx={sx.section}>
                <Typography sx={sx.title}>Secondary Sale</Typography>
                <AdminPanelMoneyDistributionSecondary />
              </Box>
            </Box>
          )
      }
    </Box>
  );
});

const sx: Record<string, SxProps> = {
  root: { m: 2.5 },
  desktopView: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '30px',
    mt: '40px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
};

