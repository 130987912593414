import type { Auction } from 'codegen-api/api-totemo-service/models';

export const isActiveAuction = (auction: Auction): boolean => {
  return auction.status === 'ACTIVE' && auction.endDate * 1000 > Date.now();
};

export const canFinalizeAuction = (auction: Auction, currentUserWallet: string): boolean => {
  return (
    auction.status === 'ACTIVE' &&
    auction.endDate * 1000 <= Date.now() &&
    auction.bids[0]?.bidderWallet === currentUserWallet
  );
};
