import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { routes } from 'app-settings';
import { LoginBtn } from 'components/LoginBtn/LoginBtn';

enum Steps {
  initial,
  signIn,
}

const Connect: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [step, setStep] = useState(Steps.initial);
  const navigate = useNavigate();

  return (
    <Box sx={sx.connectContainer}>
      <Box sx={sx.connectButtons}>
        <Box sx={sx.buttonBox}>
          {step === Steps.signIn ? (
            <Button sx={sx.whiteButton} variant="contained" onClick={() => navigate(routes.noWalletSignIn)}>
              {t('welcomePage.signInWithoutWallet')}
            </Button>
          ) : (
            <Button sx={sx.whiteButton} variant="contained" onClick={() => navigate(routes.signUp)}>
              {t('welcomePage.createNewAccount')}
            </Button>
          )}
        </Box>
        <Box sx={sx.divider}>
          <Box sx={sx.dividerLine} />
          <Typography color={theme.palette.grey['700']} fontSize="large">
            {t('common.or')}
          </Typography>
          <Box sx={sx.dividerLine} />
        </Box>
        <Box sx={sx.buttonBox}>
          {step === Steps.signIn ? (
            <LoginBtn sx={sx.whiteButton} connectTKey={'welcomePage.signInWithWallet'} showLogo />
          ) : (
            <>
              <Typography variant="h4" textAlign="center">
                {t('welcomePage.alreadyHaveAnAccount')}
              </Typography>
              <Button onClick={() => setStep(Steps.signIn)} sx={sx.whiteButton}>
                {t('welcomePage.signIn')}
              </Button>
            </>
          )}
        </Box>
      </Box>
      {step === Steps.signIn && (
        <Button sx={sx.cancelButton} onClick={() => setStep(Steps.initial)}>
          <Typography>{t('common.cancel')}</Typography>
        </Button>
      )}
    </Box>
  );
};

export default Connect;

const sx: Record<string, SxProps<Theme>> = {
  connectContainer: (theme) => ({
    bgcolor: 'grey.100',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    px: 3.5,
    mb: 1.25,
    mt: 5,
    [theme.breakpoints.down('sm')]: {
      mt: 4,
    },
  }),
  connectButtons: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
    justifyContent: 'space-between',
    p: '32px 28px',
    [theme.breakpoints.up('sm')]: {
      p: 0,
      flexDirection: 'row',
    },
  }),
  divider: (theme) => ({
    display: 'flex',
    gap: '25px',
    py: '10px',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
  }),
  dividerLine: (theme) => ({
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      height: '1px',
      width: '110px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '1px',
      height: '40px',
    },
  }),
  whiteButton: {
    color: 'black',
    backgroundColor: 'white',
    height: '28px',
    width: '260px',
    display: 'flex',
    gap: '12px',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  },
  buttonBox: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
    [theme.breakpoints.up('sm')]: {
      width: '43%',
      py: 6,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
  cancelButton: {
    p: 0,
    mb: 2,
    backgroundColor: 'grey.400',
  },
};
