import React, { FC, useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMarketplaceContext } from 'pages/Marketplace/State/MarketplaceContext';
import { useApiContext } from 'contexts/ApiContext';
import { Art } from 'codegen-api/api-totemo-service/models';
import { useFilterCounter } from 'pages/Marketplace/hooks/useFilterCounter';
import isVideo from '../../helpers/isVideo';
import VideoBox from '../VideoBox/VideoBox';

export const ArtCardJustSold: FC = React.memo(() => {
  const { art: artsApi } = useApiContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useMarketplaceContext();
  const filtersCounter = useFilterCounter(state.filters);

  const [art, setArt] = useState<Art>();

  useEffect(() => {
    const fetchJustSoldArt = async () => {
      const justSoldArt = (await artsApi.artControllerGetJustSold()).data;
      // fix BE resp type issue
      setArt((justSoldArt as unknown as Art) || {} as Art);
    };

    if (!filtersCounter) {
      fetchJustSoldArt();
    }
  }, [artsApi, filtersCounter]);

  const handleOpenDetailedView = (id: number) => {
    navigate(`${routes.art}/${id}`);
  };

  if (!art) {
    return null;
  }

  const isVideoMediaType = isVideo(art.artImage.url);

  return (
    <Card sx={sx.card} onClick={() => handleOpenDetailedView(art.id)}>
      {isVideoMediaType ? (
        <VideoBox className={'video-box'} src={art.artImage.url} />
      ) : (
        <CardMedia component="img" height="468" image={art?.artImage?.url} alt="art" sx={sx.artImage} />
      )}

      <CardContent sx={sx.artName}>
        <Typography variant="h3" component="p" color="common.white">
          {t('pages.marketplace.justSold')}
        </Typography>
      </CardContent>
    </Card>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  card: (theme) => ({
    position: 'relative',
    border: (theme) => `1px solid ${theme.palette.grey[300]}`,
    bgcolor: 'common.black',
    borderRadius: '5px',
    boxShadow: 'none',
    m: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: (theme) => theme.spacing(37.5),
    },
    '& .video-box': {
      height: 468,
      display: 'block',
      width: '100%',
      objectFit: 'cover',
    },
  }),
  artImage: {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  artName: {
    px: 2.5,
    py: 1.8,
    color: 'common.white',
  },
};
