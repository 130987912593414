export interface AdminPanelUsersColumn {
  titleTKey: string;
  id: string;
}

export const adminPanelUsersAllColumns: AdminPanelUsersColumn[] = [
  {
    titleTKey: 'Nickname',
    id: 'username',
  },
  {
    titleTKey: 'Role',
    id: 'role',
  },
  {
    titleTKey: 'E-mail',
    id: 'email',
  },
  {
    titleTKey: 'Full name',
    id: 'fullName',
  },
  {
    titleTKey: 'Address',
    id: 'wallet',
  },
  {
    titleTKey: 'Phone Number',
    id: 'phoneNumber',
  },
];
