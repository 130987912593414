/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BuyNow } from '../models';
import { BuyNowTransactionDto } from '../models';
import { CancelBuyNowMultipleDto } from '../models';
import { CreateBuyNowDto } from '../models';
import { LockSingleBuyNowDto } from '../models';
import { OmitTypeClass } from '../models';
/**
 * BuyNowApi - axios parameter creator
 * @export
 */
export const BuyNowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CancelBuyNowMultipleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerCancelMultiple: async (body: CancelBuyNowMultipleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling buyNowControllerCancelMultiple.');
            }
            const localVarPath = `/buy-now/cancel/multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} buyNowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerCancelSinge: async (buyNowId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyNowId' is not null or undefined
            if (buyNowId === null || buyNowId === undefined) {
                throw new RequiredError('buyNowId','Required parameter buyNowId was null or undefined when calling buyNowControllerCancelSinge.');
            }
            const localVarPath = `/buy-now/cancel/single/{buyNowId}`
                .replace(`{${"buyNowId"}}`, encodeURIComponent(String(buyNowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBuyNowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerCreate: async (body: CreateBuyNowDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling buyNowControllerCreate.');
            }
            const localVarPath = `/buy-now`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} artId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerGetActiveByArtId: async (artId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'artId' is not null or undefined
            if (artId === null || artId === undefined) {
                throw new RequiredError('artId','Required parameter artId was null or undefined when calling buyNowControllerGetActiveByArtId.');
            }
            const localVarPath = `/buy-now/{artId}/active`
                .replace(`{${"artId"}}`, encodeURIComponent(String(artId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuyNowTransactionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerLockMultiple: async (body: BuyNowTransactionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling buyNowControllerLockMultiple.');
            }
            const localVarPath = `/buy-now/multiple/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LockSingleBuyNowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerLockSingle: async (body: LockSingleBuyNowDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling buyNowControllerLockSingle.');
            }
            const localVarPath = `/buy-now/single/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyNowApi - functional programming interface
 * @export
 */
export const BuyNowApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CancelBuyNowMultipleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyNowControllerCancelMultiple(body: CancelBuyNowMultipleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BuyNowApiAxiosParamCreator(configuration).buyNowControllerCancelMultiple(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} buyNowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyNowControllerCancelSinge(buyNowId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BuyNowApiAxiosParamCreator(configuration).buyNowControllerCancelSinge(buyNowId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateBuyNowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyNowControllerCreate(body: CreateBuyNowDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OmitTypeClass>> {
            const localVarAxiosArgs = await BuyNowApiAxiosParamCreator(configuration).buyNowControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} artId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyNowControllerGetActiveByArtId(artId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuyNow>>> {
            const localVarAxiosArgs = await BuyNowApiAxiosParamCreator(configuration).buyNowControllerGetActiveByArtId(artId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BuyNowTransactionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyNowControllerLockMultiple(body: BuyNowTransactionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BuyNowApiAxiosParamCreator(configuration).buyNowControllerLockMultiple(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LockSingleBuyNowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyNowControllerLockSingle(body: LockSingleBuyNowDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BuyNowApiAxiosParamCreator(configuration).buyNowControllerLockSingle(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BuyNowApi - factory interface
 * @export
 */
export const BuyNowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CancelBuyNowMultipleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerCancelMultiple(body: CancelBuyNowMultipleDto, options?: any): AxiosPromise<void> {
            return BuyNowApiFp(configuration).buyNowControllerCancelMultiple(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} buyNowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerCancelSinge(buyNowId: string, options?: any): AxiosPromise<void> {
            return BuyNowApiFp(configuration).buyNowControllerCancelSinge(buyNowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBuyNowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerCreate(body: CreateBuyNowDto, options?: any): AxiosPromise<OmitTypeClass> {
            return BuyNowApiFp(configuration).buyNowControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} artId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerGetActiveByArtId(artId: number, options?: any): AxiosPromise<Array<BuyNow>> {
            return BuyNowApiFp(configuration).buyNowControllerGetActiveByArtId(artId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BuyNowTransactionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerLockMultiple(body: BuyNowTransactionDto, options?: any): AxiosPromise<void> {
            return BuyNowApiFp(configuration).buyNowControllerLockMultiple(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LockSingleBuyNowDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyNowControllerLockSingle(body: LockSingleBuyNowDto, options?: any): AxiosPromise<void> {
            return BuyNowApiFp(configuration).buyNowControllerLockSingle(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuyNowApi - object-oriented interface
 * @export
 * @class BuyNowApi
 * @extends {BaseAPI}
 */
export class BuyNowApi extends BaseAPI {
    /**
     * 
     * @param {CancelBuyNowMultipleDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyNowApi
     */
    public buyNowControllerCancelMultiple(body: CancelBuyNowMultipleDto, options?: any) {
        return BuyNowApiFp(this.configuration).buyNowControllerCancelMultiple(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} buyNowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyNowApi
     */
    public buyNowControllerCancelSinge(buyNowId: string, options?: any) {
        return BuyNowApiFp(this.configuration).buyNowControllerCancelSinge(buyNowId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {CreateBuyNowDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyNowApi
     */
    public buyNowControllerCreate(body: CreateBuyNowDto, options?: any) {
        return BuyNowApiFp(this.configuration).buyNowControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} artId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyNowApi
     */
    public buyNowControllerGetActiveByArtId(artId: number, options?: any) {
        return BuyNowApiFp(this.configuration).buyNowControllerGetActiveByArtId(artId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {BuyNowTransactionDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyNowApi
     */
    public buyNowControllerLockMultiple(body: BuyNowTransactionDto, options?: any) {
        return BuyNowApiFp(this.configuration).buyNowControllerLockMultiple(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {LockSingleBuyNowDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyNowApi
     */
    public buyNowControllerLockSingle(body: LockSingleBuyNowDto, options?: any) {
        return BuyNowApiFp(this.configuration).buyNowControllerLockSingle(body, options).then((request) => request(this.axios, this.basePath));
    }
}
