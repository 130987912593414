import React, { FC, useCallback, useMemo, useState } from 'react';
import { User } from 'codegen-api/api-totemo-service/models';
import { Avatar, Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { FieldArray, Formik, FormikProps, Form, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { inputsSocialNetworksConfig } from '../shared';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { defaultAvatarSrc, routes } from 'app-settings';
import { useUpdateUserInfoValidationSchema } from './useUpdateUserInfoValidationSchema';
import { useApiContext } from 'contexts/ApiContext';
import { EditProfileFormValues } from './EditProfileFormValues';
import { SocialNetworksInput } from './Components/SocialNetworksInput';
import { allowedAvatarFormats, InputType, ShopifyCollection, SocialNetworks } from '../../types';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as PlusIcon } from 'components/Icons/plus.svg';
import { useUpdateArtistInfoValidationSchema } from './useUpdateArtistInfoValidationSchema';
import { ContentInput } from './Components/ContentInput';
import { ScrollToFieldErrors } from 'components/ScrollToFieldErrors/ScrollToFieldErrors';
import { useAppDispatch, useAppState } from 'contexts/appState/AppContext';

interface Props {
  user: User;
  onClick(): void;
  isArtist: boolean | undefined;
  socialLinksMap: Map<SocialNetworks, string> | undefined;
}

interface Event<T = EventTarget> {
  target: T;
}

export const ProfileForm: FC<Props> = ({ socialLinksMap, isArtist, onClick, user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useAppState();
  const appDispatch = useAppDispatch();
  const { username, fullName, settings, bio, wallet } = user;
  const { users: usersApi } = useApiContext();
  const userSchema = useUpdateUserInfoValidationSchema();
  const artistSchema = useUpdateArtistInfoValidationSchema();
  const theme = useTheme();

  const [isUserInfoLoading, setLoading] = useState<boolean>(false);
  const [previewSrc, setPreviewSrc] = useState<string>('');
  const [photo, setPhoto] = useState<File | undefined>();
  const [photoUploadError, setPhotoError] = useState<boolean>(false);

  const errorCondition = (props: FormikProps<EditProfileFormValues>, fieldName: string): boolean => {
    return Boolean(getIn(props.touched, fieldName)) && Boolean(getIn(props.errors, fieldName));
  };

  const helperTextPredicate = (props: FormikProps<EditProfileFormValues>, fieldName: string): string => {
    return getIn(props.touched, fieldName) && getIn(props.errors, fieldName);
  };

  const userPhoto = useMemo(() => {
    return previewSrc || settings?.avatar?.url || defaultAvatarSrc;
  }, [previewSrc, settings?.avatar?.url]);

  const validationSchema = useMemo(() => {
    if (isArtist) {
      return artistSchema;
    } else {
      return userSchema;
    }
  }, [artistSchema, isArtist, userSchema]);

  const uploadPreview = useCallback(async (event: Event<HTMLInputElement>) => {
    const reader = new FileReader();

    reader.onload = function () {
      setPreviewSrc(reader.result as string);
    };

    if (!!event.target?.files?.length) {
      const photo = event.target?.files[0];

      reader.readAsDataURL(photo);

      if (allowedAvatarFormats.indexOf(photo.type) === -1 || photo.size >= 30_000_000) {
        setPhotoError(true);
      } else {
        setPhotoError(false);
      }

      setPhoto(photo);
    }
  }, []);

  const formInitialValues = {
    username: username,
    fullName: fullName || '',
    bio: bio || '',
    video:
      settings?.video && settings.video.length
        ? settings.video
        : [
            {
              name: '',
              link: '',
            },
          ],
    podcast:
      settings?.podcast && settings.podcast.length
        ? settings.podcast
        : [
            {
              name: '',
              link: '',
            },
          ],
    shopifyCollections:
      settings?.shopifyCollections && settings.shopifyCollections.length
        ? settings.shopifyCollections
        : [
            {
              name: '',
              id: '',
            },
          ],
    settings: {
      social: {
        twitter: socialLinksMap?.get('twitter') || '',
        instagram: socialLinksMap?.get('instagram') || '',
        facebook: socialLinksMap?.get('facebook') || '',
        tiktok: socialLinksMap?.get('tiktok') || '',
        website: socialLinksMap?.get('website') || '',
        discord: socialLinksMap?.get('discord') || '',
        snapchat: socialLinksMap?.get('snapchat') || '',
        youtube: socialLinksMap?.get('youtube') || '',
        clubhouse: socialLinksMap?.get('clubhouse') || '',
      },
      avatar: settings?.avatar || {},
      background: settings?.background || {},
    },
  };

  const onSubmit = async ({ fullName, settings, video, podcast, shopifyCollections, bio }: EditProfileFormValues) => {
    if (isUserInfoLoading) return;

    if (photoUploadError) {
      window.scrollTo(0, 0);
      return;
    }

    setLoading(true);

    const processedSocialLinks = Object.entries(settings?.social).map(([key, value]) => {
      return { name: key, link: value };
    });

    try {
      let userPhoto;

      if (!!photo) {
        userPhoto = (await usersApi.userControllerAddUserImage(photo as unknown as string, user.wallet)).data;

        if (currentUser.wallet === wallet) {
          // @ts-ignore
          appDispatch({
            type: 'UPDATE_CURRENT_USER',
            payload: { settings: { ...currentUser.settings, avatar: userPhoto } },
          });
        }
      }

      const videos = (video || []).filter((item) => item.name.trim() && item.link.trim());
      const podcasts = (podcast || []).filter((item) => item.name.trim() && item.link.trim());
      const trimmedShopifyCollections = (shopifyCollections || []).filter((item) => item.name.trim() && item.id.trim());

      await usersApi.userControllerUpdate(
        {
          fullName,
          bio,
          settings: {
            social: [...processedSocialLinks],
            avatar: userPhoto || user.settings?.avatar,
            background: user.settings?.background || {},
            video: videos.length ? videos : undefined,
            podcast: podcasts.length ? podcasts : undefined,
            shopifyCollections: trimmedShopifyCollections.length ? trimmedShopifyCollections : undefined,
          },
        },
        user.wallet,
      );
    } finally {
      setLoading(false);
    }

    navigate(`${routes.userProfile}/${user.wallet}`);
  };

  return (
    <Formik initialValues={formInitialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(props: FormikProps<EditProfileFormValues>) => (
        <Form onSubmit={props.handleSubmit} onChange={props.handleChange}>
          <ScrollToFieldErrors formikProps={props} />
          <Box sx={sx.formWrapper}>
            <Box sx={sx.userPhotoContainer}>
              <Avatar alt={username} src={userPhoto} sx={sx.avatar} id="avatar" />

              <Box sx={sx.uploadButton}>
                <Box sx={sx.photoInput}>
                  <input
                    accept="image/png, image/jpg, image/jpeg"
                    type="file"
                    id="userPhoto"
                    name="userPhoto"
                    hidden
                    onChange={uploadPreview}
                  />
                  <label htmlFor="userPhoto">{t('profilePage.changeAvatar')}</label>
                </Box>

                {photoUploadError && (
                  <Box sx={sx.error}>
                    <Typography variant="paragraphSmall" color="error.main">
                      {t('forms.errors.fileFormatIncorrect', { format: 'JPEG, PNG', maxSize: 30 })}
                    </Typography>
                  </Box>
                )}
                <Typography variant="paragraphSmall" color={theme.palette.text.secondary}>
                  {t('profilePage.imageRequirements')}
                </Typography>
              </Box>
            </Box>
            <Box sx={sx.userInfo}>
              <TextField
                disabled
                name="username"
                id="username"
                label={t('forms.usernameEdit')}
                defaultValue={formInitialValues.username}
              />

              <TextField
                name="fullName"
                id="fullName"
                variant="outlined"
                label={t('forms.fullName')}
                defaultValue={formInitialValues.fullName}
                error={errorCondition(props, 'fullName')}
                helperText={helperTextPredicate(props, 'fullName')}
              />

              {isArtist && (
                <TextField
                  variant="outlined"
                  name="bio"
                  id="bio"
                  label={t('forms.bio')}
                  multiline={true}
                  defaultValue={formInitialValues.bio}
                  error={errorCondition(props, 'bio')}
                  helperText={helperTextPredicate(props, 'bio')}
                />
              )}
            </Box>

            {isArtist && (
              <>
                <Typography variant="h4" textAlign="left" sx={sx.heading}>
                  {t('profilePage.videoHeading')}
                </Typography>
                <FieldArray name="video">
                  {(arrayHelpers) => {
                    const { form, push } = arrayHelpers;
                    const { values } = form;
                    return (
                      <Box key="video-inner">
                        {values?.video?.map((item: InputType, index: number) => {
                          return (
                            <ContentInput
                              key={`video-inner-${index}`}
                              index={index}
                              arrayHelpers={arrayHelpers}
                              formikProps={props}
                              contentType={'video'}
                              inputNames={['name', 'link']}
                              firstValue={form.values.video[index]?.name}
                              secondValue={form.values.video[index]?.link}
                            />
                          );
                        })}
                        <IconButton sx={sx.plusButton} color="secondary" onClick={() => push({ name: '', link: '' })}>
                          <PlusIcon />
                        </IconButton>
                      </Box>
                    );
                  }}
                </FieldArray>

                <Typography variant="h4" textAlign="left" sx={sx.heading}>
                  {t('profilePage.podcastHeading')}
                </Typography>
                <FieldArray name="podcast" key="podcast">
                  {(arrayHelpers) => {
                    const { form, push } = arrayHelpers;
                    const { values } = form;
                    return (
                      <>
                        <Box key="podcast-inner">
                          {values.podcast?.map((item: InputType, index: number) => {
                            return (
                              <ContentInput
                                key={`podcast-inner-${index}`}
                                index={index}
                                arrayHelpers={arrayHelpers}
                                formikProps={props}
                                contentType={'podcast'}
                                inputNames={['name', 'link']}
                                firstValue={arrayHelpers.form.values.podcast[index]?.name}
                                secondValue={arrayHelpers.form.values.podcast[index]?.link}
                              />
                            );
                          })}
                        </Box>
                        <IconButton sx={sx.plusButton} color="secondary" onClick={() => push({ name: '', link: '' })}>
                          <PlusIcon />
                        </IconButton>
                      </>
                    );
                  }}
                </FieldArray>

                <Typography variant="h4" textAlign="left" sx={sx.heading}>
                  {t('profilePage.shopifyCollections')}
                </Typography>
                <FieldArray name="shopifyCollections">
                  {(arrayHelpers) => {
                    const { form, push } = arrayHelpers;
                    const { values } = form;
                    return (
                      <Box key="shopifyCollections-inner">
                        {values?.shopifyCollections?.map((item: ShopifyCollection, index: number) => {
                          return (
                            <ContentInput
                              key={`shopifyCollections-inner-${index}`}
                              index={index}
                              arrayHelpers={arrayHelpers}
                              formikProps={props}
                              contentType={'shopifyCollections'}
                              inputNames={['name', 'id']}
                              firstValue={form.values.shopifyCollections[index]?.name}
                              secondValue={form.values.shopifyCollections[index]?.id}
                            />
                          );
                        })}
                        <IconButton sx={sx.plusButton} color="secondary" onClick={() => push({ name: '', link: '' })}>
                          <PlusIcon />
                        </IconButton>
                      </Box>
                    );
                  }}
                </FieldArray>
              </>
            )}

            {inputsSocialNetworksConfig.map((link) => {
              const { label, icon } = link;
              return (
                <SocialNetworksInput
                  name={`settings.social.${label}`}
                  key={link.label}
                  label={t(`profilePage.${label}`)}
                  icon={icon}
                  defaultValue={socialLinksMap?.get(link.label) || ''}
                  onChange={(event) => props.setFieldValue(`settings.social.${label}`, event.target.value)}
                  error={errorCondition(props, `settings.social.${label}`)}
                  helperText={helperTextPredicate(props, `settings.social.${label}`)}
                />
              );
            })}

            <Box sx={sx.buttons}>
              <AsyncButton
                isLoading={isUserInfoLoading}
                type="submit"
                sx={sx.saveBtn}
                color="accent"
                variant="contained"
              >
                {t('common.save')}
              </AsyncButton>

              <Button onClick={onClick}>{t('common.cancel')}</Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    pl: 2.5,
    pr: 2.5,
    pb: 7.5,
    maxWidth: '630px',
    margin: '0 auto',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2.5,
    mb: 5,
  },
  userPhotoContainer: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    mb: 5,
    '& > span': {
      color: theme.palette.text.secondary,
    },
  }),
  avatar: {
    width: (theme) => theme.spacing(18.25),
    height: (theme) => theme.spacing(18.25),
    mb: 3.75,
  },
  uploadButton: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '75px',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  }),
  photoInput: {
    p: (theme) => theme.spacing(1.5, 4.5),
    mb: 1.2,
    borderRadius: 1.6,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: '0.01em',
    fontSize: '14px',
    fontFamily: 'Source Sans Pro',
    lineHeight: '140%',
    width: '160px',

    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.common.white,

    '&:hover': {
      backgroundColor: (theme) => theme.palette.primary.light,
    },
    '&:active': {
      backgroundColor: (theme) => theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: (theme) => theme.palette.primary.light,
      color: (theme) => theme.palette.common.white,
    },
  },
  heading: {
    mb: 2.5,
  },
  saveBtn: {
    mb: 1.2,
  },
  plusButton: {
    bgcolor: 'secondary.main',
    width: '100%',
    borderRadius: 0.6,
    color: 'common.white',
    mb: 5,
    '&:hover': {
      bgcolor: 'secondary.hover',
    },
    '&:active': {
      bgcolor: 'secondary.main',
    },
    '&:disabled': {
      bgcolor: 'secondary.main',
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    '& > span': {
      color: 'grey.600',
    },
    my: 2.5,
  },
  inputRows: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2.5,
  },
  error: {
    color: 'error.main',
    fontSize: '12px',
    textAlign: 'center',
  },
  buttons: {
    margin: '0 auto',
    maxWidth: '220px',
  },
};
