import React from 'react';
import { ArtHistoryRecord } from 'pages/Art/ArtHistory/ArtHistoryItem';
import { Meta } from 'codegen-api/api-totemo-service/models';
import uniqBy from 'lodash.uniqby';

export interface ArtHistoryState {
  isLoading: boolean;
  isInitialLoading: boolean;
  items: ArtHistoryRecord[];
  meta: Meta;
}

const initialState: ArtHistoryState = {
  isLoading: false,
  isInitialLoading: true,
  items: [],
  meta: {
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
  },
};

export type ArtHistoryAction =
  | { type: 'INITIATION_SUCCESS'; payload: { items: ArtHistoryRecord[]; meta: Meta } }
  | { type: 'INITIATION_FAIL' }
  | { type: 'LOAD_MORE_START' }
  | { type: 'LOAD_MORE_FAIL' }
  | { type: 'LOAD_MORE_SUCCESS'; payload: { items: ArtHistoryRecord[]; meta: Meta } }
  | { type: 'ADD_ITEM'; payload: ArtHistoryRecord };

export const useArtHistoryReducer = () => {
  return React.useReducer<(state: ArtHistoryState, action: ArtHistoryAction) => ArtHistoryState>(
    artHistoryReducer,
    initialState,
  );
};

const artHistoryReducer = (state: ArtHistoryState, action: ArtHistoryAction): ArtHistoryState => {
  const newState = { ...state };

  switch (action.type) {
    case 'INITIATION_SUCCESS': {
      newState.isInitialLoading = false;
      newState.items = action.payload.items;
      newState.meta = action.payload.meta;
      break;
    }
    case 'INITIATION_FAIL': {
      newState.isInitialLoading = false;
      break;
    }
    case 'LOAD_MORE_START': {
      newState.isLoading = true;
      break;
    }
    case 'LOAD_MORE_FAIL': {
      newState.isLoading = false;
      break;
    }
    case 'LOAD_MORE_SUCCESS': {
      newState.isLoading = false;
      newState.items = uniqBy([...state.items, ...action.payload.items], 'id');
      newState.meta = action.payload.meta;
      break;
    }
    case 'ADD_ITEM': {
      newState.items = uniqBy([action.payload, ...state.items], 'id');
      break;
    }
  }
  return newState;
};
