import React, { memo, useMemo } from 'react';
import { Avatar, Box, Button, IconButton, Tooltip } from '@mui/material';
import { EMPTY_USER, useAppState } from 'contexts/appState/AppContext';
import { SignInButton } from 'components/Header/SignInButton';
import { useTranslation } from 'react-i18next';
import { defaultAvatarSrc } from 'app-settings';
import type { SxProps, Theme } from '@mui/material/styles';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { EmailNotVerified } from 'components/Modal/EmailNotVerified/EmailNotVerified';
import { useApiContext } from 'contexts/ApiContext';

interface Props {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}

const HeaderCurrentUser: React.FC<Props> = memo(({ isMenuOpen, setIsMenuOpen }) => {
  const { currentUser } = useAppState();
  const isVisitor = useHasRole('VISITOR');
  const { signUpLink } = useApiContext();
  const { t } = useTranslation();
  const sx = useSx(isMenuOpen);
  const web3Authorized = !!currentUser.wallet && currentUser.wallet !== EMPTY_USER.wallet;
  const isAuthorized = web3Authorized || currentUser.email;
  const [isEmailNotVerifiedModalOpen, setIsEmailNotVerifiedModalOpen] = React.useState<boolean>(false);
  const [resendEmailConfirmationErrorMsg, setResendEmailConfirmationErrorMsg] = React.useState<string>('');

  const profileImage = useMemo(() => {
    return currentUser.settings?.avatar?.url || defaultAvatarSrc;
  }, [currentUser.settings?.avatar?.url]);

  const handleResendEmailConfirmation = React.useCallback(async () => {
    try {
      await signUpLink.emailConfirmationControllerCreate(currentUser.email);
      setIsEmailNotVerifiedModalOpen(false);
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || '';
      setResendEmailConfirmationErrorMsg(errorMessage);
    }
  }, [currentUser.email]);

  if (!isAuthorized) {
    return (
      <Box sx={sx.root}>
        <SignInButton />
      </Box>
    );
  }

  if (!isVisitor && !web3Authorized) {
    // web2 authorized and not a visitor (== email not verified)
    return (
      <Box sx={sx.root}>
        <EmailNotVerified
          open={isEmailNotVerifiedModalOpen}
          handleClose={() => setIsEmailNotVerifiedModalOpen(false)}
          primaryButtonConfig={{ text: t('common.resendEmailConfirmation'), onClick: handleResendEmailConfirmation }}
          errorMessage={resendEmailConfirmationErrorMsg}
        />
        <Button variant="text" color="primary" size="small" onClick={() => setIsEmailNotVerifiedModalOpen(true)}>
          Email not verified
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={sx.root}>
      <Tooltip title={t('header.openSettingsTooltip') as string}>
        <IconButton size={'small'} onClick={() => setIsMenuOpen(!isMenuOpen)} sx={sx.button}>
          <Avatar sx={sx.icon} alt={currentUser.username} src={profileImage} />
        </IconButton>
      </Tooltip>
    </Box>
  );
});

export default HeaderCurrentUser;

const useSx = (isMenuOpen: boolean): Record<string, SxProps<Theme>> => {
  return React.useMemo(
    () => ({
      root: { flexGrow: 0, mr: { xs: isMenuOpen ? 2 : 2.5, md: 'none' } },
      button: {
        p: 0,
        border: (theme: Theme) => (isMenuOpen ? `${theme.spacing(0.5)} solid ${theme.palette.grey[100]}` : 0),
      },
      icon: {
        width: (theme: Theme) => theme.spacing(isMenuOpen ? 4 : 4.5),
        height: (theme: Theme) => theme.spacing(isMenuOpen ? 4 : 4.5),
      },
    }),
    [isMenuOpen],
  );
};
