import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSecondarySaleSingle } from '../SecondarySaleSingleProvider';
import { useSecondarySaleSingleValidation } from '../useSecondarySaleSingleValidation';
import { useSecondarySaleMoneyDistribution } from '../../utils/useSecondarySaleMoneyDistribution';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import Select from 'components/UiKit/Select/Select';
import { startDateOptions } from 'app-settings';
import { MobileDateTimePicker } from '@mui/lab';
import { useFormik } from 'formik';
import { addDays } from 'date-fns';
import { useSecondarySaleSingleAuctionSubmit } from './useSecondarySaleSingleAuctionSubmit';
import { SecondarySaleSubmit } from 'pages/SecondarySale/utils/SecondarySaleSubmit';
import { SecondarySaleSingleFormSwitcher } from '../SecondarySaleSingleFormSwitcher';

interface FormValues {
  price: number;
  startDateType: 'immediately' | 'specific';
  startDate: Date;
  endDate: Date;
}

export const SecondarySaleSingleAuction: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { state } = useSecondarySaleSingle();

  const validationSchema = useSecondarySaleSingleValidation();
  const moneyDistribution = useSecondarySaleMoneyDistribution(state.art);
  const handleSubmit = useSecondarySaleSingleAuctionSubmit(moneyDistribution.servicePercentage);

  const formik = useFormik<FormValues>({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <SecondarySaleSingleFormSwitcher />

      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit} noValidate>
        <Box>
          <Typography variant={'h4'} display={'flex'} mb={2} mt={5}>
            {t('secondarySale.financeConditions')}
          </Typography>

          <OutlinedTextInput
            name="price"
            id="price"
            defaultValue={formik.initialValues.price}
            type={'number'}
            label={t('secondarySale.startingPrice')}
            error={!!(formik.touched.price && formik.errors.price)}
            helperText={formik.touched.price && t(formik.errors.price as string)}
            postfix={
              <Typography variant="body1" color="grey.500">
                ETH
              </Typography>
            }
          />
        </Box>

        <Box mt={5}>
          <Typography variant={'h4'} display={'flex'} mb={2} mt={5}>
            {t('secondarySale.duration')}
          </Typography>

          <Select
            items={startDateOptions}
            name="startDateType"
            label={t('secondarySale.startDate')}
            value={formik.values.startDateType}
            onChange={(e) => formik.setFieldValue('startDateType', e.target.value)}
          />
          {formik.values.startDateType === 'specific' && (
            <Box mt={2.5}>
              <MobileDateTimePicker
                ampm={false}
                label={t('secondarySale.startDate')}
                value={formik.values.startDate}
                onChange={(e: Date | null) => formik.setFieldValue('startDate', e)}
                disablePast={true}
                maxDateTime={formik.values.endDate}
                inputFormat="yyyy-MM-dd H:mm"
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          )}

          <Box mt={2.5}>
            <MobileDateTimePicker
              ampm={false}
              label={t('secondarySale.endDate')}
              value={formik.values.endDate}
              onChange={(e: Date | null) => formik.setFieldValue('endDate', e)}
              disablePast={true}
              minDateTime={formik.values.startDate}
              inputFormat="yyyy-MM-dd H:mm"
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>

        <SecondarySaleSubmit isPublishing={state.isPublishing} artId={state.art.id} />
      </form>
    </>
  );
});

const getInitialValues = () => ({
  price: 0.001,
  startDate: new Date(),
  endDate: addDays(new Date(), 7),
  startDateType: startDateOptions[0].value,
});
