import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';

export const ArtPendingBanner: React.FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Box sx={sx.container}>
      <Typography sx={sx.title} color={'primary.main'}>
        {t('artPage.pendingBannerTitle')}
      </Typography>
      <Typography variant={'paragraphMedium'} color={'primary.main'}>
        {t('artPage.pendingBannerText')}
      </Typography>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  container: {
    p: 2,
    mt: 2,
    borderRadius: '5px',
    border: (theme) => `1px solid ${theme.palette.accent.main}`,
  },
  title: {
    fontSize: '18px',
    lineHeight: '150%',
    fontWeight: 600,
    mb: 1,
  },
};
