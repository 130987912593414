import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import type { SxProps } from '@mui/material/styles';
import { SignInLayout } from 'components/SignInLayout/SignInLayout';
import { routes } from 'app-settings';
import { useNavigate, useParams } from 'react-router-dom';
import { LoginBtn } from 'components/LoginBtn/LoginBtn';

export const WrongWallet = React.memo(() => {
  const { t } = useTranslation();
  const { wallet } = useParams<{ wallet: string }>();
  const navigate = useNavigate();

  return (
    <SignInLayout titleTKey="wrongWallet.title">
      <Typography fontWeight={600} mt={4} mb={2.5} textAlign={'center'}>
        {t('wrongWallet.subtitle')}
      </Typography>

      <Typography variant={'paragraphSmall'} mb={3.75} display={'block'} textAlign={'center'}>
        <Trans
          i18nKey={'wrongWallet.description'}
          components={[
            <Typography color={'text.secondary'} component={'span'} />,
            <Link
              target="_blank"
              underline="none"
              className={'accent-color'}
              href="mailto:totemo@totemo.com"
              rel="noreferrer"
            />,
          ]}
        />
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" onClick={() => navigate(routes.noWalletSignIn)}>
          {t('welcomePage.signInWithoutWallet')}
        </Button>
      </Box>
      <Box sx={sx.connectContainer}>
        <Box sx={sx.metamaskLogo}>
          <img alt="logo" src={'/assets/icons/metamask.svg'} />
          <Typography variant="paragraphLarge" sx={sx.metamaskLogoTitle}>
            {t('common.metamask')}
          </Typography>
        </Box>
        {wallet ? (
          <Typography mb={1} textAlign={'center'}>
            You've tried with <br />
            {wallet}
          </Typography>
        ) : null}
        <LoginBtn connectTKey={'wrongWallet.tryAnotherWallet'} />
      </Box>
    </SignInLayout>
  );
});

const sx: Record<string, SxProps> = {
  subtitle: { mt: 3.75, fontWeight: 700, whiteSpace: 'pre-line', textAlign: 'center' },
  description: { mt: 0.5, fontWeight: 400, whiteSpace: 'pre-line', textAlign: 'center', color: 'text.secondary' },
  connectContainer: {
    bgcolor: 'grey.100',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    mt: 8.75,
    p: 3.75,
  },
  metamaskLogo: {
    display: 'flex',
    alignItems: 'center',
    pb: 2.5,
  },
  metamaskLogoTitle: {
    ml: 2.5,
  },
};
