/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface UpdateArtDto
 */
export interface UpdateArtDto {
    /**
     * art id
     * @type {number}
     * @memberof UpdateArtDto
     */
    id?: any;
    /**
     * Current owner id
     * @type {number}
     * @memberof UpdateArtDto
     */
    currentOwnerId?: any;
    /**
     * New owner id
     * @type {number}
     * @memberof UpdateArtDto
     */
    newOwnerId?: any;
    /**
     * How much owner has copies of art
     * @type {number}
     * @memberof UpdateArtDto
     */
    amount?: any;
    /**
     * art status
     * @type {string}
     * @memberof UpdateArtDto
     */
    artStatus?: UpdateArtDtoArtStatusEnum;
    /**
     * order of art
     * @type {number}
     * @memberof UpdateArtDto
     */
    order?: any;
    /**
     * Tags
     * @type {Array&lt;Tag&gt;}
     * @memberof UpdateArtDto
     */
    tags?: any;
    /**
     * Id of token event
     * @type {number}
     * @memberof UpdateArtDto
     */
    eventId?: any;
    /**
     * Tags to remove
     * @type {Array&lt;Tag&gt;}
     * @memberof UpdateArtDto
     */
    removedTags?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateArtDtoArtStatusEnum {
    PUBLISHED = 'PUBLISHED',
    PROMO = 'PROMO',
    PENDING = 'PENDING',
    NOTFORSALE = 'NOT_FOR_SALE',
    DRAFT = 'DRAFT'
}

