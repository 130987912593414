import { SxProps, Theme } from '@mui/material/styles';

export const ArtOwnerItemSx: Record<string, SxProps<Theme>> = {
  itemContainer: {
    bgcolor: 'grey.100',
    mb: 1.25,
    p: 2,
    borderRadius: '5px',
    display: 'flex',
  },
  avatar: {
    mr: 2,
    width: (theme) => theme.spacing(3.75),
    height: (theme) => theme.spacing(3.75),
  },
  buy: {
    display: 'block',
    minHeight: (theme) => theme.spacing(5),
    minWidth: (theme) => theme.spacing(10),
  },
};
