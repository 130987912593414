import { useEffect, useState } from 'react';
import { Event, Tag, User } from 'codegen-api/api-totemo-service/models';
import { useMarketplaceContext } from '../State/MarketplaceContext';
import { useApiContext } from 'contexts/ApiContext';

export const useInitialFilterData = () => {
  const { users: userApi, events: eventsApi, tags: tagsApi, art: artsApi } = useApiContext();
  const { dispatch } = useMarketplaceContext();

  const [tags, setTags] = useState<Tag[]>([{ name: 'All', id: -1 }]);

  useEffect(() => {
    const fetchEvents = async () => {
      const events = (await eventsApi.eventsControllerFindAll()).data || [];

      dispatch({ type: 'FETCH_EVENTS', payload: events as unknown as Event[] });
    };

    const fetchArtists = async () => {
      const artists = (await userApi.userControllerFindAll(undefined, 'true')).data.items || [];

      dispatch({ type: 'FETCH_ARTISTS', payload: artists as User[] });
    };

    const fetchMaxPriceOnMarketplace = async () => {
      const resp = (await artsApi.artControllerFilterSettings()).data;

      dispatch({ type: 'SET_MAX_PRICE_ON_MARKET_PLACE', payload: resp?.maxPrice || 1000 });
    };

    const fetchPopulateTags = async () => {
      const resp = (await tagsApi.tagControllerPopular()).data || [];

      const tagsArray = resp
        .map((item) => {
          return { id: item.id, name: item.name };
        })
        .slice(0, 9);

      setTags([{ id: -1, name: 'All' }, ...tagsArray]);
    };

    fetchEvents();
    fetchArtists();
    fetchMaxPriceOnMarketplace();
    fetchPopulateTags();
  }, [artsApi, dispatch, eventsApi, tagsApi, userApi]);

  return tags;
};
