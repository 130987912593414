import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useUpdateArtistInfoValidationSchema = () => {
  const { t } = useTranslation();
  return React.useMemo(() => {
    return Yup.object().shape({
      fullName: Yup.string()
        .trim()
        .max(90, t('forms.errors.fieldMaxLength', { length: 90 })),
      bio: Yup.string()
        .trim()
        .max(5000, t('forms.errors.fieldMaxLength', { length: 5000 })),
      video: Yup.array().of(
        Yup.object().shape(
          {
            link: Yup.string()
              .trim()
              .when('name', {
                is: (name: any) => Boolean(name),
                then: Yup.string()
                  .required(t('forms.errors.fieldRequired'))
                  .url(t('forms.errors.urlFormat'))
                  .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
              }),
            name: Yup.string()
              .trim()
              .when('link', {
                is: (link: any) => Boolean(link),
                then: Yup.string()
                  .required(t('forms.errors.fieldRequired'))
                  .max(90, t('forms.errors.fieldMaxLength', { length: 90 })),
              }),
          },
          [
            ['link', 'name'],
            ['name', 'link'],
          ],
        ),
      ),
      podcast: Yup.array().of(
        Yup.object().shape(
          {
            link: Yup.string()
              .trim()
              .when('name', {
                is: (name: any) => Boolean(name),
                then: Yup.string()
                  .required(t('forms.errors.fieldRequired'))
                  .url(t('forms.errors.urlFormat'))
                  .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
              }),
            name: Yup.string()
              .trim()
              .when('link', {
                is: (link: any) => Boolean(link),
                then: Yup.string()
                  .required(t('forms.errors.fieldRequired'))
                  .max(90, t('forms.errors.fieldMaxLength', { length: 90 })),
              }),
          },
          [
            ['link', 'name'],
            ['name', 'link'],
          ],
        ),
      ),
      shopifyCollections: Yup.array().of(
        Yup.object().shape(
          {
            id: Yup.string()
              .trim()
              .when('name', {
                is: (name: any) => Boolean(name),
                then: Yup.string()
                  .required(t('forms.errors.fieldRequired'))
                  .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
              }),
            name: Yup.string()
              .trim()
              .when('id', {
                is: (id: any) => Boolean(id),
                then: Yup.string()
                  .required(t('forms.errors.fieldRequired'))
                  .max(90, t('forms.errors.fieldMaxLength', { length: 90 })),
              }),
          },
          [
            ['id', 'name'],
            ['name', 'id'],
          ],
        ),
      ),
      settings: Yup.object().shape({
        social: Yup.object().shape({
          website: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          instagram: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          twitter: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          facebook: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          tiktok: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          discord: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          snapchat: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          youtube: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          clubhouse: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
        }),
      }),
    });
  }, [t]);
};
