import { useMemo } from 'react';
import { MarketPlaceFormValues } from '../Form/MarketPlaceFormValues';
import { useMarketplaceContext } from '../State/MarketplaceContext';

export const useFilterCounter = (values: Partial<MarketPlaceFormValues>) => {
  const { state } = useMarketplaceContext();

  return useMemo(() => {
    const formInitialValues = {
      artist: '',
      minPrice: 0,
      maxPrice: state.maxPriceOnMarketPlace,
      tags: [],
      events: '',
    };

    const keys = Object.keys(formInitialValues);

    const filledFilters: string[] = [];

    keys.forEach((key) => {
      if (key === 'tags' && !!values.tags?.length && values.tags[0].name !== 'All') {
        filledFilters.push('tags');
      }

      if (
        formInitialValues[key as keyof MarketPlaceFormValues] !== values[key as keyof MarketPlaceFormValues] &&
        key !== 'tags'
      ) {
        filledFilters.push(key);
      }
    });
    return filledFilters.length;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.maxPriceOnMarketPlace, values, state.filters.tags]);
};
