import React from 'react';
import { Avatar, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { Loader } from 'components/UiKit/Loader/Loader';
import { defaultAvatarSrc, routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';
import { useApiContext } from 'contexts/ApiContext';
import { User } from 'codegen-api/api-totemo-service/models';
import { worksTypeMap } from './types';

type Props = { worksType: 'published' | 'drafts' };

const AdminPanelListOfArtistsWithArts: React.FC<Props> = React.memo(({ worksType }) => {
  const { t } = useTranslation();
  const api = useApiContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [artists, setArtists] = React.useState<User[]>([]);

  React.useEffect(() => {
    api.users
      .userControllerFindAll(undefined, undefined, undefined, worksTypeMap[worksType], true)
      .then((res) => setArtists((res.data.items as unknown as User[]) || []))
      .catch();

    setIsLoading(false);
  }, [api.users, worksType]);

  if (isLoading) return <Loader />;

  if (!artists.length && worksType === 'published')
    return <Box sx={sx.emptyTab}>{t('adminPanel.tabPublishedEmpty')}</Box>;

  if (!artists.length && worksType === 'drafts') return <Box sx={sx.emptyTab}>{t('adminPanel.tabDraftsEmpty')}</Box>;

  const handleOpenWorks = (wallet: string) => {
    navigate(`${routes.adminPanelCreatedArtsWorks}/${wallet}/${worksType}`);
  };

  return (
    <Box>
      {artists.map((item) => (
        <Box sx={sx.item} key={item.wallet} onClick={() => handleOpenWorks(item.wallet)}>
          <Avatar alt={item.username} src={item.settings?.avatar?.url || defaultAvatarSrc} />
          <Box sx={sx.username}>{item.username}</Box>
          <Box color={'text.secondary'}>{t('adminPanel.artworksWithCount', { count: item.arts.length })}</Box>
        </Box>
      ))}
    </Box>
  );
});

export default AdminPanelListOfArtistsWithArts;

const sx: Record<string, SxProps<Theme>> = {
  emptyTab: {
    color: 'grey.600',
    mt: 5,
    textAlign: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    mb: 2.5,
  },
  username: {
    ml: 2,
    flex: '1 1 auto',
    fontWeight: 'bold',
  },
};
