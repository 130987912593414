import React from 'react';
import TotemoMultiple from './contracts/TotemoMultiple';
import * as Ethers from 'ethers';
import { v4 as uuidv4 } from 'uuid';
import { prepareWriteContract, writeContract } from '@wagmi/core';
import { useAccount } from 'wagmi';
import { VoucherMultiple } from './types';
import { Address } from 'viem';

type ReturnValues = {
  isCanceling: boolean;
  cancelBuyNowVoucher: (input: {
    tokenId: number;
    authorWallet: string;
    pmWallet: string;
    price: number | string;
    startDate: number;
    copies: number;
    ownerWallet: string;
    signature: string;
  }) => Promise<{ result: { hash: string, from: Address }; uuid: string }>;
};

const contractAddress = process.env.REACT_APP_MULTIPLE_CONTRACT;

export const useMultipleContractCancel = (): ReturnValues => {
  const [isCanceling, setIsCanceling] = React.useState<boolean>(false);
  const { address: wallet } = useAccount();
  const cancelBuyNowVoucher = React.useCallback(
    async ({ tokenId, authorWallet, pmWallet, price, startDate, copies, ownerWallet, signature }) => {
      setIsCanceling(true);
      try {

        const weiPrice = Ethers.utils.parseEther(price).toString();

        const voucher: VoucherMultiple = {
          tokenId: tokenId,
          startMarketDate: startDate,
          author: authorWallet,
          isFirstSale: true,
          owner: ownerWallet,
          assetContract: contractAddress as Address,
          price: BigInt(weiPrice),
          pm: pmWallet,
          amount: copies!,
          data: '0x6732',
          signature,
        };

        // TODO replace with stronger identifier
        const txUuid = Ethers.utils.formatBytes32String(uuidv4().replaceAll('-', '').slice(1)) as `0x${string}`;

        const { request } = await prepareWriteContract({
          address: contractAddress as Address,
          abi: TotemoMultiple.abi,
          functionName: 'cancelSale',
          args: [txUuid, voucher],
        });


        const { hash } = await writeContract(request);
        setIsCanceling(false);
        return {
          result: {
            hash,
            from: wallet!,
          },
          uuid: txUuid,
        };
      } catch (e) {
        setIsCanceling(false);
        throw e;
      }
    },
    [],
  );

  return { isCanceling, cancelBuyNowVoucher };
};
