import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc, routes } from 'app-settings';
import { UserRolesTKeyMap } from 'components/RequireRole/RequireRole';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtArtStatusEnum, ArtBodyTokenTypeEnum } from 'codegen-api/api-totemo-service/models';
import { Link } from 'react-router-dom';

export const ArtAuthorAndOwner: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { state } = useArtDetailedView();

  if (!state.art) return null;

  const owner = state.art.tokenType === ArtBodyTokenTypeEnum.ERC721 ? state.art.owners[0]?.owner : null;

  const isOwnerVisible: boolean =
    owner && (state.art.artStatus === ArtArtStatusEnum.NOTFORSALE || state.saleData?.isFirstSale === false);

  const art = state.art;

  return (
    <Box my={5}>
      <Divider />

      <Box sx={sx.user}>
        <Link to={`${routes.userProfile}/${art.author.wallet}`}>
          <Avatar sx={sx.avatar} alt={art.author.username} src={art.author.settings.avatar?.url || defaultAvatarSrc} />
        </Link>
        <Box mx={2}>
          <Typography variant="paragraphXSmall" color="text.secondary" component={'div'}>
            {t(UserRolesTKeyMap.ARTIST)}
          </Typography>
          <Typography
            variant="paragraphSmall"
            component={Link}
            to={`${routes.userProfile}/${art.author.wallet}`}
            sx={sx.username}
          >{`@${art.author.username}`}</Typography>
        </Box>
      </Box>

      {isOwnerVisible && (
        <Box sx={sx.user}>
          <Link to={`${routes.userProfile}/${owner.wallet}`}>
            <Avatar sx={sx.avatar} alt={owner.username} src={owner.settings?.avatar?.url || defaultAvatarSrc} />
          </Link>
          <Box mx={2}>
            <Typography variant="paragraphXSmall" color="text.secondary" component={'div'}>
              {t('common.owner')}
            </Typography>
            <Typography
              variant="paragraphSmall"
              component={Link}
              to={`${routes.userProfile}/${owner.wallet}`}
              sx={sx.username}
            >{`@${owner.username}`}</Typography>
          </Box>
        </Box>
      )}

      <Divider />
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  avatar: {
    ml: 1.25,
    flex: '0 0 auto',
    width: (theme) => theme.spacing(3.75),
    height: (theme) => theme.spacing(3.75),
  },
  username: {
    fontWeight: 600,
    flexGrow: 1,
    display: 'block',
    textDecoration: 'none',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    minHeight: (theme) => theme.spacing(8.5),
  },
};
