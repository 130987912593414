import { PrimarySaleData, SecondarySaleData } from 'types';

export const getMoneyDistributionVisibleValue = (value: number, prop: string, isSecondary?: boolean): string => {
  const defaults = isSecondary ? DEFAULT_MONEY_DISTRIBUTION_SECONDARY : DEFAULT_MONEY_DISTRIBUTION_PRIMARY;
  //@ts-ignore
  const defaultValue = defaults[prop];
  return value === defaultValue ? `${value}%` : `${value}% (${defaultValue}%)`;
};

export const DEFAULT_MONEY_DISTRIBUTION_PRIMARY: PrimarySaleData = {
  address: '',
  addressPercentage: 0,
  artistPercentage: 65,
  servicePercentage: 5,
  totemoPercentage: 35,
};
export const DEFAULT_MONEY_DISTRIBUTION_SECONDARY: SecondarySaleData = {
  artistPercentage: 5,
  sellerPercentage: 90,
  servicePercentage: 5,
  totemoPercentage: 5,
};
