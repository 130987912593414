import React from 'react';
import { Box, CardMedia, SxProps, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';
import { useCreateArtReducerContext } from '../useCreateEditArtReducer';
import { ART_TYPE } from '../types';

const ChooseType: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useCreateArtReducerContext();

  const handleChangeType = (type: ART_TYPE) => {
    dispatch({ type: 'UPDATE_ART_TYPE', payload: type });
  };

  return (
    <Box sx={sx.container}>
      <Box sx={sx.card} onClick={() => handleChangeType('SINGLE')}>
        <Box sx={sx.cardHeader}>
          <Typography variant="h3">{t('pages.createArt.single')}</Typography>
          <ArrowForwardIcon color="secondary" />
        </Box>
        <CardMedia component="img" sx={sx.nftImage} image="/assets/icons/single.svg" alt="single-nft" />
      </Box>

      <Box sx={sx.card} onClick={() => handleChangeType('MULTIPLE')}>
        <Box sx={sx.cardHeader}>
          <Typography variant="h3">{t('pages.createArt.multiple')}</Typography>
          <ArrowForwardIcon color="secondary" />
        </Box>
        <CardMedia component="img" sx={sx.nftImage} image="/assets/icons/multiple.svg" alt="single-nft" />
      </Box>
    </Box>
  );
};

export default ChooseType;

const sx: Record<string, SxProps<Theme>> = {
  container: {
    padding: 0,
    mb: 10,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '30px',
  },
  card: theme => ({
    cursor: 'pointer',
    minHeight: 215,
    minWidth: 160,
    position: 'relative',
    padding: (theme) => theme.spacing(4, 3),
    bgcolor: 'grey.100',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column-reverse',
      filter: 'grayscale(100%)',
      transition: 'filter 0.3s ease',
      ':hover': {
        filter: 'none',
      },
    },
  }),
  cardHeader: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  nftImage: theme => ({
    position: 'absolute',
    left: 0,
    bottom: '-48px',
    width: 160,
    [theme.breakpoints.up('sm')]: {
      top: '-48px',
    },
  }),
};
