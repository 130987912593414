import React, { FC } from 'react';
import { FilledInput, InputAdornment, InputBaseProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import type { SxProps } from '@mui/system';

interface Props extends InputBaseProps {
  id: string;
  onChange?(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  styles?: SxProps;
  placeholder?: string;
}

export const SearchInput: FC<Props> = ({ placeholder, id, styles, onChange, ...props }: Props) => {
  return (
    <FilledInput
      sx={sx.textField}
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon sx={sx.adornment} />
        </InputAdornment>
      }
      onChange={onChange}
      inputProps={{ type: 'search' }}
      {...props}
    />
  );
};

const sx: Record<string, SxProps> = {
  textField: {
    outline: 'none',
    bgcolor: 'grey.100',
  },
  adornment: {
    alignSelf: 'center',
    color: 'grey.600',
  },
};
