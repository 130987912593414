import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { useServiceFee } from 'pages/Art/utils/useServiceFee';
import { getPriceWithoutFeeETH } from 'pages/CreateEditArtPage/utils';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc } from 'app-settings';
import { ArtOwnerSaleAction } from 'pages/Art/ArtTabs/ArtOwnerSaleItem/ArtOwnerSaleAction';
import { ArtOwner, BuyNow, SaleTransactionInfo } from 'codegen-api/api-totemo-service/models';
import { ArtOwnerItemSx as commonSx } from 'pages/Art/ArtTabs/ArtOwnerItemSx';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { useAppState } from 'contexts/appState/AppContext';
import { ReactComponent as Timer2Icon } from 'components/Icons/timer_2_line.svg';

interface Props {
  artOwner: ArtOwner;
  buyNow: BuyNow;
}

export const ArtOwnerPrimarySaleItem: React.FC<Props> = React.memo(({ artOwner, buyNow }) => {
  const { t } = useTranslation();
  const { state } = useArtDetailedView();
  const serviceFee = useServiceFee(buyNow);
  const { currentUser } = useAppState();

  const originalPrice = React.useMemo(() => {
    if (!buyNow.marketPrice) return null;

    return getPriceWithoutFeeETH(String(buyNow.marketPrice), String(serviceFee));
  }, [serviceFee, buyNow.marketPrice]);

  if (!artOwner) return null;

  const processingByUserTx: SaleTransactionInfo[] = (buyNow.transactions || []).filter(
    (tx: SaleTransactionInfo) => currentUser.wallet === tx.signer,
  );
  const processingByUserCopies: number = processingByUserTx.reduce((acc, item) => acc + +item.amount, 0);

  return (
    <Box sx={sx.itemContainer}>
      <Box display={'flex'}>
        <Avatar src={artOwner.owner.settings?.avatar?.url || defaultAvatarSrc} sx={commonSx.avatar} />
        <Box flex={'1 1 auto'}>
          <Box display={'flex'} gap={1.25} alignItems={'center'}>
            <Typography variant={'paragraphMedium'} color={'primary.main'} fontWeight={600}>
              {originalPrice}Ξ
            </Typography>
            <Box sx={sx.primaryTag}>
              {t('artPage.primaryTag', { all: state.art!.amount, available: buyNow.amount })}
            </Box>
          </Box>
          <Typography variant={'paragraphSmall'} display={'block'} color={'primary.main'}>
            {t('artPage.sellerName', { name: artOwner.owner.username })}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} ml={1.25}>
          <ArtOwnerSaleAction isPrimaryMultiple={true} buyNow={buyNow} />
        </Box>
      </Box>
      {processingByUserTx.length ? (
        <>
          <Divider sx={sx.divider} />
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography sx={sx.copies}>{t('artPage.copiesCount', { count: processingByUserCopies })}</Typography>
            <Typography sx={sx.processingLabel}>
              <span>{t('artPage.processingLabel')}</span>
              <Timer2Icon />
            </Typography>
          </Box>
        </>
      ) : null}
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  itemContainer: {
    bgcolor: 'grey.100',
    p: 2,
    borderRadius: '5px',
  },
  primaryTag: {
    bgcolor: 'grey.300',
    py: '1px',
    px: 1.5,
    borderRadius: '25px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
  },
  divider: {
    mt: 2,
    mb: 2.5,
  },
  copies: {
    pl: 5.75,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '15px',
  },
  processingLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    color: 'text.secondary',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '15px',
    svg: {
      width: (theme) => theme.spacing(2),
      height: (theme) => theme.spacing(2),
    },
  },
};
