import React from 'react';
import { UserRoleName } from 'types';
import { useAppState } from 'contexts/appState/AppContext';
import { Navigate } from 'react-router-dom';
import type { AppRoute } from 'app-settings';
import { CurrentUserState } from 'contexts/appState/types';
import { routes } from 'app-settings';

export const WalletRoles: UserRoleName[] = ['COLLECTOR', 'ARTIST', 'SUPERADMIN', 'PM'];
export const AuthRoles: UserRoleName[] = ['VISITOR', ...WalletRoles];

interface Props {
  allowedFor: UserRoleName[] | UserRoleName;
  placeholder?: React.ReactNode;
  redirectTo?: AppRoute;
  artistWallet?: string;
  children?: React.ReactNode;
}

export const RequireRole: React.FC<Props> = ({
  allowedFor,
  redirectTo = routes.home,
  children,
  artistWallet,
  placeholder = null,
}) => {
  const { currentUser } = useAppState();
  const isAllowed = Array.isArray(allowedFor)
    ? allowedFor.some((role) => isUserHasRole(currentUser, role, artistWallet))
    : isUserHasRole(currentUser, allowedFor, artistWallet);

  if (isAllowed) {
    return <>{children}</>;
  }

  return redirectTo ? <Navigate to={redirectTo} /> : <>{placeholder}</>;
};

export const isUserHasRole = (user: CurrentUserState, role: UserRoleName, artistWallet?: string): boolean => {
  switch (role) {
    case 'SUPERADMIN': {
      return user.role.some((item) => item.role === 'SUPERADMIN');
    }
    case 'ARTIST': {
      return user.role.some((item) => item.role === 'ARTIST');
    }
    case 'COLLECTOR': {
      return user.role.some((item) => item.role === 'COLLECTOR');
    }
    case 'VISITOR': {
      return user.role.some((item) => item.role === 'VISITOR');
    }
    case 'GUEST': {
      return user.role.some((item) => item.role === 'GUEST');
    }
    case 'PM': {
      return user.role.some((item) => item.role === 'PM' && item.artistWallet === artistWallet);
    }
    default:
      return false;
  }
};

export const useHasRole = (requiredRole: UserRoleName[] | UserRoleName, artistWallet?: string): boolean => {
  const { currentUser } = useAppState();

  return Array.isArray(requiredRole)
    ? requiredRole.some((role) => isUserHasRole(currentUser, role, artistWallet))
    : isUserHasRole(currentUser, requiredRole, artistWallet);
};

export const UserRolesHexMap: Record<UserRoleName, string> = {
  GUEST: '',
  VISITOR: '0xf49ce3a45cd2722868e2eaf6329f55343400042478cd0e2d029816a57274f4b3',
  COLLECTOR: '0xcbce9983fcd5fc099294db3138f1660107f2bd5b23b9fa0002d7df1db9d854cb',
  SUPERADMIN: '0xe4041c13a985afece8aab653f7b77a1e7f312381bd7738ead7806eee6c03bb1a',
  ARTIST: '0x6bdd6eec7bc953f67e3ae90ab8ab4bd466543d6dbd19cb8d62eed396b8427a05',
  PM: '0x9ad735d6ba00c62c3c140904d7356961da24b1d2d98bbfb4bacb35bd61b6928c',
};

export const UserRolesTKeyMap: Record<UserRoleName, string> = {
  GUEST: 'common.roleGuest',
  VISITOR: 'common.roleVisitor',
  COLLECTOR: 'common.roleCollector',
  SUPERADMIN: 'common.roleSuperAdmin',
  ARTIST: 'common.roleArtist',
  PM: 'common.rolePersonalManager',
};
