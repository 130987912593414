import React, { ChangeEventHandler } from 'react';
import { Box, Button, Divider, Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { ethers } from 'ethers';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { getFullPriceEth, getPriceWithoutFeeETH } from 'pages/CreateEditArtPage/utils';
import { useCanPurchase } from 'pages/Art/utils/useCanPurchase';
import { useServiceFee } from 'pages/Art/utils/useServiceFee';
import { useBalanceEth } from 'pages/Art/utils/useBalanceEth';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { useApiContext } from 'contexts/ApiContext';
import { useAppState } from 'contexts/appState/AppContext';
import { ArtDrawerPaperProps, ArtDrawerSx } from './ArtDrawerStyles';
import { getMarketPrice } from 'helpers/getMarketPrice';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import { BaseModal } from 'components/Modal/BaseModal';

export const ArtPlaceBidDrawer: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { currentUser } = useAppState();
  const { state, dispatch } = useArtDetailedView();
  const { bids: bidsApi } = useApiContext();
  const canPurchase = useCanPurchase();
  const balanceEth = useBalanceEth();
  const serviceFee = useServiceFee();
  const marketPriceEth = getMarketPrice(state.saleData).toString();
  const originalPrice = getPriceWithoutFeeETH(marketPriceEth, String(serviceFee));
  const [isPlacingBid, setIsPlacingBid] = React.useState<boolean>(false);
  const [bidEth, setBidEth] = React.useState<string>('0');
  const [error, setError] = React.useState<string>('');
  const hasBids = state.saleData && 'bids' in state.saleData && state.saleData.bids.length;
  const isMobileScreen = useIsMobileScreen();

  React.useEffect(() => {
    setError('');
    setBidEth(getBidPriceEth(originalPrice, hasBids ? undefined : '0'));
  }, [originalPrice, hasBids]);

  if (!state.art || !state.saleData || !canPurchase) {
    return null;
  }

  const fullPriceEth = getFullPriceEth(bidEth || '0', String(serviceFee));
  const feeValue = ethers.utils.formatEther(
    ethers.utils.parseEther(fullPriceEth).sub(ethers.utils.parseEther(bidEth || '0')),
  );

  const handleClose = () => dispatch({ type: 'HIDE_DRAWER' });

  const handleBidChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBidEth(e.target.value);
    if (!error) return;
    setError(
      +fullPriceEth < +getFullPriceEth(getBidPriceEth(originalPrice), String(serviceFee))
        ? 'forms.errors.bidIsTooLow'
        : '',
    );
  };

  const handlePlaceBid = () => {
    if (!state.saleData) return;

    const placeBid = async () => {
      if (!state.saleData) throw new Error('Auction data is missing');

      const minBid = getBidPriceEth(originalPrice, hasBids ? undefined : '0');
      if (+fullPriceEth < +getFullPriceEth(minBid, String(serviceFee))) {
        setError('forms.errors.bidIsTooLow');
        return;
      }
      setIsPlacingBid(true);
      try {
        const respData = (
          await bidsApi.bidControllerCreate({
            auctionId: state.saleData.id,
            artId: state.saleData.artId,
            bidPrice: +fullPriceEth,
            signature: 'signature',
            ercContract: 'ercContract',
            assetContract: process.env.REACT_APP_SINGLE_CONTRACT,
            bidderWallet: currentUser.wallet,
          })
        ).data;
        dispatch({ type: 'ADD_BID_AND_HIDE_DRAWER', payload: respData });
      } catch (e) {
      }
      setIsPlacingBid(false);
    };

    placeBid();
  };

  const Container = isMobileScreen ? Drawer : BaseModal;

  return (
    <Container sx={{ minWidth: '500px' }} anchor="bottom" open={state.isDrawerOpen} onClose={handleClose}
               PaperProps={ArtDrawerPaperProps}>
      <Box sx={sx.content}>
        <Typography variant={'h2'}>{t('artPage.placeBidTitle')}</Typography>
        <Box mt={2}>
          <Typography color={'text.secondary'} component="span">
            {t('artPage.youArePlacingBid')}
          </Typography>
          <Typography color={'primary.main'} component="span" fontWeight={600} ml={0.5}>
            {state.art.name}
          </Typography>
        </Box>
        <Box>
          <Typography variant={'paragraphSmall'} fontWeight={600} display={'block'} mt={2.5} mb={0.5}>
            {t('artPage.yourBid')}
          </Typography>
          <OutlinedTextInput
            name="bid"
            id="bid"
            value={bidEth}
            error={!!error}
            inputProps={inputProps}
            helperText={error ? t(error) : ''}
            onChange={handleBidChange}
            postfix={<Typography color="grey.500">ETH</Typography>}
          />
          <Typography variant={'paragraphXSmall'} color={'text.secondary'} component={'div'} mb={2.5} mt={0.5}>
            <Box component={'span'} mr={2.5}>
              {`${t(hasBids ? 'artPage.latestBid' : 'artPage.startingPrice')} ${originalPrice}Ξ`}
            </Box>
            <Box component={'span'}>{`${t('artPage.minBid')} ${getMinBidIncrementEth(originalPrice)}Ξ`}</Box>
          </Typography>
          <Divider sx={sx.divider} />
          <Box sx={sx.priceItem}>
            <Typography component="span" color="text.secondary">
              {t('artPage.yourBalance')}
            </Typography>
            <Typography component="span" color={'primary.main'}>{`${balanceEth} ETH`}</Typography>
          </Box>
          <Box sx={sx.priceItem}>
            <Typography component="span" color="text.secondary">{`${t(
              'artPage.serviceFee',
            )} ${serviceFee}%`}</Typography>
            <Typography component="span" color={'primary.main'}>{`${feeValue} ETH`}</Typography>
          </Box>
          <Divider />
          <Box sx={sx.priceItem} mt={2}>
            <Typography component="span" color={'primary.main'}>
              {t('artPage.youPay')}
            </Typography>
            <Typography component="span" sx={sx.total}>{`${fullPriceEth} ETH`}</Typography>
          </Box>
          <Box sx={sx.note}>
            <Typography variant={'paragraphXSmall'} color={'grey.800'} component={'p'} mb={1}>
              {t('artPage.placingBidNote')}
            </Typography>
            <Typography variant={'paragraphXSmall'} color={'primary.main'} fontWeight={600} component={'p'}>
              {t('artPage.placingBidWarn')}
            </Typography>
          </Box>
          <Box sx={sx.buttons}>
            <AsyncButton isLoading={isPlacingBid} onClick={handlePlaceBid} variant={'contained'} color={'primary'}>
              {t('artPage.placeBidBtn')}
            </AsyncButton>
            <Button sx={sx.cancel} onClick={handleClose}>
              {t('common.cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
});

const getMinBidIncrementEth = (originalPrice: string): string => {
  switch (true) {
    case +originalPrice < 0.1: {
      return '0.001';
    }
    case +originalPrice < 0.2: {
      return '0.002';
    }
    case +originalPrice < 0.5: {
      return '0.005';
    }
    case +originalPrice < 1: {
      return '0.01';
    }
    case +originalPrice < 2.5: {
      return '0.02';
    }
    default: {
      return '0.25';
    }
  }
};

const getBidPriceEth = (originalPrice: string, increment?: string): string => {
  return ethers.utils.formatEther(
    ethers.utils
      .parseEther(originalPrice)
      .add(ethers.utils.parseEther(increment || getMinBidIncrementEth(originalPrice))),
  );
};

const sx: Record<string, SxProps<Theme>> = ArtDrawerSx;

const inputProps = { type: 'number' };
