import React from 'react';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtBodyTokenTypeEnum } from 'codegen-api/api-totemo-service/models';
import { useAppState } from 'contexts/appState/AppContext';
import { ArtActionButtonAdmin } from './ArtActionButtonAdmin';
import { ArtActionButtonUser } from './ArtActionButtonUser';
import { ArtActionButtonMultiple } from './ArtActionButtonMultiple';

export const ArtActionButton: React.FC = React.memo(() => {
  const { state } = useArtDetailedView();
  const { currentUser } = useAppState();
  const isCurrentUserAdmin = useHasRole('SUPERADMIN');

  if (!state.art) return null;

  const isCurrentUserAuthor = currentUser.wallet === state.art.authorWallet;
  const isCurrentUserOwner = currentUser.wallet === state.art.owners[0].wallet;

  if (state.art.tokenType === ArtBodyTokenTypeEnum.ERC1155) {
    return <ArtActionButtonMultiple />;
  }

  if (isCurrentUserAuthor && isCurrentUserOwner) {
    return null;
  }

  if (isCurrentUserAdmin) {
    return <ArtActionButtonAdmin />;
  }

  return <ArtActionButtonUser />;
});
