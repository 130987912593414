import React from 'react';
import { Box } from '@mui/material';
import { ArtHistoryItem } from 'pages/Art/ArtHistory/ArtHistoryItem';
import { useServiceFee } from 'pages/Art/utils/useServiceFee';
import { Loader } from 'components/UiKit/Loader/Loader';
import { useApiContext } from 'contexts/ApiContext';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { socketNotificationTypes } from 'app-settings';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { useTranslation } from 'react-i18next';
import { useArtHistoryReducer } from 'pages/Art/ArtHistory/useArtHistoryReducer';

const initialCount = 3;
const loadMoreCount = 15;

export const ArtHistory: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { state, dispatch } = useArtDetailedView();
  const serviceFee = useServiceFee();
  const api = useApiContext();
  const [historyState, historyDispatch] = useArtHistoryReducer();

  const artId = state.art!.id;

  const handleLoadMore = React.useCallback(async () => {
    historyDispatch({ type: 'LOAD_MORE_START' });
    try {
      const page = historyState.meta.itemsPerPage !== loadMoreCount ? 1 : historyState.meta.currentPage + 1;
      const payload = (await api.artHistory.artHistoryControllerFindAll(artId, loadMoreCount, page)).data;
      historyDispatch({ type: 'LOAD_MORE_SUCCESS', payload });
    } catch (e) {
      historyDispatch({ type: 'LOAD_MORE_FAIL' });
    }
  }, [api.artHistory, artId, historyDispatch, historyState.meta.currentPage, historyState.meta.itemsPerPage]);

  React.useEffect(() => {
    const init = async () => {
      try {
        const payload = (await api.artHistory.artHistoryControllerFindAll(artId, initialCount, 1)).data;
        historyDispatch({ type: 'INITIATION_SUCCESS', payload });
      } catch (e) {
        historyDispatch({ type: 'INITIATION_FAIL' });
      }
    };
    init();
  }, [api.artHistory, artId, historyDispatch]);

  React.useEffect(() => {
    const notificationListener = async (messageString: string) => {
      if (!artId) return;
      try {
        const message = JSON.parse(messageString);
        const { eventType } = message;

        if (eventType === socketNotificationTypes.bid) {
          const data: Record<string, any> = JSON.parse(message.data);
          const bid = data.bid;
          if (bid.artId !== artId) return;

          dispatch({ type: 'ADD_BID', payload: bid });
          historyDispatch({ type: 'ADD_ITEM', payload: data.artHistory });
        }

        if (eventType === socketNotificationTypes.finalize) {
          const data: Record<string, any> = JSON.parse(message.data);
          if (data.artId !== artId) return;
          const artHistoryRecord = JSON.parse(data.history);
          historyDispatch({ type: 'ADD_ITEM', payload: artHistoryRecord });
        }
      } catch (e) {}
    };

    api.socket!.on('notification', notificationListener);

    return () => {
      api.socket!.off('notification', notificationListener);
    };
  }, [api.socket, artId, dispatch, historyDispatch]);

  if (historyState.isInitialLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  if (!historyState.items.length) return null;

  const showLoadMore = historyState.meta.currentPage < historyState.meta.totalPages;

  return (
    <Box>
      {historyState.items.map((item) => (
        <ArtHistoryItem key={item.id} item={item} serviceFee={serviceFee} />
      ))}
      {showLoadMore && (
        <Box mt={2.5}>
          <AsyncButton isLoading={historyState.isLoading} onClick={handleLoadMore} fullWidth variant={'outlined'}>
            {t('common.loadMore')}
          </AsyncButton>
        </Box>
      )}
    </Box>
  );
});
