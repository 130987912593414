import React from 'react';
import { useTranslation } from 'react-i18next';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { useApiContext } from 'contexts/ApiContext';
import { noop } from 'helpers';

export const AdminPanelUsersManagePropose: React.FC<{ wallet: string }> = ({ wallet }) => {
  const { t } = useTranslation();
  const { offers } = useApiContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isPending, setIsPending] = React.useState<boolean>(false);

  console.log(wallet, isPending);

  React.useEffect(() => {
    const loadOffers = async () => {
      try {
        const result = await offers.offersControllerGetByOfferFromWallet();
        const isUserHasOffer = result.data.some((item) => item.offerTo === wallet);
        if (isUserHasOffer) {
          setIsPending(true);
        } else {
          setIsPending(false);
        }
      } catch (e) {}
      setIsLoading(false);
    };

    loadOffers();
  }, [offers, wallet]);

  const handleClickPropose = async () => {
    try {
      setIsLoading(true);
      await offers.offersControllerCreate({
        offerTo: wallet,
        type: 'GRANT_ROLE',
        data: null,
      });
      setIsPending(true);
    } catch (e) {}
    setIsLoading(false);
  };

  if (isPending) {
    return (
      <AsyncButton isLoading={false} fullWidth color="secondary" variant="contained" onClick={noop}>
        {t('adminPanel.pending')}
      </AsyncButton>
    );
  }

  return (
    <AsyncButton isLoading={isLoading} fullWidth color="primary" variant="contained" onClick={handleClickPropose}>
      {t('adminPanel.proposeRightsDelegations')}
    </AsyncButton>
  );
};
