import React from 'react';
import { alpha, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { SignInLayout } from 'components/SignInLayout/SignInLayout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { routes } from 'app-settings';
import { devLog } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { useApiContext } from 'contexts/ApiContext';
import { EmailNotVerified } from 'components/Modal/EmailNotVerified/EmailNotVerified';

type TErrorMsgTranslate = '' | 'userNotFound' | 'yoCheckYourInbox';

export const ForgotPassword: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { users, signUpLink } = useApiContext();
  const validationSchema = useForgotPasswordValidationSchema();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isEmailNotVerifiedModalOpen, setIsEmailNotVerifiedModalOpen] = React.useState<boolean>(false);
  const [resendEmailConfirmationErrorMsg, setResendEmailConfirmationErrorMsg] = React.useState<string>('');

  const errorMapping: Record<string, TErrorMsgTranslate> = {
    'User not found': 'userNotFound',
  };

  const [asyncError, setAsyncError] = React.useState<TErrorMsgTranslate>('');
  const formik = useFormik<{ email: string }>({
    validationSchema,
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      try {
        setIsSubmitting(true);

        await users.userControllerForgotPassword({ email: email?.toLowerCase() });

        navigate(routes.home);
      } catch (e: any) {
        devLog(e);
        const errorMessage = e.response?.data?.message || '';

        if (e.response?.data?.message?.startsWith('Request already exists')) {
          // TODO: display time of expiration from the error message
          // new Date('Mon Nov 20 2023 18:38:57 GMT+0300 (Moscow Standard Time)').toLocaleString() -> '11/20/2023, 6:38:57 PM'
          setAsyncError('yoCheckYourInbox');
        } else if (e.response?.data?.message === 'Email is not verified') {
          setIsEmailNotVerifiedModalOpen(true);
          setAsyncError('');
        } else if (e.response?.data?.message === 'Incorrect user role') {
          navigate(routes.walletSignInRequired);
        } else {
          setAsyncError(errorMapping[errorMessage] || '');
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleCloseEmailNotVerifiedModal = React.useCallback(() => {
    setIsEmailNotVerifiedModalOpen(false);
    navigate(routes.noWalletSignIn);
  }, [navigate]);

  const handleResendEmailConfirmation = React.useCallback(async () => {
    try {
      await signUpLink.emailConfirmationControllerCreate(formik.values.email.toLowerCase());
      setIsEmailNotVerifiedModalOpen(false);
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || '';
      setResendEmailConfirmationErrorMsg(errorMessage);
    }
  }, [formik.values]);

  return (
    <SignInLayout titleTKey={'common.forgotPassword'}>
      <EmailNotVerified
        open={isEmailNotVerifiedModalOpen}
        handleClose={handleCloseEmailNotVerifiedModal}
        title={t('common.yourEmailIsNotVerified')}
        secondaryText={t('common.checkYourInboxKid')}
        secondaryButtonConfig={{ text: t('common.resendEmailConfirmation'), onClick: handleResendEmailConfirmation }}
        errorMessage={resendEmailConfirmationErrorMsg}
      />
      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
        <Box sx={sx.inputsContainer}>
          <OutlinedTextInput
            sx={{ mb: 2.5 }}
            required={true}
            name="email"
            id="email"
            type="email"
            autoComplete="email"
            label={t('forms.email')}
            error={!!((formik.touched.email && formik.errors.email) || asyncError)}
            helperText={formik.touched.email && t(formik.errors.email as string)}
          />
          {!!asyncError && <Box sx={sx.errorContainer}>{t(`forms.errors.${asyncError}`)}</Box>}
          <Box sx={sx.submitContainer}>
            <AsyncButton isLoading={isSubmitting} type="submit" variant="contained" sx={sx.submit}>
              {t('common.submit')}
            </AsyncButton>
          </Box>
        </Box>
      </form>
    </SignInLayout>
  );
});

const useForgotPasswordValidationSchema = () => {
  return React.useMemo(() => {
    return Yup.object().shape({
      email: Yup.string().trim().required('forms.errors.fieldRequired').email('forms.errors.emailInvalid'),
    });
  }, []);
};

const sx: Record<string, SxProps<Theme>> = {
  subtitle: {
    mt: 3.75,
    fontWeight: 700,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    display: 'block',
    span: {
      display: 'block',
    },
  },
  inputsContainer: {
    width: { sm: 600, xs: '100%' },
    m: '0 auto',
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    mt: 5,
  },
  submitContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  submit: { my: 3.75 },
  errorContainer: {
    alignSelf: 'stretch',
    textAlign: 'center',
    bgcolor: (theme) => alpha(theme.palette.error.main, 0.2),
    color: 'error.main',
    p: 1,
    borderRadius: '4px',
    mt: 2.5,
  },
};
