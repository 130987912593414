import React from 'react';
import { Box, Divider } from '@mui/material';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtOwnerSaleItem } from 'pages/Art/ArtTabs/ArtOwnerSaleItem/ArtOwnerSaleItem';
import type { ArtOwner, BuyNow } from 'codegen-api/api-totemo-service/models';
import { ArtOwnerInfoItem } from 'pages/Art/ArtTabs/ArtOwnerInfoItem';
import { useAppState } from 'contexts/appState/AppContext';
import { ArtOwnerPrimarySaleItem } from 'pages/Art/ArtTabs/ArtOwnerSaleItem/ArtOwnerPrimarySaleItem';

export const ArtOwners: React.FC = React.memo(() => {
  const { state } = useArtDetailedView();
  const { currentUser } = useAppState();

  if (!state.art) return null;

  const ownersMap = state.art.owners?.reduce((acc: Record<string, ArtOwner>, item: ArtOwner) => {
    acc[item.ownerWallet] = item;
    return acc;
  }, {});

  const buyNowGroupByOwner = state.art.buyNows?.reduce((acc: Record<string, BuyNow[]>, item: BuyNow) => {
    const ownerWallet = item.ownerWallet;
    if (!acc[ownerWallet]) {
      acc[ownerWallet] = [];
    }
    acc[ownerWallet].push(item);
    return acc;
  }, {});

  const ownersCopiesOnSaleMap: Record<string, number> = {};

  Object.keys(buyNowGroupByOwner).forEach((ownerWallet) => {
    ownersCopiesOnSaleMap[ownerWallet] = buyNowGroupByOwner[ownerWallet].reduce((acc: number, item: BuyNow) => {
      acc += item.amount;
      return acc;
    }, 0);
  });

  const ownersWithNotForSaleCopies = state.art.owners?.filter(
    (item: ArtOwner) => item.amount > (ownersCopiesOnSaleMap[item.ownerWallet] || 0),
  );

  const nonPrimaryBuyNows = state.art.buyNows.filter((buyNow: BuyNow) => !buyNow.isFirstSale);
  const primaryBuyNow = state.art.buyNows.find((buyNow: BuyNow) => buyNow.isFirstSale);

  return (
    <>
      {primaryBuyNow && (
        <Box>
          <ArtOwnerPrimarySaleItem artOwner={ownersMap[primaryBuyNow.ownerWallet]} buyNow={primaryBuyNow} />
        </Box>
      )}
      {primaryBuyNow && (nonPrimaryBuyNows.length || ownersWithNotForSaleCopies.length) ? (
        <Box my={2.5}>
          <Divider />
        </Box>
      ) : null}
      <Box>
        {nonPrimaryBuyNows.map((item: BuyNow) => (
          <ArtOwnerSaleItem buyNow={item} artOwner={ownersMap[item.ownerWallet]} key={item.id} />
        ))}
      </Box>
      <Box>
        {ownersWithNotForSaleCopies.map((item: ArtOwner) => (
          <ArtOwnerInfoItem
            currentUserWallet={currentUser.wallet}
            notForSaleCount={item.amount - (ownersCopiesOnSaleMap[item.ownerWallet] || 0)}
            artOwner={ownersMap[item.ownerWallet]}
            key={item.id}
          />
        ))}
      </Box>
    </>
  );
});
