import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { SxProps, Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { Art, ArtBodyTokenTypeEnum } from 'codegen-api/api-totemo-service/models';
import { useApiContext } from 'contexts/ApiContext';
import { NonMovableContainer } from 'components/UiKit/NonMovableContainer/NonMovableContainer';
import { routes } from 'app-settings';
import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown';
import { DownBaseModal } from 'components/Modal/DownBaseModal';
import { useSingleContract } from 'abi/useSingleContract';
import { useMultipleContract } from 'abi/useMultipleContract';
import { useAppState } from 'contexts/appState/AppContext';


const AdminPanelGifts: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const api = useApiContext();
  const [arts, setArts] = useState<Art[]>([]);
  const [giftArt, setGiftArt] = useState<Art | null>(null);
  const [recipientWallet, setRecipientWallet] = useState<string>('');
  const [walletError, setWalletError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAppState();

  const theme = useTheme();
  const {
    transferFrom: transferFromSingle,
  } = useSingleContract();
  const {
    transferFrom: transferFromMultiple,
  } = useMultipleContract();

  useEffect(() => {
    api.art
      .artControllerFindAll(undefined, undefined, undefined, true)
      // @ts-ignore
      .then((res) => setArts((res.data.items as Art[]) || []))
      .catch();
  }, [api.art]);


  const sendGift = useCallback(async () => {
    if (!giftArt) return;

    if (!recipientWallet) {
      return setWalletError(true);
    }
    setWalletError(false);
    setIsLoading(true);

    try {
      if (giftArt.tokenType === ArtBodyTokenTypeEnum.ERC721) {
        await transferFromSingle(currentUser.wallet, recipientWallet, giftArt.tokenId);
      } else {
        await transferFromMultiple(currentUser.wallet, recipientWallet, giftArt.tokenId);
      }
      setGiftArt(null);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);

  }, [recipientWallet, giftArt]);

  if (!arts.length) return <Box sx={sx.emptyTab}>{t('adminPanel.tabGiftsEmpty')}</Box>;

  return (
    <>
      <Box component="ul" sx={sx.list}>
        {arts.map((work) => {
          return (
            <Box key={work.id} sx={sx.work} data-id={work.id}>
              <NonMovableContainer sx={sx.nonMovable}>
                <Avatar sx={sx.preview} alt={work.name} src={work.artImage.url} variant="rounded" />
                <Box sx={sx.artName} component={Link} to={`${routes.art}/${work.id}`}>
                  {work.name}
                </Box>
                <Button sx={sx.sendBtn} onClick={() => setGiftArt(work)}>
                  <Typography fontWeight={600} fontSize={14}>{t('common.send')}</Typography>
                  <ArrowCircleDown sx={sx.sendIcon} />
                </Button>
              </NonMovableContainer>
            </Box>
          );
        })}
        <DownBaseModal
          isShowing={!!giftArt}
          handleClose={() => setGiftArt(null)}
        >
          <Box sx={sx.sendGiftModal}>
            <Typography fontWeight={900} fontSize={24}>{t('sendAsGift.title')}</Typography>
            <Box>
              <Trans
                i18nKey={'sendAsGift.description'}
                components={[
                  <Typography color={theme.palette.grey[700]} component="span" />,
                  <Typography
                    component="span"
                    fontWeight={600}
                    color="black" />,
                ]}
                values={{
                  giftName: giftArt?.name,
                }}
              />
            </Box>
            <Box>
              <Typography fontWeight={600} fontSize={14}>{t('sendAsGift.walletInputLabel')}</Typography>
              <TextField
                value={recipientWallet}
                onChange={(e) => setRecipientWallet(e.target.value)}
                error={walletError}
                helperText={walletError && t('sendAsGift.emptyWallet')}
              />
            </Box>
            <Box sx={sx.actionButtons}>
              <Button
                onClick={sendGift}
                variant="contained"
                disabled={isLoading}
              >
                {isLoading ? t('sendAsGift.sending') : t('sendAsGift.sendAGift')}
              </Button>
              <Button onClick={() => setGiftArt(null)}>{t('common.cancel')}</Button>
            </Box>
          </Box>
        </DownBaseModal>
      </Box>
    </>
  );
});

export default AdminPanelGifts;

const sx: Record<string, SxProps<Theme>> = {
  emptyTab: {
    color: 'grey.600',
    mt: 5,
    textAlign: 'center',
  },
  list: {
    my: 0.5,
    px: 0,
    listStyleType: 'none',
  },
  nonMovable: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    height: '100%',
  },
  work: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    py: 1.25,
    bgcolor: 'background.default',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: 'grey.300',
  },
  icon: {
    color: 'grey.600',
    fontSize: 0,
    p: 0,
    flex: '0 0 auto',
    mr: 1.5,
  },
  preview: {
    mr: 1.5,
    flex: '0 0 auto',
  },
  artName: {
    fontWeight: 400,
    mr: 1.5,
    flexGrow: 1,
    color: 'inherit',
    textDecoration: 'none',
  },
  sendBtn: {
    display: 'flex',
    gap: 1,
    p: 0,
    minWidth: '54px',
  },
  sendIcon: {
    transform: 'rotate(-90deg)',
    width: '16px',
    height: '16px',
  },
  sendGiftModal: {
    p: '40px 30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  actionButtons: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    pb: '10px',
    mt: '30px',
  },
  balanceInfo: {
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: theme => theme.palette.grey[300],
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    py: '10px',
  },
  balanceField: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
