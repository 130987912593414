import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArtMoneyDistributionSx as sx } from './ArtMoneyDistributionSx';
import { PrimarySaleData, SecondarySaleData } from 'types';

type Props = {
  moneyDistribution: {
    primarySale: PrimarySaleData;
    secondarySale: SecondarySaleData;
  };
};

export const ArtMoneyDistributionAuthor: React.FC<Props> = React.memo(({ moneyDistribution }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h3">{t('artPage.moneyDistribution')}</Typography>
      <Box display={'flex'} gap={2} mt={2.5}>
        <Box flex="1 1 50%">
          <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600}>
            {t('artPage.primarySale')}
          </Typography>
          <Box sx={sx.distributionBox}>
            <Typography variant="paragraphMedium" color={'primary.accent'}>
              {t('artPage.artist')}
            </Typography>
            <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
              {moneyDistribution.primarySale.artistPercentage}%
            </Typography>
          </Box>
        </Box>
        <Box flex="1 1 50%">
          <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600}>
            {t('artPage.secondarySale')}
          </Typography>
          <Box sx={sx.distributionBox}>
            <Typography variant="paragraphMedium" color={'primary.accent'}>
              {t('artPage.artist')}
            </Typography>
            <Typography variant="paragraphMedium" color={'primary.accent'} fontWeight={600} mt={1.25} component="div">
              {moneyDistribution.secondarySale.artistPercentage}%
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
