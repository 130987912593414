import React from 'react';
import { appReducer } from 'contexts/appState/appReducer';
import type { AppAction, AppDispatch, AppProviderProps, AppState, CurrentUserState } from 'contexts/appState/types';
import { localStorageKeys } from 'app-settings';
import { UserRole } from 'types';

const AppStateContext = React.createContext<AppState | undefined>(undefined);
const AppDispatchContext = React.createContext<AppDispatch | undefined>(undefined);

export const EMPTY_ROLE: UserRole = {
  id: -1,
  created: '',
  updated: '',
  artistWallet: '',
  userWallet: '',
  userId: -1,
  role: 'GUEST',
};

export const EMPTY_USER: CurrentUserState = {
  artistTotalSale: 0,
  sub: undefined,
  isFeatured: false,
  order: -1,
  id: -1,
  created: '',
  updated: '',
  username: '',
  fullName: '',
  email: '',
  password: '',
  // defaults to null on BE, but empty string on FE (and compares currentUser wallet to empty string on FE)
  wallet: '',
  nonce: 0,
  currencyType: 'USD',
  pending: '',
  role: [EMPTY_ROLE],
  settings: {
    avatar: '',
    background: '',
    podcast: [],
    video: [],
    social: [],
    shopifyCollections: [],
  },
  arts: [],
  bio: '',
  availableInvites: 0,
  totalInvited: 0,
  phoneNumber: '',
};

const initializerArg: AppState = {
  currentUser: EMPTY_USER,
  exchangeRate: {
    timestamp: 0,
  },
  isWrongNetworkOpen: false,
};

const initializer = (initializerArg: AppState): AppState => {
  try {
    const profileString = localStorage.getItem(localStorageKeys.profile);

    if (!profileString) return initializerArg;

    const profile = JSON.parse(profileString);

    if (profile.sub) {
      profile.id = profile.sub;
    }

    return { ...initializerArg, currentUser: profile };
  } catch (e) {
    return initializerArg;
  }
};

// Learn more in https://kentcdodds.com/blog/how-to-use-react-context-effectively http://kcd.im/optimize-context
function AppStateProvider({ children }: AppProviderProps) {
  const [state, dispatch] = React.useReducer<(state: AppState, action: AppAction) => AppState, AppState>(
    appReducer,
    initializerArg,
    initializer,
  );

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppStateProvider');
  }
  return context;
}

function useApp(): [AppState, AppDispatch] {
  return [useAppState(), useAppDispatch()];
}

export { AppStateProvider, useAppState, useAppDispatch, useApp };
