import React from 'react';
import { useApiContext } from 'contexts/ApiContext';
import { socketNotificationTypes } from 'app-settings';

export const useSocketPurchaseConfirmCallback = (callback: () => void, artId?: number) => {
  const { socket } = useApiContext();
  React.useEffect(() => {
    const notificationListener = async (messageString: string) => {
      if (!artId) return;
      try {
        const message = JSON.parse(messageString);
        const { eventType } = message;
        if (![socketNotificationTypes.buyNow, socketNotificationTypes.finalize].includes(eventType)) return;
        const data: Record<string, any> = JSON.parse(message.data);
        if (data.artId === artId) {
          callback();
        }
      } catch (e) {}
    };

    socket!.on('notification', notificationListener);

    return () => {
      socket!.off('notification', notificationListener);
    };
  }, [socket, callback, artId]);
};
