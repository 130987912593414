import React from 'react';
import { Box, SxProps } from '@mui/material';

const homePageStyles: SxProps = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

interface Props {
  children: React.ReactNode;
}

export const BaseLayout: React.FC<Props> = ({ children }) => {
  return <Box sx={homePageStyles}>{children}</Box>;
};
