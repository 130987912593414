import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { Art } from 'codegen-api/api-totemo-service/models';
import { useAvailableCopies } from 'hooks/useAvailableCopies';

export const ArtAvailableCopies: React.FC<{ art: Art }> = React.memo(({ art }) => {
  const { t } = useTranslation();

  const count = useAvailableCopies(art);

  if (count === null) return null;

  return (
    <Typography color="primary.main" variant="paragraphMedium" mt={1.25} component="div">
      {t('artPage.availableCopies', { count, total: art.amount })}
    </Typography>
  );
});
