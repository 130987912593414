import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import useTopArtistsPagination from 'hooks/useTopArtistsPagination';
import { getTotalSale } from 'helpers/user';
import useIsMobileScreen from 'helpers/useIsMobileScreen';

const TopArtists: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileScreen = useIsMobileScreen();

  const { artists } = useTopArtistsPagination(isMobileScreen ? 9 : 12);

  const goToTopArtistsPage = () => {
    navigate(routes.topArtists);
  };

  return (
    <Container sx={sx.container}>
      <Box sx={sx.header}>
        <Typography variant="h2" sx={sx.headerText}>
          {t('topArtists.title')}
        </Typography>
        <Typography variant="body1" sx={sx.headerViewAll} onClick={goToTopArtistsPage}>
          {t('common.viewAll')}
        </Typography>
      </Box>

      <Divider sx={sx.divider} />

      <Box sx={sx.artistList}>
        {artists.map((user, index) => {
          const openArtistProfile = () => navigate(`${routes.userProfile}/${user.wallet}`);
          return (
            <Box key={user.id} sx={sx.artistBox}>
              <Typography sx={sx.artistNumber} variant="subtitle1" onClick={openArtistProfile}>
                #{index + 1}
              </Typography>

              <Avatar
                onClick={openArtistProfile}
                sx={sx.preview}
                alt={'username'}
                src={user.settings?.avatar?.url}
                variant="circular"
              />

              <Box>
                <Typography onClick={openArtistProfile} variant="body2" sx={sx.artistName}>
                  {user.username}
                </Typography>
                <Typography onClick={openArtistProfile} variant="body2" sx={sx.artistValue}>
                  {getTotalSale(user.artistTotalSale)}Ξ
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
});

export default TopArtists;

const sx: Record<string, SxProps<Theme>> = {
  container: {
    boxSizing: 'border-box',
    padding: '0 0 0 20px',
    mb: 4,
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    pb: 2,
    pr: 2.5,
  },
  divider: {
    mr: 2.5,
  },
  headerText: {
    lineHeight: 1,
  },
  headerViewAll: {
    lineHeight: 1,
    cursor: 'pointer',
  },
  artistList: theme => ({
    padding: '20px 0',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: '70px 70px 70px',
    gridTemplateColumns: '280px 280px 280px',
    overflowY: 'hidden',
    overflowX: 'auto',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  }),
  artistBox: {
    width: 280,
    pr: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  artistNumber: {
    width: '100%',
    mr: 2.25,
    color: 'grey.700',
    fontSize: 18,
    cursor: 'pointer',
  },
  artistName: {
    color: 'black',
    fontSize: 18,
    fontWeight: 600,
    width: 145,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  artistValue: {
    color: 'grey.700',
    fontSize: 16,
    width: 145,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  preview: {
    mr: 2.25,
    width: 60,
    height: 60,
    cursor: 'pointer',
  },
};
