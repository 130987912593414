import React from 'react';
import { Box, CardMedia } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface VideoBoxProps {
  src: string;
  className?: string;
  controls?: boolean;
  voluble?: boolean;
}

const VideoBox: React.FC<VideoBoxProps> = (props) => {
  return (
    <Box sx={sx.box}>
      <CardMedia
        className={props.className ? props.className : `video`}
        component="video"
        src={props.src}
        autoPlay
        loop
        playsInline
        muted={!props.voluble}
        controls={props.controls}
      />
      <img alt="video" src="/assets/images/video.png" className="video-icon" />
    </Box>
  );
};

export default VideoBox;

const sx: Record<string, SxProps<Theme>> = {
  box: {
    position: 'relative',
    width: '100%',
    '& .video': {
      width: '100%',
      objectFit: 'contain',
      display: 'block',
    },
    '& .video-icon': {
      width: 30,
      height: 30,
      position: 'absolute',
      right: 10,
      top: 10,
    },
  },
};
