export const en = {
  adminPanel: {
    pageName: 'Admin panel',
    usersTitle: 'Users',
    createdArtsTitle: 'Created arts',
    finalizeAuctionTitle: 'Finalize auction',
    eventsTitle: 'Events',
    featuredArtistTitle: 'Featured artist',
    moneyDistributionTitle: 'Money distribution',
    accessRequestsTitle: 'Access Requests',
    tabPrimarySale: 'Primary sale',
    moneyDistributionArtist: 'Artist',
    moneyDistributionSeller: 'Seller',
    moneyDistributionTotemo: 'Totemo',
    moneyDistributionOptionalAddress: 'Optional address',
    moneyDistributionOptionalAddressSub: 'eg. wall/hotel other business',
    moneyDistributionServiceFee: 'Service fee',
    moneyDistributionServiceFeeSub: 'totemo.art',
    moneyDistributionServiceFeeSecondarySub: 'if secondary sale is sold on totemo.art',
    moneyDistributionEditPrimaryTitle: 'Primary sale',
    moneyDistributionEditSecondaryTitle: 'Secondary Sale',
    includingOptionalAddress: 'Including optional address',
    tabSecondarySale: 'Secondary sale',
    usersSearchPlaceholder: 'Nickname, full name, e-mail or wallet',
    usersNoResultsTitle: 'No results',
    usersNoResultsText: 'There are no users for "{{value}}"',
    viewFullProfile: 'View full profile',
    authArtist: 'Authenticate artist',
    proposeRightsDelegations: 'Propose rights delegation',
    pending: 'Pending...',
    createArt: 'Create art',
    addFeaturedArtistBtn: 'Add featured artist',
    addToFeaturedTitle: 'Add featured artist',
    addToFeaturedSearchPlaceholder: 'Nickname or full name',
    authenticateArtistBtn: 'Authenticate artist',
    tabPublished: 'Published',
    tabPublishedEmpty: "Your artists don't have a primary sale",
    tabDraftsEmpty: "Your artists don't have a draft",
    tabExploreEmpty: "Your artists don't have a promo",
    tabGiftsEmpty: "Your artists don't have gifts",
    artworksWithCount_one: '{{count}} artwork',
    artworksWithCount_other: '{{count}} artworks',
    publish: 'Publish to Explore',
    moveToDrafts: 'Move to drafts',
    tabExplore: 'Explore',
    tabGifts: 'Gifts',
    tabDrafts: 'Drafts',
    createEventBtn: 'Create event',
    createEventTitle: 'Create event',
    reorderTitle: 'Reorder',
    eventInformation: 'Event information',
    nobodyIsFeatured: 'Nobody is featured',
    auction: 'Auction',
    buyNow: 'Buy now',
    noResultsAddFeaturedArtist: 'There are no artists for "{{artistName}}"',
    noUser: 'Select an User to view details',
  },
  signIn: {
    totemoDescription: 'TOTEMO is the world’s leading street art digital platform',
    totemoPerks: [
      'providing <strong>permanency</strong> to artworks',
      '<strong>accessibility</strong> to collectors',
      'a <strong>revenue stream</strong> to artists',
    ],
    connectWithoutWalletBtn: 'Connect without wallet',
    warnTitle: 'Access to marketplace via invitation link only',
    warnDescription:
      '<1>If you are an artist and want to create your NFTs</1> request access <1>or</1> contact us via email <0>artist@totemo.art</0>',
    downloadMetamask: 'If you don’t have a MetaMask wallet, click <0>here</0> to download',
    featuredArtists: 'Featured artists',
    lookInside: 'Just wanna look inside?',
    learnMore: 'Do you want to learn more about us?',
  },
  signUp: {
    pageTitle: 'Create a totemo account',
    readTerms: 'I have read and agree to <0>Terms of Services</0> and <1>Privacy Policy</1>.',
    submit: 'Sign Up',
    description: 'This account type gives you an opportunity to buy and sell NFTs. ',
    descriptionEmphasized:
      'If you are an artist and want to create your NFTs contact us via email <0>totemo@totemo.com</0> after sign up.',
  },
  welcomePage: {
    createNewAccount: 'Create new account',
    alreadyHaveAnAccount: 'Already have an account?',
    signIn: 'Sign In',
    signInWithWallet: 'Sign in with wallet',
    signInWithoutWallet: 'Sign in without wallet',
  },
  signInWithoutWallet: {
    title: 'Sign in without wallet',
    description:
      'Totemo allows you to buy and sell NFTs. Be sure to connect your crypto wallet later to get into the action.',
    descriptionEmphasized:
      'If you are an artist and want to create your NFTs contact us via email <0>artist@totemo.art</0> after sign up.',
    notInvitedTitle: 'Your account is not invited',
    notInvitedDescription: 'Looks like you don’t have a valid invite link. Try again or sign up for the waiting list.',
    tryAgainBtn: 'Try again',
  },
  requestAccess: {
    title: 'Request access',
  },
  emailVerification: {
    title: 'Email verification in progress...',
    footerText:
      'We are the world’s first NFT auction house dedicated exclusively to graffiti & street artists. Find the greatest in curated street talent here.',
  },
  header: {
    signIn: 'Sign In',
    openSettingsTooltip: 'Open settings',
    primaryCurrency: 'Primary currency',
    myProfile: 'My Profile',
    adminPanel: 'Admin panel',
    marketplace: 'Marketplace',
    events: 'Events',
    artists: 'Artists',
    explore: 'Explore',
    faq: 'FAQ',
    connectWallet: 'Connect Wallet',
  },
  footer: {
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    faq: 'FAQ',
    contactUs: 'Contact us',
    copyright: 'TOTEMO 2023, Inc. All rights reserved.',
  },
  forms: {
    email: 'Email',
    username: 'Nickname',
    password: 'Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    repeatPassword: 'Repeat password',
    usernameEdit: 'Username',
    fullName: 'Full name',
    bio: 'Bio',
    linkLabel: 'Link',
    nameLabel: 'Name',
    chooseFile: 'Choose file',
    eventImageNote: 'PNG or JPEG, 2048x1330 size \n recommended',
    name: 'Name',
    location: 'Location',
    link: 'Link',
    artist: 'Artist',
    seller: 'Seller',
    totemo: 'Totemo',
    serviceFee: 'Service Fee',
    optionalAddress: 'Optional address',
    optionalAddressPercent: 'Optional address %',
    resetToDefault: 'Reset to default',
    forgotPassword: 'Forgot password?',
    errors: {
      somethingWentWrong: 'Something went wrong',
      emailInvalid: 'Email format is incorrect.',
      usernameMinLength: 'Nickname must be minimum 2 characters',
      fieldRequired: 'Yo, you gotta fill this in!',
      fieldUnsupportedCharacters:
        "That's dope that you know a language we don't, but please use only english letters, 0-9 and no spaces so we can understand.",
      fieldMaxLength: 'Cannot compute 👀 Try entering something less than {{length}} characters.',
      fieldMaxLengthTags: 'Cannot compute 👀 Try entering something less than {{length}} tags.',
      urlFormat: 'Link format is incorrect',
      minValue: 'Min. value is {{value}}.',
      maxValue: 'Max. value is {{value}}.',
      minCopiesValue: 'The minimal amount of copies is 2',
      positiveOnly: 'Only positive number.',
      integersOnly: 'Integers only',
      artistTotemoSeller100: 'Artist+Totemo+Seller must be equal 100%',
      artistTotemoOptionalAddress100: 'Artist+Totemo+Optional Address must be equal 100%',
      artistTotemo100: 'Artist+Totemo+Optional Address must be equal 100%',
      fileFormatIncorrect: `Let's try that again! File could be: {{format}}. Max {{maxSize}} Mb.`,
      addressInvalid: 'Address format is incorrect.',
      // TODO: use correct message
      bidIsTooLow: 'Your bid is too low',
      subscriberExist: 'Email has already been taken',
      notUniqueEmail: '{{field}} email is already taken. Get creative!',
      notUniqueUsername: '{{field}} username is already taken. Get creative!',
      wrongOne: 'Wrong nickname or email',
      userNotFound: 'User not found: maybe you are using the wrong email or you need to create an account',
      passwordMustContain:
        'Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character (!#$%&*+,-.:;?@^_~)',
      mustMatchNewPassword: 'Must match new password',
      mustNotBeOldPassword: 'New password should differ from the old one',
      wrongPassword: 'Wrong password',
      wrongUserRole: 'Wrong user role',
      requestNotFound: 'Request not found',
      yoCheckYourInbox: 'Yo, check your inbox',
    },
  },
  userProfileCard: {
    authBtn: 'Authenticate artist',
    viewProfileBtn: 'View full profile',
  },
  profilePage: {
    userWantsToBeYourPM: '@{{nickname}} wants to become your personal manager',
    pleaseSignTransaction: 'Please, sign transaction in your wallet.',
    confirmDelegation: 'Confirm delegation',
    changeCoverBtn: 'Change cover',
    editProfileBtn: 'Edit Profile',
    seeAllLnk: 'See All',
    seeLess: 'See Less',
    videosHeading: 'Videos',
    videoHeading: 'Video',
    podcastsHeading: 'Podcasts',
    podcastHeading: 'Podcast',
    marketValueLabel: 'Market value',
    noData: 'no data',
    socialNetLabel: 'Social Network Links',
    uploadCover: 'Upload cover',
    selectFile: 'Select file',
    changeAvatar: 'Change avatar',
    imageRequirements: 'We recommend square cropped jpg, png.',
    website: 'Website',
    instagram: 'Instagram',
    twitter: 'Twitter',
    facebook: 'Facebook',
    tiktok: 'TikTok',
    discord: 'Discord',
    snapchat: 'Snapchat',
    youtube: 'YouTube',
    clubhouse: 'Clubhouse',
    podcastLinks: 'Podcast links',
    videoLinks: 'Video links',
    nothingToDisplay: 'Nothing to display here',
    ownedTab: 'Owned',
    creationsTab: 'Creations',
    activityTab: 'Activity',
    invitationsLeft_one: '<0>{{count}}</0> <1>Invitation</1>',
    invitationsLeft_other: '<0>{{count}}</0> <1>Invitations</1>',
    invited_one: '<0>{{count}}</0> <1>Invited friends</1>',
    invited_other: '<0>{{count}}</0> <1>Invited friends</1>',
    invite: 'Invite friends',
    linkCopied: 'Link copied!',
    shopifyCollections: 'Shopify collections',
    merchTitle: 'Merch',
  },
  common: {
    submit: 'Submit',
    roleGuest: 'Guest',
    roleVisitor: 'Visitor',
    roleCollector: 'Collector',
    roleSuperAdmin: 'Super staff',
    roleArtist: 'Artist',
    rolePersonalManager: 'Personal manager',
    owner: 'Owner',
    delete: 'Delete',
    add: 'Add',
    metamask: 'Metamask',
    areYouSure: 'Are you sure?',
    allWillBeLost: 'all changes will be lost',
    continue: 'Continue',
    confirm: 'Confirm',
    cancel: 'Cancel',
    readMore: 'Read more',
    readLess: 'Read less',
    edit: 'Edit',
    link: 'Link',
    id: 'Id',
    save: 'Save',
    apply: 'Apply',
    eventsTitle: 'Events',
    tags: 'Tags',
    name: 'Name',
    viewMore: 'View more',
    deleteArt: 'Delete art?',
    artWasDeleted: 'Art was deleted',
    noResults: 'No results',
    sortBy: 'Sort by',
    oldestToNewest: 'Oldest to newest',
    newestToOldest: 'Newest to oldest',
    afterPublish: 'Right after publish',
    specificDate: 'Pick specific date',
    loadMore: 'Load more',
    viewAll: 'View all',
    walletAlreadyInUse: 'This wallet is already used in another account',
    walletAlreadyInUseSubtitle:
      'Please try to switch the wallet in Metamask and try with another one, or return to sign in page and use this wallet.',
    returnToSignIn: 'Return to Sign in',
    tryAnotherWallet: 'Try another wallet',
    pleaseCheckYourInboxToVerifyYourRegistration: 'Please check your inbox to verify your registration',
    yourEmailIsNotVerified: "Your email ain't verified",
    checkYourInboxKid: 'Check your inbox, kid!!!',
    resendEmailConfirmation: 'Re-send Email confirmation',
    goToHome: 'Go to Home page',
    signOut: 'Sign Out',
    connect: 'Connect',
    signMessageBtn: 'Sign message',
    connectWallet: 'Connect wallet',
    or: 'or',
    time: {
      days: 'days',
      hours: 'hours',
      minutes: 'minutes',
      seconds: 'seconds',
    },
    send: 'Send',
    changePassword: 'Change password',
    forgotPassword: 'Forgot password',
  },
  pages: {
    createArt: {
      createArt: 'Create art',
      createSingleArt: 'Create single art',
      editSingleArt: 'Edit single art',
      createMultipleArt: 'Create multiple art',
      editMultipleArt: 'Edit multiple art',
      createArtNote:
        'A Single Art is created in the quantity of only one. A Multiple Art is created in an unlimited amount of copies.',
      single: 'Single',
      multiple: 'Multiple',
      backToAdminPanel: 'Back to the admin panel',
      chooseFile: 'Choose file',
      chooseCoverImageFileLabel: 'Choose cover image',
      chooseFileNote: 'MP4, PNG, JPG \n Max 100Mb',
      chooseImageFileNote: 'PNG, JPG \n Max 100Mb',
      chooseFileErrorText: 'File could be: MP 4, PNG, JPG \n Max 100Mb',
      nftInformation: 'NFT information',
      title: 'Title',
      description: 'Description',
      location: 'Location',
      tags: 'Tags',
      next: 'Next',
      nextDescription: 'You can save as drafts or publish to explore on the next page',
      putOnMarketplace: 'Put on marketplace',
      buyNow: 'Buy now',
      auction: 'Auction',
      financeConditions: 'Finance conditions',
      price: 'Price',
      startingPrice: 'Starting price',
      duration: 'Duration',
      draft: 'Save as draft',
      promo: 'Publish to Explore',
      publish: 'Publish',
      serviceFee: 'Service fee',
      finalPrice: 'Final price',
      startDate: 'Start date',
      endDate: 'End date',
      amountOfCopies: 'Amount of copies',
      copies: 'Copies',
      primarySale: 'Primary sale',
      secondarySale: 'Secondary sale',
      goToArt: 'Go to art page',
      artPublished: 'Art successfully published!',
      moneyDistribution: 'Money distribution',
      setMoneyDistribution: 'Set money distribution',
      artist: 'Artist',
      totemo: 'Totemo',
      seller: 'Seller',
      includingOptionalAddress: 'Including optional address',
      optionalAddress: 'Optional address',
      optionalAddressPercent: 'Optional address percent',
      eventName: 'Event name',
    },
    marketplace: {
      title: 'Marketplace',
      filterBy: 'Filter by',
      filterWithCount_one: 'Clear {{count}} filter',
      filterWithCount_other: 'Clear {{count}} filters',
      art_one: '{{count}} art',
      art_other: '{{count}} arts',
      events: 'Events',
      artist: 'Artist',
      artistName: 'Artist Name',
      priceRange: 'Price Range',
      tags: 'Tags',
      visitArtStudio: 'Visit artstudio',
      justSold: 'Just sold',
    },
  },
  homePage: {
    description:
      'We are the world’s first NFT auction house dedicated exclusively to graffiti & street artists. Find the greatest in curated talent here.',
    event: 'EVENT',
    curate: 'CURATE',
    auction: 'AUCTION',
    upcomingEvents: 'Events',
    featuredArtist: 'Featured Artist',
    explore: 'Explore',
    newsLettersTitle: 'Subscribe to our newsletter',
    newsLettersTitleDesc: 'Sign-up to be the first to learn about our upcoming events and NFT drops',
    subscribeBtn: 'Subscribe',
    confirmSubscription: "You've been subscribed",
    thankYou: 'Thank you!',
    featuredIn: 'Featured in',
    buyingStreetArtFaqHeader: 'Buying street art FAQ',
    faqQuestionFirst: 'What does it mean to purchase street art? What exactly am I buying?',
    faqAnswerFirst:
      'By its very nature, graffiti and street art is confined to a single geographical location and is often quite temporary, being painted over by municipalities, destroyed by the elements, etc. Totemo represents a new way to purchase street art as NFTs (non-fungible tokens).\n' +
      'NFTs are basically digital receipts that confer ownership of something (in this case, a specific piece of work by a street artist) to a specific collector. While multiple images of any given piece of street art might exist online, owning the NFT provides proof that you have ownership of the piece as authorized by the artist. NFTs can be resold or traded to other collectors in the same way as physical art, baseball cards or any other collectible.',
    faqQuestionSecond: 'How do pieces of street art become NFTs?',
    faqAnswerSecond:
      'Artists collaborate with Totemo staff, our digital designers, our event promoters, etc, to take their original street art pieces and turn them into digital collectible NFTs that live on the Ethereum blockchain.',
    faqQuestionThird: 'How do I purchase street art?',
    faqAnswerThird:
      'On the Totemo marketplace collectors can find original works made for sale by graffiti and street artists. At live Totemo events or even just searching the website, collectors can connect their crypto wallet and purchase auctioned or for-sale pieces via Ethereum.',
    faqQuestionFourth: 'Why would I purchase street art?',
    faqAnswerFourth:
      'Collectors of street art NFTs come in all types. Some people aim to show their love to the scene, some want to support specific artists, and some collect as they believe the assets will accumulate in value and can be sold at a later date for a profit. The cool thing is that no matter what motivates a collector, the act of collecting always supports the artists, as they receive a portion of every transaction (primary and secondary sales).',
  },
  artPage: {
    promo: 'Promo',
    notForSale: 'Not for sale',
    draft: 'Draft',
    price: 'Price',
    location: 'Location',
    event: 'Event',
    follow: 'Follow',
    unfollow: 'Unfollow',
    artist: 'Artist',
    seller: 'Seller',
    totemo: 'Totemo',
    serviceFee: 'Service fee',
    secondaryServiceFeeHint: 'if secondary sale is sold\non totemo.art',
    optionalAddress: 'Optional address',
    primarySale: 'Primary sale',
    secondarySale: 'Secondary sale',
    moneyDistribution: 'Money distribution',
    buyNow: 'Buy now',
    moveToDrafts: 'Move to drafts',
    cancelBuyNow: 'Cancel buy now',
    cancelAuction: 'Cancel auction',
    confirmPurchase: 'Confirm purchase',
    youArePurchasing: 'You are purchasing',
    yourBalance: 'Your balance',
    youPay: 'You pay',
    pending: 'Pending...',
    sellArt: 'Sell art',
    startDate: 'Start date',
    auction: 'Auction',
    winner: 'Winner',
    auctionStatusEnded: 'Ended',
    timeLeft: 'Time left',
    processing: 'Processing...',
    auctionEndsIn: '• Auction ends in',
    latestBid: 'Latest bid',
    startingPrice: 'Starting price',
    placeBidTitle: 'Place a bid',
    placeBidBtn: 'Place a bid',
    youArePlacingBid: 'You are placing a bid for',
    yourBid: 'Your bid',
    minBid: 'Min bid',
    placingBidNote:
      'By placing a bid you confirm that you will have enough tokens on your account if you win the auction.',
    placingBidWarn: 'Please note that the settlement would be done only if you win the auction.',
    finalCheckout: 'Final checkout',
    finalCheckoutBtn: 'Final checkout',
    youWonAuction: 'You won auction for',
    history: 'History',
    owners: 'Owners',
    sellerName: 'by @{{name}}',
    historyMessages: {
      PUBLISHED_BUY_NOW: '<1>@{{username}}</1> <0>created "Buy now" sale</0>',
      PUBLISHED_AUCTION: '<1>@{{username}}</1> <0>created "Auction" sale</0>',
      BUY_721: '<1>@{{username}}</1> <0>purchased with a price of {{price}}Ξ</0>',
      BUY_1155_one: '<1>@{{username}}</1> <0>purchased {{count}} copy with a price of {{price}}Ξ for each</0>',
      BUY_1155_other: '<1>@{{username}}</1> <0>purchased {{count}} copies with a price of {{price}}Ξ for each</0>',
      BID: '<1>@{{username}}</1> {{count}} <0>placed a bid of {{price}}Ξ</0>',
      REVERSE_PRICE_MET: '<1>@{{username}}</1> <0>met reserve price</0>',
      AUCTION_WINNER_FOUND: '<1>@{{username}}</1> <0>won the auction with a bid of {{price}}Ξ</0>',
      FINALIZE_AUCTION: '<1>@{{username}}</1> <0>purchased with a price of {{price}}Ξ</0>',
      NFT_MINTED: '<1>@{{username}}</1> <0>minted token</0>',
      SALE_CANCELED: '<0>The sale was cancelled</0>',
    },
    buyNowStartIn: 'Buy now start in',
    auctionStartIn: 'Auction start in',
    left: 'left',
    availableCopies: '{{count}} of {{total}} available',
    from: 'From',
    buy: 'Buy',
    sell: 'Sell',
    itemsForSale_one: '{{count}} item not for sale',
    itemsForSale_other: '{{count}} items not for sale',
    enterQuantity: 'Enter quantity',
    pendingBannerTitle: 'Woohoo! You got it! Give us a minute to box this up for you.',
    pendingBannerText: 'Don’t close your window just yet!',
    completeYourCheck: 'Complete your check out (via Metamask) within',
    wasMovedNotForSaleTitle: 'Art moved to Not for sale',
    wasMovedNotForSaleDescription: 'You can find it in your profile',
    wasMovedNotForSaleDescriptionAdmin: "You can find it in user's profile",
    wasMovedToDraftsTitle: 'Art moved to Drafts',
    wasMovedToDraftsDescriptionAdmin: 'You can find it in Admin Panel',
    goToProfile: 'Go to Profile',
    goToAdminPanel: 'Go to Admin panel',
    primaryTag: 'Primary {{available}}/{{all}}',
    copiesCount_one: '{{count}} copy',
    copiesCount_other: '{{count}} copies',
    processingLabel: 'Processing',
  },
  secondarySale: {
    title: 'Sell art',
    price: 'Price',
    saleFee: 'Sale fee',
    youWillGet: 'You will get',
    startDate: 'Start date',
    endDate: 'End date',
    financeConditions: 'Finance conditions',
    startingPrice: 'Starting price',
    duration: 'Duration',
    formTitle: 'Put on marketplace',
    publish: 'Publish',
  },
  topArtists: {
    title: 'Top artists',
    artists: '# Artist',
    totalSale: 'Total sale',
  },
  wrongNetwork: {
    title: 'Wrong network',
    description: 'Looks like you connected to unsupported network. Change network to {{chainName}}.',
    close: 'Close',
  },
  notFound: {
    title: 'Page not found',
    subtitle: "Sorry, the page you’re looking for doesn't exist.",
    goToSignUp: 'Go to Sign Up page',
  },
  connectWallet: {
    title: 'Connect a wallet to buy and sell NFTs',
    subTitle: 'Install Metamask to connect to TOTEMO',
    description: 'We do not own your private keys and cannot access your funds without your prior consent.',
    skipForNow: 'Skip for now',
    ifYouSkip: "If you skip a wallet connection you wouldn't be able to buy and sell NFTs",
    connectWalletBtn: 'Connect wallet',
  },
  wrongWallet: {
    title: "Your wallet isn't connected",
    subtitle:
      'Please be sure to join using a connected wallet or use "Sign in without wallet" option if you haven\'t connected one yet',
    tryAnotherWallet: 'Try another wallet',
    description:
      '<0>If you are an artist and want to create your NFTs</0> request access <0>or</0> contact us via email <1>artist@totemo.art</1>',
  },
  walletSignInRequired: {
    title: 'Woops!!!<0></0>Looks like you already have a wallet linked to your account',
    subtitle: 'Connect your existing crypto wallet to log in',
  },
  sendAsGift: {
    title: 'Sending a gift',
    description: '<0>You are sending a gift NFT</0> <1>{{giftName}}</1>',
    walletInputLabel: 'Recipient wallet',
    yourBalance: 'Your Balance',
    gasFee: 'Gas fee',
    youPay: 'You pay',
    sendAGift: 'Send a gift',
    emptyWallet: 'Fill this input',
    sending: 'Sending...',
  },
};
