import React from 'react';
import axios from 'axios';
import { hasTrueField } from 'helpers/hasTrueField';
import { localStorageKeys } from 'app-settings';
import { useLogout } from 'contexts/appState/useLogout';

export function useAuthInterceptorsEffect() {
  const logout = useLogout();

  React.useEffect(() => {
    const authRequestInterceptor = axios.interceptors.request.use(function (config) {
      const jwt = localStorage.getItem(localStorageKeys.jwt);
      if (!jwt) return config;

      if (!hasTrueField(config.headers, 'Authorization')) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${jwt}`,
        };
      }

      return config;
    });

    const authResponseInterceptor = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const { response } = error;

        switch (response?.status) {
          case 401: {
            logout();
            break;
          }
          default:
          // do nothing
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.request.eject(authRequestInterceptor);
      axios.interceptors.response.eject(authResponseInterceptor);
    };
  }, [logout]);
}
