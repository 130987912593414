import React from 'react';
import type { Art } from 'codegen-api/api-totemo-service/models';

interface State {
  art: Art;
  saleType: 'buyNow' | 'auction';
  isPublishing: boolean;
}

const initializer = (art: Art): State => ({
  art,
  saleType: 'buyNow',
  isPublishing: false,
});

export type Action =
  | { type: 'SET_ART'; payload: Art }
  | { type: 'SET_SALE_TYPE'; payload: 'buyNow' | 'auction' }
  | { type: 'STOP_PUBLISHING' }
  | { type: 'START_PUBLISHING' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ART': {
      return { ...state, art: action.payload };
    }
    case 'SET_SALE_TYPE': {
      return { ...state, saleType: action.payload };
    }
    case 'START_PUBLISHING': {
      return { ...state, isPublishing: true };
    }
    case 'STOP_PUBLISHING': {
      return { ...state, isPublishing: false };
    }
    default:
      return state;
  }
};

const SecondarySaleSingleContext = React.createContext<{ state: State; dispatch: React.Dispatch<Action> } | undefined>(
  undefined,
);

interface Props {
  art: Art,
  children: React.ReactNode
}

export const SecondarySaleSingleProvider: React.FC<Props> = ({ children, art }) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>, Art>(reducer, art, initializer);

  return (
    <SecondarySaleSingleContext.Provider value={{ state, dispatch }}>{children}</SecondarySaleSingleContext.Provider>
  );
};

export const useSecondarySaleSingle = () => {
  const context = React.useContext(SecondarySaleSingleContext);
  if (context === undefined) {
    throw new Error('useSecondarySaleSingle must be used within a SecondarySaleSingleContext');
  }
  return context;
};
