export const getStartDate = (timestamp: number): string => {
  if (!!timestamp) {
    return new Date(timestamp)
      .toLocaleDateString('default', {
        day: 'numeric',
        month: 'short',
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
      })
      .replace(',', '');
  }
  return '';
};