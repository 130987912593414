import React from 'react';
import { ReactComponent as WebsiteIcon } from 'components/Icons/website.svg';
import { ReactComponent as InstagramIcon } from 'components/Icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'components/Icons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'components/Icons/facebook.svg';
import { ReactComponent as TikTokIcon } from 'components/Icons/tik_tok.svg';
import { ReactComponent as DiscordIcon } from 'components/Icons/discord.svg';
import { ReactComponent as SnapchatIcon } from 'components/Icons/snapchat.svg';
import { ReactComponent as YoutubeIcon } from 'components/Icons/youtube.svg';
import { ReactComponent as ClubhouseIcon } from 'components/Icons/clubhouse.svg';
import { SvgIcon } from '@mui/material';
import { SocialNetworks } from "../types";

interface SocialNetworksInput {
  label: SocialNetworks,
  icon: JSX.Element
}

export const inputsSocialNetworksConfig: Array<SocialNetworksInput> = [
  {
    label: 'website',
    icon: <SvgIcon component={WebsiteIcon} inheritViewBox fontSize="small" />,
  },
  {
    label: 'instagram',
    icon: <SvgIcon component={InstagramIcon} inheritViewBox fontSize="small" />,
  },
  {
    label: 'twitter',
    icon: <SvgIcon component={TwitterIcon} inheritViewBox fontSize="small" />,
  },
  {
    label: 'facebook',
    icon: <SvgIcon component={FacebookIcon} inheritViewBox fontSize="small" />,
  },
  {
    label: 'tiktok',
    icon: <SvgIcon component={TikTokIcon} inheritViewBox fontSize="small" />,
  },
  {
    label: 'discord',
    icon: <SvgIcon component={DiscordIcon} inheritViewBox fontSize="small" />,
  },
  {
    label: 'snapchat',
    icon: <SvgIcon component={SnapchatIcon} inheritViewBox fontSize="small" />,
  },
  {
    label: 'youtube',
    icon: <SvgIcon component={YoutubeIcon} inheritViewBox fontSize="small" />,
  },
  {
    label: 'clubhouse',
    icon: <SvgIcon component={ClubhouseIcon} inheritViewBox fontSize="small" />,
  },
];
