import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import type { AppRoute } from 'app-settings';
import { routes } from 'app-settings';
import { adminPanelSx } from 'pages/AdminPanel/AdminPanelStyles';

const AdminPanelCreatedArts: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeTab = (e: React.SyntheticEvent, tab: AppRoute) => {
    navigate(tab);
  };

  const currentTab =
    location.pathname === routes.adminPanelCreatedArts ? routes.adminPanelCreatedArtsPublished : location.pathname;

  return (
    <Box m={2.5}>
      <Tabs sx={adminPanelSx.subTabs} value={currentTab} onChange={handleChangeTab} centered>
        <Tab value={routes.adminPanelCreatedArtsPublished} label={t('adminPanel.tabPublished')} />
        <Tab value={routes.adminPanelCreatedArtsExplore} label={t('adminPanel.tabExplore')} />
        <Tab value={routes.adminPanelCreatedArtsGifts} label={t('adminPanel.tabGifts')} />
        <Tab value={routes.adminPanelCreatedArtsDrafts} label={t('adminPanel.tabDrafts')} />
      </Tabs>
      <Outlet />
    </Box>
  );
});

export default AdminPanelCreatedArts;
