import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArtCard } from 'components/ArtCard/ArtCard';
import { TypeArts } from '../../types';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useFetchArtsForTabs } from '../../hooks/useFetchArtsForTabs';
import { useProfileReducerContext } from '../../State/useProfileReducer';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';

interface Wallet {
  wallet: string | undefined;
  artType: TypeArts;
}

export const ArtsTab = ({ wallet, artType }: Wallet) => {
  const { t } = useTranslation();
  const { state, dispatch } = useProfileReducerContext();

  const arts = useFetchArtsForTabs(artType, wallet);

  if (!arts?.length) {
    return (
      <Typography variant={'paragraphLarge'} color={'text.secondary'} component={'p'} mt={6} textAlign={'center'}>
        {t('profilePage.nothingToDisplay')}
      </Typography>
    );
  }

  return (
    <>
      <Box sx={sx.tab}>
        {arts?.map((art) => {
          return <ArtCard art={art} key={art.id} />;
        })}
      </Box>

      {state.currentArtsPage < state.totalPages && (
        <AsyncButton
          isLoading={state.isArtsLoading}
          variant="outlined"
          onClick={() => dispatch({ type: 'FETCH_MORE_ARTS' })}
          sx={sx.loadMoreBtn}
        >
          {t('common.loadMore')}
        </AsyncButton>
      )}
    </>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  tab: (theme) => ({
    width: '100%',
    px: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gap: '30px',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'start',
    },
  }),
  loadMoreBtn: (theme) => ({
    width: theme.spacing(27.5),
    mx: 'auto',
    mb: 7.5,
  }),
};
