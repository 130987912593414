/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface Art
 */
export interface Art {
    /**
     * 
     * @type {Date}
     * @memberof Art
     */
    created: any;
    /**
     * 
     * @type {Date}
     * @memberof Art
     */
    updated: any;
    /**
     * 
     * @type {number}
     * @memberof Art
     */
    id: any;
    /**
     * 
     * @type {string}
     * @memberof Art
     */
    tokenType: any;
    /**
     * 
     * @type {string}
     * @memberof Art
     */
    tokenId: any;
    /**
     * 
     * @type {UploadFileSchema}
     * @memberof Art
     */
    artImage: any;
    /**
     * 
     * @type {UploadFileSchema}
     * @memberof Art
     */
    previewImage: any;
    /**
     * 
     * @type {string}
     * @memberof Art
     */
    authorWallet: any;
    /**
     * 
     * @type {number}
     * @memberof Art
     */
    authorId: any;
    /**
     * 
     * @type {number}
     * @memberof Art
     */
    eventId: any;
    /**
     * 
     * @type {string}
     * @memberof Art
     */
    name: any;
    /**
     * 
     * @type {string}
     * @memberof Art
     */
    description: any;
    /**
     * 
     * @type {string}
     * @memberof Art
     */
    location: any;
    /**
     * 
     * @type {number}
     * @memberof Art
     */
    amount: any;
    /**
     * 
     * @type {any}
     * @memberof Art
     */
    moneyDistribution: any;
    /**
     * 
     * @type {string}
     * @memberof Art
     */
    artStatus: ArtArtStatusEnum;
    /**
     * 
     * @type {Array&lt;ArtOwner&gt;}
     * @memberof Art
     */
    owners: any;
    /**
     * 
     * @type {User}
     * @memberof Art
     */
    author: any;
    /**
     * 
     * @type {number}
     * @memberof Art
     */
    order: any;
    /**
     * 
     * @type {BuyNow}
     * @memberof Art
     */
    buyNows: any;
    /**
     * 
     * @type {Auction}
     * @memberof Art
     */
    auctions: any;
    /**
     * 
     * @type {number}
     * @memberof Art
     */
    viewsCount: any;
    /**
     * 
     * @type {Array&lt;string&gt;}
     * @memberof Art
     */
    tags: any;
    /**
     * 
     * @type {Event}
     * @memberof Art
     */
    event: any;
    /**
     * 
     * @type {boolean}
     * @memberof Art
     */
    isGift: any;
}

/**
    * @export
    * @enum {string}
    */
export enum ArtArtStatusEnum {
    PUBLISHED = 'PUBLISHED',
    PROMO = 'PROMO',
    PENDING = 'PENDING',
    NOTFORSALE = 'NOT_FOR_SALE',
    DRAFT = 'DRAFT'
}

