import React, { FC, useMemo } from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';
import { useExchangeRate } from 'contexts/appState/useExchangeRate';
import { UserInfo } from './UserInfo';
import { useServiceFee } from './useServiceFee';
import { AuctionTimer } from './AuctionTimer';
import { BuySection } from './BuySection';
import { ArtWithSaleInfo } from 'types';
import { getMarketPrice } from 'helpers/getMarketPrice';
import { isAuction } from 'helpers/isAuction';
import { getPriceWithoutFeeETH } from 'pages/CreateEditArtPage/utils';
import isVideo from '../../helpers/isVideo';
import VideoBox from '../VideoBox/VideoBox';
import { useAvailableCopies } from 'hooks/useAvailableCopies';

interface Props {
  art: ArtWithSaleInfo;
}

export const ArtCard: FC<Props> = React.memo(({ art }: Props) => {
  const navigate = useNavigate();
  const { saleData, author } = art;
  const { owner } =
    art.owners.sort((a: any, b: any) => new Date(a.created).getTime() - new Date(b.created).getTime())[
      art.owners.length - 1
    ] || {};
  const availableCopies = useAvailableCopies(art);
  const artPrice = getMarketPrice(saleData);
  const serviceFee = useServiceFee(art);
  const originalPrice = getPriceWithoutFeeETH(String(artPrice), String(serviceFee));

  const priceInFiatCurrency = useExchangeRate(artPrice);

  // is buy now or auction
  const isBuyNowSale = !isAuction(saleData);
  const isAuctionSale = isAuction(saleData);

  // sale status check
  const isForSale = art.artStatus === 'PUBLISHED';

  // show bids or show starting price on auction card

  const hasBids = isAuctionSale && !!saleData?.bids?.length;

  // sales start and end
  const startSalesDateTimestamp = (saleData?.startDate || saleData?.startMarketDate) * 1000;
  const endAuctionDateTimestamp = saleData?.endDate * 1000;

  // not started
  const isNotStarted: boolean = (startSalesDateTimestamp || 0) > Date.now();

  // live
  const isLiveSale: boolean =
    (startSalesDateTimestamp || 0) < Date.now() &&
    endAuctionDateTimestamp > Date.now() &&
    saleData?.status === 'ACTIVE';

  // secondary sale
  const isOwnerVisible: boolean = art.artStatus === 'NOT_FOR_SALE' || art.saleData?.isFirstSale === false;

  // waits finalization

  // single or multiple
  const isMultiple = art.tokenType === 'ERC1155';

  // redirects
  const handleOpenDetailedView = (id: number) => {
    navigate(`${routes.art}/${id}`);
  };

  const buySectionProps = {
    isBuyNowSale,
    isAuctionSale,
    isLiveSale,
    isNotStarted,
    originalPrice,
    priceInFiatCurrency: priceInFiatCurrency?.fiatPrice,
    startSalesDateTimestamp,
    isMultiple,
    hasBids,
  };

  const preview = useMemo(() => {
    const isVideoMediaType = isVideo(art.artImage.url);
    const mainImage = art.artImage?.url;
    const previewImage = art.previewImage?.url;

    if (previewImage) {
      return <CardMedia component="img" height="335" image={previewImage} alt="art" sx={sx.artImage} />;
    }

    if (isVideoMediaType) {
      return <VideoBox className="video-box" src={mainImage} />;
    } else {
      return <CardMedia component="img" height="335" image={mainImage} alt="art" sx={sx.artImage} />;
    }
  }, []);

  return (
    <Card sx={sx.card} onClick={() => handleOpenDetailedView(art.id)}>
      {isLiveSale && isAuctionSale && <AuctionTimer endDateTimestamp={endAuctionDateTimestamp} />}

      {preview}

      <CardContent sx={sx.artName}>
        <Typography variant="h3" component="p">
          {art.name}
        </Typography>

        <Typography variant="paragraphMedium" color="text.secondary">
          {isMultiple ? `${availableCopies || 0}/${art.amount}` : '1/1'}
        </Typography>
      </CardContent>

      <Box sx={sx.artistAndOwner}>
        <UserInfo
          userRole={'artist'}
          username={author.username}
          avatarUrl={author.settings?.avatar?.url}
          wallet={author.wallet}
        />

        {isOwnerVisible && (
          <UserInfo
            userRole={'owner'}
            username={owner.username}
            avatarUrl={owner.settings?.avatar?.url}
            wallet={owner.wallet}
          />
        )}
      </Box>

      {isForSale && <BuySection {...buySectionProps} />}
    </Card>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  card: {
    width: '300px',
    overflow: 'hidden',
    position: 'relative',
    border: (theme) => `1px solid ${theme.palette.grey[300]}`,
    boxShadow: 'none',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    m: '0 auto',
    '& .video-box': {
      height: 300,
      display: 'block',
      width: '100%',
      objectFit: 'cover',
    },
  },
  artImage: {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    width: '300px',
    height: '300px',
  },
  artName: {
    px: 2.5,
    py: 1.8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  artistAndOwner: {
    display: 'flex',
  },
};
