import React from 'react';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtBuyNowDrawer } from './ArtBuyNowDrawer';
import { Auction } from 'codegen-api/api-totemo-service/models';
import { useCanPurchase } from 'pages/Art/utils/useCanPurchase';
import { ArtFinalizeDrawer } from './ArtFinalizeDrawer';
import { ArtPlaceBidDrawer } from './ArtPlaceBidDrawer';
import { useAppState } from 'contexts/appState/AppContext';
import { canFinalizeAuction, isActiveAuction } from 'pages/Art/utils/auction';
import { isAuction } from 'helpers/isAuction';

export const ArtDrawer: React.FC = React.memo(() => {
  const { state } = useArtDetailedView();
  const { currentUser } = useAppState();
  const isAuctionArt = isAuction(state.saleData);
  const canPurchase = useCanPurchase();

  if (!canPurchase) return null;

  if (!isAuctionArt) return <ArtBuyNowDrawer />;

  const auction: Auction = state.saleData as Auction;

  const isActive = isActiveAuction(auction);

  if (isActive) return <ArtPlaceBidDrawer />;

  const canFinalize = canFinalizeAuction(auction, currentUser.wallet);

  if (canFinalize) return <ArtFinalizeDrawer />;

  return null;
});
