import React from 'react';
import { Box, Button, Divider, Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { getPriceWithoutFeeETH } from 'pages/CreateEditArtPage/utils';
import { ethers } from 'ethers';
import { useServiceFee } from 'pages/Art/utils/useServiceFee';
import { useBalanceEth } from 'pages/Art/utils/useBalanceEth';
import { ArtDrawerPaperProps, ArtDrawerSx } from './ArtDrawerStyles';
import type { SxProps, Theme } from '@mui/material/styles';
import { getMarketPrice } from 'helpers/getMarketPrice';
import { useSingleContract } from 'abi/useSingleContract';

export const ArtFinalizeDrawer: React.FC = React.memo(() => {
  const { state, dispatch } = useArtDetailedView();
  const { finalizeAuction } = useSingleContract();
  const { t } = useTranslation();
  const serviceFee = useServiceFee();
  const balanceEth = useBalanceEth();
  const [isCheckouting, setIsCheckouting] = React.useState<boolean>(false);

  if (!state.art || !state.saleData) {
    return null;
  }

  const handleCheckout = () => {
    const checkout = async () => {
      if (!state.art || !state.saleData || 'marketPrice' in state.saleData) return;

      setIsCheckouting(true);
      try {
        const resp = await finalizeAuction(state.art, state.saleData);

        if (!resp) {
          throw new Error('Finalize failed');
        }

        dispatch({
          type: 'SET_PENDING_TO_SINGLE',
          payload: {
            signer: resp.finalizeAuctionResult.from?.toLowerCase(),
            transactionHash: resp.finalizeAuctionResult.hash,
          },
        });
        handleClose();
      } catch (e) {
        console.log(e);
      }
      setIsCheckouting(false);
    };

    checkout();
  };

  const handleClose = () => dispatch({ type: 'HIDE_DRAWER' });

  const marketPriceEth = getMarketPrice(state.saleData).toString();
  const originalPrice = getPriceWithoutFeeETH(marketPriceEth, String(serviceFee));
  const feeValue = ethers.utils.formatEther(
    ethers.utils.parseEther(marketPriceEth).sub(ethers.utils.parseEther(originalPrice)),
  );

  return (
    <Drawer anchor={'bottom'} open={state.isDrawerOpen} onClose={handleClose} PaperProps={ArtDrawerPaperProps}>
      <Box sx={sx.content}>
        <Typography variant={'h2'}>{t('artPage.finalCheckout')}</Typography>

        <Box mt={2}>
          <Typography color={'text.secondary'} component="span">
            {t('artPage.youWonAuction')}
          </Typography>
          <Typography color={'primary.main'} component="span" fontWeight={600} ml={0.5}>
            {state.art.name}
          </Typography>
        </Box>

        <Box mt={3} mb={5}>
          <Box sx={sx.priceItem}>
            <Typography component="span">{t('artPage.latestBid')}</Typography>
            <Typography component="span">{`${originalPrice} ETH`}</Typography>
          </Box>
          <Box sx={sx.priceItem}>
            <Typography component="span">{`${t('artPage.serviceFee')} ${serviceFee}%`}</Typography>
            <Typography component="span">{`${feeValue} ETH`}</Typography>
          </Box>
          <Divider sx={sx.divider} />
          <Box sx={sx.priceItem}>
            <Typography component="span" color={'text.secondary'}>
              {t('artPage.yourBalance')}
            </Typography>
            <Typography component="span" color={'text.secondary'}>{`${balanceEth} ETH`}</Typography>
          </Box>
          <Box sx={sx.priceItem}>
            <Typography component="span" color={'primary.main'}>
              {t('artPage.youPay')}
            </Typography>
            <Typography component="span" sx={sx.total}>{`${marketPriceEth} ETH`}</Typography>
          </Box>
        </Box>

        <Box sx={sx.buttons}>
          <AsyncButton isLoading={isCheckouting} onClick={handleCheckout} variant={'contained'} color={'primary'}>
            {t('common.confirm')}
          </AsyncButton>
          <Button sx={sx.cancel} onClick={handleClose}>
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
});

const sx: Record<string, SxProps<Theme>> = ArtDrawerSx;
