import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ShopifyCollection } from 'pages/Profile/types';
import { v4 as uuidv4 } from 'uuid';

export const ArtistShopifyMerchSection: FC<ShopifyCollection> = ({ id: collectionId, name: collectionName }) => {
  const componentId = useMemo(() => uuidv4(), []);
  const theme = useTheme();

  const ShopifyBuyInit = useCallback(() => {
    // @ts-ignore
    var client = ShopifyBuy.buildClient({
      domain: '787e66-3.myshopify.com',
      storefrontAccessToken: 'edbc5b0d18084608c154716173f12007',
    });
    // @ts-ignore
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent('collection', {
        id: collectionId,
        node: document.getElementById(`collection-component-${componentId}`),
        moneyFormat: '%C2%A5%7B%7Bamount_no_decimals%7D%7D',
        options: {
          product: {
            styles: {
              product: {
                'border-radius': '5px',
                '@media (min-width: 601px)': {
                  'max-width': 'calc(25% - 20px)',
                  'margin-left': '20px',
                  'margin-bottom': '50px',
                  width: 'calc(25% - 20px)',
                },
              },
              button: {
                'background-color': theme.palette.accent.main,
                ':hover': {
                  'background-color': theme.palette.accent.hover,
                },
                ':focus': {
                  'background-color': theme.palette.accent.hover,
                },
              },
            },
            buttonDestination: 'modal',
            contents: {
              options: false,
            },
            text: {
              button: 'View product',
            },
          },
          productSet: {
            styles: {
              products: {
                '@media (min-width: 601px)': {
                  'margin-left': '-20px',
                },
              },
            },
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true,
            },
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': '100%',
                  'margin-left': '0px',
                  'margin-bottom': '0px',
                },
              },
            },
            text: {
              button: 'Add to cart',
            },
          },
          option: {},
          cart: {
            text: {
              total: 'Subtotal',
              button: 'Checkout',
            },
            styles: {
              button: {
                'background-color': theme.palette.accent.main,
                ':hover': {
                  'background-color': theme.palette.accent.hover,
                },
                ':focus': {
                  'background-color': theme.palette.accent.hover,
                },
              },
            },
          },
          toggle: {
            styles: {
              toggle: {
                'background-color': theme.palette.accent.main,
                ':hover': {
                  'background-color': theme.palette.accent.hover,
                },
                ':focus': {
                  'background-color': theme.palette.accent.hover,
                },
              },
            },
          },
        },
      });
    });
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, componentId, window?.ShopifyBuy]);

  useEffect(() => {
    // @ts-ignore
    if (window?.ShopifyBuy && window?.ShopifyBuy?.UI) {
      ShopifyBuyInit();
    }
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ShopifyBuyInit, window?.ShopifyBuy]);

  return (
    <Box sx={{ mt: 3, width: '100%' }}>
      <Typography variant="h3" color="text.secondary" sx={{ mb: 5 }}>
        {collectionName}
      </Typography>
      <div id={`collection-component-${componentId}`}></div>
    </Box>
  );
};
