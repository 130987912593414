import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const useMarketplaceFiltersValidationSchema = () => {
  const { t } = useTranslation();

  return React.useMemo(
    () =>
      Yup.object().shape({
        minPrice: Yup.number().min(0, t('forms.errors.minValue', { value: 0 })),
      }),
    [t],
  );
};
