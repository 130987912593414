import React, { FC } from 'react';
import { Box, Button, SxProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/system';
import { defaultAvatarSrc } from 'app-settings';
import { User } from 'codegen-api/api-totemo-service/models';

interface Props {
  artists: User[];
  onAddFeaturedArtist: (artist: User) => Promise<void>;
}

export const FeaturedArtistsAddList: FC<Props> = ({ artists, onAddFeaturedArtist }: Props) => {
  const { t } = useTranslation();
  return (
    <Box component="ul" sx={sx.list}>
      {artists.map((artist: User) => {
        return (
          <Box key={artist.wallet} component="li" sx={sx.user}>
            <Avatar sx={sx.avatar} alt={artist.username} src={artist.settings?.avatar?.url || defaultAvatarSrc} />

            <Box sx={sx.userDetails}>
              <Box sx={sx.fullname}>{artist.fullName}</Box>
              <Box sx={sx.username}>{artist.username}</Box>
            </Box>

            <Button
              color={'secondary'}
              size={'small'}
              variant={'contained'}
              sx={sx.addBtn}
              onClick={() => onAddFeaturedArtist(artist)}
            >
              {t('common.add')}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  list: {
    my: 0.5,
    px: 0,
    listStyleType: 'none',
  },
  avatar: {
    flex: '0 0 auto',
  },
  user: {
    py: 2,
    display: 'flex',
    alignItems: 'center',
  },
  userDetails: {
    flex: '1 1 auto',
    px: 2,
  },
  fullname: {
    fontWeight: 600,
  },
  username: {
    fontSize: 12,
  },
  addBtn: {
    fontWeight: 600,
  },
};
