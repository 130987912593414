import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArtHistory } from 'pages/Art/ArtHistory/ArtHistory';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtArtStatusEnum } from 'codegen-api/api-totemo-service/models';

export const ArtHistorySingle: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { state } = useArtDetailedView();

  if (!state.art || state.art.artStatus === ArtArtStatusEnum.DRAFT) {
    return null;
  }

  return (
    <Box my={5}>
      <Typography variant={'h3'} mb={2}>
        {t('artPage.history')}
      </Typography>
      <ArtHistory />
    </Box>
  );
});
