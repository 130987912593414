import * as Ethers from 'ethers';

export const addServiceFee = (price: string | number, serviceFee: string | number) => {
  return +price + (+price * +serviceFee) / 100;
};

export const getFullPriceEth = (priceETH: string, serviceFee: string): string => {
  try {
    const weiValue = Ethers.utils
      .parseEther(priceETH)
      .mul(Ethers.utils.parseEther(String(100 + +serviceFee)))
      .div(Ethers.utils.parseEther('100'))
      .toString();
    return Ethers.utils.formatEther(weiValue);
  } catch (e) {
    return '0';
  }
};
export const getFullPriceWei = (priceETH: string, serviceFee: string): string => {
  try {
    return Ethers.utils
      .parseEther(priceETH)
      .mul(Ethers.utils.parseEther(String(100 + +serviceFee)))
      .div(Ethers.utils.parseEther('100'))
      .toString();
  } catch (e) {
    return '0';
  }
};

export const formatPriceWeiToEth = (priceWei: string): string => {
  try {
    return Ethers.utils.formatEther(priceWei).toString();
  } catch (e) {
    return '0';
  }
};

export const getPriceWithoutFeeETH = (priceETH: string, serviceFee: string): string => {
  try {
    const weiValue = Ethers.utils
      .parseEther(priceETH)
      .mul(Ethers.utils.parseEther('100'))
      .div(Ethers.utils.parseEther(String(100 + +serviceFee)))
      .toString();
    return Ethers.utils.formatEther(weiValue);
  } catch (e) {
    return '0';
  }
};

export const getSecondarySellerEarningsEth = (marketPriceEth: string | number, sellerPercentage: string | number) =>
  Ethers.utils.formatEther(
    Ethers.utils
      .parseEther(String(marketPriceEth || 0))
      .mul(sellerPercentage)
      .div(100)
      .toString(),
  );
