import { Box, Typography, Button } from '@mui/material';
import { BaseModal, BaseModalProps } from '../BaseModal';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

interface Props extends BaseModalProps {
  onDelete: () => void;
}

export const DeleteModal: FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <BaseModal {...props}>
      <Box px={1} pb={3}>
        <Typography variant="h6">{t('common.deleteArt')}</Typography>
        <Typography component="div" variant="paragraphLarge" mt={2} mb={4} textAlign={'center'}>
          {t('common.allWillBeLost')}
        </Typography>
        <Button variant="contained" color="primary" fullWidth onClick={props.onDelete}>
          {t('common.delete')}
        </Button>
      </Box>
    </BaseModal>
  );
};

export const ConfirmDeleteModal: FC<BaseModalProps> = (props) => {
  const { t } = useTranslation();
  return (
    <BaseModal {...props}>
      <Typography variant="h2" textAlign="center">
        {t('common.artWasDeleted')}
      </Typography>
    </BaseModal>
  );
};
