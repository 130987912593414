import React from 'react';
import { alpha, Box, Button, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { SignInLayout } from 'components/SignInLayout/SignInLayout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { localStorageKeys, routes } from 'app-settings';
import { devLog } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'contexts/appState/AppContext';
import { User } from 'codegen-api/api-totemo-service/models';
import { getUserDataFromJWT } from 'helpers/jwt';
import { useApiContext } from 'contexts/ApiContext';

export const NoWalletSignIn: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const { users, auth } = useApiContext();
  const validationSchema = useNoWalletSignInValidationSchema();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [asyncError, setAsyncError] = React.useState<'' | 'wrongOne' | 'userNotFound'>('');
  const formik = useFormik<{ email: string; password: string }>({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }) => {
      try {
        setIsSubmitting(true);

        const tokenResp = await auth.authControllerLogin({ email, password });
        //@ts-ignore
        const jwt = tokenResp.data.access_token;
        localStorage.setItem(localStorageKeys.jwt, jwt);
        const userData: Omit<User, 'currencyType'> & { sub: number } = getUserDataFromJWT(jwt);
        const resp = await users.userControllerGetFullUser(userData.id || userData.sub);
        appDispatch({
          type: 'SET_CURRENT_USER',
          payload: resp.data,
        });
        navigate(routes.home);
      } catch (e: any) {
        devLog(e);
        if (e.response?.data?.message === 'email or password is incorrect') {
          setAsyncError('wrongOne');
        } else if (e.response?.data?.message === 'User not found') {
          setAsyncError('userNotFound');
        } else if (e.response?.data?.message === 'You cannot sign in by email and password') {
          navigate(routes.walletSignInRequired);
        } else {
          setAsyncError('');
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <SignInLayout titleTKey={'signInWithoutWallet.title'}>
      <Subtitle />
      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
        <Box sx={sx.inputsContainer}>
          <OutlinedTextInput
            sx={{ mb: 2.5 }}
            required={true}
            name="email"
            id="email"
            autoComplete="email"
            label={t('forms.email')}
            error={!!((formik.touched.email && formik.errors.email) || asyncError)}
            helperText={formik.touched.email && t(formik.errors.email as string)}
          />
          <OutlinedTextInput
            required={true}
            name="password"
            id="password"
            type="password"
            autoComplete="password"
            label={t('forms.password')}
            error={!!((formik.touched.password && formik.errors.password) || asyncError)}
            helperText={formik.touched.password && t(formik.errors.password as string)}
          />
          <Button
            variant="text"
            size="medium"
            onClick={() => navigate(routes.forgotPassword)}
            sx={sx.forgotPasswordBtn}
          >
            {t('forms.forgotPassword')}
          </Button>
          {!!asyncError && <Box sx={sx.errorContainer}>{t(`forms.errors.${asyncError}`)}</Box>}
          <Box sx={sx.submitContainer}>
            <AsyncButton isLoading={isSubmitting} type="submit" variant="contained" sx={sx.submit}>
              {t('common.submit')}
            </AsyncButton>
          </Box>
        </Box>
      </form>
    </SignInLayout>
  );
});

const Subtitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Typography component="span" variant="paragraphSmall" textAlign="center" sx={sx.subtitle}>
      <Box component="span" color="text.secondary">
        {t('signInWithoutWallet.description')}
      </Box>
      <Box component="span" fontWeight={600}>
        <Trans
          i18nKey={'signInWithoutWallet.descriptionEmphasized'}
          components={[
            <Link
              target="_blank"
              underline="none"
              className={'accent-color'}
              href="mailto:artist@totemo.com"
              rel="noreferrer"
            />,
          ]}
        />
      </Box>
    </Typography>
  );
};

const useNoWalletSignInValidationSchema = () => {
  return React.useMemo(() => {
    return Yup.object().shape({
      email: Yup.string().trim().required('forms.errors.fieldRequired').email('forms.errors.emailInvalid'),
      password: Yup.string().required(),
    });
  }, []);
};

const sx: Record<string, SxProps<Theme>> = {
  subtitle: {
    mt: 3.75,
    fontWeight: 700,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    display: 'block',
    span: {
      display: 'block',
    },
  },
  inputsContainer: {
    width: { sm: 600, xs: '100%' },
    m: '0 auto',
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    mt: 5,
  },
  submitContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  submit: { my: 3.75 },
  forgotPasswordBtn: {
    alignSelf: 'flex-end',
    color: (theme) => theme.palette.grey[600],
  },
  errorContainer: {
    alignSelf: 'stretch',
    textAlign: 'center',
    bgcolor: (theme) => alpha(theme.palette.error.main, 0.2),
    color: 'error.main',
    p: 1,
    borderRadius: '4px',
    mt: 2.5,
  },
};
