import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { useExchangeRate } from 'contexts/appState/useExchangeRate';
import { ArtArtStatusEnum, ArtBodyTokenTypeEnum, Auction } from 'codegen-api/api-totemo-service/models';
import { useServiceFee } from 'pages/Art/utils/useServiceFee';
import { getPriceWithoutFeeETH } from 'pages/CreateEditArtPage/utils';
import { getMarketPrice } from 'helpers/getMarketPrice';
import { isAuction } from 'helpers/isAuction';
import ArtFollow from './ArtFollow';
import { EMPTY_USER, useAppState } from 'contexts/appState/AppContext';
import ArtViews from './ArtViews';
import useIsMobileScreen from '../../helpers/useIsMobileScreen';

export const ArtPriceAndViews: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { state } = useArtDetailedView();
  const price = getMarketPrice(state.saleData);
  const serviceFee = useServiceFee();
  const rates = useExchangeRate(price);
  const { currentUser } = useAppState();
  const isMobileScreen = useIsMobileScreen();
  const theme = useTheme();

  if (!state.art) return null;

  if (state.art.artStatus === ArtArtStatusEnum.PROMO) {
    return (
      <Box sx={sx.promo}>
        <Typography variant="paragraphLarge" fontWeight={600}>
          {t('artPage.promo')}
        </Typography>
        {isMobileScreen && <ArtViews count={state.art.viewsCount} />}
      </Box>
    );
  }
  if (state.art.artStatus === ArtArtStatusEnum.NOTFORSALE) {
    return (
      <Box sx={sx.promo}>
        <Typography variant="paragraphLarge" fontWeight={600}>
          {t('artPage.notForSale')}
        </Typography>
        {isMobileScreen && <ArtViews count={state.art.viewsCount} />}
      </Box>
    );
  }
  if (state.art.artStatus === ArtArtStatusEnum.DRAFT) {
    return (
      <Box sx={sx.promo}>
        <Typography variant="paragraphLarge">{t('artPage.draft')}</Typography>
      </Box>
    );
  }

  if (!state.saleData || state.art.artStatus !== ArtArtStatusEnum.PUBLISHED) return null;

  const latestBid = ('bids' in state.saleData && state.saleData.bids?.[0]) || null;

  let titleTKey: string;
  switch (true) {
    case state.art.tokenType === ArtBodyTokenTypeEnum.ERC1155: {
      titleTKey = state.art.buyNows.length > 1 ? 'artPage.from' : 'artPage.price';
      break;
    }
    case !isAuction(state.saleData): {
      titleTKey = 'artPage.price';
      break;
    }
    case !!latestBid?.bidPrice: {
      titleTKey = 'artPage.latestBid';
      break;
    }
    default: {
      titleTKey = 'artPage.startingPrice';
    }
  }

  const originalPrice = getPriceWithoutFeeETH(String(price), String(serviceFee));

  return (
    <Box>
      <Typography variant="paragraphLarge" fontWeight={600} color="text.secondary">
        {t(titleTKey)}
      </Typography>
      <Box sx={sx.values}>
        <Box display="flex" justifyContent="space-between">
          <Box sx={sx.price}>
            <Box sx={sx.priceEth}>{originalPrice}Ξ</Box>
            {rates ? <Box sx={sx.priceFiat}>~({rates.fiatPrice})</Box> : null}
          </Box>
          <Box sx={sx.viewFollow}>
            {isMobileScreen && <ArtViews count={state.art.viewsCount} />}
            {isMobileScreen && currentUser.wallet !== EMPTY_USER.wallet && <ArtFollow />}
          </Box>
        </Box>
        {!isMobileScreen && isAuction(state.saleData) && (
          <Box>
            <Typography fontSize={14} color={theme.palette.grey['700']}>{t('artPage.startingPrice')}</Typography>
            <Typography fontSize={14} fontWeight={700}>{(state.saleData as Auction).startPrice}Ξ</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  promo: {
    color: 'primary.main',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  views: {
    gap: 0.5,
    color: 'text.secondary',
    display: 'flex',
    alignItems: 'center',
  },
  values: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: 1.25,
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.25,
  },
  priceEth: {
    fontSize: '24px',
    color: 'primary.main',
    fontWeight: 600,
  },
  priceFiat: {
    color: 'text.secondary',
  },
  viewFollow: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 1,
  },
};
