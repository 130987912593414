import React from 'react';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtSaleCancelSuccessPopup } from 'pages/Art/ArtPopups/ArtSaleCancelSuccessPopup';

export const ArtPopups: React.FC = React.memo(() => {
  const { state } = useArtDetailedView();

  switch (true) {
    case !state.isInfoPopupOpen: {
      return null;
    }
    case state.infoPopupType === 'movedToDrafts': {
      return <ArtSaleCancelSuccessPopup open={true} />;
    }
    default: {
      return null;
    }
  }
});
