import React, { memo } from 'react';
import Box from '@mui/material/Box';
import type { SxProps, Theme } from '@mui/material/styles';
import { routes } from 'app-settings';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from 'components/Icons/close.svg';
import { Divider, Typography } from '@mui/material';
import Logo from 'components/Header/Logo';

interface Props {
  onClose: () => void;
}

interface NavItem {
  title: string;
  linkUrl: string;
}

const nonAdminItems: NavItem[] = [
  { title: 'header.marketplace', linkUrl: routes.marketplace },
  { title: 'header.events', linkUrl: `${routes.homeEvents}` },
  { title: 'header.artists', linkUrl: `${routes.homeArtists}` },
  { title: 'header.explore', linkUrl: `${routes.homeExplore}` },
  { title: 'header.faq', linkUrl: `${routes.homeFaq}` },
];

const adminItems: NavItem[] = [{ title: 'header.adminPanel', linkUrl: routes.adminPanel }, ...nonAdminItems];

const HeaderNavigationMenu: React.FC<Props> = memo(({ onClose: handleClose }) => {
  const isAdmin = useHasRole('SUPERADMIN');
  const { t } = useTranslation();

  const items = isAdmin ? adminItems : nonAdminItems;

  return (
    <Box sx={sx.root}>
      <Box sx={sx.item}>
        <Box onClick={handleClose}>
          <Logo />
        </Box>
        <IconButton sx={sx.close} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Divider sx={sx.divider} />
      </Box>
      <nav aria-label="main pages">
        {items.map((item) => (
          <Link to={item.linkUrl} onClick={handleClose} key={item.linkUrl}>
            <Box sx={sx.item}>
              <Typography variant={'h3'} component={'span'} className={'headlines'}>
                {t(item.title)}
              </Typography>
              <Divider sx={sx.divider} />
            </Box>
          </Link>
        ))}
      </nav>
    </Box>
  );
});

export default HeaderNavigationMenu;

const sx: Record<string, SxProps<Theme>> = {
  root: {
    minWidth: '100vw',
    a: {
      textDecoration: 'none',
      display: 'block',
    },
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    p: 3,
    color: 'common.black',
  },
  item: {
    height: 'var(--header-height)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    position: 'absolute',
    bottom: '-1px',
    left: (theme) => theme.spacing(2.5),
    right: (theme) => theme.spacing(2.5),
  },
};
