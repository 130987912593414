import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const saleValidation = (t: TFunction<'translation', undefined>) =>
  Yup.object().shape({
    price: Yup.number().when('saleType', {
      is: 'buy',
      then: Yup.number()
        .required(t('forms.errors.fieldRequired')),
    }),
    startingPrice: Yup.number().when('saleType', {
      is: 'auction',
      then: Yup.number()
        .positive(t('forms.errors.positiveOnly'))
        .min(0.001, t('forms.errors.minValue', { value: 0.001 }))
        .required(t('forms.errors.fieldRequired'))
        .typeError(t('forms.errors.positiveOnly')),
    }),
    copies: Yup.number()
      .min(2, t('forms.errors.minCopiesValue'))
      .integer(t('forms.errors.integersOnly'))
      .required(t('forms.errors.fieldRequired')),
    primaryArtistPercentage: Yup.number()
      .min(0, t('forms.errors.minValue', { value: 0 }))
      .max(100, t('forms.errors.maxValue', { value: 100 }))
      .integer(t('forms.errors.integersOnly'))
      .required(t('forms.errors.fieldRequired')),
    primaryTotemoPercentage: Yup.number()
      .min(0, t('forms.errors.minValue', { value: 0 }))
      .max(100, t('forms.errors.maxValue', { value: 100 }))
      .integer(t('forms.errors.integersOnly'))
      .required(t('forms.errors.fieldRequired')),
    primaryAddressPercentage: Yup.number().when('includeOptionalAddress', {
      is: true,
      then: Yup.number()
        .min(0, t('forms.errors.minValue', { value: 0 }))
        .max(100, t('forms.errors.maxValue', { value: 100 }))
        .integer(t('forms.errors.integersOnly'))
        .required(t('forms.errors.fieldRequired')),
    }),
    primaryAddress: Yup.string().when('includeOptionalAddress', {
      is: true,
      then: Yup.string()
        .matches(/^0x[a-fA-F0-9]{40}$/, 'forms.errors.addressInvalid')
        .required(t('forms.errors.fieldRequired')),
    }),
  });
