import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';
import { Box, Button, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import { StyledSwitch } from 'components/UiKit/StyledSwitch/StyledSwitch';

type Props = {
  formik: FormikValues;
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const MoneyDistribution: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);
  const [isSetMoneyDistribution, setIsSetMoneyDistribution] = useState(false);

  const { touched, errors, values, setFieldValue } = formik;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleChangeOptionalAddressSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFieldValue('includeOptionalAddress', checked);
  };

  return (
    <React.Fragment>
      <Typography variant="h4" sx={sx.textBlock}>
        {t('pages.createArt.moneyDistribution')}
      </Typography>

      {isSetMoneyDistribution ? (
        <>
          <Tabs value={tab} variant="fullWidth" onChange={handleChangeTab}>
            <Tab label={t('pages.createArt.primarySale')} {...a11yProps(0)} sx={sx.tabs} />
            <Tab label={t('pages.createArt.secondarySale')} {...a11yProps(1)} sx={sx.tabs} />
          </Tabs>

          <Box id="tab-panel-primary" role="tabpanel" aria-labelledby="tab-primary" hidden={tab !== 0} sx={sx.tabPanel}>
            {tab === 0 && (
              <React.Fragment>
                <Box sx={sx.inputGroup}>
                  <OutlinedTextInput
                    name="primaryArtistPercentage"
                    id="primaryArtistPercentage"
                    value={values['primaryArtistPercentage']}
                    label={t('pages.createArt.artist')}
                    error={!!(touched.primaryArtistPercentage && errors.primaryArtistPercentage)}
                    helperText={touched.primaryArtistPercentage && t(errors.primaryArtistPercentage as string)}
                  />
                </Box>

                <Box sx={sx.inputGroup}>
                  <OutlinedTextInput
                    name="primaryTotemoPercentage"
                    id="primaryTotemoPercentage"
                    value={values['primaryTotemoPercentage']}
                    label={t('pages.createArt.totemo')}
                    error={!!(touched.primaryTotemoPercentage && errors.primaryTotemoPercentage)}
                    helperText={touched.primaryTotemoPercentage && t(errors.primaryTotemoPercentage as string)}
                  />
                </Box>

                <Box sx={sx.inputGroup}>
                  <OutlinedTextInput
                    name="primaryServiceFee"
                    id="primaryServiceFee"
                    disabled={true}
                    value={values['primaryServiceFee']}
                    label={t('pages.createArt.serviceFee')}
                    error={!!(touched.primaryServiceFee && errors.primaryServiceFee)}
                    helperText={touched.primaryServiceFee && t(errors.primaryServiceFee as string)}
                  />
                </Box>

                <Box sx={sx.optionalAddressBlock}>
                  <Typography variant="subtitle1" sx={sx.bold}>
                    {t('pages.createArt.includingOptionalAddress')}
                  </Typography>
                  <StyledSwitch
                    checked={values['includeOptionalAddress']}
                    onChange={handleChangeOptionalAddressSwitch}
                  />
                </Box>

                {values['includeOptionalAddress'] && (
                  <React.Fragment>
                    <Box sx={sx.inputGroup}>
                      <OutlinedTextInput
                        name="primaryAddress"
                        id="primaryAddress"
                        value={values['primaryAddress']}
                        label={t('pages.createArt.optionalAddress')}
                        error={!!(touched.primaryAddress && errors.primaryAddress)}
                        helperText={touched.primaryAddress && t(errors.primaryAddress as string)}
                      />
                    </Box>

                    <Box sx={sx.inputGroup}>
                      <OutlinedTextInput
                        name="primaryAddressPercentage"
                        id="primaryAddressPercentage"
                        value={values['primaryAddressPercentage']}
                        label={t('pages.createArt.optionalAddressPercent')}
                        error={!!(touched.primaryAddressPercentage && errors.primaryAddressPercentage)}
                        helperText={touched.primaryAddressPercentage && t(errors.primaryAddressPercentage as string)}
                      />
                    </Box>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Box>

          <Box id="tab-panel-1" role="tabpanel" aria-labelledby="tab-1" hidden={tab !== 1} sx={sx.tabPanel}>
            {tab === 1 && (
              <React.Fragment>
                <Box sx={sx.inputGroup}>
                  <OutlinedTextInput
                    name="secondarySellerPercentage"
                    id="secondarySellerPercentage"
                    value={values['secondarySellerPercentage']}
                    label={t('pages.createArt.seller')}
                    error={!!(touched.secondarySellerPercentage && errors.secondarySellerPercentage)}
                    helperText={touched.secondarySellerPercentage && t(errors.secondarySellerPercentage as string)}
                  />
                </Box>

                <Box sx={sx.inputGroup}>
                  <OutlinedTextInput
                    name="secondaryArtistPercentage"
                    id="secondaryArtistPercentage"
                    value={values['secondaryArtistPercentage']}
                    label={t('pages.createArt.artist')}
                    error={!!(touched.secondaryArtistPercentage && errors.secondaryArtistPercentage)}
                    helperText={touched.secondaryArtistPercentage && t(errors.secondaryArtistPercentage as string)}
                  />
                </Box>

                <Box sx={sx.inputGroup}>
                  <OutlinedTextInput
                    name="secondaryTotemoPercentage"
                    id="secondaryTotemoPercentage"
                    value={values['secondaryTotemoPercentage']}
                    label={t('pages.createArt.totemo')}
                    error={!!(touched.secondaryTotemoPercentage && errors.secondaryTotemoPercentage)}
                    helperText={touched.secondaryTotemoPercentage && t(errors.secondaryTotemoPercentage as string)}
                  />
                </Box>

                <Box sx={sx.inputGroup}>
                  <OutlinedTextInput
                    name="secondaryServiceFee"
                    id="secondaryServiceFee"
                    disabled={true}
                    value={values['secondaryServiceFee']}
                    label={t('pages.createArt.serviceFee')}
                    error={!!(touched.secondaryServiceFee && errors.secondaryServiceFee)}
                    helperText={touched.secondaryServiceFee && t(errors.secondaryServiceFee as string)}
                  />
                </Box>
              </React.Fragment>
            )}
          </Box>
        </>
      ) : (
        <Box sx={sx.inputIndent}>
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={() => setIsSetMoneyDistribution(true)}
            sx={sx.setMoneyDistribution}
          >
            {t('pages.createArt.setMoneyDistribution')}
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

export default MoneyDistribution;

const sx: Record<string, SxProps<Theme>> = {
  tabs: {
    mt: 2,
    fontSize: 16,
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    textTransform: 'none',
    borderBottom: (theme) => `1px solid ${theme.palette.grey['300']}`,
  },
  bold: {
    fontWeight: 900,
  },
  textBlock: {
    mt: 5,
    textAlign: 'left',
  },
  setMoneyDistribution: {
    width: '100%',
  },
  inputIndent: {
    py: 2,
  },
  inputGroup: theme => ({
    pb: 2,
    [theme.breakpoints.up('lg')]: {
      'input': {
        backgroundColor: theme.palette.grey[50],
      },
    },

  }),
  optionalAddressBlock: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  },
  tabPanel: {
    pt: 2,
  },

};
