import React, { FC, useMemo } from 'react';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { ReactComponent as FilterIcon } from 'components/Icons/filter.svg';
import { useFilterCounter } from './hooks/useFilterCounter';
import { ReactComponent as CloseIcon } from 'components/Icons/close.svg';
import { Loader } from 'components/UiKit/Loader/Loader';
import { NoResults } from './Components/NoResults';
import { ArtStudioLink } from './Components/ArtStudioLink';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';
import { PopularTags } from 'pages/Marketplace/Components/PopularTagsPanel';
import { ArtCard } from 'components/ArtCard/ArtCard';
import { useMarketplaceContext } from './State/MarketplaceContext';
import { Footer } from 'components/Footer/Footer';
import { ArtCardJustSold } from 'components/ArtCard/ArtCardJustSold';
import { useFetchArt } from './hooks/useFetchArt';
import { MarketplaceFilterForm } from './Form/MarketplaceFilterForm';
import { useInitialFilterData } from './hooks/useInitialFilterData';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import { Header } from '../../components/Header';

export const MarketplacePageContent: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useMarketplaceContext();
  const filtersCounter = useFilterCounter(state.filters);

  const arts = useFetchArt();
  const tags = useInitialFilterData();
  const isNoResults = !state.isLoading && !arts.length;
  const visitArtStudio = !state.isLoading && !arts.length && state.filters.artist !== '';
  const isMobileScreen = useIsMobileScreen();

  const handleOpenFilters = (): void => {
    dispatch({ type: 'OPEN_FILTER_MODAL' });
  };

  const handleResetFilters = async () => {
    dispatch({ type: 'RESET_FILTERS' });
  };

  const handleRedirectToArtStudio = (wallet: string) => {
    navigate(`${routes.userProfile}/${wallet}`);
  };

  const artistStudioToVisit = state.artists?.find((item) => state.filters.artist === item.username);

  const checkedTagsValuesForFilter = useMemo(() => {
    return state.checkedTags.find((item) => item.name === 'All') ? [] : state.checkedTags;
  }, [state.checkedTags]);

  if (state.isFiltersOpen && isMobileScreen) {
    return <MarketplaceFilterForm onFiltersReset={handleResetFilters} checkedTags={checkedTagsValuesForFilter} />;
  }

  return (
    <>
      <Container fixed sx={{ m: '0 auto' }}>
        <Header />
        <Box sx={sx.container}>
          <Typography variant={'h1'} textAlign={'center'}>
            {t('pages.marketplace.title')}
          </Typography>
          <Box sx={sx.filterButtonWrapper}>
            <Button
              variant={'contained'}
              size={'small'}
              startIcon={<FilterIcon />}
              sx={sx.filterButton}
              onClick={handleOpenFilters}
            >
              {t('pages.marketplace.filterBy')}
            </Button>
            {
              state.isFiltersOpen && <Box sx={sx.filtersPopup}>
                    <MarketplaceFilterForm onFiltersReset={handleResetFilters} checkedTags={checkedTagsValuesForFilter} />
                </Box>
            }

            {!!filtersCounter && (
              <Button
                variant={'contained'}
                size={'small'}
                endIcon={<CloseIcon />}
                sx={{ ...sx.filterButton, ...sx.clearButtonWrapper } as SxProps<Theme>}
                onClick={handleResetFilters}
              >
                {filtersCounter}
              </Button>
            )}

            <Typography variant="paragraphSmall" textAlign={'center'} color="text.disabled" ml={'auto'}>
              {t('pages.marketplace.art', { count: state.artsCount })}
            </Typography>
          </Box>
          <PopularTags tags={tags} />
        </Box>

        <Divider />

        {state.isLoading && (
          <Box sx={{ mt: 5 }}>
            <Loader />
          </Box>
        )}

        {isNoResults && <NoResults />}

        {visitArtStudio && (
          <ArtStudioLink
            artist={artistStudioToVisit!}
            onClick={() => handleRedirectToArtStudio(artistStudioToVisit!.wallet)}
          />
        )}

        <Box sx={sx.arts}>
          {!filtersCounter ? <ArtCardJustSold /> : null}
          {arts?.map((art) => {
            return <ArtCard art={art} key={art.id} />;
          })}
        </Box>

        {state.currentPage < state.totalPages && (
          <Button variant="outlined" onClick={() => dispatch({ type: 'FETCH_MORE' })} sx={sx.loadMoreBtn}>
            {t('common.loadMore')}
          </Button>
        )}
      </Container>
      <Footer />
    </>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  container: {
    px: 2.5,
  },
  filterButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    mt: 6,
    mb: 3.25,
  },
  clearButtonWrapper: {
    borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 0,
    ml: 1.6,
    pl: 1.6,
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    '& > span > svg': {
      color: 'grey.300',
    },
  },
  filterButton: {
    backgroundColor: 'transparent',
    color: 'primary.main',
    '&:hover, &:active': {
      backgroundColor: 'transparent',
    },
    pr: 1.6,
    py: 0.75,
  },
  arts: theme => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    my: 5,
    gap: 3.75,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },

  }),
  loadMoreBtn: {
    mx: 'auto',
    mb: 5,
  },
  filtersPopup: {
    width: '410px',
    height: '760px',
    backgroundColor: 'white',
    zIndex: 999,
    boxShadow: '0px 0px 40px rgba(30, 30, 30, 0.2)',
    position: 'absolute',
    top: 40,
  },
};
