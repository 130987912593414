import React from 'react';
import { Button, CircularProgress, Box, SxProps } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { Theme } from '@mui/material/styles';
import { noop } from 'helpers';

const AsyncButton: React.FC<ButtonProps & { isLoading: boolean }> = ({ isLoading, children, onClick, ...props }) => {
  const size = React.useMemo(() => {
    return props.size === 'large' ? 22 : 15;
  }, [props.size]);

  return (
    <Button {...props} onClick={isLoading ? noop : onClick}>
      {isLoading ? (
        <Box sx={sx.loader}>
          <CircularProgress size={size} color={props.color === 'secondary' ? 'inherit' : 'secondary'} />
        </Box>
      ) : (
        children
      )}
    </Button>
  );
};

export default AsyncButton;

const sx: Record<string, SxProps<Theme>> = {
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& .MuiCircularProgress-svg': {
      transform: 'scale(1.5)',
    },
  },
};
