import React, { FC, useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { ArtistShopifyMerchSection } from './ArtistShopifyMerchSection';
import { useTranslation } from 'react-i18next';
import { ShopifyCollection } from 'pages/Profile/types';

interface ArtistShopifyMerchProps {
  collections: ShopifyCollection[];
}

export const ArtistShopifyMerch: FC<ArtistShopifyMerchProps> = ({ collections }) => {
  const { t } = useTranslation();
  const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

  const loadScript = useCallback(() => {
    console.log('loadScript');

    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!window?.ShopifyBuy || !window?.ShopifyBuy?.UI) {
      loadScript();
    }
  }, [loadScript]);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h2" sx={{ textAlign: 'center', m: 2 }}>
        {t`profilePage.merchTitle`}
      </Typography>
      {collections.map((collection) => (
        <ArtistShopifyMerchSection {...collection} />
      ))}
    </Box>
  );
};
