import { Box, Button, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getStartDate } from 'helpers/getStartDate';

interface Props {
  isBuyNowSale: boolean;
  isAuctionSale: boolean;
  isLiveSale: boolean;
  isNotStarted: boolean;
  originalPrice: string;
  priceInFiatCurrency: string | undefined;
  startSalesDateTimestamp: number;
  isMultiple: boolean | undefined;
  hasBids: boolean;
}

export const BuySection: FC<Props> = ({
  isBuyNowSale,
  isAuctionSale,
  isLiveSale,
  isNotStarted,
  originalPrice,
  priceInFiatCurrency,
  startSalesDateTimestamp,
  isMultiple,
  hasBids,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={
        {
          ...sx.buySection,
          ...(isMultiple && sx.buySectionMultiple),
          ...(isNotStarted && sx.priceInfoNotStarted),
        } as SxProps<Theme>
      }
    >
      <Box sx={sx.info}>
        <Typography variant="paragraphSmall" color="text.secondary" textAlign={'left'} fontWeight={600}>
          {isBuyNowSale || isNotStarted
            ? t('artPage.price')
            : hasBids
              ? t('artPage.latestBid')
              : t('artPage.startingPrice')}
        </Typography>

        <Box sx={sx.container}>
          <Typography
            variant="paragraphLarge"
            color="text.primary"
            fontWeight={600}
            component="p"
            alignItems="center"
            display="flex"
            gap={0.5}
          >
            {originalPrice} Ξ
          </Typography>
          <Typography variant="paragraphSmall" color="text.secondary">
            {`~(${priceInFiatCurrency})`}
          </Typography>
        </Box>
      </Box>

      {isNotStarted && (
        <Box sx={sx.info}>
          <Typography variant="paragraphSmall" color="text.secondary" fontWeight={600}>
            {isBuyNowSale ? t('artPage.buyNowStartIn') : t('artPage.auctionStartIn')}
          </Typography>
          <Typography variant="paragraphLarge" color="text.primary">
            {getStartDate(startSalesDateTimestamp)}
          </Typography>
        </Box>
      )}

      {isLiveSale && isAuctionSale && (
        <Button variant="outlined" size="small" sx={sx.bidBtn}>
          <Typography variant="paragraphSmall" color="text.primary" fontWeight={600}>
            {t('artPage.placeBidBtn')}
          </Typography>
        </Button>
      )}
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  buySection: {
    borderTop: (theme) => `solid 1px ${theme.palette.grey[200]}`,
    minHeight: 80,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '15px 20px',
    position: 'relative',
  },
  buySectionMultiple: {
    '&::after': {
      content: '""',
      width: '90%',
      bgcolor: 'grey.200',
      height: 5,
      position: 'absolute',
      bottom: -5,
      left: '5%',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      zIndex: -1,
    },
    '&::before': {
      content: '""',
      width: '80%',
      bgcolor: 'grey.50',
      height: 5,
      position: 'absolute',
      bottom: -9,
      left: '10%',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      zIndex: -1,
    },
  },
  info: {
    display: 'flex',
    flexBasis: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  priceInfoNotStarted: {
    bgcolor: 'grey.200',
  },
  bidBtn: {
    height: 40,
    width: 137,
  },
};
