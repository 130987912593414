import React from 'react';
import useScript from 'hooks/useScript';

export const GoogleRecaptchaV2: React.FC<{ onChange: (token: string) => void }> = React.memo(({ onChange }) => {
  React.useLayoutEffect(() => {
    window.onloadGRecaptchaCallback = () => {
      window.grecaptchaContainerId = window.grecaptcha.render('grecaptcha_container', {
        sitekey: process.env.REACT_APP_RECAPTCHA_KEY,
      });
    };
    window.grecaptchaContainerCallback = onChange;
  }, [onChange]);

  useScript('https://www.google.com/recaptcha/api.js?onload=onloadGRecaptchaCallback&render=explicit');

  React.useEffect(() => {
    return () => {
      window.grecaptcha.reset && window.grecaptchaContainerId && window.grecaptcha.reset(window.grecaptchaContainerId);
    };
  }, []);

  return <div key="grecaptcha_container" id="grecaptcha_container" data-callback="grecaptchaContainerCallback" />;
});
