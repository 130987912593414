import React, { memo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from './Logo';
import { Drawer, SxProps } from '@mui/material';
import HeaderCurrentUser from 'components/Header/HeaderCurrentUser';
import HeaderCurrentUserMenu from 'components/Header/HeaderCurrentUserMenu';
import HeaderNavigationMenu from 'components/Header/HeaderNavigationMenu';
import { Theme } from '@mui/material/styles';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import Navigation from './Navigation';

export const Header: React.FC = memo(() => {
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState<boolean>(false);
  const [isNavMenuOpen, setIsNavMenuOpen] = React.useState<boolean>(false);
  const isMobileScreen = useIsMobileScreen();

  return (
    <AppBar sx={sx.appBar}>
      <Toolbar disableGutters sx={sx.toolbar}>

        {
          isMobileScreen && <Box sx={sx.navigationMenu}>
                <IconButton
                    size="large"
                    aria-label="navigation menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => setIsNavMenuOpen(true)}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor="left"
                    open={isNavMenuOpen}
                    ModalProps={{
                      keepMounted: true,
                    }}
                >
                    <HeaderNavigationMenu onClose={() => setIsNavMenuOpen(false)} />
                </Drawer>
            </Box>
        }

        <Box sx={sx.logoContainer}>
          <Logo />
        </Box>

        <Box sx={sx.rightMenu}>
          {!isMobileScreen && <Navigation />
          }
          <Box sx={sx.currentUser}>
            <HeaderCurrentUser isMenuOpen={isUserMenuOpen} setIsMenuOpen={setIsUserMenuOpen} />
          </Box>
        </Box>
      </Toolbar>
      <HeaderCurrentUserMenu isMenuOpen={isUserMenuOpen} onClose={() => setIsUserMenuOpen(false)} />
    </AppBar>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  appBar: (theme) => ({
    position: 'relative',
    zIndex: 2,
    color: 'inherit',
    backgroundColor: theme.palette.background.default,
  }),
  toolbar: {
    position: 'relative',
    zIndex: 1,
    borderBottom: '1px solid',
    borderColor: 'grey.300',
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigationMenu: {
    flex: '0 0 auto',
    position: 'relative',
    zIndex: 1,
    ml: 1.25,
  },
  logoContainer: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    px: '10px',
    borderBottom: '1px solid black',
  },
  currentUser: {
    position: 'relative',
    zIndex: 1,
  },
  rightMenu: {
    display: 'flex',
    flexDirection: 'row',
    gap: '50px',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    gap: '50px',
  },
};
