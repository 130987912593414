import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import CheckCard from 'components/UiKit/CheckCrard/CheckCard';

interface Props {
  selected: 'buyNow' | 'auction';
  onCheckBuyNow: () => void;
  onCheckAuction: () => void;
}

export const SecondarySaleFormSwitcher: React.FC<Props> = React.memo(
  ({ selected, onCheckBuyNow: handleCheckBuyNow, onCheckAuction: handleCheckAuction }) => {
    const { t } = useTranslation();

    return (
      <Box>
        <Typography variant="h3" sx={sx.title}>
          {t('secondarySale.formTitle')}
        </Typography>
        <Box display={'flex'} gap={2} mt={2.5}>
          <CheckCard
            svgName="buy-now"
            label="pages.createArt.buyNow"
            value={selected === 'buyNow'}
            onChange={handleCheckBuyNow}
          />
          <CheckCard
            svgName="auction"
            label="pages.createArt.auction"
            value={selected === 'auction'}
            onChange={handleCheckAuction}
          />
        </Box>
      </Box>
    );
  },
);

const sx: Record<string, SxProps<Theme>> = {};
