import React from 'react';
import { ArtPageWrapper } from './ArtPageWrapper';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import { Loader } from 'components/UiKit/Loader/Loader';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtPreviewMedia } from 'components/Art/ArtPreviewMedia';
import { useFetchArt } from 'pages/Art/utils/useFetchArt';
import { ArtPriceAndViews } from 'pages/Art/ArtPriceAndViews';
import { ArtTags } from 'components/Art/ArtTags';
import { ArtAuthorAndOwner } from 'pages/Art/ArtAuthorAndOwner';
import { ArtLocationAndEvent } from 'components/Art/ArtLocationAndEvent';
import { ArtDescription } from 'components/Art/ArtDescription';
import { ArtMoneyDistribution } from 'pages/Art/MoneyDistribution/ArtMoneyDistribution';
import { ArtActionButton } from 'pages/Art/ArtActionButton/ArtActionButton';
import { ArtAuctionStatus } from 'pages/Art/ArtAuctionStatus/ArtAuctionStatus';
import { ArtDrawer } from 'pages/Art/ArtDrawer/ArtDrawer';
import { isAuction } from 'helpers/isAuction';
import { ArtAvailableCopies } from 'pages/Art/ArtAvailableCopies';
import { ArtHistorySingle } from 'pages/Art/ArtHistory/ArtHistorySingle';
import { ArtBodyTokenTypeEnum } from 'codegen-api/api-totemo-service/models';
import { ArtTabs } from 'pages/Art/ArtTabs/ArtTabs';
import { ArtBuyNowStatus } from 'pages/Art/ArtBuyNowStatus/ArtBuyNowStatus';
import { UpdateArtSockets } from 'pages/Art/utils/UpdateArtSockets';
import { ArtPopups } from 'pages/Art/ArtPopups/ArtPopups';
import ArtViews from './ArtViews';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import { EMPTY_USER, useAppState } from '../../contexts/appState/AppContext';
import ArtFollow from './ArtFollow';

const ArtPageContent = () => {
  const { state } = useArtDetailedView();
  useFetchArt();

  const isAuctionArt = isAuction(state.saleData);
  const isMobileScreen = useIsMobileScreen();
  const { currentUser } = useAppState();

  if (!state.art || state.isLoading) {
    return <Loader />;
  }


  return (
    <Box sx={sx.wrapper}>
      <Box>
        <ArtPreviewMedia art={state.art} />
      </Box>
      <Box sx={sx.info}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h1">
            {state.art.name}
          </Typography>

          {!isMobileScreen && (
            <Box display="flex" gap={2}>
              <ArtViews count={state.art.viewsCount} />
              {currentUser.wallet !== EMPTY_USER.wallet && <ArtFollow />}
            </Box>
          )
          }
        </Box>

        <Box sx={sx.sellInfo}>
          <ArtPriceAndViews />

          <ArtAvailableCopies art={state.art} />

          {isAuctionArt ? <ArtAuctionStatus /> : <ArtBuyNowStatus />}
        </Box>

        {!isMobileScreen && <ArtActionButton />}

        <ArtLocationAndEvent art={state.art} />

        {isMobileScreen && <ArtTags art={state.art} />}

        <ArtAuthorAndOwner />

        {state.art.description ? <ArtDescription art={state.art} /> : null}

        {!isMobileScreen && <ArtTags art={state.art} />}

        {state.art.tokenType === ArtBodyTokenTypeEnum.ERC721 ? <ArtHistorySingle /> : <ArtTabs />}

        <ArtMoneyDistribution />

        {isMobileScreen && <ArtActionButton />}

        <ArtDrawer />

        <ArtPopups />

        <UpdateArtSockets />
      </Box>

    </Box>
  );
};

export const ArtPage = () => {
  return (
    <ArtPageWrapper>
      <ArtPageContent />
    </ArtPageWrapper>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  wrapper: theme => ({
    pt: 1.25,
    px: 2.5,
    pb: 2.5,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      gap: '110px',
    },
  }),
  info: {
    maxWidth: '520px',
    width: '100%',
  },
  sellInfo: theme => ({
    display: 'flex',
    flexDirection: 'column',
    mt: 5,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  }),
};
