import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Box, Typography, Collapse } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { InputType } from '../../../types';

interface Props {
  items: InputType[];
}

const LinkList: FC<Props> = ({ items }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Box sx={sx.container}>
      <Collapse collapsedSize={70} in={isOpen}>
        <Box sx={sx.list}>
          {
            items.map((item: InputType) => (
              <Link
                href={item.link}
                target="_blank"
                sx={sx.link}
              >
                <OpenInNew sx={sx.openIcon} />
                <Typography
                  sx={sx.name}>{item.name}</Typography>
              </Link>
            ))
          }
        </Box>

      </Collapse>
      {items.length > 2 && (
        <Link
          onClick={() => setIsOpen(!isOpen)}
          sx={sx.collapseButton}>
          {isOpen ? t('profilePage.seeLess') : t('profilePage.seeAllLnk')}
        </Link>
      )}
    </Box>
  );
};

export default LinkList;


const sx: Record<string, SxProps<Theme>> = {
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    textDecoration: 'none',
    width: '100%',
    '& svg': {
      color: theme => theme.palette.grey[600],
    },
    ':hover': {
      color: theme => theme.palette.accent.main,
      '& svg': {
        color: theme => theme.palette.accent.main,
      },
    },
  },
  openIcon: {
    width: '16px',
    height: '16px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  collapseButton: {
    color: theme => theme.palette.grey[600],
    fontSize: '14px',
    textDecoration: 'none',
    cursor: 'pointer',
    ':hover': {
      color: theme => theme.palette.accent.main,
    },
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 600,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
};
