import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { useAppState } from 'contexts/appState/AppContext';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import { SignInLayout } from 'components/SignInLayout/SignInLayout';
import { BaseModal, BaseModalProps } from 'components/Modal/BaseModal';
import { WalletAlreadyInUse } from 'components/Modal/WalletAlreadyInUse/WalletAlreadyInUse';
import { LoginBtn } from 'components/LoginBtn/LoginBtn';

export const ConnectWallet: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const state = useAppState();
  const navigate = useNavigate();
  const hasWallet = !!state.currentUser.wallet;
  const [isConfirmSkipOpen, setIsConfirmSkipOpen] = React.useState<boolean>(false);
  const [isWrongWallet, setIsWrongWallet] = React.useState<boolean>(false);
  const [lastTriedWallet, setLastTriedWallet] = React.useState<string>('');

  React.useEffect(() => {
    if (!hasWallet) return;

    navigate(routes.home);
  }, [hasWallet, navigate]);

  if (hasWallet) {
    return null;
  }

  const handleError = (e: any) => {
    if (e.response?.data?.message === 'Wallet already in use') {
      setIsWrongWallet(true);
    }
  };

  return (
    <SignInLayout>
      <Typography variant="h2" textAlign="center" fontSize={'30px !important'} mt={6.5}>
        {t('connectWallet.title')}
      </Typography>
      <Typography variant="h4" textAlign="center" mt={3.75}>
        {t('connectWallet.subTitle')}
      </Typography>
      <Typography variant="paragraphSmall" textAlign="center" color="text.secondary" mt={0.5}>
        {t('connectWallet.description')}
      </Typography>
      <Box sx={sx.connectContainer}>
        <Box sx={sx.metamaskLogo}>
          <img alt="logo" src={'/assets/icons/metamask.svg'} />
          <Typography variant="paragraphLarge" sx={sx.metamaskLogoTitle}>
            {t('common.metamask')}
          </Typography>
        </Box>
        <LoginBtn onError={handleError} updateWallet={true} onManualConnect={setLastTriedWallet} />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button variant="text" size={'small'} sx={sx.skipBtn} onClick={() => setIsConfirmSkipOpen(true)}>
          {t('connectWallet.skipForNow')}
        </Button>
      </Box>
      <ConfirmModal open={isConfirmSkipOpen} handleClose={() => setIsConfirmSkipOpen(false)} />
      <WalletAlreadyInUse open={isWrongWallet} handleClose={() => setIsWrongWallet(false)} wallet={lastTriedWallet} />
    </SignInLayout>
  );
});

const ConfirmModal: FC<BaseModalProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BaseModal {...props}>
      <Box px={1} pb={3}>
        <Typography variant="h2" textAlign="center">
          {t('common.areYouSure')}
        </Typography>
        <Typography color={'text.secondary'} mt={2.5} textAlign={'center'}>
          {t('connectWallet.ifYouSkip')}
        </Typography>
        <Box mt={2.5}>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(routes.home)}>
            {t('common.continue')}
          </Button>
        </Box>
        <Box mt={2.5}>
          <LoginBtn variant="outlined" fullWidth sx={{ mb: 0, width: '100%' }} />
        </Box>
      </Box>
    </BaseModal>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  connectContainer: {
    bgcolor: 'grey.100',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    mt: 5,
  },
  metamaskLogo: {
    display: 'flex',
    alignItems: 'center',
    pt: 3.75,
    pb: 2.5,
  },
  metamaskLogoTitle: {
    ml: 2.5,
  },
  skipBtn: {
    fontWeight: 600,
    m: 2,
  },
};
