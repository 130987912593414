import React, {ReactNode, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select as BaseSelect, SelectChangeEvent,} from '@mui/material';
import {useTranslation} from 'react-i18next';

type Props = {
  name: string;
  label?: string;
  value?: string;
  onChange(event: SelectChangeEvent, child?: ReactNode): void;
  items: { value: string | number; label: string }[];
};

const Select: React.FC<Props> = ({name, value, label, onChange, items}) => {
  const {t} = useTranslation();
  const [selectedValue, setSelectedValue] = useState(value || '');

  const handleOnChange = (e: SelectChangeEvent) => {
    setSelectedValue(e.target.value);
    onChange(e);
  }

  return (
    <FormControl fullWidth>
      {!!label && <InputLabel id={name + 'label'}>{t(label!)}</InputLabel>}
      <BaseSelect
        id={name}
        labelId={name + 'label'}
        value={selectedValue}
        label={label}
        onChange={handleOnChange}
        onClose={console.log}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {t(item.label)}
          </MenuItem>
        ))}
      </BaseSelect>
    </FormControl>
  );
};

export default Select;
