import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MoreIcon } from 'components/Icons/more_action.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useContextMenu } from 'components/UiKit/ContextMenu/useContextMenu';
import { Art, UpdateArtDtoArtStatusEnum } from 'codegen-api/api-totemo-service/models';
import { useApiContext } from 'contexts/ApiContext';
import { NonMovableContainer } from 'components/UiKit/NonMovableContainer/NonMovableContainer';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';

const AdminPanelCreatedArtsExplore: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const api = useApiContext();
  const navigate = useNavigate();
  const [arts, setArts] = React.useState<Art[]>([]);
  const { anchorEl, handleOpenMenu, handleCloseMenu, getDataValue } = useContextMenu();

  const handleRedirectToReorderPage = () => {
    const id = getDataValue('id');

    if (typeof id === 'string') {
      const idx = arts.findIndex((work) => work.id === parseInt(id));

      // redirect to selected art position
      const yPosition = Math.abs(idx * 80);

      navigate(routes.adminPanelCreatedArtsExploreReorder, { state: yPosition });
    }

    handleCloseMenu();
  };

  const handleMoveToDraftsItem = () => {
    const id = getDataValue('id');

    if (typeof id === 'string') {
      setArts(arts?.filter((work) => work.id !== parseInt(id)));
    }

    const moveToDrafts = async () => {
      try {
        await api.art.artControllerUpdate({ artStatus: UpdateArtDtoArtStatusEnum.DRAFT, id });
      } catch (e) {}
    };

    moveToDrafts();
    handleCloseMenu();
  };

  useEffect(() => {
    api.art
      // @ts-ignore
      .artControllerFindAll('PROMO')
      .then((res) => setArts((res.data.items as Art[]) || []))
      .catch();
  }, [api.art]);

  if (!arts.length) return <Box sx={sx.emptyTab}>{t('adminPanel.tabExploreEmpty')}</Box>;

  return (
    <>
      <Box component="ul" sx={sx.list}>
        {arts.map((work) => {
          return (
            <Box key={work.id} sx={sx.work} data-id={work.id}>
              <NonMovableContainer sx={sx.nonMovable}>
                <Avatar sx={sx.preview} alt={work.name} src={work.artImage.url} variant="rounded" />
                <Box sx={sx.artName} component={Link} to={`${routes.art}/${work.id}`}>
                  {work.name}
                </Box>
                <IconButton sx={sx.icon} onClick={handleOpenMenu}>
                  <MoreIcon />
                </IconButton>
              </NonMovableContainer>
            </Box>
          );
        })}
      </Box>

      <Menu
        id={'item menu'}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': '' }}
      >
        <MenuItem onClick={handleRedirectToReorderPage}>{t('adminPanel.reorderTitle')}</MenuItem>
        <MenuItem onClick={handleMoveToDraftsItem}>{t('adminPanel.moveToDrafts')}</MenuItem>
      </Menu>
    </>
  );
});

export default AdminPanelCreatedArtsExplore;

const sx: Record<string, SxProps<Theme>> = {
  emptyTab: {
    color: 'grey.600',
    mt: 5,
    textAlign: 'center',
  },
  list: {
    my: 0.5,
    px: 0,
    listStyleType: 'none',
  },
  nonMovable: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    height: '100%',
  },
  work: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    py: 1.25,
    bgcolor: 'background.default',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: 'grey.300',
  },
  icon: {
    color: 'grey.600',
    fontSize: 0,
    p: 0,
    flex: '0 0 auto',
    mr: 1.5,
  },
  preview: {
    mr: 1.5,
    flex: '0 0 auto',
  },
  artName: {
    fontWeight: 400,
    mr: 1.5,
    flexGrow: 1,
    color: 'inherit',
    textDecoration: 'none',
  },
};
