import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useSecondarySaleMultipleValidation = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    price: Yup.number()
      .positive(t('forms.errors.positiveOnly'))
      .min(0.001, t('forms.errors.minValue', { value: 0.001 }))
      .required(t('forms.errors.fieldRequired')),
  });
};
