import React from 'react';
import * as Yup from 'yup';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { BaseModal } from 'components/Modal/BaseModal';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import { useFormik } from 'formik';
import { StyledSwitch } from 'components/UiKit/StyledSwitch/StyledSwitch';
import { DEFAULT_MONEY_DISTRIBUTION_PRIMARY } from 'helpers/moneyDistributionHelpers';
import { useApiContext } from 'contexts/ApiContext';
import { PrimarySaleData, SecondarySaleData } from 'types';
import { ethers } from 'ethers';

interface Props {
  isOpen: boolean;
  distributionValues: PrimarySaleData;
  secondarySaleData: SecondarySaleData;
  onClose: (value?: PrimarySaleData) => void;
}

interface FormValues {
  address: string;
  addressPercentage: number;
  artistPercentage: number;
  servicePercentage: number;
  totemoPercentage: number;
  includeOptionalAddress: boolean;
}

export const AdminPanelMoneyDistributionEditPrimary: React.FC<Props> = React.memo(
  ({ isOpen, distributionValues, onClose: handleClose, secondarySaleData }) => {
    return (
      <BaseModal open={isOpen} handleClose={() => handleClose()} mx={2.5}>
        <EditForm distributionValues={distributionValues} onClose={handleClose} secondarySaleData={secondarySaleData} />
      </BaseModal>
    );
  },
);

export const EditForm: React.FC<Omit<Props, 'isOpen'>> = React.memo(
  ({ distributionValues, onClose: handleClose, secondarySaleData }) => {
    const { t } = useTranslation();
    const { appSettings } = useApiContext();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [formError, setFormError] = React.useState<string>('');
    const validationSchema = useValidationSchema();

    const onSubmit = React.useCallback(
      async (values: FormValues) => {
        if (isSubmitting) return;

        const sumError = validateFieldsSum(values);
        if (sumError) {
          setFormError(sumError);
          return;
        }

        setIsSubmitting(true);
        try {
          await appSettings.appSettingsControllerUpdateMoneyDistributionConfig({
            moneyDistribution: {
              primarySale: {
                address: (values.includeOptionalAddress && values.address) || null,
                addressPercentage: (values.includeOptionalAddress && values.addressPercentage) || null,
                artistPercentage: values.artistPercentage,
                servicePercentage: values.servicePercentage,
                totemoPercentage: values.totemoPercentage,
              },
              secondarySale: { ...secondarySaleData },
            },
          });
          handleClose(values);
        } catch (e) {}
        setIsSubmitting(false);
      },
      [appSettings, handleClose, isSubmitting, secondarySaleData],
    );

    const formik = useFormik<FormValues>({
      initialValues: {
        artistPercentage: distributionValues.artistPercentage,
        totemoPercentage: distributionValues.totemoPercentage,
        servicePercentage: distributionValues.servicePercentage,
        address: distributionValues.address || '',
        addressPercentage: distributionValues.addressPercentage || 0,
        includeOptionalAddress: !!distributionValues.address,
      },
      validationSchema,
      onSubmit,
    });

    const { handleSubmit, handleChange, errors, touched, setValues, values } = formik;

    const handleResetToDefaults = () => {
      setValues({
        ...DEFAULT_MONEY_DISTRIBUTION_PRIMARY,
        includeOptionalAddress: !!DEFAULT_MONEY_DISTRIBUTION_PRIMARY.addressPercentage,
      });
    };

    return (
      <>
        <Typography mb={3.75} variant={'h2'}>
          {t('adminPanel.moneyDistributionEditPrimaryTitle')}
        </Typography>
        <form onSubmit={handleSubmit} onChange={handleChange}>
          <OutlinedTextInput
            value={values.artistPercentage}
            sx={sx.input}
            required={true}
            name="artistPercentage"
            id="artistPercentage"
            inputProps={inputNumberProps}
            label={t('forms.artist')}
            error={!!(touched.artistPercentage && errors.artistPercentage)}
            helperText={touched.artistPercentage && t(errors.artistPercentage as string)}
          />
          <OutlinedTextInput
            value={values.totemoPercentage}
            sx={sx.input}
            required={true}
            name="totemoPercentage"
            id="totemoPercentage"
            inputProps={inputNumberProps}
            label={t('forms.totemo')}
            error={!!(touched.totemoPercentage && errors.totemoPercentage)}
            helperText={touched.totemoPercentage && t(errors.totemoPercentage as string)}
          />
          <OutlinedTextInput
            value={values.servicePercentage}
            sx={sx.input}
            required={true}
            name="servicePercentage"
            InputProps={{
              startAdornment: '+',
            }}
            id="servicePercentage"
            inputProps={inputNumberProps}
            label={t('forms.serviceFee')}
            error={!!(touched.servicePercentage && errors.servicePercentage)}
            helperText={touched.servicePercentage && t(errors.servicePercentage as string)}
          />
          <Box sx={sx.switch}>
            <Typography variant="paragraphLarge" fontWeight={600}>
              {t('adminPanel.includingOptionalAddress')}
            </Typography>
            <StyledSwitch id="includeOptionalAddress" checked={values.includeOptionalAddress} />
          </Box>
          {values.includeOptionalAddress && (
            <>
              <OutlinedTextInput
                required={true}
                sx={sx.input}
                value={values.addressPercentage}
                name="addressPercentage"
                id="addressPercentage"
                inputProps={inputNumberProps}
                label={t('forms.optionalAddressPercent')}
                error={!!(touched.addressPercentage && errors.addressPercentage)}
                helperText={touched.addressPercentage && t(errors.addressPercentage as string)}
              />
              <OutlinedTextInput
                required={true}
                value={values.address}
                name="address"
                id="address"
                label={t('forms.optionalAddress')}
                error={!!(touched.address && errors.address)}
                helperText={touched.address && t(errors.address as string)}
              />
            </>
          )}
          <Box display={'flex'} justifyContent={'center'} mt={5}>
            <AsyncButton isLoading={isSubmitting} color={'accent'} variant={'contained'} type={'submit'}>
              {t('common.save')}
            </AsyncButton>
          </Box>
          {formError && (
            <Typography mt={1} variant={'caption'} color={'error.main'} component="div" textAlign={'center'}>
              {t(formError)}
            </Typography>
          )}
          <Box display={'flex'} justifyContent={'center'} mt={2.5}>
            <Button type={'button'} onClick={handleResetToDefaults}>
              {t('forms.resetToDefault')}
            </Button>
          </Box>
        </form>
      </>
    );
  },
);

const sx: Record<string, SxProps<Theme>> = {
  input: {
    mb: 2.5,
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 3.75,
  },
};

const useValidationSchema = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return Yup.object().shape({
      artistPercentage: Yup.number()
        .min(0, t('forms.errors.minValue', { value: 0 }))
        .max(100, t('forms.errors.maxValue', { value: 100 }))
        .integer(t('forms.errors.integersOnly'))
        .required(t('forms.errors.fieldRequired')),
      totemoPercentage: Yup.number()
        .min(0, t('forms.errors.minValue', { value: 0 }))
        .max(100, t('forms.errors.maxValue', { value: 100 }))
        .integer(t('forms.errors.integersOnly'))
        .required(t('forms.errors.fieldRequired')),
      servicePercentage: Yup.number()
        .min(0, t('forms.errors.minValue', { value: 0 }))
        .max(10, t('forms.errors.maxValue', { value: 10 }))
        .integer(t('forms.errors.integersOnly'))
        .required(t('forms.errors.fieldRequired')),
      addressPercentage: Yup.number().when('includeOptionalAddress', {
        is: true,
        then: Yup.number()
          .min(1, t('forms.errors.minValue', { value: 1 }))
          .max(100, t('forms.errors.maxValue', { value: 100 }))
          .integer(t('forms.errors.integersOnly'))
          .required(t('forms.errors.fieldRequired')),
      }),
      address: Yup.string().when('includeOptionalAddress', {
        is: true,
        then: Yup.string()
          .required(t('forms.errors.fieldRequired'))
          .test('validEtherAddress', t('forms.errors.addressInvalid'), function (value: string | undefined) {
            return ethers.utils.isAddress(value!);
          }),
      }),
    });
  }, [t]);
};

const validateFieldsSum = (values: FormValues): string => {
  if (values.includeOptionalAddress) {
    return values.artistPercentage + values.totemoPercentage + values.addressPercentage === 100
      ? ''
      : 'forms.errors.artistTotemoOptionalAddress100';
  }
  return values.artistPercentage + values.totemoPercentage === 100 ? '' : 'forms.errors.artistTotemo100';
};

const inputNumberProps = { type: 'number' };
