import React from 'react';
import { Box, Container, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { Footer } from 'components/Footer/Footer';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { adminPanelRoutes, routes } from 'app-settings';
import { Header } from 'components/Header';

const tabs: { route: typeof adminPanelRoutes[keyof typeof adminPanelRoutes]; tKey: string }[] = [
  { tKey: 'adminPanel.usersTitle', route: adminPanelRoutes.users },
  { tKey: 'adminPanel.createdArtsTitle', route: adminPanelRoutes.createdArts },
  { tKey: 'adminPanel.finalizeAuctionTitle', route: adminPanelRoutes.finalizeAuction },
  { tKey: 'adminPanel.eventsTitle', route: adminPanelRoutes.events },
  { tKey: 'adminPanel.featuredArtistTitle', route: adminPanelRoutes.featuredArtist },
  { tKey: 'adminPanel.moneyDistributionTitle', route: adminPanelRoutes.moneyDistribution },
  { tKey: 'adminPanel.accessRequestsTitle', route: adminPanelRoutes.accessRequests },
];

const AdminPanel = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const page = pathname.replace(`${routes.adminPanel}/`, '').split('/')[0];

  const handleChange = (event: React.SyntheticEvent, route: string) => {
    navigate(`${routes.adminPanel}/${route}`);
  };

  return (
    <AdminPanelLayout>
      <Header />
      <Typography variant="h2" sx={sx.pageTitle}>
        {t('adminPanel.pageName')}
      </Typography>
      <Box sx={sx.tabsContainer}>
        <Tabs value={page || tabs[0].route} onChange={handleChange} variant="scrollable" scrollButtons={false}>
          {tabs.map((tab) => (
            <Tab key={tab.route} label={t(tab.tKey)} value={tab.route} />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </AdminPanelLayout>
  );
};

export default AdminPanel;

const AdminPanelLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <BaseLayout>
    <Container fixed sx={sx.container}>
      {children}
    </Container>
    <Footer />
  </BaseLayout>
);

const sx: Record<string, SxProps<Theme>> = {
  container: {
    margin: '0 auto',
  },
  pageTitle: {
    mb: 2.25,
    pl: 2.5,
    py: '30px',
  },
  tabsContainer: (theme) => ({
    position: 'relative',
    borderBottom: 1,
    borderColor: 'divider',
    px: 0,
    [theme.breakpoints.down('sm')]: {
      ':after, :before': {
        content: '""',
        display: 'block',
        height: 'calc(100% + 1px)',
        width: (theme) => theme.spacing(2.5),
        position: 'absolute',
        right: 0,
        top: 0,
        background: 'linear-gradient(270deg, #FFFFFF 18.75%, rgba(255, 255, 255, 0) 100%)',
        zIndex: 1,
      },
      ':before': {
        right: 'auto',
        left: 0,
        background: 'linear-gradient(270deg, #FFFFFF 18.75%, rgba(255, 255, 255, 0) 100%)',
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
      },
    },

    '& .MuiTab-root': {
      px: 0,
      textTransform: 'none',
      fontWeight: 700,
      fontSize: 20,
    },
    '& .MuiTabs-flexContainer': {
      gap: '40px',
      [theme.breakpoints.down('sm')]: {
        ':after, :before': {
          content: '""',
          display: 'block',
          flex: (theme) => `0 0 ${theme.spacing(1.5)}`,
        },
      },
    },
  }),
};
