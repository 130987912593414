import React from 'react';
import { User } from 'codegen-api/api-totemo-service/models';
import { useTranslation } from 'react-i18next';
import { useGrantRole } from 'abi/useGrantRole';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { devLog } from 'helpers';

const AdminPanelUsersManageAuthenticate: React.FC<{ user: User }> = ({ user }) => {
  const { t } = useTranslation();
  const { grantRole, isWaiting } = useGrantRole();

  const handleClickAuthenticate = async () => {
    try {
      const result = await grantRole(user.wallet);
      devLog(result);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AsyncButton isLoading={isWaiting} fullWidth color="primary" variant="contained" onClick={handleClickAuthenticate}>
      {t('adminPanel.authenticateArtistBtn')}
    </AsyncButton>
  );
};

export default AdminPanelUsersManageAuthenticate;
