import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import type { Event } from 'codegen-api/api-totemo-service/models/event';
import { ReactComponent as DeleteIcon } from 'components/Icons/delete_bin.svg';

interface Props {
  event: Event;
  onDelete: (event: Event) => void;
}

export const AdminPanelEventsCard: React.FC<Props> = React.memo(({ event, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Box mt={3.75}>
      <Box sx={sx.poster} style={{ backgroundImage: `url(${event.image.url})` }} />
      <Typography fontWeight={600} mb={1.25}>
        {event.name}
      </Typography>
      <Box display="flex" alignItems="flex-start">
        <Box sx={sx.description}>
          <Typography sx={sx.location}>{event.location}</Typography>
          <Typography sx={sx.link} component="a" href={event.link}>
            {t('common.link')}
          </Typography>
        </Box>
        <Button onClick={() => onDelete(event)} variant="contained" size="medium" color="secondary" sx={sx.deleteBtn}>
          <DeleteIcon />
          <span>{t('common.delete')}</span>
        </Button>
      </Box>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  poster: {
    mb: 2,
    borderRadius: '5px',
    width: '100%',
    height: 0,
    paddingTop: '65%',
    background: 'no-repeat center/cover',
  },
  description: {
    flex: '1 1 auto',
    display: 'grid',
    mr: 2.25,
  },
  location: {
    lineHeight: (theme) => theme.spacing(2.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
  },
  link: {
    lineHeight: (theme) => theme.spacing(2.5),
    display: 'block',
    color: 'text.secondary',
    textDecoration: 'none',
  },
  deleteBtn: {
    py: 1.5,
    flex: '0 0 auto',
    lineHeight: (theme) => theme.spacing(2),
    svg: {
      mr: 1,
      width: (theme) => theme.spacing(2),
      height: (theme) => theme.spacing(2),
    },
  },
};
