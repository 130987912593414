import React, { FC, useMemo } from 'react';
import { User } from 'codegen-api/api-totemo-service/models';
import { useApiContext } from 'contexts/ApiContext';
import { SearchInput } from 'components/UiKit/SearchInput/SearchInput';
import { Box, Container, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { Theme } from '@mui/system';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import BackButton from 'components/UiKit/BackButton/BackButton';
import { Loader } from 'components/UiKit/Loader/Loader';
import { FeaturedArtistsAddList } from './AdminPanelFeaturedArtistsAddList';
import { Header } from 'components/Header';

const AdminPanelFeaturedArtistsAdd: React.FC = () => {
  const { t } = useTranslation();
  const { users: usersApi } = useApiContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [users, setUsers] = React.useState<User[]>([]);
  const [searchString, setSearchString] = React.useState<string>('');
  const [searchDebounced] = useDebounce(searchString, 400);

  const handleAdd = async (user: User) => {
    setUsers(users.filter((artist) => artist.id !== user.id));
    await usersApi.userControllerUpdateFeatureArtist(user.wallet, 'true');
  };

  const handleChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => setSearchString(e.target.value);

  React.useEffect(() => {
    usersApi
      .userControllerFindAll(undefined, 'true', 'false')
      .then((resp) => {
        setUsers(resp.data.items as unknown as User[]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [usersApi]);

  const filteredUsers = useMemo(() => {
    return users.filter((artist) => {
      return (
        artist.username?.toLowerCase().includes(searchDebounced.toLowerCase()) ||
        artist.fullName?.toLowerCase().includes(searchDebounced.toLowerCase())
      );
    });
  }, [searchDebounced, users]);

  if (isLoading) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Box sx={sx.title}>
        <BackButton />
        <Typography variant="h3">{t('adminPanel.addToFeaturedTitle')}</Typography>
      </Box>

      <Box sx={sx.searchContainer}>
        <SearchInput
          onChange={handleChangeSearch}
          id="search"
          placeholder={t('adminPanel.addToFeaturedSearchPlaceholder')}
          fullWidth
        />
      </Box>

      {!filteredUsers.length && searchDebounced.length ? (
        <>
          <Typography variant="paragraphLarge" fontWeight={600} component="p">
            {t('adminPanel.usersNoResultsTitle')}
          </Typography>

          <Typography variant="paragraphMedium" color="text.secondary">
            {t('adminPanel.noResultsAddFeaturedArtist', { artistName: searchDebounced })}
          </Typography>
        </>
      ) : (
        <FeaturedArtistsAddList artists={filteredUsers} onAddFeaturedArtist={handleAdd} />
      )}
    </ContentWrapper>
  );
};

const ContentWrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <BaseLayout>
      <Container fixed sx={{ m: '0 auto' }}>
        <Header />
        <Box sx={sx.contentRoot}>{children}</Box>
      </Container>
    </BaseLayout>
  );
};

export default AdminPanelFeaturedArtistsAdd;

const sx: Record<string, SxProps<Theme>> = {
  contentRoot: {
    mt: -1,
    px: 2.5,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  searchContainer: {
    my: 2.5,
  },
};
