import type { SxProps, Theme } from '@mui/material/styles';

export const adminPanelSx: Record<string, SxProps<Theme>> = {
  subTabs: {
    minHeight: (theme) => theme.spacing(4.5),
    mb: 2.5,
    '& .MuiTab-root': {
      color: 'grey.600',
      textTransform: 'none',
      fontWeight: 600,
      lineHeight: '21px',
      fontSize: '14px',
      p: 1,
      m: 0,
      flex: '1 1 30%',
      position: 'relative',
      zIndex: 1,
      minHeight: (theme) => theme.spacing(4.5),
      '&.Mui-selected': {
        color: 'primary.main',
      },
    },
    '& .MuiTabs-indicator': {
      height: '100%',
      bgcolor: 'grey.200',
      borderRadius: (theme) => theme.spacing(11.25),
    },
  },
};
