import {
  Art,
  ArtBodyTokenTypeEnum,
  BuyNow,
  CreateBuyNowDtoStatusEnum,
  SaleTransactionInfo,
} from 'codegen-api/api-totemo-service/models';

export const useAvailableCopies = (art: Art): number | null => {
  if (art.tokenType !== ArtBodyTokenTypeEnum.ERC1155) return null;
  return (art.buyNows || []).reduce((acc: number, item: BuyNow) => {
    if (item.status !== CreateBuyNowDtoStatusEnum.ACTIVE) return acc;

    const lockedCopies = (item.transactions || []).reduce(
      (lockedAmount: number, tx: SaleTransactionInfo) => lockedAmount + tx.amount,
      0,
    );

    return acc + item.amount - lockedCopies;
  }, 0);
};
