import { FormikErrors, FormikValues } from "formik";

export const getFieldErrorNames = (formikErrors: FormikErrors<FormikValues>): string[] => {
  const transformObjectToDotNotation = (obj: FormikErrors<FormikValues>, prefix = '', result: string[] = []) => {
    Object.keys(obj).forEach((key) => {
      // @ts-ignore
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        // @ts-ignore
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};