import { Box, Chip } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useMarketplaceContext } from '../State/MarketplaceContext';
import { Tag } from 'codegen-api/api-totemo-service/models';

interface Props {
  tags: Tag[];
}

export const PopularTags: FC<Props> = ({ tags }: Props) => {
  const { state, dispatch } = useMarketplaceContext();

  useEffect(() => {
    if (!!state.filters.tags?.length) {
      dispatch({ type: 'CHECK_POPULAR_TAGS', payload: state.filters.tags });
    } else {
      dispatch({ type: 'CHECK_POPULAR_TAGS', payload: [{ name: 'All', id: undefined }] });
    }
  }, [dispatch, state.filters.tags]);

  const handleCheckTag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const currentTag = tags.find((item) => item.name === e.currentTarget.dataset.tag);
    const isCurrentTagInChecked = !!state.checkedTags.find((item) => item.name === e.currentTarget.dataset.tag);

    if (currentTag?.name === 'All') {
      dispatch({ type: 'CHECK_POPULAR_TAGS', payload: [{ name: 'All', id: undefined }] });
      dispatch({ type: 'CHECK_TAGS', payload: [] });
      return;
    }

    if (!isCurrentTagInChecked) {
      const filtered = state.checkedTags?.filter((item) => item.name !== 'All');
      dispatch({ type: 'CHECK_POPULAR_TAGS', payload: [...filtered!, currentTag!] });
      dispatch({ type: 'CHECK_TAGS', payload: [] });
      return;
    }

    if (state.checkedTags.length > 1) {
      const checked = state.checkedTags.filter((item) => item.name !== currentTag?.name);
      dispatch({ type: 'CHECK_POPULAR_TAGS', payload: checked });
      return;
    }

    dispatch({ type: 'CHECK_POPULAR_TAGS', payload: [{ name: 'All', id: undefined }] });
  };

  const checkedTagsColorsMap = state.checkedTags.reduce((acc: Record<string, boolean>, item) => {
    acc[item.name] = true;
    return acc;
  }, {});

  return (
    <Box sx={sx.tags}>
      {tags.map((tag) => {
        return (
          <Chip
            key={tag.id}
            variant="filled"
            clickable
            size="small"
            color={checkedTagsColorsMap[tag.name] ? 'primary' : 'default'}
            label={tag.name}
            onClick={handleCheckTag}
            data-tag={tag.name}
          />
        );
      })}
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  tags: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    gap: 1.2,
    mb: 2.5,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MuiChip-root': {
      height: theme.spacing(4.375),
      px: 0.2,
    },
  }),
};
