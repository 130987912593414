import React from 'react';
import { SecondarySaleFormSwitcher } from 'pages/SecondarySale/utils/SecondarySaleFormSwitcher';
import { useSecondarySaleSingle } from 'pages/SecondarySale/SecondarySaleSingle/SecondarySaleSingleProvider';

export const SecondarySaleSingleFormSwitcher: React.FC = React.memo(() => {
  const { state, dispatch } = useSecondarySaleSingle();

  return (
    <SecondarySaleFormSwitcher
      selected={state.saleType}
      onCheckBuyNow={() => dispatch({ type: 'SET_SALE_TYPE', payload: 'buyNow' })}
      onCheckAuction={() => dispatch({ type: 'SET_SALE_TYPE', payload: 'auction' })}
    />
  );
});
