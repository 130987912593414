import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { SignInLayout } from 'components/SignInLayout/SignInLayout';
import { LoginBtn } from 'components/LoginBtn/LoginBtn';

export const WalletSignInRequired: React.FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <SignInLayout>
      <Typography variant="h1" textAlign="center">
        <Trans i18nKey={'walletSignInRequired.title'} components={[<br />]} />
      </Typography>
      <Typography variant="h5" textAlign="center" color={'text.secondary'} mt={3.75}>
        {t('walletSignInRequired.subtitle')}
      </Typography>
      <Box sx={sx.connectContainer}>
        <Box sx={sx.metamaskLogo}>
          <img alt="logo" src={'/assets/icons/metamask.svg'} />
          <Typography variant="paragraphLarge" sx={sx.metamaskLogoTitle}>
            {t('common.metamask')}
          </Typography>
        </Box>
        <LoginBtn />
      </Box>
    </SignInLayout>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  connectContainer: {
    bgcolor: 'grey.100',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    mt: 5,
    p: 3.75,
  },
  metamaskLogo: {
    display: 'flex',
    alignItems: 'center',
    pb: 2.5,
  },
  metamaskLogoTitle: {
    ml: 2.5,
  },
};
