import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { Footer } from 'components/Footer/Footer';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import ImageUploader from 'components/UiKit/ImageUploader/ImageUploader';
import { useFormik } from 'formik';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import * as Yup from 'yup';
import { useApiContext } from 'contexts/ApiContext';
import { Header } from 'components/Header';

type FormValues = {
  name: string;
  location: string;
  link: string;
  file: File[];
};

const formInitialValues = {
  name: '',
  location: '',
  link: '',
  file: [],
};

export const AdminPanelEventsCreate: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = useApiContext();
  const validationSchema = useEventsCreateValidation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async ({ file, name, location, link }: FormValues) => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        await api.events.eventsControllerCreate(file[0] as unknown as string, name, link, location);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
      navigate(routes.adminPanelEvents);
    },
    [isLoading, navigate, api.events],
  );

  const formik = useFormik<FormValues>({
    initialValues: formInitialValues,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, handleChange, errors, touched, setFieldValue, setFieldError } = formik;

  return (
    <BaseLayout>
      <Container fixed>
        <Header />
        <Box px={2.5} pb={8.75}>
          <Typography variant="h2" sx={sx.title}>
            {t('adminPanel.createEventTitle')}
          </Typography>
          <form onSubmit={handleSubmit} onChange={handleChange}>
            <ImageUploader
              name={'file'}
              accept={'image/png,image/jpeg'}
              onChange={(files) => setFieldValue('file', files)}
              onError={(error) => setFieldError('file', error)}
              max={1}
              text={t('forms.eventImageNote')}
              errorText={touched.file && (errors.file as string)}
            />
            <Typography variant="h4" align={'left'} mt={2} mb={-0.5}>
              {t('adminPanel.eventInformation')}
            </Typography>
            <OutlinedTextInput
              sx={sx.input}
              required={true}
              name="name"
              id="name"
              autoComplete={'off'}
              label={t('forms.name')}
              error={!!(touched.name && errors.name)}
              helperText={touched.name && t(errors.name as string)}
            />
            <OutlinedTextInput
              sx={sx.input}
              required={true}
              name="location"
              id="location"
              autoComplete={'off'}
              label={t('forms.location')}
              error={!!(touched.location && errors.location)}
              helperText={touched.location && t(errors.location as string)}
            />
            <OutlinedTextInput
              sx={sx.input}
              required={true}
              name="link"
              id="link"
              autoComplete={'off'}
              label={t('forms.link')}
              error={!!(touched.link && errors.link)}
              helperText={touched.link && t(errors.link as string)}
            />
            <AsyncButton
              fullWidth
              isLoading={isLoading}
              sx={sx.saveBtn}
              variant="contained"
              color="accent"
              type="submit"
            >
              {t('common.save')}
            </AsyncButton>
          </form>
        </Box>
      </Container>
      <Footer />
    </BaseLayout>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  title: {
    mb: 3.75,
  },
  saveBtn: {
    mt: 5,
  },
  input: {
    mt: 2.5,
  },
};

const useEventsCreateValidation = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return Yup.object().shape({
      name: Yup.string()
        .trim()
        .required(t('forms.errors.fieldRequired'))
        .max(90, t('forms.errors.fieldMaxLength', { length: 90 })),
      location: Yup.string()
        .trim()
        .required(t('forms.errors.fieldRequired'))
        .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
      link: Yup.string()
        .trim()
        .required(t('forms.errors.fieldRequired'))
        .max(255, t('forms.errors.fieldMaxLength', { length: 255 }))
        .url(t('forms.errors.urlFormat')),
      image: Yup.array().min(1, t('forms.errors.fieldRequired')),
    });
  }, [t]);
};
