import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArtOwnerItemSx as sx } from 'pages/Art/ArtTabs/ArtOwnerItemSx';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc } from 'app-settings';
import type { ArtOwner, BuyNow } from 'codegen-api/api-totemo-service/models';
import { getPriceWithoutFeeETH } from 'pages/CreateEditArtPage/utils';
import { ArtOwnerSaleAction } from 'pages/Art/ArtTabs/ArtOwnerSaleItem/ArtOwnerSaleAction';
import { useServiceFee } from 'pages/Art/utils/useServiceFee';

interface Props {
  artOwner: ArtOwner;
  buyNow: BuyNow;
}

export const ArtOwnerSaleItem: React.FC<Props> = React.memo(({ artOwner, buyNow }) => {
  const { t } = useTranslation();
  const serviceFee = useServiceFee(buyNow);

  const originalPrice = React.useMemo(() => {
    if (!buyNow.marketPrice) return null;

    return getPriceWithoutFeeETH(String(buyNow.marketPrice), String(serviceFee));
  }, [serviceFee, buyNow.marketPrice]);

  if (!artOwner) return null;

  return (
    <Box sx={sx.itemContainer}>
      <Avatar src={artOwner.owner.settings?.avatar?.url || defaultAvatarSrc} sx={sx.avatar} />
      <Box flex={'1 1 auto'}>
        <Typography variant={'paragraphMedium'} color={'primary.main'} fontWeight={600}>
          {originalPrice}Ξ
        </Typography>
        <Typography variant={'paragraphSmall'} display={'block'} color={'primary.main'}>
          {t('artPage.sellerName', { name: artOwner.owner.username })}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <ArtOwnerSaleAction buyNow={buyNow} />
      </Box>
    </Box>
  );
});
