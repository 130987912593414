/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface ArtBody
 */
export interface ArtBody {
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    file?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    preview?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    tokenType?: ArtBodyTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    ownerWallet?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    authorWallet?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    name?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    tokenId?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    description?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    eventId?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    location?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    tags?: any | null;
    /**
     * 
     * @type {number}
     * @memberof ArtBody
     */
    amount?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    key?: any;
    /**
     * 
     * @type {any}
     * @memberof ArtBody
     */
    moneyDistribution?: any;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    artStatus?: ArtBodyArtStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ArtBody
     */
    isGift?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum ArtBodyTokenTypeEnum {
    ERC721 = 'ERC721',
    ERC1155 = 'ERC1155'
}
/**
    * @export
    * @enum {string}
    */
export enum ArtBodyArtStatusEnum {
    PROMO = 'PROMO',
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED'
}

