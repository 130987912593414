import React from 'react';
import { useApiContext } from 'contexts/ApiContext';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { useParams } from 'react-router-dom';
import { useSocketPurchaseConfirmCallback } from 'hooks/useSocketPurchaseConfirmCallback';
import { devLog } from 'helpers';
import type { BuyNow } from 'codegen-api/api-totemo-service/models';

export const useFetchArt = () => {
  const { artId } = useParams<{ artId: string }>();
  const api = useApiContext();
  const { dispatch } = useArtDetailedView();
  const [reloadCounter, setReloadCounter] = React.useState<number>(0);

  const socketBuyNowCallback = React.useCallback(() => {
    setReloadCounter(reloadCounter + 1);
  }, [reloadCounter]);

  useSocketPurchaseConfirmCallback(socketBuyNowCallback, Number(artId));

  React.useEffect(() => {
    if (!artId) return;

    const fetchArt = async () => {
      try {
        const art = (await api.art.artControllerFindOneOrFail(artId)).data;
        if (art.buyNows.length > 1) {
          art.buyNows.sort((a: BuyNow, b: BuyNow) => a.marketPrice - b.marketPrice);
        }

        if (art.auctions?.length) {
          art.auctions[0].bids = [];
          const lastBid = (await api.bids.bidControllerPaginate(art.auctions[0].id, 1, 1)).data.items[0];
          lastBid && (art.auctions[0].bids[0] = lastBid);
        }
        // do not await counter
        api.art.artControllerView(+artId).catch();

        dispatch({ type: 'SET_ART', payload: art });
      } catch (e) {
        devLog(e);
      }
    };

    fetchArt();
  }, [api.art, api.bids, artId, dispatch, reloadCounter]);
};
