import React, { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc } from 'app-settings';
import Box from '@mui/material/Box';
import { User } from 'codegen-api/api-totemo-service/models';
import { SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface Props {
  artist: User;
  onClick(): void;
}

export const ArtStudioLink: FC<Props> = ({ artist, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={sx.wrapper}>
      <Typography variant={'paragraphSmall'} component={"p"} sx={sx.paragraph}>{t('pages.marketplace.visitArtStudio')}</Typography>
      <Box sx={sx.user} onClick={onClick}>
        <Avatar sx={sx.avatar} alt={artist?.username} src={artist?.settings?.avatar?.url || defaultAvatarSrc} />
        <Box sx={sx.username}>{artist?.username}</Box>
      </Box>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  wrapper: {
    mt: 4,
    borderTop: (theme) => `solid 1px ${theme.palette.grey[300]}`,
    px: 2.5,
  },
  paragraph: {
    display: 'inline-block',
    mt: 1.4,
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    minHeight: (theme) => theme.spacing(8.5),
    bgcolor: 'background.default',
  },
  username: {
    fontWeight: 600,
    mx: 2,
    flexGrow: 1,
  },
  avatar: {
    flex: '0 0 auto',
  },
};
