/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Art } from '../models';
import { ArtFilterSettingsDto } from '../models';
import { ArtPaginatedDto } from '../models';
import { DeleteResult } from '../models';
import { UpdateArtDto } from '../models';
/**
 * ArtApi - axios parameter creator
 * @export
 */
export const ArtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} file 
         * @param {string} preview 
         * @param {string} tokenType 
         * @param {string} ownerWallet 
         * @param {string} authorWallet 
         * @param {string} name 
         * @param {string} tokenId 
         * @param {string} description 
         * @param {string} eventId 
         * @param {string} location 
         * @param {string} tags 
         * @param {number} amount 
         * @param {string} key 
         * @param {any} moneyDistribution 
         * @param {string} artStatus 
         * @param {string} isGift 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerCreate: async (file: string, preview: string, tokenType: string, ownerWallet: string, authorWallet: string, name: string, tokenId: string, description: string, eventId: string, location: string, tags: string, amount: number, key: string, moneyDistribution: any, artStatus: string, isGift: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'preview' is not null or undefined
            if (preview === null || preview === undefined) {
                throw new RequiredError('preview','Required parameter preview was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'tokenType' is not null or undefined
            if (tokenType === null || tokenType === undefined) {
                throw new RequiredError('tokenType','Required parameter tokenType was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'ownerWallet' is not null or undefined
            if (ownerWallet === null || ownerWallet === undefined) {
                throw new RequiredError('ownerWallet','Required parameter ownerWallet was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'authorWallet' is not null or undefined
            if (authorWallet === null || authorWallet === undefined) {
                throw new RequiredError('authorWallet','Required parameter authorWallet was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'tokenId' is not null or undefined
            if (tokenId === null || tokenId === undefined) {
                throw new RequiredError('tokenId','Required parameter tokenId was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'description' is not null or undefined
            if (description === null || description === undefined) {
                throw new RequiredError('description','Required parameter description was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'tags' is not null or undefined
            if (tags === null || tags === undefined) {
                throw new RequiredError('tags','Required parameter tags was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount','Required parameter amount was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'moneyDistribution' is not null or undefined
            if (moneyDistribution === null || moneyDistribution === undefined) {
                throw new RequiredError('moneyDistribution','Required parameter moneyDistribution was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'artStatus' is not null or undefined
            if (artStatus === null || artStatus === undefined) {
                throw new RequiredError('artStatus','Required parameter artStatus was null or undefined when calling artControllerCreate.');
            }
            // verify required parameter 'isGift' is not null or undefined
            if (isGift === null || isGift === undefined) {
                throw new RequiredError('isGift','Required parameter isGift was null or undefined when calling artControllerCreate.');
            }
            const localVarPath = `/art`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            if (preview !== undefined) { 
                localVarFormParams.append('preview', preview as any);
            }

            if (tokenType !== undefined) { 
                localVarFormParams.append('tokenType', tokenType as any);
            }

            if (ownerWallet !== undefined) { 
                localVarFormParams.append('ownerWallet', ownerWallet as any);
            }

            if (authorWallet !== undefined) { 
                localVarFormParams.append('authorWallet', authorWallet as any);
            }

            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }

            if (tokenId !== undefined) { 
                localVarFormParams.append('tokenId', tokenId as any);
            }

            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }

            if (eventId !== undefined) { 
                localVarFormParams.append('eventId', eventId as any);
            }

            if (location !== undefined) { 
                localVarFormParams.append('location', location as any);
            }

            if (tags !== undefined) { 
                localVarFormParams.append('tags', tags as any);
            }

            if (amount !== undefined) { 
                localVarFormParams.append('amount', amount as any);
            }

            if (key !== undefined) { 
                localVarFormParams.append('key', key as any);
            }

            if (moneyDistribution !== undefined) { 
                localVarFormParams.append('moneyDistribution', moneyDistribution as any);
            }

            if (artStatus !== undefined) { 
                localVarFormParams.append('artStatus', artStatus as any);
            }

            if (isGift !== undefined) { 
                localVarFormParams.append('isGift', isGift as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerDeleteDraft: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling artControllerDeleteDraft.');
            }
            const localVarPath = `/art/{id}/draft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFeedOfArts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/art/feedOfArts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page page
         * @param {number} limit limit
         * @param {string} [authorId] filter by artist id
         * @param {string} [ownerId] filter by owner id
         * @param {number} [minPriceArt] minimum price for art
         * @param {number} [maxPriceArt] maximum price for art
         * @param {string} [tags] art tags separated by \&quot;,\&quot; example &amp;tags&#x3D;1,2,3
         * @param {number} [eventId] art event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFilter: async (page: number, limit: number, authorId?: string, ownerId?: string, minPriceArt?: number, maxPriceArt?: number, tags?: string, eventId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling artControllerFilter.');
            }
            // verify required parameter 'limit' is not null or undefined
            if (limit === null || limit === undefined) {
                throw new RequiredError('limit','Required parameter limit was null or undefined when calling artControllerFilter.');
            }
            const localVarPath = `/art/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (minPriceArt !== undefined) {
                localVarQueryParameter['minPriceArt'] = minPriceArt;
            }

            if (maxPriceArt !== undefined) {
                localVarQueryParameter['maxPriceArt'] = maxPriceArt;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['eventId'] = eventId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFilterSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/art/filter/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array&lt;string&gt;} [artStatuses] 
         * @param {string} [authorWallet] 
         * @param {string} [ownerWallet] 
         * @param {boolean} [isGift] 
         * @param {boolean} [orderByName] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFindAll: async (artStatuses?: Array<string>, authorWallet?: string, ownerWallet?: string, isGift?: boolean, orderByName?: boolean, limit?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/art`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (artStatuses) {
                localVarQueryParameter['artStatuses'] = artStatuses;
            }

            if (authorWallet !== undefined) {
                localVarQueryParameter['authorWallet'] = authorWallet;
            }

            if (ownerWallet !== undefined) {
                localVarQueryParameter['ownerWallet'] = ownerWallet;
            }

            if (isGift !== undefined) {
                localVarQueryParameter['isGift'] = isGift;
            }

            if (orderByName !== undefined) {
                localVarQueryParameter['orderByName'] = orderByName;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFindOneOrFail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling artControllerFindOneOrFail.');
            }
            const localVarPath = `/art/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerGetArtForExternalApp: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling artControllerGetArtForExternalApp.');
            }
            const localVarPath = `/art/external/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerGetJustSold: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/art/justsold`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateArtDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerUpdate: async (body: UpdateArtDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling artControllerUpdate.');
            }
            const localVarPath = `/art`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array&lt;Art&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerUpdateArtOrder: async (body: Array<Art>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling artControllerUpdateArtOrder.');
            }
            const localVarPath = `/art/order/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} file 
         * @param {string} preview 
         * @param {string} name 
         * @param {string} description 
         * @param {string} location 
         * @param {string} eventId 
         * @param {string} tags 
         * @param {number} amount 
         * @param {string} key 
         * @param {any} moneyDistribution 
         * @param {string} artStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerUpdateDraft: async (id: number, file: string, preview: string, name: string, description: string, location: string, eventId: string, tags: string, amount: number, key: string, moneyDistribution: any, artStatus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'preview' is not null or undefined
            if (preview === null || preview === undefined) {
                throw new RequiredError('preview','Required parameter preview was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'description' is not null or undefined
            if (description === null || description === undefined) {
                throw new RequiredError('description','Required parameter description was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'tags' is not null or undefined
            if (tags === null || tags === undefined) {
                throw new RequiredError('tags','Required parameter tags was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount','Required parameter amount was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'moneyDistribution' is not null or undefined
            if (moneyDistribution === null || moneyDistribution === undefined) {
                throw new RequiredError('moneyDistribution','Required parameter moneyDistribution was null or undefined when calling artControllerUpdateDraft.');
            }
            // verify required parameter 'artStatus' is not null or undefined
            if (artStatus === null || artStatus === undefined) {
                throw new RequiredError('artStatus','Required parameter artStatus was null or undefined when calling artControllerUpdateDraft.');
            }
            const localVarPath = `/art/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearer required


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }

            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            if (preview !== undefined) { 
                localVarFormParams.append('preview', preview as any);
            }

            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }

            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }

            if (location !== undefined) { 
                localVarFormParams.append('location', location as any);
            }

            if (eventId !== undefined) { 
                localVarFormParams.append('eventId', eventId as any);
            }

            if (tags !== undefined) { 
                localVarFormParams.append('tags', tags as any);
            }

            if (amount !== undefined) { 
                localVarFormParams.append('amount', amount as any);
            }

            if (key !== undefined) { 
                localVarFormParams.append('key', key as any);
            }

            if (moneyDistribution !== undefined) { 
                localVarFormParams.append('moneyDistribution', moneyDistribution as any);
            }

            if (artStatus !== undefined) { 
                localVarFormParams.append('artStatus', artStatus as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} artId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerView: async (artId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'artId' is not null or undefined
            if (artId === null || artId === undefined) {
                throw new RequiredError('artId','Required parameter artId was null or undefined when calling artControllerView.');
            }
            const localVarPath = `/art/{artId}/view`
                .replace(`{${"artId"}}`, encodeURIComponent(String(artId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtApi - functional programming interface
 * @export
 */
export const ArtApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} file 
         * @param {string} preview 
         * @param {string} tokenType 
         * @param {string} ownerWallet 
         * @param {string} authorWallet 
         * @param {string} name 
         * @param {string} tokenId 
         * @param {string} description 
         * @param {string} eventId 
         * @param {string} location 
         * @param {string} tags 
         * @param {number} amount 
         * @param {string} key 
         * @param {any} moneyDistribution 
         * @param {string} artStatus 
         * @param {string} isGift 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerCreate(file: string, preview: string, tokenType: string, ownerWallet: string, authorWallet: string, name: string, tokenId: string, description: string, eventId: string, location: string, tags: string, amount: number, key: string, moneyDistribution: any, artStatus: string, isGift: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Art>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerCreate(file, preview, tokenType, ownerWallet, authorWallet, name, tokenId, description, eventId, location, tags, amount, key, moneyDistribution, artStatus, isGift, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerDeleteDraft(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResult>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerDeleteDraft(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerFeedOfArts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Art>>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerFeedOfArts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} page page
         * @param {number} limit limit
         * @param {string} [authorId] filter by artist id
         * @param {string} [ownerId] filter by owner id
         * @param {number} [minPriceArt] minimum price for art
         * @param {number} [maxPriceArt] maximum price for art
         * @param {string} [tags] art tags separated by \&quot;,\&quot; example &amp;tags&#x3D;1,2,3
         * @param {number} [eventId] art event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerFilter(page: number, limit: number, authorId?: string, ownerId?: string, minPriceArt?: number, maxPriceArt?: number, tags?: string, eventId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Art>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerFilter(page, limit, authorId, ownerId, minPriceArt, maxPriceArt, tags, eventId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerFilterSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtFilterSettingsDto>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerFilterSettings(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array&lt;string&gt;} [artStatuses] 
         * @param {string} [authorWallet] 
         * @param {string} [ownerWallet] 
         * @param {boolean} [isGift] 
         * @param {boolean} [orderByName] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerFindAll(artStatuses?: Array<string>, authorWallet?: string, ownerWallet?: string, isGift?: boolean, orderByName?: boolean, limit?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtPaginatedDto>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerFindAll(artStatuses, authorWallet, ownerWallet, isGift, orderByName, limit, page, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerFindOneOrFail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Art>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerFindOneOrFail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerGetArtForExternalApp(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerGetArtForExternalApp(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerGetJustSold(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Art>>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerGetJustSold(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateArtDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerUpdate(body: UpdateArtDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Art>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerUpdate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array&lt;Art&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerUpdateArtOrder(body: Array<Art>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Art>>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerUpdateArtOrder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} file 
         * @param {string} preview 
         * @param {string} name 
         * @param {string} description 
         * @param {string} location 
         * @param {string} eventId 
         * @param {string} tags 
         * @param {number} amount 
         * @param {string} key 
         * @param {any} moneyDistribution 
         * @param {string} artStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerUpdateDraft(id: number, file: string, preview: string, name: string, description: string, location: string, eventId: string, tags: string, amount: number, key: string, moneyDistribution: any, artStatus: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Art>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerUpdateDraft(id, file, preview, name, description, location, eventId, tags, amount, key, moneyDistribution, artStatus, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} artId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async artControllerView(artId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ArtApiAxiosParamCreator(configuration).artControllerView(artId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ArtApi - factory interface
 * @export
 */
export const ArtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} file 
         * @param {string} preview 
         * @param {string} tokenType 
         * @param {string} ownerWallet 
         * @param {string} authorWallet 
         * @param {string} name 
         * @param {string} tokenId 
         * @param {string} description 
         * @param {string} eventId 
         * @param {string} location 
         * @param {string} tags 
         * @param {number} amount 
         * @param {string} key 
         * @param {any} moneyDistribution 
         * @param {string} artStatus 
         * @param {string} isGift 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerCreate(file: string, preview: string, tokenType: string, ownerWallet: string, authorWallet: string, name: string, tokenId: string, description: string, eventId: string, location: string, tags: string, amount: number, key: string, moneyDistribution: any, artStatus: string, isGift: string, options?: any): AxiosPromise<Art> {
            return ArtApiFp(configuration).artControllerCreate(file, preview, tokenType, ownerWallet, authorWallet, name, tokenId, description, eventId, location, tags, amount, key, moneyDistribution, artStatus, isGift, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerDeleteDraft(id: string, options?: any): AxiosPromise<DeleteResult> {
            return ArtApiFp(configuration).artControllerDeleteDraft(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFeedOfArts(options?: any): AxiosPromise<Array<Art>> {
            return ArtApiFp(configuration).artControllerFeedOfArts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page page
         * @param {number} limit limit
         * @param {string} [authorId] filter by artist id
         * @param {string} [ownerId] filter by owner id
         * @param {number} [minPriceArt] minimum price for art
         * @param {number} [maxPriceArt] maximum price for art
         * @param {string} [tags] art tags separated by \&quot;,\&quot; example &amp;tags&#x3D;1,2,3
         * @param {number} [eventId] art event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFilter(page: number, limit: number, authorId?: string, ownerId?: string, minPriceArt?: number, maxPriceArt?: number, tags?: string, eventId?: number, options?: any): AxiosPromise<Art> {
            return ArtApiFp(configuration).artControllerFilter(page, limit, authorId, ownerId, minPriceArt, maxPriceArt, tags, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFilterSettings(options?: any): AxiosPromise<ArtFilterSettingsDto> {
            return ArtApiFp(configuration).artControllerFilterSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array&lt;string&gt;} [artStatuses] 
         * @param {string} [authorWallet] 
         * @param {string} [ownerWallet] 
         * @param {boolean} [isGift] 
         * @param {boolean} [orderByName] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFindAll(artStatuses?: Array<string>, authorWallet?: string, ownerWallet?: string, isGift?: boolean, orderByName?: boolean, limit?: number, page?: number, options?: any): AxiosPromise<ArtPaginatedDto> {
            return ArtApiFp(configuration).artControllerFindAll(artStatuses, authorWallet, ownerWallet, isGift, orderByName, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerFindOneOrFail(id: string, options?: any): AxiosPromise<Art> {
            return ArtApiFp(configuration).artControllerFindOneOrFail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerGetArtForExternalApp(id: number, options?: any): AxiosPromise<void> {
            return ArtApiFp(configuration).artControllerGetArtForExternalApp(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerGetJustSold(options?: any): AxiosPromise<Array<Art>> {
            return ArtApiFp(configuration).artControllerGetJustSold(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateArtDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerUpdate(body: UpdateArtDto, options?: any): AxiosPromise<Art> {
            return ArtApiFp(configuration).artControllerUpdate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array&lt;Art&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerUpdateArtOrder(body: Array<Art>, options?: any): AxiosPromise<Array<Art>> {
            return ArtApiFp(configuration).artControllerUpdateArtOrder(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} file 
         * @param {string} preview 
         * @param {string} name 
         * @param {string} description 
         * @param {string} location 
         * @param {string} eventId 
         * @param {string} tags 
         * @param {number} amount 
         * @param {string} key 
         * @param {any} moneyDistribution 
         * @param {string} artStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerUpdateDraft(id: number, file: string, preview: string, name: string, description: string, location: string, eventId: string, tags: string, amount: number, key: string, moneyDistribution: any, artStatus: string, options?: any): AxiosPromise<Art> {
            return ArtApiFp(configuration).artControllerUpdateDraft(id, file, preview, name, description, location, eventId, tags, amount, key, moneyDistribution, artStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} artId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        artControllerView(artId: number, options?: any): AxiosPromise<string> {
            return ArtApiFp(configuration).artControllerView(artId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArtApi - object-oriented interface
 * @export
 * @class ArtApi
 * @extends {BaseAPI}
 */
export class ArtApi extends BaseAPI {
    /**
     * 
     * @param {string} file 
     * @param {string} preview 
     * @param {string} tokenType 
     * @param {string} ownerWallet 
     * @param {string} authorWallet 
     * @param {string} name 
     * @param {string} tokenId 
     * @param {string} description 
     * @param {string} eventId 
     * @param {string} location 
     * @param {string} tags 
     * @param {number} amount 
     * @param {string} key 
     * @param {any} moneyDistribution 
     * @param {string} artStatus 
     * @param {string} isGift 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerCreate(file: string, preview: string, tokenType: string, ownerWallet: string, authorWallet: string, name: string, tokenId: string, description: string, eventId: string, location: string, tags: string, amount: number, key: string, moneyDistribution: any, artStatus: string, isGift: string, options?: any) {
        return ArtApiFp(this.configuration).artControllerCreate(file, preview, tokenType, ownerWallet, authorWallet, name, tokenId, description, eventId, location, tags, amount, key, moneyDistribution, artStatus, isGift, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerDeleteDraft(id: string, options?: any) {
        return ArtApiFp(this.configuration).artControllerDeleteDraft(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerFeedOfArts(options?: any) {
        return ArtApiFp(this.configuration).artControllerFeedOfArts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} page page
     * @param {number} limit limit
     * @param {string} [authorId] filter by artist id
     * @param {string} [ownerId] filter by owner id
     * @param {number} [minPriceArt] minimum price for art
     * @param {number} [maxPriceArt] maximum price for art
     * @param {string} [tags] art tags separated by \&quot;,\&quot; example &amp;tags&#x3D;1,2,3
     * @param {number} [eventId] art event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerFilter(page: number, limit: number, authorId?: string, ownerId?: string, minPriceArt?: number, maxPriceArt?: number, tags?: string, eventId?: number, options?: any) {
        return ArtApiFp(this.configuration).artControllerFilter(page, limit, authorId, ownerId, minPriceArt, maxPriceArt, tags, eventId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerFilterSettings(options?: any) {
        return ArtApiFp(this.configuration).artControllerFilterSettings(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {Array&lt;string&gt;} [artStatuses] 
     * @param {string} [authorWallet] 
     * @param {string} [ownerWallet] 
     * @param {boolean} [isGift] 
     * @param {boolean} [orderByName] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerFindAll(artStatuses?: Array<string>, authorWallet?: string, ownerWallet?: string, isGift?: boolean, orderByName?: boolean, limit?: number, page?: number, options?: any) {
        return ArtApiFp(this.configuration).artControllerFindAll(artStatuses, authorWallet, ownerWallet, isGift, orderByName, limit, page, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerFindOneOrFail(id: string, options?: any) {
        return ArtApiFp(this.configuration).artControllerFindOneOrFail(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerGetArtForExternalApp(id: number, options?: any) {
        return ArtApiFp(this.configuration).artControllerGetArtForExternalApp(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerGetJustSold(options?: any) {
        return ArtApiFp(this.configuration).artControllerGetJustSold(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateArtDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerUpdate(body: UpdateArtDto, options?: any) {
        return ArtApiFp(this.configuration).artControllerUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {Array&lt;Art&gt;} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerUpdateArtOrder(body: Array<Art>, options?: any) {
        return ArtApiFp(this.configuration).artControllerUpdateArtOrder(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} id 
     * @param {string} file 
     * @param {string} preview 
     * @param {string} name 
     * @param {string} description 
     * @param {string} location 
     * @param {string} eventId 
     * @param {string} tags 
     * @param {number} amount 
     * @param {string} key 
     * @param {any} moneyDistribution 
     * @param {string} artStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerUpdateDraft(id: number, file: string, preview: string, name: string, description: string, location: string, eventId: string, tags: string, amount: number, key: string, moneyDistribution: any, artStatus: string, options?: any) {
        return ArtApiFp(this.configuration).artControllerUpdateDraft(id, file, preview, name, description, location, eventId, tags, amount, key, moneyDistribution, artStatus, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} artId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtApi
     */
    public artControllerView(artId: number, options?: any) {
        return ArtApiFp(this.configuration).artControllerView(artId, options).then((request) => request(this.axios, this.basePath));
    }
}
