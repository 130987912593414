import type { SxProps, Theme } from '@mui/material/styles';

export const moneyDistributionSx: Record<string, SxProps<Theme>> = {
  list: (theme) => ({
    mb: 5,
    [theme.breakpoints.up('sm')]: {
      border: '1px solid',
      borderRadius: '10px',
      borderColor: theme.palette.grey['300'],
      padding: '40px',
    },

  }),
  item: {
    display: 'flex',
    mb: 3.75,
    alignItems: 'center',
    height: '50px',
  },
  name: {
    flex: '1 1 auto',
  },
  container: (theme) => ({
    [theme.breakpoints.up('sm')]: {},
  }),
};
