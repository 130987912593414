import React from 'react';
import { Box, Container, SxProps, Typography } from '@mui/material';
import { Footer } from '../../components/Footer/Footer';
import { BaseLayout } from '../../components/BaseLayout/BaseLayout';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';
import { CreateArtContext, steps } from './useCreateEditArtReducer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../app-settings';
import CurrentStep from './CurrentStep';
import Title from './Title';
import Description from './Description';
import { Loader } from '../../components/UiKit/Loader/Loader';
import { commonSx as sx } from './styles';
import useEditArtForm from './useEditArtForm';
import { Header } from '../../components/Header';

const EditArtPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state, dispatch, isInitialized } = useEditArtForm();

  if (!isInitialized) {
    return (
      <Box sx={sx.loader}>
        <Loader />
      </Box>
    );
  }

  return (
    <CreateArtContext.Provider value={{ state, dispatch }}>
      <BaseLayout>
        <Container fixed sx={{ m: '0 auto' }}>
          <Header />
          <Container sx={sx.container}>
            <Box
              sx={{ ...sx.inlineFlex, ...sx.backWrapper } as SxProps<Theme>}
              onClick={() => navigate(routes.adminPanel)}
            >
              <ArrowBackIcon fontSize="small" sx={sx.backIcon} />
              <Typography variant="body1" sx={{ ...sx.back, ...sx.zeroIndent } as SxProps<Theme>}>
                {t('pages.createArt.backToAdminPanel')}
              </Typography>
            </Box>

            <Box sx={{ ...sx.inlineFlex, ...sx.titleWrapper, ...sx.between } as SxProps<Theme>}>
              <Typography variant="h2" sx={sx.zeroIndent}>
                <Title />
              </Typography>
              <Typography variant="h3" color="primary.main" sx={sx.zeroIndent}>
                {steps[state.currentStep]}/2
              </Typography>
            </Box>

            {state.currentStep !== 'FILL_SALE_INFO' && (
              <Typography variant="body2" color="text.secondary" sx={sx.description}>
                <Description />
              </Typography>
            )}

            <CurrentStep />
          </Container>
        </Container>

        <Footer />

        {state.isLoading && (
          <Box sx={sx.loader}>
            <Loader />
          </Box>
        )}
      </BaseLayout>
    </CreateArtContext.Provider>
  );
};

export default EditArtPage;
