import React from 'react';
import { CssBaseline } from '@mui/material';
import SingletonProviders from './contexts/SingletonProviders';
import { WrongNetworkModal } from 'components/Modal/WrongNetworkModal/WrongNetworkModal';
import Router from './Router';

function App() {
  return (
    <SingletonProviders>
      <CssBaseline />
      <Router />
      <WrongNetworkModal />
    </SingletonProviders>
  );
}

export default App;
