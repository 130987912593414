import { useFormik } from 'formik';
import { MarketPlaceFormValues } from './MarketPlaceFormValues';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Button, Chip, Divider, SxProps, TextField, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'components/Icons/close.svg';
import Slider from '@mui/material/Slider';
import { ReactComponent as Ether } from 'components/Icons/ether.svg';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { ReactComponent as ArrowIcon } from 'components/Icons/arrow_down.svg';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import { User, Event, Tag } from 'codegen-api/api-totemo-service/models';
import { useApiContext } from 'contexts/ApiContext';
import { useDebounce } from 'use-debounce';
import { useMarketplaceContext } from '../State/MarketplaceContext';
import { useMarketplaceFiltersValidationSchema } from './ValidationSchema';
import { useFilterCounter } from '../hooks/useFilterCounter';

interface Props {
  onFiltersReset(): void;

  checkedTags: Tag[];
}

export const MarketplaceFilterForm: FC<Props> = ({ checkedTags, onFiltersReset: handleFiltersReset }) => {
  const { t } = useTranslation();

  const { tags: tagApi } = useApiContext();

  const { state, dispatch } = useMarketplaceContext();

  const { artists, events, filters, maxPriceOnMarketPlace } = state;

  // tags
  const [isTagSelectOpen, setTagSelectOpen] = useState<boolean>(false);
  const [tagOptionsLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue] = useDebounce(state.searchTagValue, 400);

  React.useEffect(() => {
    const fetchTags = async () => {
      if (isTagSelectOpen) {
        try {
          setIsLoading(true);
          const tags = (await tagApi.tagControllerFindAll(10, searchValue || undefined)).data;
          dispatch({ type: 'FETCH_TAGS', payload: tags });
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTags();
  }, [dispatch, isTagSelectOpen, searchValue, tagApi]);

  const artistsOptions = useMemo(() => {
    return artists.map((artist: User) => ({ value: artist.id, label: artist.username }));
  }, [artists]);

  const eventsOptions = useMemo(() => {
    return events.map((event: Event) => ({ value: event.id, label: event.name }));
  }, [events]);

  const formInitialValues = useMemo(() => {
    return {
      artist: filters.artist || '',
      minPrice: filters.minPrice!,
      maxPrice: filters.maxPrice!,
      tags: filters.tags?.filter((tag) => tag.name !== 'All') || [],
      events: filters.events || '',
    };
  }, [filters.artist, filters.events, filters.maxPrice, filters.minPrice, filters.tags]);

  const handleCloseFilters = (): void => {
    dispatch({ type: 'CLOSE_FILTER_MODAL' });
  };

  const handleFiltersSubmit = async (filters: Partial<MarketPlaceFormValues>) => {
    dispatch({ type: 'FILL_FILTER', payload: filters });
  };

  const onSubmit = (fieldsToSend: Partial<MarketPlaceFormValues>) => {
    handleFiltersSubmit(fieldsToSend);
    handleCloseFilters();
  };

  const validationSchema = useMarketplaceFiltersValidationSchema();

  const formik = useFormik<MarketPlaceFormValues>({
    initialValues: formInitialValues,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, handleChange, errors, touched, values, setFieldValue, setValues } = formik;

  const handleReset = () => {
    setValues(formInitialValues);
    handleFiltersReset();
  };

  // populate tags from marketplace page
  useEffect(() => {
    if (checkedTags && !filters.tags?.length) {
      setFieldValue('tags', checkedTags);
    }
  }, [checkedTags, filters.tags?.length, setFieldValue]);

  const handlePriceFilter = useCallback(
    (_event, value) => {
      setFieldValue('minPrice', value[0]);
      setFieldValue('maxPrice', value[1]);
    },
    [setFieldValue],
  );

  const handleChangeSearchTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SEARCH_TAGS_CHANGED', payload: e.target.value });
  };

  const handleAddTag = (_event: React.SyntheticEvent, value: (Tag | string)[], reason: string) => {
    if (reason === 'createOption' || reason === 'selectOption') {
      setFieldValue('tags', value);
    }
  };

  const handleDeleteTag = (label: string) => () => {
    setFieldValue(
      'tags',
      values.tags.filter((item) => item.name !== label),
    );
  };

  const filtersCounter = useFilterCounter(values);
  const priceRangeStep = getPriceStep(maxPriceOnMarketPlace);

  return (
    <form onChange={handleChange} onSubmit={handleSubmit} noValidate>
      <Box sx={sx.container}>
        <Box sx={sx.buttonContainer}>
          <Button variant={'contained'} size={'small'} sx={sx.filterButton} onClick={handleReset}>
            {t('pages.marketplace.filterWithCount', {
              count: filtersCounter,
            })}
          </Button>

          <IconButton sx={sx.close} onClick={handleCloseFilters}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        <Typography variant={'h4'} textAlign="left" mt={2}>
          {t('pages.marketplace.artist')}
        </Typography>
        <Autocomplete
          sx={sx.artistInput}
          options={artistsOptions.map((artist) => artist.label)}
          onChange={(_event, value) => setFieldValue('artist', value)}
          renderInput={(params) => (
            <OutlinedTextInput
              {...params}
              name="artist"
              id="artist"
              label={t('pages.marketplace.artist')}
              error={!!(touched.artist && errors.artist)}
            />
          )}
          disableClearable
          popupIcon={<ArrowIcon />}
          value={values.artist}
        />

        <Typography variant={'h4'} textAlign="left" sx={sx.headings}>
          {t('pages.marketplace.priceRange')}
        </Typography>
        <Slider
          name="slider"
          id="slider"
          size="small"
          max={maxPriceOnMarketPlace}
          min={0}
          step={priceRangeStep}
          valueLabelDisplay="auto"
          value={[values.minPrice, values.maxPrice]}
          onChange={handlePriceFilter}
          sx={sx.priceSlider}
          defaultValue={[filters.minPrice!, filters.maxPrice!]}
        />
        <Box sx={sx.priceFilterInputs}>
          <TextField
            name="minPrice"
            id="minPrice"
            type="number"
            inputMode="decimal"
            value={values.minPrice}
            variant="filled"
            sx={sx.priceInput}
            InputProps={{
              startAdornment: <Ether />,
            }}
            error={!!errors.minPrice}
            helperText={touched.minPrice && t(errors.minPrice as string)}
          />
          <TextField
            name="maxPrice"
            id="maxPrice"
            type="number"
            inputMode="decimal"
            value={values.maxPrice}
            variant="filled"
            sx={sx.priceInput}
            InputProps={{
              startAdornment: <Ether />,
            }}
            error={!!errors.maxPrice}
            helperText={touched.maxPrice && t(errors.maxPrice as string)}
          />
        </Box>

        <Typography variant={'h4'} textAlign="left" sx={sx.headings}>
          {t('pages.marketplace.tags')}
        </Typography>
        <Autocomplete
          open={isTagSelectOpen}
          loading={tagOptionsLoading}
          onOpen={() => {
            setTagSelectOpen(true);
          }}
          onClose={() => {
            setTagSelectOpen(false);
          }}
          multiple
          sx={sx.tags}
          id="tags-filled"
          options={state.tags!}
          getOptionLabel={(option: Tag) => option.name}
          freeSolo={false}
          clearIcon={false}
          onChange={handleAddTag}
          renderTags={() => null}
          renderInput={(params) => (
            <OutlinedTextInput {...params} label={t('common.tags')} onChange={handleChangeSearchTags} />
          )}
          disableClearable
          popupIcon={<ArrowIcon />}
        />

        <Box mt={1} sx={sx.chipContainer}>
          {values.tags?.map((item) => (
            <Chip
              sx={sx.chip}
              variant="filled"
              key={item.id}
              label={item.name}
              deleteIcon={<CloseIcon />}
              onDelete={handleDeleteTag(item.name)}
            />
          ))}
        </Box>

        <Typography variant={'h4'} textAlign="left" sx={sx.headings}>
          {t('pages.marketplace.events')}
        </Typography>
        <Autocomplete
          sx={sx.events}
          options={eventsOptions.map((event) => event.label)}
          onChange={(_event, value) => setFieldValue('events', value)}
          renderInput={(params) => (
            <OutlinedTextInput {...params} name="event" id="event" label={t('common.eventsTitle')} />
          )}
          popupIcon={<ArrowIcon />}
          disableClearable
          value={values.events}
        />
      </Box>
      <Box id="fixed-menu" sx={sx.fixedMenu}>
        <Button variant="contained" color="accent" type="submit" fullWidth sx={sx.applyBtn}>
          {t('common.apply')}
        </Button>
      </Box>
    </form>
  );
};

const getPriceStep = (maxPriceOnMarketPlace: number): number => {
  switch (true) {
    case maxPriceOnMarketPlace < 0.1: {
      return 0.001;
    }
    case maxPriceOnMarketPlace < 1: {
      return 0.01;
    }
    case maxPriceOnMarketPlace < 10: {
      return 0.1;
    }
    case maxPriceOnMarketPlace < 100: {
      return 1;
    }
    default: {
      return Math.round(maxPriceOnMarketPlace / 100);
    }
  }
};

const sx: Record<string, SxProps<Theme>> = {
  container: {
    pt: 2.5,
    px: 2.5,
    pb: 0.6,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 3,
  },
  filterButton: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: 'primary.main',
    p: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  select: {
    color: 'primary.main',
    mt: 2,
    '&.MuiInputBase-root': {
      '& .MuiSelect-icon': {
        top: 'calc(50% - 2px)',
        right: 2,
      },
    },
  },
  chip: {
    bgcolor: 'grey.150',
    height: 40,
    borderRadius: 1,
    fontWeight: 'bold',
    '& .MuiChip-deleteIcon': {
      mr: 2,
    },
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  fixedMenu: {
    height: '70px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'common.white',
    mt: 2.5,
    px: 2.5,
    py: 1.2,
  },
  priceFilterInputs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  events: {
    mt: 2,
    color: 'secondary.main',
    '&  .MuiAutocomplete-hasClearIcon': {
      pr: 0,
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '14px',
    },
  },
  headings: { mt: 6.25 },
  artistInput: {
    mt: 2,
    color: 'secondary.main',
    '&  .MuiAutocomplete-hasClearIcon': {
      pr: 0,
    },
  },
  priceSlider: { mt: 1.6 },
  priceInput: {
    width: 132,
    '& .MuiFilledInput-root > svg': {
      color: 'grey.300',
      mr: 2,
    },
    '& .MuiFilledInput-root': {
      height: 44,
    },
  },
  tags: { mt: 2 },
  applyBtn: {
    maxWidth: 335,
  },
};
