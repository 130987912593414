import { goerli, mainnet } from 'viem/chains';

const routingBasePath = process.env.REACT_APP_ROUTING_BASE_PATH;

export const adminPanelRoutes = {
  users: 'users',
  createdArts: 'created-arts',
  createdArtsExplore: 'explore',
  createdArtsGifts: 'gifts',
  createdArtsDrafts: 'drafts',
  createdArtsPublished: 'published',
  createdArtsWorks: 'artworks',
  finalizeAuction: 'finalize-auction',
  events: 'events',
  reorder: 'reorder',
  eventsCreate: 'create',
  featuredArtist: 'featured-artist',
  featuredArtistAdd: 'add',
  moneyDistribution: 'money-distribution',
  moneyDistributionPrimary: 'primary-sale',
  moneyDistributionSecondary: 'secondary-sale',
  accessRequests: 'access-requests',
};

export const routes = {
  home: `${routingBasePath}/`,
  emailVerification: `${routingBasePath}/email-verification`,
  walletSignInRequired: `${routingBasePath}/wallet-sign-in-required`,
  connectWallet: `${routingBasePath}/connect-wallet`,
  wrongWallet: `${routingBasePath}/wrong-wallet`,
  homeEvents: `${routingBasePath}/#events`,
  homeArtists: `${routingBasePath}/#artists`,
  homeExplore: `${routingBasePath}/#explore`,
  homeFaq: `${routingBasePath}/#faq`,
  termsOfServices: `https://docs.google.com/document/d/1qga6DbrfuNDp_6UB0b1DJBcG0-hMYJbc`,
  privacyPolicy: `https://docs.google.com/document/d/17bv1b7GFzdRsRcI3V2U7k92flrNmIr4L`,
  marketplace: `${routingBasePath}/marketplace`,
  events: `${routingBasePath}/events`,
  artists: `${routingBasePath}/artists`,
  adminPanel: `${routingBasePath}/admin-panel`,
  adminPanelUsers: `${routingBasePath}/admin-panel/${adminPanelRoutes.users}`,
  adminPanelCreatedArts: `${routingBasePath}/admin-panel/${adminPanelRoutes.createdArts}`,
  adminPanelCreatedArtsWorks: `${routingBasePath}/admin-panel/${adminPanelRoutes.createdArts}/${adminPanelRoutes.createdArtsWorks}`,
  adminPanelCreatedArtsExplore: `${routingBasePath}/admin-panel/${adminPanelRoutes.createdArts}/${adminPanelRoutes.createdArtsExplore}`,
  adminPanelCreatedArtsGifts: `${routingBasePath}/admin-panel/${adminPanelRoutes.createdArts}/${adminPanelRoutes.createdArtsGifts}`,
  adminPanelCreatedArtsDrafts: `${routingBasePath}/admin-panel/${adminPanelRoutes.createdArts}/${adminPanelRoutes.createdArtsDrafts}`,
  adminPanelCreatedArtsPublished: `${routingBasePath}/admin-panel/${adminPanelRoutes.createdArts}/${adminPanelRoutes.createdArtsPublished}`,
  adminPanelCreatedArtsExploreReorder: `${routingBasePath}/admin-panel/${adminPanelRoutes.createdArts}/${adminPanelRoutes.createdArtsExplore}/${adminPanelRoutes.reorder}`,
  adminPanelFinalizeAuction: `${routingBasePath}/admin-panel/${adminPanelRoutes.finalizeAuction}`,
  adminPanelEvents: `${routingBasePath}/admin-panel/${adminPanelRoutes.events}`,
  adminPanelEventsReorder: `${routingBasePath}/admin-panel/${adminPanelRoutes.events}/${adminPanelRoutes.reorder}`,
  adminPanelEventsCreate: `${routingBasePath}/admin-panel/${adminPanelRoutes.events}/${adminPanelRoutes.eventsCreate}`,
  adminPanelFeaturedArtist: `${routingBasePath}/admin-panel/${adminPanelRoutes.featuredArtist}`,
  adminPanelFeaturedArtistAdd: `${routingBasePath}/admin-panel/${adminPanelRoutes.featuredArtist}/${adminPanelRoutes.featuredArtistAdd}`,
  adminPanelFeaturedArtistReorder: `${routingBasePath}/admin-panel/${adminPanelRoutes.featuredArtist}/${adminPanelRoutes.reorder}`,
  adminPanelMoneyDistribution: `${routingBasePath}/admin-panel/${adminPanelRoutes.moneyDistribution}`,
  adminPanelMoneyDistributionPrimary: `${routingBasePath}/admin-panel/${adminPanelRoutes.moneyDistribution}/${adminPanelRoutes.moneyDistributionPrimary}`,
  adminPanelMoneyDistributionSecondary: `${routingBasePath}/admin-panel/${adminPanelRoutes.moneyDistribution}/${adminPanelRoutes.moneyDistributionSecondary}`,
  adminPanelAccessRequests: `${routingBasePath}/admin-panel/${adminPanelRoutes.accessRequests}`,
  connect: `${routingBasePath}/connect`,
  noWalletSignIn: `${routingBasePath}/sign-in-without-wallet`,
  signUp: `${routingBasePath}/sign-up`,
  notFound: `${routingBasePath}/not-found`,
  userProfile: `${routingBasePath}/profile`,
  createArt: `${routingBasePath}/create-art`,
  editArt: `${routingBasePath}/edit-art`,
  secondarySale: `${routingBasePath}/secondary-sale`,
  art: `${routingBasePath}/art`,
  topArtists: `${routingBasePath}/top-artists`,
  changePassword: `${routingBasePath}/change-password`,
  forgotPassword: `${routingBasePath}/forgot-password`,
} as const;

export const finalizeWindow = 86400_000 * 2;

export const limitArtsPerPage = 15;

export const localStorageKeys = {
  jwt: 'jwt',
  profile: 'profile',
  createArt: 'createArt',
  walletconnect: 'walletconnect',
} as const;

export const socketNotificationTypes = {
  notification: 'notification',
  buyNow: 'buynow',
  bid: 'bid',
  finalize: 'finalize',
  saleStatusChanged: 'salestatuschanged',
  winnerchanged: 'winnerchanged',
  transactionFailed: 'transactionfailed',
  artCreated: 'artcreated',
};

export const defaultAvatarSrc = '/assets/icons/default_profile_avatar.png';
export const defaultAvatarHomepageSrc = '/assets/images/default_avatar_homepage.svg';
export const defaultCoverSrc = '/assets/images/default_cover.png';

export type AppRoute = typeof routes[keyof typeof routes];

export const startDateOptions: { value: 'immediately' | 'specific'; label: string }[] = [
  { value: 'immediately', label: 'common.afterPublish' },
  { value: 'specific', label: 'common.specificDate' },
];

export const BaseGasLimit = BigInt(100_000_000);

export const wc2chains = [goerli, mainnet];
export const defaultChain = process.env.REACT_APP_ENVIRONMENT === 'production' ? mainnet : goerli;

export const wc2ProjectId = '035e5c37581d841260280d38a950af0e';
