import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc } from 'app-settings';
import { UserRolesTKeyMap } from 'components/RequireRole/RequireRole';
import React, { FC } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { User } from 'codegen-api/api-totemo-service/models';

interface Props {
  artist: User;
  white?: boolean;
}

export const ArtistLabel: FC<Props> = ({ artist, white }: Props) => {
  const { t } = useTranslation();

  // nested 'a' tag issue
  /*const handleRedirectToArtistPage = (wallet: string, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`${routes.userProfile}/${wallet}`);
  };*/

  return (
    <Box key={artist.wallet} sx={white ? ({ ...sx.artistCard, ...sx.white } as SxProps<Theme>) : sx.artistCard}>
      <Avatar
        sx={white ? sx.avatarMedium : sx.avatar}
        alt={artist.username}
        src={artist?.settings?.avatar?.url || defaultAvatarSrc}
      />

      <Box sx={sx.nickName}>
        <Typography
          sx={sx.userRole}
          variant="paragraphXSmall"
          color={white ? 'common.white' : 'text.secondary'}
          component="p"
        >
          {t(UserRolesTKeyMap.ARTIST)}
        </Typography>

        <Typography
          fontWeight={600}
          variant="paragraphXSmall"
          color={white ? 'common.white' : 'text.primary'}
          component="p"
        >
          {`@${artist?.username}`}
        </Typography>
      </Box>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  artistCard: {
    display: 'flex',
    alignItems: 'center',
    minHeight: (theme) => theme.spacing(4.1),
    backgroundColor: 'transparent',
  },
  avatar: {
    flex: '0 0 auto',
    width: 24,
    height: 24,
  },
  avatarMedium: {
    flex: '0 0 auto',
    width: 36,
    height: 36,
  },
  nickName: {
    ml: 1.2,
  },
  userRole: {
    fontSize: '12px',
    flexGrow: 1,
  },
  white: {
    position: 'relative',
    zIndex: 1,
  },
};
