import { Box, IconButton, TextField, Divider } from '@mui/material';
import { FieldArrayRenderProps, FormikProps, getIn } from 'formik';
import { FC } from 'react';
import { EditProfileFormValues } from '../EditProfileFormValues';
import { ReactComponent as RemoveIcon } from 'components/Icons/close.svg';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface Props {
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  contentType: string;
  formikProps: FormikProps<EditProfileFormValues>;
  inputNames: [string, string];
  firstValue: string;
  secondValue: string;
}

export const ContentInput: FC<Props> = ({
  arrayHelpers,
  index,
  inputNames,
  firstValue,
  secondValue,
  formikProps,
  contentType,
}: Props) => {
  const { t } = useTranslation();

  const firstHelperText =
    getIn(formikProps.touched, `${contentType}.${index}.${inputNames[0]}`) &&
    getIn(formikProps.errors, `${contentType}.${index}.${inputNames[0]}`);

  const firstHasError =
    Boolean(getIn(formikProps.touched, `${contentType}.${index}.${inputNames[0]}`)) &&
    Boolean(getIn(formikProps.errors, `${contentType}.${index}.${inputNames[0]}`));

  const secondHelperText =
    getIn(formikProps.touched, `${contentType}.${index}.${inputNames[1]}`) &&
    getIn(formikProps.errors, `${contentType}.${index}.${inputNames[1]}`);

  const secondHasError =
    Boolean(getIn(formikProps.touched, `${contentType}.${index}.${inputNames[1]}`)) &&
    Boolean(getIn(formikProps.errors, `${contentType}.${index}.${inputNames[1]}`));

  return (
    <Box>
      <Box sx={sx.inputWrapper}>
        <Box sx={sx.inputRows}>
          <TextField
            label={t(`common.${inputNames[0]}`)}
            variant="outlined"
            name={`${contentType}.${index}.${inputNames[0]}`}
            value={firstValue}
            onChange={formikProps.handleChange}
            error={firstHasError}
            helperText={firstHelperText}
          />
          <TextField
            label={t(`common.${inputNames[1]}`)}
            variant="outlined"
            name={`${contentType}.${index}.${inputNames[1]}`}
            value={secondValue}
            onChange={formikProps.handleChange}
            error={secondHasError}
            helperText={secondHelperText}
          />
        </Box>
        <IconButton color="primary" aria-label="remove" component="span" onClick={() => arrayHelpers.remove(index)}>
          <RemoveIcon />
        </IconButton>
      </Box>
      <Divider />
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  inputWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    '& > span': {
      color: 'grey.600',
    },
    my: 2.5,
  },
  inputRows: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2.5,
  },
};
