import { BuyNow, CreateBuyNowDtoStatusEnum, SaleTransactionInfo } from 'codegen-api/api-totemo-service/models';

export const isBuyNowAvailableMultiple = (buyNow: BuyNow, currentUserWallet: string): boolean => {
  const isSeller = currentUserWallet === buyNow.ownerWallet || currentUserWallet === buyNow.pmWallet;

  if (isSeller || buyNow.status !== CreateBuyNowDtoStatusEnum.ACTIVE) return false;

  const lockedCopies = (buyNow.transactions || []).reduce(
    (lockedAmount: number, tx: SaleTransactionInfo) => lockedAmount + tx.amount,
    0,
  );

  return buyNow.amount - lockedCopies > 0;
};
