import { Box, Button, Typography } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppState } from 'contexts/appState/AppContext';
import { routes } from 'app-settings';

export const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useAppState();
  const isAuth = state.currentUser.email || state.currentUser.wallet;

  return (
    <Box sx={sx.root}>
      <Box mt={'110px'}>
        <img src={'assets/icons/404.svg'} alt="404" />
      </Box>
      <Typography variant="h1" mt={2.75} mb={1.5}>
        {t('notFound.title')}
      </Typography>
      <Typography variant="paragraphLarge" mb={5.25}>
        {t('notFound.subtitle')}
      </Typography>
      <Button variant={'contained'} color={'accent'} onClick={() => navigate(isAuth ? routes.home : routes.signUp)}>
        {t(isAuth ? 'common.goToHome' : 'notFound.goToSignUp')}
      </Button>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: 'grey.200',
  },
};
