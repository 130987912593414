export type SocialNetworks =
  | 'website'
  | 'instagram'
  | 'twitter'
  | 'facebook'
  | 'tiktok'
  | 'discord'
  | 'snapchat'
  | 'youtube'
  | 'clubhouse';

export interface InputType {
  name: SocialNetworks;
  link: string;
}

export type ShopifyCollection = {
  id: string;
  name: string;
};

export type Wallet = {
  wallet: string;
};

export type TypeArts = 'creations' | 'owned';

export const allowedAvatarFormats = ['image/jpeg', 'image/png', 'image/jpg'];
