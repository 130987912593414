import React, { FC, useState, useMemo } from 'react';
import { Avatar, Box, Button, Link, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as UploadCoverIcon } from 'components/Icons/add_image.svg';
import { ReactComponent as MedalIcon } from 'components/Icons/medal_icon.svg';
import { useTranslation } from 'react-i18next';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Theme } from '@mui/material/styles';
import { User } from 'codegen-api/api-totemo-service/models';
import { ProfileTabs } from '../Tabs/ProfileTabs';
import { inputsSocialNetworksConfig } from '../shared';
import CollapsibleParagraph from 'react-collapsible-paragraph';
import { DownBaseModal } from 'components/Modal/DownBaseModal';
import { UploadCoverModal } from './Components/UploadCoverModal';
import { SocialNetworks } from '../../types';
import { ContentModal } from './Components/ContentModal';
import { UserOfferSection } from 'pages/Profile/Layouts/ProfileView/Components/UserOfferSection';
import { defaultAvatarSrc } from 'app-settings';
import { ethers } from 'ethers';
import roundPrice from 'helpers/roundPrice';
import { InviteBar } from 'pages/Profile/Layouts/ProfileView/Components/InviteBar';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import LinkList from './Components/LinkList';
import { ArtistShopifyMerch } from './Components/ShopifyMerch/ArtistShopifyMerch';

interface Props {
  user: User;
  isCurrentUser: boolean;
  hasRightsToEdit: boolean | undefined;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  socialLinksMap: Map<SocialNetworks, string> | undefined;
  isUploadCoverModalOpen: boolean;
  handleUploadCoverModalOpen(): void;
  handleUploadCoverModalClose(): void;
  handleUploadCover(event: React.ChangeEvent<HTMLInputElement>): void;
  background: string;
}

export const ArtistProfile: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { username, fullName, role, settings, bio, artistTotalSale, phoneNumber } = props.user;
  const [isVideosOpen, setVideosOpen] = useState<boolean>(false);
  const [isPodcastsOpen, setPodcastsOpen] = useState<boolean>(false);
  const isMobileScreen = useIsMobileScreen();

  const handleOpenPodcasts = () => {
    setPodcastsOpen(true);
  };

  const handleClosePodcasts = () => {
    setPodcastsOpen(false);
  };

  const handleOpenVideos = () => {
    setVideosOpen(true);
  };

  const handleCloseVideos = () => {
    setVideosOpen(false);
  };

  const contentModalFlow = useMemo(() => {
    if (isVideosOpen) {
      return 'video';
    }
    if (isPodcastsOpen) {
      return 'podcast';
    }
  }, [isPodcastsOpen, isVideosOpen]);

  return (
    <Box sx={sx.pageWrapper}>
      {props.isCurrentUser && <UserOfferSection />}
      <Box sx={props.hasRightsToEdit ? sx.blurCover : sx.cover}>
        <img src={props.background} alt={`${username}-cover`} id="cover-image" />

        <Avatar alt={username} src={settings.avatar?.url || defaultAvatarSrc} sx={sx.avatar} />

        {props.hasRightsToEdit && (
          <>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={sx.uploadCover}
              onClick={props.handleUploadCoverModalOpen}
            >
              <UploadCoverIcon />
            </IconButton>

            <DownBaseModal
              isShowing={props.isUploadCoverModalOpen}
              handleOpen={props.handleUploadCoverModalOpen}
              handleClose={props.handleUploadCoverModalOpen}
            >
              <UploadCoverModal handleClose={props.handleUploadCoverModalClose} onChange={props.handleUploadCover} />
            </DownBaseModal>

            <Button variant="contained" color="primary" size="medium" onClick={props.handleUploadCoverModalOpen}>
              {t('profilePage.changeCoverBtn')}
            </Button>
          </>
        )}
      </Box>

      <Box sx={sx.content}>
        <Box sx={sx.infoWrapper}>
          <Typography variant="h2" textAlign="center" sx={{ mb: 1 }}>
            {username}
          </Typography>
          <Typography variant="paragraphMedium" color="text.secondary" textAlign="center" sx={{ mb: 1 }}>
            {fullName || ''}
          </Typography>
          <Typography variant="paragraphMedium" color="text.secondary" textAlign="center" sx={{ mb: 1 }}>
            {phoneNumber || ''}
          </Typography>
          <Box sx={sx.label}>{role[0].role.toLowerCase()}</Box>
          {props.isCurrentUser ? <InviteBar /> : null}
          {artistTotalSale && (
            <Box sx={sx.marketValue} mt={props.isCurrentUser ? 2.5 : 0}>
              <MedalIcon />
              <Typography variant="paragraphMedium">{t('profilePage.marketValueLabel')}</Typography>
              <Typography noWrap fontWeight={600} variant="paragraphMedium" sx={sx.artistTotalSale}>
                {+artistTotalSale ? roundPrice(ethers.utils.formatEther(artistTotalSale)) : t('profilePage.noData')}
              </Typography>
            </Box>
          )}
          <Box sx={sx.bio}>
            {/* @ts-ignore */}
            <CollapsibleParagraph
              lines={4}
              locales={{ expand: `${t('common.readMore')}`, collapse: `${t('common.readLess')}` }}
            >
              {bio || ''}
            </CollapsibleParagraph>
          </Box>

          {isMobileScreen && (
            <Box sx={sx.buttonContainer}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                startIcon={<PlayArrowIcon />}
                key="podcast"
                disabled={!settings?.podcast?.length}
                onClick={handleOpenPodcasts}
              >
                {t('profilePage.podcastsHeading')}
              </Button>

              <DownBaseModal
                isShowing={isPodcastsOpen}
                handleOpen={handleOpenPodcasts}
                handleClose={handleOpenPodcasts}
              >
                <ContentModal
                  handleClose={handleClosePodcasts}
                  content={settings?.podcast}
                  contentModalFlow={contentModalFlow}
                />
              </DownBaseModal>

              <DownBaseModal isShowing={isVideosOpen} handleOpen={handleOpenVideos} handleClose={handleOpenVideos}>
                <ContentModal
                  handleClose={handleCloseVideos}
                  content={settings?.video}
                  contentModalFlow={contentModalFlow}
                />
              </DownBaseModal>

              <Button
                variant="contained"
                color="secondary"
                size="medium"
                startIcon={<VideocamIcon />}
                key="video"
                disabled={!settings?.video?.length}
                onClick={handleOpenVideos}
              >
                {t('profilePage.videosHeading')}
              </Button>
            </Box>
          )}

          <Box sx={sx.socialLinks}>
            {inputsSocialNetworksConfig.map(
              ({ label, icon }) =>
                !!props.socialLinksMap?.get(`${label}`) && (
                  <Link
                    key={label}
                    sx={sx.linkLabel}
                    target={'_blank'}
                    href={props.socialLinksMap?.get(`${label}`)}
                    rel="noreferrer"
                  >
                    {icon}
                  </Link>
                ),
            )}
          </Box>

          {props.hasRightsToEdit && (
            <Button variant="outlined" size="large" sx={{ mb: 8 }} onClick={props.onClick}>
              {t('profilePage.editProfileBtn')}
            </Button>
          )}

          {!!settings?.shopifyCollections?.length && <ArtistShopifyMerch collections={settings?.shopifyCollections} />}
        </Box>

        <Box sx={sx.artsWrapper}>
          <ProfileTabs isArtistProfile />
        </Box>
      </Box>

      {!isMobileScreen && (
        <Box sx={sx.videos}>
          <Box sx={sx.videoBlock}>
            <Typography fontWeight="bold" fontSize="lg">
              {t('profilePage.podcastsHeading')}
            </Typography>
            <LinkList items={settings?.podcast || []} />
          </Box>
          <Box sx={sx.videoBlock}>
            <Typography fontWeight="bold" fontSize="lg">
              {t('profilePage.videosHeading')}
            </Typography>
            <LinkList items={settings?.video || []} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  pageWrapper: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    pl: 3,
    pr: 3,
    [theme.breakpoints.up('lg')]: {
      p: 0,
    },
  }),
  cover: (theme) => ({
    position: 'relative',
    height: (theme) => theme.spacing(17.25),
    width: '100%',
    mb: 7,
    '& #cover-image': {
      borderRadius: '10px',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      height: (theme) => theme.spacing(23.5),
      borderRadius: 2,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  blurCover: (theme) => ({
    position: 'relative',
    height: (theme) => theme.spacing(17.25),
    width: '100%',
    mb: 7,
    transition: 'all 500ms ease-in-out',
    '& .MuiButton-root': {
      display: 'none',
      transition: 'display 800ms ease-in-out',
    },
    '& .MuiIconButton-root': {
      position: 'absolute',
      bottom: 20,
      right: 20,
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      height: (theme) => theme.spacing(23.5),
      borderRadius: 2,
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover #cover-image': {
        filter: 'opacity(0.6)',
      },
      '&:hover .MuiButton-root': {
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
  }),
  avatar: (theme) => ({
    position: 'absolute',
    bottom: '-50%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    height: (theme) => theme.spacing(12),
    width: (theme) => theme.spacing(12),
    border: (theme) => `2px solid ${theme.palette.background.default}`,
    [theme.breakpoints.up('lg')]: {
      left: 150,
      height: (theme) => theme.spacing(17.5),
      width: (theme) => theme.spacing(17.5),
      transform: 'translate(-50%, -50%)',
    },
  }),
  uploadCover: (theme) => ({
    bgcolor: 'background.default',
    opacity: 0.8,
    backdropFilter: 'blur(5px)',
    borderRadius: 1,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  }),
  content: (theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      px: '80px',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 14,
    },
  }),
  infoWrapper: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
    },
  }),
  artsWrapper: {
    width: '100%',
  },
  label: {
    height: 20,
    borderRadius: 3,
    textAlign: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'grey.200',
    textTransform: 'capitalize',
    fontSize: '12px',
    pl: 1.6,
    pr: 1.6,
    mb: 2.5,
  },
  marketValue: (theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mb: 2.5,
    '& span:nth-of-type(1)': {
      ml: 1.5,
    },
    '& span:nth-of-type(2)': {
      ml: 3,
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  }),
  artistTotalSale: { maxWidth: 100 },
  bio: (theme) => ({
    width: '100%',
    mb: 2.5,
    lineHeight: '19.6px',
    textAlign: 'center',
    fontSize: '14px',
    '& > *': {
      width: '100%',
    },
    '& a': {
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      '& span': {
        textAlign: 'left',
        justifyContent: 'flex-start',
        '& a': {
          width: '100%',
          textAlign: 'left',
        },
      },
    },
  }),
  buttonContainer: {
    display: 'flex',
    gap: 2,
    mb: 1,
  },
  socialLinks: {
    display: 'flex',
    flexWrap: 'no-wrap',
    gap: 1,
    justifyContent: 'space-between',
    mt: 2,
    mb: 4,
  },
  linkLabel: {
    color: (theme) => theme.palette.primary.main,
    '& svg': {
      '&:hover': {
        fill: (theme) => theme.palette.primary.light,
      },
    },
  },
  contentLinks: (theme) => ({
    color: 'grey.150',
    bgcolor: 'grey.200',
    width: '100%',
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column',
    mb: 1,
    gap: 3,
    pl: 4,
    pr: 4,
    pb: 3,
    pt: 3,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }),
  contentLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: 'grey.600',
      alignSelf: 'center',
      mr: 1,
    },
    '& > span:hover': {
      color: 'accent.main',
    },
  },
  videos: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    m: 3,
  },
  videoBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    borderRadius: '5px',
    p: '20px 30px',
    bgcolor: (theme) => theme.palette.grey[100],
  },
};
