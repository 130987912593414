import React, { useEffect } from 'react';
import { Box, Container, SxProps, Typography } from '@mui/material';
import { Footer } from '../../components/Footer/Footer';
import { BaseLayout } from '../../components/BaseLayout/BaseLayout';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';
import useCreateEditArtReducer, { CreateArtContext, initialState, steps } from './useCreateEditArtReducer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../app-settings';
import CurrentStep from './CurrentStep';
import Title from './Title';
import Description from './Description';
import { useAppState } from '../../contexts/appState/AppContext';
import { Loader } from '../../components/UiKit/Loader/Loader';
import { useApiContext } from '../../contexts/ApiContext';
import { commonSx as sx } from './styles';
import { User } from 'codegen-api/api-totemo-service/models';
import useIsMobileScreen from '../../helpers/useIsMobileScreen';
import { Header } from 'components/Header';

const CreateArtPage: React.FC = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useCreateEditArtReducer(initialState);
  const { currentUser } = useAppState();
  const { userRoles: userRolesApi, users: userApi } = useApiContext();
  const { wallet } = useParams<{ wallet: string }>();
  const navigate = useNavigate();
  const isMobileScreen = useIsMobileScreen();

  useEffect(() => {
    if (!wallet || !currentUser) {
      return;
    }

    const initialize = async () => {
      try {
        dispatch({ type: 'LOADING', payload: true });

        const { data: roles } = await userRolesApi.userRolesControllerFilterWalletByPmWallet([wallet]);
        const { data: user } = await userApi.userControllerFindOneByWalletOrFail(wallet);

        if (!roles.some((w: string) => w === wallet)) {
          navigate(routes.adminPanel);
          return;
        }

        dispatch({ type: 'INITIALIZED', payload: user as User });
      } catch (e) {
        navigate(routes.adminPanel);
        console.error(e);
      }
    };
    initialize().then();
  }, [wallet, currentUser, userRolesApi, navigate, dispatch, userApi]);

  return (
    <CreateArtContext.Provider value={{ state, dispatch }}>
      <BaseLayout>
        <Container sx={sx.container}>
          <Header />
          {state.currentStep !== 'CHOOSE_ART_TYPE' && (
            <Box
              sx={{ ...sx.inlineFlex, ...sx.backWrapper } as SxProps<Theme>}
              onClick={() => navigate(routes.adminPanel)}
            >
              <ArrowBackIcon fontSize="small" sx={sx.backIcon} />
              <Typography variant="body1" sx={{ ...sx.back, ...sx.zeroIndent } as SxProps<Theme>}>
                {t('pages.createArt.backToAdminPanel')}
              </Typography>
            </Box>
          )}

          <Box sx={{ ...sx.inlineFlex, ...sx.titleWrapper, ...sx.between } as SxProps<Theme>}>
            <Typography variant="h2" sx={sx.zeroIndent}>
              <Title />
            </Typography>
            {
              isMobileScreen && (
                <>
                  {
                    state.currentStep !== 'CHOOSE_ART_TYPE' && (
                      <Typography variant="h3" color="primary.main" sx={sx.zeroIndent}>
                        {steps[state.currentStep]}/2
                      </Typography>
                    )
                  }
                </>
              )
            }
          </Box>

          {state.currentStep !== 'FILL_SALE_INFO' && (
            <Typography variant="body2" color="text.secondary" sx={sx.description}>
              <Description />
            </Typography>
          )}
          <CurrentStep />
        </Container>

        <Footer />

        {state.isLoading && (
          <Box sx={sx.loader}>
            <Loader />
          </Box>
        )}
      </BaseLayout>
    </CreateArtContext.Provider>
  );
};

export default CreateArtPage;
