import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useUpdateUserInfoValidationSchema = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return Yup.object().shape({
      fullName: Yup.string()
        .trim()
        .max(90, t('forms.errors.fieldMaxLength', { length: 90 })),
      settings: Yup.object().shape({
        social: Yup.object().shape({
          website: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          instagram: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          twitter: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          facebook: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          tiktok: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          discord: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          snapchat: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          youtube: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
          clubhouse: Yup.string()
            .trim()
            .url(t('forms.errors.urlFormat'))
            .max(255, t('forms.errors.fieldMaxLength', { length: 255 })),
        }),
      }),
    });
  }, [t]);
};
