import React from 'react';
import { Box } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';

export const NonMovableContainer: React.FC<{ sx: SxProps<Theme>, children?: React.ReactNode }> = React.memo(({
  sx,
  children,
}) => {
  return (
    <Box sx={sx} onTouchStart={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
      <>{children}</>
    </Box>
  );
});
