import React from 'react';
import { useTranslation } from 'react-i18next';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { ArtArtStatusEnum, Auction } from 'codegen-api/api-totemo-service/models';
import { Box, Button } from '@mui/material';
import { EMPTY_USER, useAppState } from 'contexts/appState/AppContext';
import { canFinalizeAuction, isActiveAuction } from 'pages/Art/utils/auction';
import { isAuction } from 'helpers/isAuction';
import { useNavigate } from 'react-router-dom';
import { routes } from 'app-settings';
import { ArtActionButtonAdmin } from './ArtActionButtonAdmin';
import { ArtActionButtonSx as sx } from './ArtActionStyles';
import { useHasRole } from 'components/RequireRole/RequireRole';
import { EmailNotVerified } from 'components/Modal/EmailNotVerified/EmailNotVerified';

export const ArtActionButtonUser: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { currentUser } = useAppState();
  const { state, dispatch } = useArtDetailedView();
  const navigate = useNavigate();
  const isVisitor = useHasRole('VISITOR');
  const isPending = state.art?.artStatus === ArtArtStatusEnum.PENDING;
  const isPublished = state.art?.artStatus === ArtArtStatusEnum.PUBLISHED;
  const isNotForSale = state.art?.artStatus === ArtArtStatusEnum.NOTFORSALE;
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = React.useState<boolean>(false);

  if (!state.art) return null;

  const ownerWallet = state.art.owners[0]?.ownerWallet;
  if (isNotForSale && ownerWallet === currentUser.wallet) {
    return (
      <Box sx={sx.wrapper}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => navigate(`${routes.secondarySale}/${state.art!.id}`)}
        >
          {t('artPage.sellArt')}
        </Button>
      </Box>
    );
  }

  if (!isPublished && !isPending) return null;

  const isAuctionArt = isAuction(state.saleData);

  const web3Authorized = !!currentUser.wallet && currentUser.wallet !== EMPTY_USER.wallet;
  const isAuthorized = web3Authorized || currentUser.email;

  const handleOpenDrawer = () => {
    if (!isAuthorized) {
      navigate(routes.connect);
    } else if (!web3Authorized) {
      // web 2 authorized
      if (!isVisitor) {
        setIsVerifyEmailModalOpen(true);
      } else {
        navigate(routes.connectWallet);
      }
    } else {
      dispatch({ type: 'SHOW_DRAWER' });
    }
  };

  if (isPending) {
    return (
      <Box sx={sx.wrapper}>
        <Button fullWidth variant="contained" color="secondary">
          {t('artPage.pending')}
        </Button>
      </Box>
    );
  }

  if (ownerWallet === currentUser.wallet) {
    return <ArtActionButtonAdmin />;
  }

  const isBuyNowStarted =
    state.saleData && 'startMarketDate' in state.saleData && state.saleData.startMarketDate * 1000 < Date.now();

  if (!isAuctionArt && isBuyNowStarted) {
    return (
      <>
        <Box sx={sx.wrapper}>
          <Button fullWidth onClick={handleOpenDrawer} variant="contained" color="primary">
            {t('artPage.buyNow')}
          </Button>
        </Box>
        <EmailNotVerified open={isVerifyEmailModalOpen} handleClose={() => setIsVerifyEmailModalOpen(false)} />
      </>
    );
  }

  const auction: Auction = state.saleData as Auction;
  const isActive = isActiveAuction(auction);
  const canFinalize = canFinalizeAuction(auction, currentUser.wallet);

  if (isActive) {
    return (
      <>
        <Box sx={sx.wrapper}>
          <Button fullWidth onClick={handleOpenDrawer} variant="contained" color="primary">
            {t('artPage.placeBidBtn')}
          </Button>
        </Box>
        <EmailNotVerified open={isVerifyEmailModalOpen} handleClose={() => setIsVerifyEmailModalOpen(false)} />
      </>
    );
  }

  if (canFinalize) {
    return (
      <Box sx={sx.wrapper}>
        <Button fullWidth onClick={handleOpenDrawer} variant="contained" color="secondary">
          {t('artPage.finalCheckoutBtn')}
        </Button>
      </Box>
    );
  }

  return null;
});
