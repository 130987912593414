import { User } from 'codegen-api/api-totemo-service/models';
import { useMemo } from 'react';
import { isUserHasRole } from 'components/RequireRole/RequireRole';

export function useHasRightsToEdit(currentUser: User, wallet: string | undefined) {
  return useMemo(() => {
    // artist has rights to edit own profile
    if (isUserHasRole(currentUser, 'ARTIST') && currentUser.wallet === wallet) {
      return true;
    }
    // collector has rights to edit own profile
    if (isUserHasRole(currentUser, 'COLLECTOR') && currentUser.wallet === wallet) {
      return true;
    }
    // PM of artist has rights to edit profile
    if (isUserHasRole(currentUser, 'PM', wallet)) {
      return true;
    }
    // superadmin and their page
    if (isUserHasRole(currentUser, 'SUPERADMIN') && currentUser.wallet === wallet) {
      return true;
    }
  }, [currentUser, wallet]);
}
