import { useEffect, useState } from 'react';
import { Art } from '../../codegen-api/api-totemo-service/models';
import { useAppState } from '../../contexts/appState/AppContext';
import { useApiContext } from '../../contexts/ApiContext';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../app-settings';
import useCreateEditArtReducer, { State } from './useCreateEditArtReducer';
import { addDays } from 'date-fns';

const useEditArtForm = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [art, setArt] = useState<Art | undefined>();

  const { currentUser } = useAppState();
  const navigate = useNavigate();

  const { artId } = useParams<{ artId: string }>();

  const { userRoles: userRolesApi, art: artApi } = useApiContext();

  const [state, dispatch] = useCreateEditArtReducer({
    currentStep: 'FILL_ART_INFO',
    isEdit: true,
    isLoading: true,
  });

  useEffect(() => {
    if (!art) {
      return;
    }

    const { primarySale, secondarySale } = JSON.parse(art.moneyDistribution);

    const payload: State = {
      currentStep: 'FILL_ART_INFO',
      type: art.tokenType === 'ERC721' ? 'SINGLE' : 'MULTIPLE',
      isEdit: true,
      isLoading: false,
      artist: art.author,
      art,
      artInfoForm: {
        title: art.name,
        description: art.description,
        location: art.location,
        files: [],
        tags: art.tags,
      },
      saleInfoForm: {
        price: 0.001,
        startingPrice: 0.001,
        startDateType: 'immediately',
        saleType: 'buy',
        startDate: new Date(),
        endDate: addDays(new Date(), 1),
        copies: art.amount,
        primaryArtistPercentage: primarySale.artistPercentage,
        primaryTotemoPercentage: primarySale.totemoPercentage,
        primaryServiceFee: primarySale.servicePercentage,
        includeOptionalAddress: !!primarySale.address,
        primaryAddress: primarySale.address || '',
        primaryAddressPercentage: primarySale.addressPercentage || 0,
        secondarySellerPercentage: secondarySale.sellerPercentage,
        secondaryArtistPercentage: secondarySale.artistPercentage,
        secondaryTotemoPercentage: secondarySale.totemoPercentage,
        secondaryServiceFee: secondarySale.servicePercentage,
        isGift: secondarySale.isGift,
      },
    };
    dispatch({ type: 'UPDATE_INITIAL_EDIT', payload });
  }, [art, dispatch]);

  useEffect(() => {
    if (!artId || !currentUser || isInitialized) {
      return;
    }

    const initialize = async () => {
      try {
        const { data: art } = await artApi.artControllerFindOneOrFail(artId);
        const { data: userRoles } = await userRolesApi.userRolesControllerFilterWalletByPmWallet([art.authorWallet]);

        if (!userRoles.some((w: string) => w === art.authorWallet) || art.artStatus !== 'DRAFT') {
          navigate(routes.adminPanel);
          return;
        }

        setArt(art);
        setIsInitialized(true);
      } catch (e) {
        navigate(routes.adminPanel);
      }
    };

    initialize().then();
  }, [currentUser, userRolesApi, artApi, navigate, isInitialized, artId]);

  return { state, dispatch, isInitialized };
};

export default useEditArtForm;
