import React, { useCallback, useContext, useMemo } from 'react';
import { useAccount, useNetwork, useSignMessage } from 'wagmi';
import { AxiosError } from 'axios';
import { useWeb3Modal } from '@web3modal/react';
import { defaultChain } from 'app-settings';

export interface IWC2Context {
  isConnected: boolean;

  makeSignature(updateWallet?: boolean): Promise<string | AxiosError>;

  openW3M: () => void;
  isW3MOpen: boolean;
  isWrongChain: boolean;
}

export const WC2Context = React.createContext<IWC2Context | undefined>(undefined);

interface WC2ProviderProps {
  allowedChainId: number;
  getNoncePhrase: (wallet: string, updateWallet?: boolean) => Promise<number | undefined>;
}

const WC2Provider = ({ children, getNoncePhrase, allowedChainId }: React.PropsWithChildren<WC2ProviderProps>) => {
  const { open: openWeb3Modal, isOpen: isW3MOpen, setDefaultChain } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const { chain } = useNetwork();

  const isWrongChain = useMemo(() => {
    if (!isConnected) {
      return false;
    }

    return !!allowedChainId && chain?.id !== allowedChainId;
  }, [chain, allowedChainId, isConnected]);

  const openW3M = useCallback(async () => {
    setDefaultChain(defaultChain);
    await openWeb3Modal();
  }, [openWeb3Modal, setDefaultChain]);

  const makeSignature = useCallback(
    async (updateWallet?: boolean): Promise<string | AxiosError> => {
      if (!address) {
        return ''; // TODO: return error
      }
      let nonce: number;
      try {
        nonce = (await getNoncePhrase(address, updateWallet)) as number;
      } catch (e) {
        throw e as AxiosError;
      }

      const message = `I'm use my nonce ${nonce}`;
      return signMessageAsync({ message });
    },
    [signMessageAsync, address, getNoncePhrase],
  );

  const value: IWC2Context = {
    isConnected,
    makeSignature,
    openW3M,
    isW3MOpen,
    isWrongChain,
  };

  return <WC2Context.Provider value={value}>{children}</WC2Context.Provider>;
};

export function useWC2Context(): IWC2Context {
  const context = useContext(WC2Context);

  if (typeof context === 'undefined') {
    throw new Error('useWC2Context must be used within a WC2Provider');
  }

  return context;
}

export default WC2Provider;
