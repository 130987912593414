import { FC } from 'react';
import { useEndDateDistance } from 'hooks/useEndDateDistance';
import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface Props {
  endDateTimestamp: number;
}

export const AuctionTimer: FC<Props> = ({ endDateTimestamp }: Props) => {
  const { t } = useTranslation();
  const distance = useEndDateDistance(endDateTimestamp) as string;

  return (
    <Box sx={sx.wrapper}>
      <Typography variant="paragraphSmall" color="text.primary" fontWeight={600}>
        {distance}
      </Typography>
      <Typography variant="paragraphSmall" color="text.primary" ml={0.8}>
        {t('artPage.left')}
      </Typography>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  wrapper: theme => ({
    position: 'absolute',
    top: 294,
    left: 10,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    px: 1.5,
    py: 0.6,
    borderRadius: 0.6,
    '&:before': {
      display: 'inline-block',
      bgcolor: 'accent.main',
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      content: '""',
      verticalAlign: 'middle',
      mr: 1,
    },
    [theme.breakpoints.up('lg')]: {
      top: 256,
    },
  }),

};
