import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { ReactComponent as Timer2Icon } from 'components/Icons/timer_2_line.svg';

export const ArtAuctionProcessing: React.FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Box sx={sx.root}>
      <Typography color={'inherit'} fontWeight={600}>
        {t('artPage.processing')}
      </Typography>
      <Timer2Icon />
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'text.secondary',
    svg: {
      ml: 1.5,
      width: (theme) => theme.spacing(2.5),
      height: (theme) => theme.spacing(2.5),
    },
  },
};
