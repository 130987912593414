import React, { useCallback } from 'react';
import WC2Provider from './WC2Context';
import { useApiContext } from 'contexts/ApiContext';
import { defaultChain, wc2ProjectId, wc2chains } from '../../app-settings';
import { Chain, configureChains, createConfig, WagmiConfig } from 'wagmi';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';

export const setupWagmi = (chains: Chain[], projectId: string) => {
  const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ chains, projectId }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return {
    ethereumClient,
    projectId,
    wagmiConfig,
  };
};

const { wagmiConfig, ethereumClient, projectId: pId } = setupWagmi(wc2chains, wc2ProjectId);

const WC2LayerProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { users } = useApiContext();

  const getNoncePhrase = useCallback(
    async (wallet: string, updateWallet?: boolean) => {
      let resp;
      if (updateWallet) {
        resp = await users.userControllerGetNonceAndUpdateById(wallet);
      } else {
        resp = await users.userControllerGetNewNonce(wallet);
      }
      return resp?.data;
    },
    [users],
  );
  return (
    <WagmiConfig config={wagmiConfig}>
      <WC2Provider getNoncePhrase={getNoncePhrase} allowedChainId={defaultChain.id}>
        {children}
      </WC2Provider>
      <Web3Modal projectId={pId} ethereumClient={ethereumClient} />
    </WagmiConfig>
  );
};

export default WC2LayerProvider;
