import { useEffect, useMemo, useState } from 'react';
import { addDays } from 'date-fns';
import { createArtLocalStorage } from '../../types';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';
import { useApiContext } from 'contexts/ApiContext';
import { useCreateArtReducerContext } from '../../useCreateEditArtReducer';
import { MoneyDistribution } from 'types';

const useSaleInfoForm = (localStorageFormValues: createArtLocalStorage | null) => {
  const navigate = useNavigate();
  const [settings, setSettings] = useState<MoneyDistribution | null>(null);
  const { state, dispatch } = useCreateArtReducerContext();

  const { appSettings: appSettingsApi } = useApiContext();

  useEffect(() => {
    dispatch({ type: 'LOADING', payload: true });
    appSettingsApi
      .appSettingsControllerGetAppSettings()
      .then(({ data }) => {
        setSettings(data.moneyDistribution);
        dispatch({ type: 'LOADING', payload: false });
      })
      .catch((_e) => {
        dispatch({ type: 'LOADING', payload: false });
        navigate(routes.adminPanel);
      });
  }, [appSettingsApi, dispatch, navigate]);

  const { saleInfoForm } = (localStorageFormValues as createArtLocalStorage) || {};
  const { secondarySale, primarySale } = (settings as MoneyDistribution) || {};
  const formValues = useMemo(
    () => ({
      price: saleInfoForm?.price || 0.001,
      startingPrice: saleInfoForm?.startingPrice || 0.001,
      startDateType: saleInfoForm?.startDateType || 'immediately',
      saleType: state.type === 'MULTIPLE' ? 'buy' : saleInfoForm?.saleType || 'buy',
      startDate: saleInfoForm?.startDate ? new Date(saleInfoForm?.startDate) : new Date(),
      endDate: saleInfoForm?.endDate ? addDays(new Date(saleInfoForm?.endDate), 1) : addDays(new Date(), 1),
      copies: saleInfoForm?.copies || 2,
      primaryArtistPercentage: saleInfoForm?.primaryArtistPercentage ?? primarySale?.artistPercentage ?? 65,
      primaryTotemoPercentage: saleInfoForm?.primaryTotemoPercentage ?? primarySale?.totemoPercentage ?? 35,
      primaryServiceFee: saleInfoForm?.primaryServiceFee ?? primarySale?.servicePercentage ?? 5,
      includeOptionalAddress: !!saleInfoForm?.primaryAddress || !!primarySale?.address || false,
      primaryAddress: saleInfoForm?.primaryAddress || primarySale?.address || '',
      primaryAddressPercentage: saleInfoForm?.primaryAddressPercentage ?? primarySale?.addressPercentage ?? 0,
      secondarySellerPercentage: saleInfoForm?.secondarySellerPercentage ?? secondarySale?.sellerPercentage ?? 90,
      secondaryArtistPercentage: saleInfoForm?.secondaryArtistPercentage ?? secondarySale?.artistPercentage ?? 5,
      secondaryTotemoPercentage: saleInfoForm?.secondaryTotemoPercentage ?? secondarySale?.totemoPercentage ?? 5,
      secondaryServiceFee: saleInfoForm?.secondaryServiceFee ?? secondarySale?.servicePercentage ?? 5,
      isGift: false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localStorageFormValues, settings],
  );

  return state.isEdit ? state.saleInfoForm! : formValues;
};

export default useSaleInfoForm;
