import React from 'react';
import { useTranslation } from 'react-i18next';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { ArtArtStatusEnum, UpdateArtDtoArtStatusEnum } from 'codegen-api/api-totemo-service/models';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { useApiContext } from 'contexts/ApiContext';
import { isAuction } from 'helpers/isAuction';
import { ArtActionButtonSx as sx } from './ArtActionStyles';
import { Box } from '@mui/material';

export const ArtActionButtonAdmin: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const api = useApiContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { state, dispatch } = useArtDetailedView();

  if (!state.art) return null;

  const isPublished = state.art.artStatus === ArtArtStatusEnum.PUBLISHED;
  const isPromo = state.art.artStatus === ArtArtStatusEnum.PROMO;

  if (isPromo) {
    const handleMoveToDrafts = () => {
      const move = async () => {
        setIsLoading(true);
        await api.art.artControllerUpdate({ artStatus: UpdateArtDtoArtStatusEnum.DRAFT, id: state.art!.id });
        dispatch({ type: 'UPDATE_ART_STATUS', payload: ArtArtStatusEnum.DRAFT });
        setIsLoading(false);
      };

      move();
    };

    return (
      <Box sx={sx.wrapper}>
        <AsyncButton fullWidth isLoading={isLoading} onClick={handleMoveToDrafts} variant="contained" color="secondary">
          {t('artPage.moveToDrafts')}
        </AsyncButton>
      </Box>
    );
  }

  const isAuctionWithBids = state.saleData && 'bids' in state.saleData && state.saleData.bids.length;

  if (isPublished && !isAuctionWithBids) {
    const handleCancelSale = () => {
      const cancel = async () => {
        setIsLoading(true);
        try {
          const infoPopupType = state.saleData?.isFirstSale ? 'movedToDrafts' : 'movedNotForSale';
          const newStatus = state.saleData?.isFirstSale ? ArtArtStatusEnum.DRAFT : ArtArtStatusEnum.NOTFORSALE;

          isAuction(state.saleData)
            ? await api.auction.auctionControllerCancel(state.saleData!.id)
            : await api.buyNow.buyNowControllerCancelSinge(state.saleData!.id);

          dispatch({
            type: 'CANCEL_SUCCESS',
            payload: {
              artStatus: newStatus,
              infoPopupType,
              saleId: state.saleData!.id,
            },
          });
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      };

      cancel();
    };

    return (
      <Box sx={sx.wrapper}>
        <AsyncButton fullWidth isLoading={isLoading} onClick={handleCancelSale} variant="contained" color="secondary">
          {t(isAuction(state.saleData) ? 'artPage.cancelAuction' : 'artPage.cancelBuyNow')}
        </AsyncButton>
      </Box>
    );
  }

  return null;
});
