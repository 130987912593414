import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { BaseLayout } from 'components/BaseLayout/BaseLayout';
import Logo from 'components/Header/Logo';
import { routes } from 'app-settings';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useApiContext } from 'contexts/ApiContext';
import { useAppDispatch } from 'contexts/appState/AppContext';

export const EmailVerification: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { verificationId } = useParams<{ verificationId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { signUpLink } = useApiContext();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const verifyEmail = async (verificationId: string) => {
    try {
      setIsLoading(true);
      const newUserRole = (await signUpLink.emailConfirmationControllerActivate(verificationId)).data;
      appDispatch({ type: 'UPDATE_CURRENT_USER', payload: { role: [newUserRole] } });
      navigate(routes.home);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!verificationId) {
      return;
    }
    verifyEmail(verificationId);
  }, [verificationId]);

  return (
    <BaseLayout>
      <Box px={4}>
        <Box sx={sx.logoContainer}>
          <Logo link={routes.home} />
        </Box>
        <Typography variant="h2" textAlign="center">
          {t('emailVerification.title')}
        </Typography>
        <Box pt={3} textAlign="center">
          {isLoading && <CircularProgress color="inherit" />}
        </Box>
      </Box>
      <Box sx={sx.footer}>
        <Typography pt={4} pb={9} textAlign="center">
          {t('emailVerification.footerText')}
        </Typography>
      </Box>
    </BaseLayout>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  logoContainer: {
    my: 15,
    mb: 9,
    display: 'flex',
    justifyContent: 'center',
  },
  footer: {
    px: 2.5,
    bgcolor: 'grey.100',
    borderRadius: '15px 15px 0 0',
  },
};
