import { utils } from 'ethers';

export const getTotalSale = (value: string): string => {
  try {
    const result = utils.formatEther(value);
    return (+result).toFixed(2);
  } catch (e) {
    return value;
  }
};
