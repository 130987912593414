/* tslint:disable */
/* eslint-disable */
/**
 * Totemo Backend API
 * Totemo Backend REST API services
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateUserRoleDto } from '../models';
import { DeleteResult } from '../models';
import { FindUserRoleDto } from '../models';
import { UpdateUserRoleDto } from '../models';
import { UserRole } from '../models';
/**
 * UserRolesApi - axios parameter creator
 * @export
 */
export const UserRolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserRoleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerCreate: async (body: CreateUserRoleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userRolesControllerCreate.');
            }
            const localVarPath = `/user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array&lt;string&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerFilterWalletByPmWallet: async (body: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userRolesControllerFilterWalletByPmWallet.');
            }
            const localVarPath = `/user-roles/filter-wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindUserRoleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerFindOne: async (body: FindUserRoleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userRolesControllerFindOne.');
            }
            const localVarPath = `/user-roles/find-one`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerRemove: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userRolesControllerRemove.');
            }
            const localVarPath = `/user-roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserRoleDto} body 
         * @param {string} userWallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerUpdate: async (body: UpdateUserRoleDto, userWallet: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userRolesControllerUpdate.');
            }
            // verify required parameter 'userWallet' is not null or undefined
            if (userWallet === null || userWallet === undefined) {
                throw new RequiredError('userWallet','Required parameter userWallet was null or undefined when calling userRolesControllerUpdate.');
            }
            const localVarPath = `/user-roles/{userWallet}`
                .replace(`{${"userWallet"}}`, encodeURIComponent(String(userWallet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRolesApi - functional programming interface
 * @export
 */
export const UserRolesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserRoleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesControllerCreate(body: CreateUserRoleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRole>> {
            const localVarAxiosArgs = await UserRolesApiAxiosParamCreator(configuration).userRolesControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array&lt;string&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesControllerFilterWalletByPmWallet(body: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await UserRolesApiAxiosParamCreator(configuration).userRolesControllerFilterWalletByPmWallet(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRole>>> {
            const localVarAxiosArgs = await UserRolesApiAxiosParamCreator(configuration).userRolesControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {FindUserRoleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesControllerFindOne(body: FindUserRoleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRole>>> {
            const localVarAxiosArgs = await UserRolesApiAxiosParamCreator(configuration).userRolesControllerFindOne(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesControllerRemove(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResult>> {
            const localVarAxiosArgs = await UserRolesApiAxiosParamCreator(configuration).userRolesControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateUserRoleDto} body 
         * @param {string} userWallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesControllerUpdate(body: UpdateUserRoleDto, userWallet: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRole>> {
            const localVarAxiosArgs = await UserRolesApiAxiosParamCreator(configuration).userRolesControllerUpdate(body, userWallet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserRolesApi - factory interface
 * @export
 */
export const UserRolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateUserRoleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerCreate(body: CreateUserRoleDto, options?: any): AxiosPromise<UserRole> {
            return UserRolesApiFp(configuration).userRolesControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array&lt;string&gt;} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerFilterWalletByPmWallet(body: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return UserRolesApiFp(configuration).userRolesControllerFilterWalletByPmWallet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerFindAll(options?: any): AxiosPromise<Array<UserRole>> {
            return UserRolesApiFp(configuration).userRolesControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindUserRoleDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerFindOne(body: FindUserRoleDto, options?: any): AxiosPromise<Array<UserRole>> {
            return UserRolesApiFp(configuration).userRolesControllerFindOne(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerRemove(id: string, options?: any): AxiosPromise<DeleteResult> {
            return UserRolesApiFp(configuration).userRolesControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserRoleDto} body 
         * @param {string} userWallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesControllerUpdate(body: UpdateUserRoleDto, userWallet: string, options?: any): AxiosPromise<UserRole> {
            return UserRolesApiFp(configuration).userRolesControllerUpdate(body, userWallet, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRolesApi - object-oriented interface
 * @export
 * @class UserRolesApi
 * @extends {BaseAPI}
 */
export class UserRolesApi extends BaseAPI {
    /**
     * 
     * @param {CreateUserRoleDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesControllerCreate(body: CreateUserRoleDto, options?: any) {
        return UserRolesApiFp(this.configuration).userRolesControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {Array&lt;string&gt;} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesControllerFilterWalletByPmWallet(body: Array<string>, options?: any) {
        return UserRolesApiFp(this.configuration).userRolesControllerFilterWalletByPmWallet(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesControllerFindAll(options?: any) {
        return UserRolesApiFp(this.configuration).userRolesControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {FindUserRoleDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesControllerFindOne(body: FindUserRoleDto, options?: any) {
        return UserRolesApiFp(this.configuration).userRolesControllerFindOne(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesControllerRemove(id: string, options?: any) {
        return UserRolesApiFp(this.configuration).userRolesControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateUserRoleDto} body 
     * @param {string} userWallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesControllerUpdate(body: UpdateUserRoleDto, userWallet: string, options?: any) {
        return UserRolesApiFp(this.configuration).userRolesControllerUpdate(body, userWallet, options).then((request) => request(this.axios, this.basePath));
    }
}
