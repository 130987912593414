import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseModal, BaseModalProps } from 'components/Modal/BaseModal';
import { useLogout } from 'contexts/appState/useLogout';
import { localStorageKeys } from 'app-settings';
import { devLog } from 'helpers';

export const WalletAlreadyInUse: FC<BaseModalProps & { wallet?: string }> = ({ wallet, ...props }) => {
  const { t } = useTranslation();
  const logout = useLogout();

  const handleTryAnotherWallet: React.MouseEventHandler = (e) => {
    localStorage.removeItem(localStorageKeys.walletconnect);
    props.handleClose?.(e);
  };

  React.useEffect(() => {
    devLog(wallet);
  }, [wallet]);

  return (
    <BaseModal {...props}>
      <Box px={1} pb={3}>
        <Typography variant="h2" textAlign="center">
          {t('common.walletAlreadyInUse')}
        </Typography>
        <Typography color={'text.secondary'} mt={2.5} textAlign={'center'}>
          {t('common.walletAlreadyInUseSubtitle')}
        </Typography>
        <Box mt={2.5}>
          <Button variant="contained" color="primary" fullWidth onClick={handleTryAnotherWallet}>
            {t('common.tryAnotherWallet')}
          </Button>
        </Box>
        <Box mt={2.5}>
          <Button variant="outlined" fullWidth onClick={() => logout()}>
            {t('common.returnToSignIn')}
          </Button>
        </Box>
      </Box>
    </BaseModal>
  );
};
