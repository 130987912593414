import React from 'react';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc, routes } from 'app-settings';
import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  userRole: string;
  username: string;
  avatarUrl: string;
  wallet: string;
}

export const UserInfo = ({ userRole, avatarUrl, username, wallet }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenProfile = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, wallet: string) => {
    event.stopPropagation();
    navigate(`${routes.userProfile}/${wallet}`);
  };

  let userRoleKey;

  switch (userRole) {
    case 'artist':
      userRoleKey = 'common.roleArtist';
      break;

    case 'owner':
      userRoleKey = 'common.owner';
      break;

    default:
      userRoleKey = '';
  }

  if (!username) {
    return null;
  }

  return (
    <Box sx={sx.artistCard} onClick={(e) => handleOpenProfile(e, wallet)}>
      <Avatar sx={sx.avatar} alt={'username'} src={avatarUrl || defaultAvatarSrc} />
      <Box sx={sx.nickName}>
        <Typography sx={sx.userRole} variant="paragraphXSmall" color="text.secondary" component="p">
          {t(userRoleKey)}
        </Typography>
        <Typography variant="paragraphXSmall" color="text.primary" component="p">
          {`@${username}`}
        </Typography>
      </Box>
    </Box>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  avatar: {
    flex: '0 0 auto',
    width: (theme) => theme.spacing(3),
    height: (theme) => theme.spacing(3),
  },
  artistCard: {
    display: 'flex',
    alignItems: 'center',
    minHeight: (theme) => theme.spacing(4.1),
    bgcolor: 'background.default',
    mb: 1.8,
    flexBasis: '50%',
    '&:first-of-type': {
      ml: 2.5,
    },
  },
  nickName: {
    ml: 1.2,
  },
  userRole: {
    fontSize: '12px',
    fontWeight: 600,
    flexGrow: 1,
  },
};
