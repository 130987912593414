import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OutlinedTextInput } from 'components/UiKit/OutlinedInput/OutlinedInput';
import { useFormik } from 'formik';
import Select from 'components/UiKit/Select/Select';
import { MobileDateTimePicker } from '@mui/lab';
import { startDateOptions } from 'app-settings';
import { useSecondarySaleSingleValidation } from '../useSecondarySaleSingleValidation';
import { SecondarySaleSingleFormSwitcher } from '../SecondarySaleSingleFormSwitcher';
import { useSecondarySaleSingle } from '../SecondarySaleSingleProvider';
import { useSecondarySaleSingleByNowSubmit } from './useSecondarySaleSingleByNowSubmit';
import { useSecondarySaleMoneyDistribution } from '../../utils/useSecondarySaleMoneyDistribution';
import { SecondarySaleSubmit } from 'pages/SecondarySale/utils/SecondarySaleSubmit';
import { getSecondarySellerEarningsEth } from 'pages/CreateEditArtPage/utils';

interface FromValues {
  price: number;
  startDateType: 'immediately' | 'specific';
  startDate: Date;
}

export const SecondarySaleSingleBuyNow: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { state } = useSecondarySaleSingle();
  const validationSchema = useSecondarySaleSingleValidation();
  const moneyDistribution = useSecondarySaleMoneyDistribution(state.art);

  const handleSubmit = useSecondarySaleSingleByNowSubmit(moneyDistribution.servicePercentage);

  const formik = useFormik<FromValues>({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: handleSubmit,
  });

  const saleFee = 100 - moneyDistribution.sellerPercentage;
  const earnings = getSecondarySellerEarningsEth(formik.values.price, moneyDistribution.sellerPercentage);

  return (
    <>
      <SecondarySaleSingleFormSwitcher />

      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit} noValidate>
        <Box>
          <Typography variant={'h4'} display={'flex'} mb={2} mt={5}>
            {t('secondarySale.financeConditions')}
          </Typography>

          <OutlinedTextInput
            name="price"
            id="price"
            defaultValue={formik.initialValues.price}
            type={'number'}
            label={t('secondarySale.price')}
            error={!!(formik.touched.price && formik.errors.price)}
            helperText={formik.touched.price && t(formik.errors.price as string)}
            postfix={
              <Typography variant="body1" color="grey.500">
                ETH
              </Typography>
            }
          />

          <Box mt={2} display={'flex'} justifyContent={'space-between'}>
            <Typography variant="paragraphMedium" color="text.secondary">
              {t('secondarySale.saleFee')}
            </Typography>
            <Typography variant="paragraphMedium" fontWeight={600}>{`${saleFee}%`}</Typography>
          </Box>
          <Box mt={1.25} display={'flex'} justifyContent={'space-between'}>
            <Typography variant="paragraphMedium" color="text.secondary">
              {t('secondarySale.youWillGet')}
            </Typography>
            <Typography variant="paragraphMedium" fontWeight={600}>{`${earnings} ETH`}</Typography>
          </Box>
        </Box>

        <Box mt={5}>
          <Typography variant={'h4'} display={'flex'} mb={2}>
            {t('secondarySale.startDate')}
          </Typography>

          <Box>
            <Select
              items={startDateOptions}
              name="startDateType"
              value={formik.values.startDateType}
              onChange={(e) => formik.setFieldValue('startDateType', e.target.value)}
            />

            {formik.values.startDateType === 'specific' && (
              <Box mt={2.5}>
                <MobileDateTimePicker
                  ampm={false}
                  label={t('secondarySale.startDate')}
                  value={formik.values.startDate}
                  onChange={(e: Date | null) => formik.setFieldValue('startDate', e)}
                  disablePast={true}
                  inputFormat="yyyy-MM-dd H:mm"
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            )}
          </Box>

          <SecondarySaleSubmit isPublishing={state.isPublishing} artId={state.art.id} />
        </Box>
      </form>
    </>
  );
});

const getInitialValues = () => ({
  price: 0.001,
  startDate: new Date(),
  startDateType: startDateOptions[0].value,
});
