import React from 'react';
import { useCreateArtReducerContext } from './useCreateEditArtReducer';
import { useTranslation } from 'react-i18next';

const Description: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useCreateArtReducerContext();

  switch (state.currentStep) {
    case 'FILL_ART_INFO': {
      return t(`@${state.artist?.username}${state.artist?.fullName ? ' / ' + state.artist?.fullName : ''}`);
    }
    case 'FILL_SALE_INFO':
      return t('');
    default:
      return t('pages.createArt.createArtNote');
  }
};

export default Description;
