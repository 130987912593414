import React from 'react';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import AsyncButton from 'components/UiKit/AsyncButton/AsyncButton';
import { useLogin } from 'contexts/appState/useLogin';
import { routes } from 'app-settings';
import { useNavigate } from 'react-router-dom';
import { ButtonProps } from '@mui/material/Button/Button';
import { useWeb3Modal } from '@web3modal/react';
import { useWC2Context } from 'contexts/WC2Layer/WC2Context';

type Props = ButtonProps & {
  onError?: (e: any) => void;
  connectTKey?: string;
  updateWallet?: boolean;
  onManualConnect?: (wallet: string) => void;
  showLogo?: boolean;
};

export const LoginBtn: React.FC<Props> = React.memo(
  ({
    sx: externalSx,
    onError,
    connectTKey = 'common.connect',
    variant = 'contained',
    updateWallet,
    onManualConnect,
    showLogo,
    ...props
  }) => {
    const { t } = useTranslation();
    const login = useLogin();
    const [isAuthorizing, setIsAuthorizing] = React.useState<boolean>(false);
    const handleAlreadyExistsError = useAlreadyExists();

    const { isConnected } = useWC2Context();

    const { open: openW3M } = useWeb3Modal();

    const handleClickLogin = async () => {
      if (!isConnected) {
        return openW3M();
      }
      setIsAuthorizing(true);
      try {
        await login(updateWallet);
      } catch (e: any) {
        onError && onError(e);
        handleAlreadyExistsError(e);
      }
      setIsAuthorizing(false);
    };

    const sxStyles = externalSx ? ({ ...sx.btn, ...externalSx } as SxProps<Theme>) : sx.btn;

    return (
      <AsyncButton {...props} isLoading={isAuthorizing} onClick={handleClickLogin} variant={variant} sx={sxStyles}>
        {showLogo && <img alt="logo" src="/assets/icons/metamask.svg" width="34px" />}
        {t(isConnected ? connectTKey : 'common.connectWallet')}
      </AsyncButton>
    );
  },
);

const sx: Record<string, SxProps<Theme>> = {
  btn: {
    width: '260px',
    display: 'flex',
    gap: '12px',
    '&:hover': {
      color: 'white',
    },
  },
};

const useAlreadyExists = () => {
  const navigate = useNavigate();
  return React.useCallback(
    (e: any) => {
      if (e.response?.data?.message === 'User does not exist') {
        const splitUrl = (e.request?.responseURL || '/').split('/');
        const wallet = splitUrl[splitUrl.length - 2];
        navigate(`${routes.wrongWallet}/${wallet}`);
      }
    },
    [navigate],
  );
};
