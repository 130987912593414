import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { format } from 'date-fns';

export const ArtSaleNotStarted: React.FC<{ startDateTimestamp: number }> = React.memo(({ startDateTimestamp }) => {
  const { t } = useTranslation();

  return (
    <Box sx={sx.root}>
      <Typography color={'text.secondary'} fontWeight={600}>
        {t('artPage.startDate')}
      </Typography>
      <Typography color={'primary.main'} fontWeight={600} fontSize={'18px'}>
        {format(startDateTimestamp, 'd MMMM H:mm')}
      </Typography>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  root: {
    mt: 2.5,
    bgcolor: 'grey.100',
    borderRadius: '5px',
    p: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
