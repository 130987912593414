import React from 'react';
import { Box, CardMedia, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';
import { Loader } from 'components/UiKit/Loader/Loader';

export type DataUrl = { data: string | ArrayBuffer | null; type: 'video' | 'image' };

type Prop = {
  index: number;
  url: DataUrl;
  handleClearImg: (i: number) => void;
};

const Image: React.FC<Prop> = ({ index, url, handleClearImg }) => {
  return (
    <React.Fragment>
      {url.type === 'image' ? (
        <Box
          style={{
            backgroundImage: `url(${url.data})`,
          }}
          sx={sx.media}
        />
      ) : (
        <Box>
          <Box
            position={'absolute'}
            sx={sx.video}
            width={'100%'}
            height={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Loader />
          </Box>
          <Box position={'relative'} zIndex={1}>
            <CardMedia component="video" sx={sx.media} src={url.data!.toString()} autoPlay loop muted playsInline />
          </Box>
        </Box>
      )}
      <Box sx={sx.closeWrapper} onClick={() => handleClearImg(index)}>
        <CloseIcon />
      </Box>
    </React.Fragment>
  );
};

export default Image;

const sx: Record<string, SxProps<Theme>> = {
  media: theme => ({
    height: '130px',
    width: '225px',
    borderRadius: '5px',
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('lg')]: {
      height: '290px',
      width: '490px',
    },
  }),
  closeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '40px',
    margin: '0 0 0 20px',
    borderRadius: '5px',
    color: '#000',
    bgcolor: 'grey.300',
  },
};
