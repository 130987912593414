import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiContext } from 'contexts/ApiContext';
import { useUserProfileReducer } from '../State/useProfileReducer';
import { useAppDispatch, useAppState } from 'contexts/appState/AppContext';
import { ProfilePageWrapper } from '../ProfilePageWrapper';
import { defaultCoverSrc, routes } from 'app-settings';
import { Loader } from 'components/UiKit/Loader/Loader';
import { ArtistProfile } from './ProfileView/ArtistProfile';
import { UserProfile } from './ProfileView/UserProfile';
import { isUserHasRole } from 'components/RequireRole/RequireRole';
import { InputType, SocialNetworks, Wallet } from '../types';
import { useHasRightsToEdit } from '../hooks/useHasRightsToEdit';

export const ProfilePage = () => {
  const navigate = useNavigate();
  const { users: usersApi } = useApiContext();
  const { currentUser } = useAppState();
  const appDispatch = useAppDispatch();
  const [state, dispatch] = useUserProfileReducer();
  const { wallet } = useParams<Wallet>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isCurrentUser = useMemo(() => {
    return currentUser.wallet === state.userInfo.wallet;
  }, [currentUser, state.userInfo]);

  const redirectToEditPage = useCallback(() => {
    navigate(`${routes.userProfile}/${wallet}/edit`);
  }, [navigate, wallet]);

  React.useEffect(() => {
    if (!wallet) {
      return;
    }

    const fetchUserInfo = async () => {
      try {
        //TODO: replace with single request
        const responseUser = await usersApi.userControllerFindOneByWalletOrFail(wallet);
        const fullUser = await usersApi.userControllerGetFullUser(responseUser.data.id);

        dispatch({ type: 'FETCH_USER_INFO_FULFILLED', payload: fullUser.data });
      } catch (error: any) {
        dispatch({ type: 'FETCH_USER_INFO_REJECTED', payload: error.data });
        navigate(routes.notFound);
      }
    };

    fetchUserInfo();
  }, [dispatch, wallet, navigate, usersApi]);

  React.useEffect(() => {
    if (!isCurrentUser) return;
    const isInvitesUpdated =
      currentUser.totalInvited !== state.userInfo.totalInvited ||
      Number(currentUser.availableInvites) !== Number(state.userInfo.availableInvites);
    if (!isInvitesUpdated) return;

    appDispatch({
      type: 'UPDATE_CURRENT_USER',
      payload: { totalInvited: state.userInfo.totalInvited, availableInvites: Number(state.userInfo.availableInvites) },
    });
  }, [appDispatch, currentUser.availableInvites, currentUser.totalInvited, isCurrentUser, state.userInfo]);

  const hasRightsToEdit = useHasRightsToEdit(currentUser, wallet);

  const isArtist = useMemo(() => {
    if (!!state.userInfo?.role) {
      return isUserHasRole(state.userInfo, 'ARTIST');
    }
  }, [state.userInfo]);

  const socialLinksMap = useMemo(() => {
    if (!!state.userInfo?.settings?.social?.length) {
      return new Map<SocialNetworks, string>(
        state.userInfo?.settings?.social?.map((item: InputType) => {
          return [item?.name, item?.link];
        }),
      );
    }
  }, [state.userInfo?.settings]);

  const [isUploadCoverModalOpen, setIsUploadCoverModalOpen] = useState<boolean>(false);

  const handleUploadCoverModalClose = useCallback(() => {
    setIsUploadCoverModalOpen(false);
  }, []);

  const handleUploadCoverModalOpen = useCallback(() => {
    setIsUploadCoverModalOpen(true);
  }, []);

  const handleUploadCover = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!!event.target?.files) {
        setIsLoading(true);
        const resp = await usersApi
          .userControllerEditArtStudio(event.target?.files[0] as unknown as string, state.userInfo.wallet)
          .then((resp) => resp.data)
          .catch((_e) => setIsLoading(false));

        dispatch({ type: 'EDIT_ARTSTUDIO_BACKGROUND_FULFILLED', payload: resp });

        setIsLoading(false);

        handleUploadCoverModalClose();
      }
    },
    [dispatch, handleUploadCoverModalClose, state.userInfo.wallet, usersApi],
  );

  const background = useMemo(() => {
    return state.userInfo.settings?.background?.url || defaultCoverSrc;
  }, [state.userInfo.settings?.background?.url]);

  const profilePageProps = {
    user: state.userInfo,
    isCurrentUser,
    hasRightsToEdit,
    onClick: redirectToEditPage,
    socialLinksMap,
    isUploadCoverModalOpen,
    handleUploadCoverModalOpen,
    handleUploadCoverModalClose,
    handleUploadCover,
    background,
  };

  return (
    <ProfilePageWrapper>
      {state.isProfileLoading || isLoading ? (
        <Loader />
      ) : isArtist ? (
        <ArtistProfile {...profilePageProps} />
      ) : (
        <UserProfile {...profilePageProps} />
      )}
    </ProfilePageWrapper>
  );
};
