import React from 'react';
import { localStorageKeys, socketNotificationTypes } from 'app-settings';
import { EMPTY_USER, useApp } from 'contexts/appState/AppContext';
import { sendSocketEvent, useApiContext } from 'contexts/ApiContext';
import { useAuthInterceptorsEffect } from './useAuthInterceptorsEffect';
import { useWeb3ProviderEventsHandler } from './useWeb3ProviderEventsHandler';

export const AppEffects: React.FC = () => {
  const [state, dispatch] = useApp();
  const { users, socket } = useApiContext();

  useAuthInterceptorsEffect();
  useWeb3ProviderEventsHandler();

  React.useEffect(() => {
    const isAuthorized = state.currentUser.wallet !== EMPTY_USER.wallet || state.currentUser.email;
    if (isAuthorized) {
      try {
        localStorage.setItem(localStorageKeys.profile, JSON.stringify(state.currentUser));
      } catch (e) {
      }
    }
  }, [state.currentUser, users, dispatch]);

  React.useEffect(() => {
    const actualizeData = async () => {
      if (state.currentUser.wallet === EMPTY_USER.wallet) return;

      state.currentUser.wallet &&
      sendSocketEvent({ name: 'joinUserRoom', data: state.currentUser.wallet.toLowerCase() });

      try {
        const user = await users.userControllerGetFullUser(state.currentUser.id);
        dispatch({ type: 'SET_CURRENT_USER', payload: user.data });
      } catch (e) {
      }
    };

    actualizeData();
  }, [state.currentUser.wallet, users, socket, dispatch, state.currentUser.id]);

  React.useEffect(() => {
    if (state.currentUser.wallet === EMPTY_USER.wallet) return;

    const notificationListener = async (messageString: string) => {
      try {
        const message = JSON.parse(messageString);
        const { eventType } = message;
        const data: Record<string, any> = JSON.parse(message).data;

        if (eventType !== socketNotificationTypes.notification) return;
        const wallet = state.currentUser.wallet.toLowerCase() || '';
        const isArtistRoleGranted = data.to?.toLowerCase() === wallet;
        const isPMRoleGranted =
          data.type === 'GRANT_ROLE' && // TODO: Replace with enum from codegen after swagger fix
          data.status === 'ACCEPTED' &&
          data.offerFrom?.toLowerCase() === wallet;

        if (!isArtistRoleGranted && !isPMRoleGranted) return;

        const user = await users.userControllerGetFullUser(state.currentUser.id);
        dispatch({ type: 'SET_CURRENT_USER', payload: user.data });
      } catch (e) {
      }
    };

    socket!.on('notification', notificationListener);

    return () => {
      socket!.off('notification', notificationListener);
    };
  }, [dispatch, socket, state.currentUser.id, state.currentUser.wallet, users]);

  return null;
};
