import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import { DistanceObject, useEndDateDistance } from 'hooks/useEndDateDistance';
import { useArtDetailedView } from 'pages/Art/ArtDetailedViewContext';
import { theme } from 'styles/theme';

const DESKTOP_TIME_ENTITIES = ['days', 'hours', 'minutes', 'seconds'];

export const ArtAuctionInProgress: React.FC<{ endDateTimestamp: number }> = React.memo(({ endDateTimestamp }) => {
  const { t } = useTranslation();
  const distance = useEndDateDistance(endDateTimestamp, true);
  const { dispatch } = useArtDetailedView();


  React.useEffect(() => {
    if (distance) return;

    dispatch({ type: 'RERENDER' });
  }, [distance, dispatch]);

  return (
    <Box sx={sx.root}>
      <Typography color="accent.main" fontWeight={600} minWidth={120}>
        {t('artPage.auctionEndsIn')}
      </Typography>
      <Box display="flex" justifyContent="space-between" width="100%">
        {
          DESKTOP_TIME_ENTITIES.map(entity => (
            <Box>
              <Typography
                fontWeight={700}
                fontSize={18}
              >
                {
                  (distance as DistanceObject)?.[entity as keyof DistanceObject]
                }
              </Typography>
              <Typography color={theme.palette.grey['700']} fontSize={12}>{t(`common.time.${entity}`)}</Typography>
            </Box>
          ))
        }
      </Box>
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  root: theme => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 2,
    [theme.breakpoints.up('lg')]: {},
  }),
};
