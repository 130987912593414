import React, { FC } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import type { SxProps } from '@mui/system';
import { ReactComponent as DropDownIcon } from 'components/Icons/dropdown.svg';
import { ReactComponent as CheckIcon } from 'components/Icons/check_icon.svg';
import { Theme } from '@mui/material/styles';

interface Props {
  items: { value: string; label?: string }[];
  value: string;
  onChange?(event: SelectChangeEvent): void;
  styles?: SxProps;
}

export const DropDown: FC<Props> = ({ items, value, onChange }: Props) => {
  return (
    <>
      <FormControl>
        <Select
          sx={sx.select}
          value={value}
          onChange={onChange}
          disableUnderline
          variant="standard"
          IconComponent={DropDownIcon}
          MenuProps={{
            sx: sx.menu,
          }}
        >
          {items?.map((item) => {
            return (
              <MenuItem key={item.value} sx={sx.selectItem} value={item.value}>
                {item.label || item.value}
                {value === item.value ? <CheckIcon /> : null}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const sx: Record<string, SxProps<Theme>> = {
  select: {
    color: 'common.black',
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: 16,
    height: 32,
    '& .MuiSelect-select > svg': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      bgcolor: 'transparent',
    },
    '&.MuiInputBase-root': {
      '& .MuiSelect-icon': {
        top: 'calc(50% - 2px)',
        right: (theme) => theme.spacing(1),
      },
    },
  },
  selectItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover, &.Mui-focusVisible': {
      bgcolor: 'grey.100',
    },
    '&.MuiMenuItem-root.MuiButtonBase-root.Mui-selected': {
      bgcolor: 'grey.300',
    },
  },
  menu: {
    mt: 1,
  },
};
