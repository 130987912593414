import React, { CSSProperties } from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc, routes } from 'app-settings';
import { ReactComponent as ExternalLinkIcon } from 'components/Icons/external_link.svg';
import { format } from 'date-fns';
import { getPriceWithoutFeeETH } from 'pages/CreateEditArtPage/utils';
import type { User } from 'codegen-api/api-totemo-service/models';
import { Link } from 'react-router-dom';

interface Props {
  item: ArtHistoryRecord;
  serviceFee: number;
}

export const ArtHistoryItem: React.FC<Props> = React.memo(({ item, serviceFee }) => {
  const eventDate = React.useMemo(() => {
    return format(new Date(item.created), `MMM d, yyyy 'at' HH:mm`);
  }, [item.created]);

  const user = item.user;
  const data = item.data;
  const price = data?.bidSize || data?.price;

  const originalPrice = React.useMemo(() => {
    if (!data || !price) return null;

    return getPriceWithoutFeeETH(String(price), String(serviceFee));
  }, [data, price, serviceFee]);

  if (!data) return null;

  const tKey = `artPage.historyMessages.${item.operationType}`;

  return (
    <Box sx={sx.itemContainer}>
      {user ? (
        <Box mr={2}>
          <Link style={styles.block} to={`${routes.userProfile}/${user.wallet}`}>
            <Avatar src={user.settings?.avatar?.url || defaultAvatarSrc} sx={sx.avatar} />
          </Link>
        </Box>
      ) : null}
      <Box flex={'1 1 auto'}>
        <Trans
          i18nKey={tKey}
          components={[
            <span />,
            user ? <Link style={styles.link} to={`${routes.userProfile}/${user.wallet}`} /> : <strong />,
          ]}
          values={{
            count: data.amountOfCopies,
            price: originalPrice,
            username: user?.username ?? '',
          }}
        />
        <Typography variant={'paragraphSmall'} display={'block'} color={'text.secondary'}>
          {eventDate}
        </Typography>
      </Box>
      {data.transactionHash ? (
        <Box
          component={'a'}
          href={`${process.env.REACT_APP_ETHERSCAN}/tx/${data.transactionHash}`}
          target="_blank"
          rel="noreferrer noopener"
          sx={sx.etherscanLink}
        >
          <ExternalLinkIcon />
        </Box>
      ) : null}
    </Box>
  );
});

const sx: Record<string, SxProps<Theme>> = {
  itemContainer: {
    bgcolor: 'grey.100',
    mb: 1.25,
    p: 2,
    borderRadius: '5px',
    display: 'flex',
  },
  avatar: {
    width: (theme) => theme.spacing(3.75),
    height: (theme) => theme.spacing(3.75),
  },
  etherscanLink: {
    p: 0.25,
    alignSelf: 'center',
    color: 'grey.600',
    display: 'block',
    svg: {
      display: 'block',
    },
  },
};

const styles: Record<string, CSSProperties> = {
  link: {
    fontWeight: 600,
    textDecoration: 'none',
    color: 'inherit',
  },
  block: {
    display: 'block',
  },
};

//  TODO: replace with enum from API
export enum ArtHistoryOperationType {
  PUBLISHED_BUY_NOW = 'PUBLISHED_BUY_NOW',
  PUBLISHED_AUCTION = 'PUBLISHED_AUCTION',
  BUY_721 = 'BUY_721',
  BUY_1155 = 'BUY_1155',
  BID = 'BID',
  REVERSE_PRICE_MET = 'REVERSE_PRICE_MET',
  AUCTION_WINNER_FOUND = 'AUCTION_WINNER_FOUND',
  FINALIZE_AUCTION = 'FINALIZE_AUCTION',
  NFT_MINTED = 'NFT_MINTED',
  SALE_CANCELED = 'SALE_CANCELED',
}

export interface ArtHistoryRecord {
  id: number;
  artId: number;
  operationType: ArtHistoryOperationType;
  data: ArtHistoryRecordData;
  user?: User;
  created: string;
}

interface ArtHistoryRecordData {
  transactionHash?: string;
  username?: string;
  wallet?: string;
  amountOfCopies?: number;
  bidSize?: number;
  price?: number;
  avatar?: {
    key: string;
    url: string;
  };
}
