import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps, Theme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { defaultAvatarSrc } from 'app-settings';
import { ReactComponent as TimerIcon } from 'components/Icons/timer_line.svg';
import { useEndDateDistance } from 'hooks/useEndDateDistance';
import { useApiContext } from 'contexts/ApiContext';
import { User } from 'codegen-api/api-totemo-service/models';
import { useAppState } from 'contexts/appState/AppContext';

export const ArtAuctionNotFinalized: React.FC<{ finalizeDateTimestamp: number; winnerWallet: string }> = React.memo(
  ({ finalizeDateTimestamp, winnerWallet }) => {
    const { t } = useTranslation();
    const api = useApiContext();
    const { currentUser } = useAppState();
    const timeLeft = useEndDateDistance(finalizeDateTimestamp) as string;
    const [winner, setWinner] = React.useState<User | null>(null);

    const isCurrentUserWinner = currentUser.wallet === winnerWallet;

    React.useEffect(() => {
      const fetchWinner = async () => {
        try {
          const resp = await api.users.userControllerFindOneByWalletOrFail(winnerWallet);
          setWinner(resp.data as User);
        } catch (e) {
        }
      };
      fetchWinner();
    }, [api.users, winnerWallet]);

    const avatar = winner && winner.settings?.avatar?.url ? winner.settings.avatar.url : defaultAvatarSrc;

    return (
      <Box>
        <Box sx={sx.row}>
          <Typography fontWeight={600} color={'text.secondary'} flex={'0 0 50%'}>
            {t('artPage.auction')}
          </Typography>
          <Box sx={sx.status}>
            <Typography fontWeight={600} mr={1.25} color={'inherit'}>
              {t('artPage.auctionStatusEnded')}
            </Typography>
            <TimerIcon />
          </Box>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        {isCurrentUserWinner && (
          <Box sx={sx.row}>
            <Box sx={sx.winner}>
              <Avatar sx={sx.avatar} src={avatar} />
              <Box ml={2}>
                <Typography variant="paragraphXSmall" color="text.secondary" component={'div'}>
                  {t('artPage.winner')}
                </Typography>
                <Typography variant="paragraphSmall" fontWeight={600} component={'div'} display={'block'}>
                  {winner ? `@${winner.username}` : ''}
                </Typography>
              </Box>
            </Box>
            <Box sx={sx.timeLeft}>
              <Typography variant="paragraphXSmall" color="text.secondary" component={'div'}>
                {t('artPage.completeYourCheck')}
              </Typography>
              <Typography variant="paragraphSmall" fontWeight={600} component={'div'} display={'block'}>
                {timeLeft}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  },
);

const sx: Record<string, SxProps<Theme>> = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    color: 'primary.main',
    flex: '0 0 50%',
  },
  winner: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 50%',
  },
  avatar: {
    flex: '0 0 auto',
    width: (theme) => theme.spacing(3.75),
    height: (theme) => theme.spacing(3.75),
  },
  timeLeft: {
    flex: '0 0 50%',
  },
};
