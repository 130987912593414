import React, { useMemo } from 'react';
import { useAccount, useBalance } from 'wagmi';

export const useBalanceEth = (): string => {
  const { address } = useAccount();
  // @ts-ignore
  const { data } = useBalance({
    address,
  });

  return data?.formatted || '0';
};
