import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { User } from 'codegen-api/api-totemo-service/models';
import { defaultAvatarSrc, routes } from 'app-settings';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Avatar, Box, Container, SxProps, Typography, Link as MuiLink } from '@mui/material';
import { Theme } from '@mui/system';
import capitalize from 'lodash/capitalize';
import { WalletHex } from 'components/UiKit/WalletHex/WalletHex';
import { ReactComponent as ForwardIcon } from 'components/Icons/forward.svg';
import { AdminPanelUsersManageBtn } from './AdminPanelUsersManage/AdminPanelUsersManageBtn';

const UserInfo: React.FC<{ user?: User }> = ({ user }) => {
  const { t } = useTranslation();

  if (!user) {
    return (
      <Container sx={sx.root}>
        <Typography sx={{ color: 'grey.600' }}>{t('adminPanel.noUser')}</Typography>
      </Container>
    );
  }

  return (
    <Box sx={sx.root}>
      <Avatar sx={sx.avatar} alt={user.username} src={user.settings?.avatar?.url || defaultAvatarSrc} />
      <Typography fontWeight={400} variant="paragraphSmall">
        {capitalize(user?.role?.[0]?.role) || 'Guest'}
      </Typography>
      <Box sx={sx.username}>
        <Typography variant="paragraphLarge" fontWeight="bold">
          {user.username || '-'}
        </Typography>
        <Typography sx={sx.email}>{user.email || '-'}</Typography>
      </Box>
      {user.wallet ? (
        <Typography
          component={MuiLink}
          underline="none"
          href={`${process.env.REACT_APP_ETHERSCAN}/address/${user.wallet}`}
          target="_blank"
          rel="noreferrer noopener"
          variant="paragraphLarge"
          sx={sx.openUser}
        >
          <Box sx={sx.walletContainer}>
            <WalletHex hex={user.wallet} />
          </Box>
          <OpenInNewIcon sx={sx.openIcon} />
        </Typography>
      ) : null}
      {user.wallet ? (
        <Box sx={sx.profileLink}>
          <Link to={`${routes.userProfile}/${user.wallet}`}>
            <Box component="span">{t('adminPanel.viewFullProfile')}</Box>
            <ForwardIcon />
          </Link>
        </Box>
      ) : null}
      <AdminPanelUsersManageBtn user={user} />
    </Box>
  );
};

export default UserInfo;

const sx: Record<string, SxProps<Theme>> = {
  root: {
    bgcolor: 'grey.100',
    p: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    height: '400px',
    borderRadius: '10px',
    minHeight: '440px',
    minWidth: '440px',
  },
  avatar: {
    width: '110px',
    height: '110px',
  },
  openUser: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    maxWidth: '100%',
  },
  walletContainer: {
    maxWidth: (theme) => `calc(100% - ${theme.spacing(3.75)})`,
    p: 2,
  },
  openIcon: {
    width: 14,
    height: 14,
    color: 'grey.600',
  },
  email: {
    color: 'grey.600',
    display: 'block',
  },
  profileLink: {
    a: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      fontWeight: 600,
      mb: 2,
      svg: {
        ml: 0.5,
        position: 'relative',
        top: 1,
        height: (theme) => theme.spacing(2),
        width: (theme) => theme.spacing(2),
      },
    },
  },
  username: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    alignItems: 'center',
  },
};
