import React, { FC } from 'react';
import { BaseLayout } from '../../components/BaseLayout/BaseLayout';
import { MarketplaceProvider } from './State/MarketplaceContext';
import { MarketplacePageContent } from './MarketplaceContent';

export const MarketplacePage: FC = () => {
  return (
    <BaseLayout>
      <MarketplaceProvider>
        <MarketplacePageContent />
      </MarketplaceProvider>
    </BaseLayout>
  );
};
