import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { moneyDistributionSx as sx } from './MoneyDistributionStyles';
import { Loader } from 'components/UiKit/Loader/Loader';
import { getMoneyDistributionVisibleValue } from 'helpers/moneyDistributionHelpers';
import useIsMobileScreen from 'helpers/useIsMobileScreen';
import {
  AdminPanelMoneyDistributionEditSecondary,
} from './AdminPanelMoneyDistributionEdit/AdminPanelMoneyDistributionEditSecondary';
import { useApiContext } from 'contexts/ApiContext';
import { PrimarySaleData, SecondarySaleData } from 'types';

export const AdminPanelMoneyDistributionSecondary: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { appSettings } = useApiContext();
  const isMobileScreen = useIsMobileScreen();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [data, setData] = React.useState<{ primarySale: PrimarySaleData; secondarySale: SecondarySaleData } | null>(
    null,
  );

  React.useEffect(() => {
    const fetchAppSettings = async () => {
      try {
        const resp = await appSettings.appSettingsControllerGetAppSettings();
        setData(resp.data.moneyDistribution);
      } catch (e) {
      }
      setIsLoading(false);
    };
    fetchAppSettings();
  }, [appSettings]);

  if (isLoading || !data) {
    return <Loader />;
  }

  const secondarySaleData = data.secondarySale;
  const handleClose = (value?: SecondarySaleData) => {
    if (value) {
      setData({
        primarySale: data.primarySale,
        secondarySale: value,
      });
    }
    setIsEditing(false);
  };

  return (
    <Box>
      <Box sx={sx.list}>
        <Box sx={sx.item}>
          <Box sx={sx.name}>{t('adminPanel.moneyDistributionSeller')}</Box>
          <Typography component={'span'} fontWeight={600}>
            {getMoneyDistributionVisibleValue(secondarySaleData.sellerPercentage, 'sellerPercentage', true)}
          </Typography>
        </Box>
        <Box sx={sx.item}>
          <Box sx={sx.name}>{t('adminPanel.moneyDistributionArtist')}</Box>
          <Typography component={'span'} fontWeight={600}>
            {getMoneyDistributionVisibleValue(secondarySaleData.artistPercentage, 'artistPercentage', true)}
          </Typography>
        </Box>
        <Box sx={sx.item}>
          <Box sx={sx.name}>{t('adminPanel.moneyDistributionTotemo')}</Box>
          <Typography component={'span'} fontWeight={600}>
            {getMoneyDistributionVisibleValue(secondarySaleData.totemoPercentage, 'totemoPercentage', true)}
          </Typography>
        </Box>
        <Box sx={sx.item}>
          <Box sx={sx.name}>
            <div>{t('adminPanel.moneyDistributionServiceFee')}</div>
            <Typography component={'div'} display={'block'} variant={'paragraphSmall'} color={'text.secondary'}>
              {t('adminPanel.moneyDistributionServiceFeeSecondarySub')}
            </Typography>
          </Box>

          <Typography component={'span'} fontWeight={600}>
            +{getMoneyDistributionVisibleValue(secondarySaleData.servicePercentage, 'servicePercentage', true)}
          </Typography>
        </Box>
      </Box>
      <Button fullWidth={isMobileScreen} variant={'outlined'} onClick={() => setIsEditing(true)}>
        {t('common.edit')}
      </Button>
      {data && (
        <AdminPanelMoneyDistributionEditSecondary
          isOpen={isEditing}
          distributionValues={secondarySaleData}
          onClose={handleClose}
          primarySaleData={data.primarySale}
        />
      )}
    </Box>
  );
});
